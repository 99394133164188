import { Style } from "../../../game-to-app/enums";

/**
 * Defines application routes.
 * @export
 */
export enum Routes {
	About = '/about',
	AppStore = '/store',
	Help = '/help',
	HelpAssistant = '/help/assistant',
	HelpControlsMouse = '/help/controls-mouse',
	HelpControlsTouch = '/help/controls-touch',
	HelpFeedback = '/help/feedback',
	HelpLevels = '/help/levels',
	HelpOverview = '/help/overview',
	HelpPlaying = '/help/playing',
	HelpScore = '/help/score',
	HelpSettings = '/help/settings',
	HelpShop = '/help/shop',
	Levels = '/levels',
	LevelsList = '/levels/list',
	LevelsEdit = '/levels/edit/:style/:id',
	LevelsNew = '/levels/new/:style',
	Privacy = '/privacy',
	Profile = '/profile',
	QuickStart = '/quickstart',
	Root = '/',
	Settings = '/settings',
	SettingsAssistant = '/settings/assistant',
	SettingsGame = '/settings/game',
	SettingsSound = '/settings/sound',
	Shop = '/shop',
	Terms = '/terms',
}

/**
 * Defines static route query parameters.
 * @export
 */
export class RouteQueryParameters {
	private static LevelsList: string = 'style={0}';

	public static LevelsBox: string = RouteQueryParameters.LevelsList.replace('{0}', Style.Box.toFixed(0));
	public static LevelsClassic: string = RouteQueryParameters.LevelsList.replace('{0}', Style.Classic.toFixed(0));
	public static LevelsCube: string = RouteQueryParameters.LevelsList.replace('{0}', Style.Cube.toFixed(0));
	public static LevelsPane: string = RouteQueryParameters.LevelsList.replace('{0}', Style.Pane.toFixed(0));

	public static style = (style: string): string => RouteQueryParameters.LevelsList.replace('{0}', style);
}

/**
 * Defines dynamic routes.
 * @export
 */
export class DynamicsRoutes {
	/**
	 * Gets the level edit route.
	 * @param id The level id.
	 * @returns The route.
	 * @static
	 */
	public static levelsEdit = (style: number, id: string): string => {
		return Routes.LevelsEdit.replace(':style', `${style}`).replace(':id', id);
	};

	/**
	 * Gets the new level route.
	 * @param style The style for the new level.
	 * @returns The route.
	 * @static
	 */
	public static levelsNew = (style: number): string => {
		return Routes.LevelsNew.replace(':style', `${style}`);
	};
}