import { deleteDB, IDBPDatabase, openDB } from 'idb';
import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { ErrorCodes, ErrorSource } from '../error-codes';
import { Events } from '../telemetry/events';
import { ICustomProperties } from '../telemetry/interfaces';
import { Telemetry } from '../telemetry/telemetry';
import { ModelNames } from './enums';
import {
	IModelCacheManager,
	IModelMetadata,
	IModelMetadataVersioned,
	IModelUpgradeManager,
	IModelValues,
	IStore
} from './model-interfaces';
import { modelMetadata as modelMetadataV1 } from './models/v1/model';
import { modelMetadata as modelMetadataV2 } from './models/v2/model';
import { modelMetadata as modelMetadataV3 } from './models/v3/model';
import { modelMetadata as modelMetadataV4 } from './models/v4/model';
import { modelMetadata as modelMetadataV5 } from './models/v5/model';
import { modelMetadata as modelMetadataV6 } from './models/v6/model';
import { GameStatsStore } from './stores/gamestats-store';
import { LevelsStore } from './stores/levels-store';
import { ProductsStore } from './stores/products-store';
import { SavedGamesStore } from './stores/savedgames-store';
import { SettingsStore } from './stores/settings-store';
import { StoreFactory } from './stores/store-factory';
import { UserPreferencesStore } from './stores/userpreferences-store';

/**
 * The Database class provides the data store for the application.
 * @export
 * @class Database
 */
export class Database {
	// the singleton database instance
	private static _instance: Database = new Database();

	// the indexed db
	private _database: Nullable<IDBPDatabase> = null;
	private _isOpen: boolean = false;

	// all metadata versions
	private _modelMetadata: IModelMetadataVersioned[][] = [
		modelMetadataV1,
		modelMetadataV2,
		modelMetadataV3,
		modelMetadataV4,
		modelMetadataV5,
		modelMetadataV6,
	];

	// the current metadata version
	private _currentModelMetadata: Record<string, IModelMetadata<IModelValues>> =
		this._modelMetadata[Math.min(metadata.database.version, this._modelMetadata.length) - 1]
			.reduce((acc: Record<string, IModelMetadata<IModelValues>>, versioned: IModelMetadataVersioned) => {
				acc[versioned.metadata.name] = versioned.metadata;

				return acc;
			}, {});

	// the stores (initialized when database is opened)
	private _stores: Record<string, IStore> = {};

	/**
	 * Gets the game stats store.
	 * @readonly
	 */
	public static get gameStats(): GameStatsStore {
		return this._instance._stores[ModelNames.GameStats] as GameStatsStore;
	}

	/**
	 * Gets the levels store.
	 * @readonly
	 */
	public static get levels(): LevelsStore {
		return this._instance._stores[ModelNames.Levels] as LevelsStore;
	}

	/**
	 * Gets the products store.
	 * @readonly
	 */
	public static get products(): ProductsStore {
		return this._instance._stores[ModelNames.Products] as ProductsStore;
	}

	/**
	 * Gets the saved games store.
	 * @readonly
	 */
	public static get savedGames(): SavedGamesStore {
		return this._instance._stores[ModelNames.SavedGames] as SavedGamesStore;
	}

	/**
	 * Gets the settings store.
	 * @readonly
	 */
	public static get settings(): SettingsStore {
		return this._instance._stores[ModelNames.Settings] as SettingsStore;
	}

	/**
	 * Gets the user preferences store.
	 * @readonly
	 */
	public static get userPreferences(): UserPreferencesStore {
		return this._instance._stores[ModelNames.UserPreferences] as UserPreferencesStore;
	}

	/**
	 * Gets the raw database.
	 * @readonly
	 */
	public static get rawDB(): Nullable<IDBPDatabase> {
		return this._instance._database;
	}

	/**
	 * Creates an instance of Database.
	 * @memberof Database
	 */
	private constructor() {
		// singletom, instance should only be created from this class.
	}

	/**
	 * Opens the database.
	 * @param onBlocked A function called when a version upgrade is blocked by an open connection
	 * to the lower version from another app instance.
	 * @param onBlocking A function called when the current connection is blocking an upgrade
	 * initiated by another instance of the app.
	 * @param onTerminated A function called when the current connection was terminated.
	 * @returns true if the database was opened; otherwise, false.
	 */
	public static open = async (
		onBlocked: () => void,
		onBlocking: () => void,
		onTerminated: () => void): Promise<boolean> => {
		return await Database._instance.openDatabase(
			onBlocked,
			onBlocking,
			onTerminated);
	};

	/**
	 * Closes the database once all pending transactions are committed.
	 */
	public static closeDatabase = (): void => {
		Database._instance.closeDatabase();
	};

	/**
	 * Opens the database.
	 * @param onBlocked A function called when a version upgrade is blocked by an open connection
	 * to the lower version from another app instance.
	 * @param onBlocking A function called when the current connection is blocking an upgrade
	 * initiated by another instance of the app.
	 * @param onTerminated A function called when the current connection was terminated.
	 * @returns true if the database was opened; otherwise, false.
	 */
	private openDatabase = async (
		onBlocked: () => void,
		onBlocking: () => void,
		onTerminated: () => void): Promise<boolean> => {
		const targetLogicalVersion: number = metadata.database.version;
		const targetPhysicalVersion: number = metadata.database.version * 2;
		let errorOccurred: boolean = false;

		// physical version numbers will always be even.
		// any schema change will increase the physical version by 2.
		// this is because of the three migration steps outlined below. 

		if (!this._isOpen) {
			try {
				const physicalVersion = await this.getPhysicalVersion();

				if (physicalVersion > targetPhysicalVersion) {
					throw new Error(
						`Detected unxpected database version ${physicalVersion} (target version: ${targetPhysicalVersion}).`);
				}

				if (physicalVersion === targetPhysicalVersion) {
					this._database = await openDB(
						metadata.database.name,
						targetPhysicalVersion,
						{
							blocked: onBlocked,
							blocking: onBlocking,
							terminated: onTerminated,
						}
					);
				}
				else {
					const startPhysicalUpgradeVersion: number = physicalVersion + 1;
					const startLogicalUpgradeVersion: number = Math.floor(physicalVersion / 2) + 1;
					const endLogicalUpgradeVersion: number = targetLogicalVersion;

					let currentPhysicalUpgradeVersion: number = startPhysicalUpgradeVersion;
					let currentLogicalUpgradeVersion: number = startLogicalUpgradeVersion;

					while (currentLogicalUpgradeVersion <= endLogicalUpgradeVersion) {
						const versionUpgrades: IModelMetadataVersioned[] = this._modelMetadata[currentLogicalUpgradeVersion - 1];

						// close if already open from previous version upgrade
						if (this._database) {
							this._database.close();
						}

						this._database = await openDB(
							metadata.database.name,
							currentPhysicalUpgradeVersion,
							{
								// step 1: pre-migration, make incremental schema changes (synchronous)
								upgrade: (database: IDBPDatabase, oldVersion: number, newVersion: number) => {
									versionUpgrades.forEach((versionUpgrade: IModelMetadataVersioned): void => {

										if (versionUpgrade.requiresUpgrade) {
											const manager: Nullable<IModelUpgradeManager> =
												versionUpgrade.metadata.getUpgradeManager(database);

											if (manager) {
												this.logTelemetryEvent(
													Events.DatabaseMigration,
													{
														model: versionUpgrade.metadata.name,
														step: 'pre-upgrade',
														fromVersion: oldVersion,
														toVersion: newVersion,
													}
												);

												manager.preUpgrade(oldVersion, newVersion);
											}
										}
									})
								},
								blocked: onBlocked,
								blocking: onBlocking,
								terminated: onTerminated,
							}
						);

						if (this._database) {
							const database = this._database as IDBPDatabase;

							// step 2: migrate existing data to new schema.
							for (let i: number = 0; i < versionUpgrades.length; i++) {
								if (versionUpgrades[i].requiresUpgrade) {

									const manager: Nullable<IModelUpgradeManager> =
										versionUpgrades[i].metadata.getUpgradeManager(database);

									if (manager) {
										this.logTelemetryEvent(
											Events.DatabaseMigration,
											{
												model: versionUpgrades[i].metadata.name,
												step: 'migration',
												fromVersion: database.version - 1,
												toVersion: database.version,
											}
										);

										const migrationSucceeded: boolean =
											await manager.migrate(database.version - 1, database.version);

										if (!migrationSucceeded) {
											throw new Error(`Data migration for ${versionUpgrades[i].metadata.name}, version ${database.version} failed.`);
										}
									}
								}
							}

							// close the intermediate version
							database.close();
							currentPhysicalUpgradeVersion += 1;

							this._database = await openDB(
								metadata.database.name,
								currentPhysicalUpgradeVersion,
								{
									// step 3: post-migration, make final schema changes (synchronous)
									upgrade: (database: IDBPDatabase, oldVersion: number, newVersion: number) => {
										versionUpgrades.forEach((versionUpgrade: IModelMetadataVersioned): void => {

											if (versionUpgrade.requiresUpgrade) {
												const manager: Nullable<IModelUpgradeManager> =
													versionUpgrade.metadata.getUpgradeManager(database);

												if (manager) {
													this.logTelemetryEvent(
														Events.DatabaseMigration,
														{
															model: versionUpgrade.metadata.name,
															step: 'post-upgrade',
															fromVersion: oldVersion,
															toVersion: newVersion,
														}
													);

													manager.postUpgrade(oldVersion, newVersion);
												}
											}
										})
									},
								}
							);
						}

						// go to next version
						currentLogicalUpgradeVersion += 1;
						currentPhysicalUpgradeVersion += 1;
					}
				}

				if (this._database) {
					// open
					this._isOpen = true;

					// load all data into cache
					await this.initializeCache();

					// initialize the stores
					this.initializeStores();
				}
			}
			catch (error: unknown) {
				errorOccurred = true;
				const { message } = error as Error;

				this.logTelemetryEvent(Events.DatabaseError, {
					source: ErrorSource.Database,
					errorCode: ErrorCodes.DatabaseOpenFailed,
					error: message,
				});

				throw (error instanceof Error ? error : new Error(message || 'Unknown exception'));
			}
		}

		return !!this._database && !errorOccurred;
	};

	/**
	 * Closes the database once all pending transactions are committed.
	 */
	private closeDatabase = (): void => {
		if (this._database !== null) {
			this._database.close();
		}
	};

	/**
	 * Initializes the cache for each model.
	 * @private
	 * @returns A promise that resolves when all caches are initialized.
	 */
	private initializeCache = async (): Promise<void> => {
		if (this._database) {

			for (const key of Object.keys(this._currentModelMetadata)) {
				const manager: IModelCacheManager = this._currentModelMetadata[key].getCacheManager(this._database);
				await manager.load();
			}
		}
	};

	/**
	 * Initializes the store for each model.
	 * @private
	 */
	private initializeStores = (): void => {
		if (this._database) {
			for (const key of Object.keys(this._currentModelMetadata)) {
				this._stores[key] = StoreFactory.get(this._currentModelMetadata[key].getCacheManager(this._database));
			}
		}
	};

	/**
	 * Gets the current physical version of the database.
	 * @returns The physical version number of the database.
	 * @private
	 */
	private getPhysicalVersion = async (): Promise<number> => {
		let databaseExists: boolean = true;

		return new Promise((resolve, reject) => {
			openDB(
				metadata.database.name,
				undefined,
				{
					upgrade: (database: IDBPDatabase, oldVersion: number) => {
						databaseExists = oldVersion > 0;
					}
				})
				.then((database: IDBPDatabase) => {
					database.close();

					// if the database didn't exist, then opening it did create it.
					// remove it again.
					if (!databaseExists) {
						deleteDB(metadata.database.name);
					}

					resolve(databaseExists ? database.version : 0);
				})
				.catch((reason) => reject(reason));
		});
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}