import { NativeAudio } from '@capacitor-community/native-audio';
import { Nullable } from '../../../../shared/types';
import { IAudio, IAudioManager, IAudioMetadata } from '../../interfaces';
import { Database } from '../../storage/database';
import { ISettings } from '../../storage/views/settings';
import { Events } from '../../telemetry/events';
import { ICustomProperties } from '../../telemetry/interfaces';
import { Telemetry } from '../../telemetry/telemetry';
import { AudioFailure, SoundEffect } from '../enums';
import { AudioSource } from './audio-source';

/**
 * The AudioManagerAndroid class implements functions to manage sound effects for Android.
 * @export
 * @class AudioManagerAndroid
 */
export class AudioManagerAndroid implements IAudioManager {
	private _soundEffects: Record<string, IAudio> = {};

	/**
	 * Plays the requested sound effect.
	 * @param id The sound effect id.
	 * @returns true if the sound is played; otherwise, false.
	 */
	public playSoundEffect = (id: SoundEffect): boolean => {
		const effect: Nullable<IAudio> = this.get(id);
		let result: boolean = false;

		if (effect) {
			AudioManagerAndroid.refreshVolume(effect);

			if (!effect.volume.muted) {
				result = true;

				// set volume
				NativeAudio.setVolume({
					assetId: effect.id,
					volume: effect.volume.volume * effect.level,
				})
					.catch((error) => {
						this.logTelemetryEvent(
							Events.AudioFailure,
							{
								asset: effect.id,
								failure: AudioFailure.SetVolume,
								error: error,
							}
						);
					});

				// play
				NativeAudio.play({ assetId: effect.id, time: 0 })
					.catch((error) => {
						this.logTelemetryEvent(
							Events.AudioFailure,
							{
								asset: effect.id,
								failure: AudioFailure.Play,
								error: error,
							}
						);
					});
			}
		}

		return result;
	};

	/**
	 * Loads the sound effects.
	 * @returns A promise that resolves when all sound effects have loaded.
	 * @private
	 */
	public load = (): Promise<boolean> => {
		this.initialize();

		const keys = Object.keys(this._soundEffects);
		let remaining: number = keys.length;

		return new Promise((resolve) => {
			if (remaining === 0) {
				resolve(true);
			}
			else {
				keys.forEach((key: string) => {
					const soundEffect: IAudio = this._soundEffects[key];

					this.preload(soundEffect)
						.then((assetId: string) => {
							this._soundEffects[assetId].loaded = true;

							// resolve when no effects remain
							remaining -= 1;

							if (remaining === 0) {
								resolve(true);
							}
						})
						.catch((error) => {
							this.logTelemetryEvent(
								Events.AudioFailure,
								{
									failure: AudioFailure.Load,
									error: error,
								}
							);
							
							// don't fail, even if not loaded
							remaining -= 1;

							if (remaining === 0) {
								resolve(true);
							}
						});
				});
			}
		});
	};

	/**
	 * Preloads a sound effect.
	 * @param soundEffect The sound effect.
	 * @returns A Promise that resolves with the asset id when the effect is preloaded.
	 * @private
	 * @memberof AudioManagerAndroid
	 */
	private preload = async (soundEffect: IAudio): Promise<string> => {
		await NativeAudio.preload({
			assetId: soundEffect.id,
			assetPath: soundEffect.source,
			audioChannelNum: 1,
			isUrl: false,
		});

		return soundEffect.id;
	};

	/**
	 * Initializes the sound effects.
	 * @returns The sound effects.
	 * @private
	 */
	private initialize = (): void => {
		const settings: ISettings = Database.settings.get();
		const metadata: IAudioMetadata[] = AudioManagerAndroid.getMetadata();
		const masterVolume = settings.sound.volume.volume;

		metadata.forEach((meta: IAudioMetadata) => {
			const effectVolume: number = settings.sound.sfx && settings.sound.sfx[meta.id]
				? settings.sound.sfx[meta.id].volume
				: 1;
			const soundVolume = masterVolume * effectVolume;

			this._soundEffects[meta.id] = {
				id: meta.id,
				source: meta.source,
				level: meta.level,
				volume: { volume: soundVolume, muted: soundVolume === 0 },
				audio: null,
				loaded: false,
			};
		});
	};

	/**
	 * Gets the requested sound effect.
	 * @param id The sound effect id.
	 * @returns The sound effect.
	 * @memberof SoundEffects
	 */
	private get = (id: SoundEffect): Nullable<IAudio> => {
		const soundEffect: IAudio = this._soundEffects[id];

		return soundEffect && soundEffect.loaded ? soundEffect : null;
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};

	/**
	 * Gets the sound effects metadata.
	 * @returns The sound effects metadata.
	 * @private
	 * @static
	 */
	private static getMetadata = (): IAudioMetadata[] => {
		return [
			{
				id: SoundEffect.Boom,
				source: AudioSource.Boom,
				level: 0.5,
			},
			{
				id: SoundEffect.Click,
				source: AudioSource.Click,
				level: 0.1,
			},
			{
				id: SoundEffect.Loss,
				source: AudioSource.Loss,
				level: 0.5,
			},
			{
				id: SoundEffect.Win,
				source: AudioSource.Win,
				level: 0.1,
			},
		];
	};

	/**
	 * Gets the volume for the given sound effect.
	 * @param audio The sound effect.
	 * @returns The sound effect volume.
	 * @private
	 * @static
	 */
	private static refreshVolume = (audio: IAudio): void => {
		const settings: ISettings = Database.settings.get();

		// volume
		const masterVolume = settings.sound.volume.volume;
		const effectVolume: number = settings.sound.sfx && settings.sound.sfx[audio.id]
			? settings.sound.sfx[audio.id].volume
			: 1;
		const soundVolume = masterVolume * effectVolume;

		// update
		audio.volume = { volume: soundVolume, muted: soundVolume === 0 }
	};
}