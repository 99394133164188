import { Nullable } from '../../../shared/types';
import { Dictionary } from '../../shared/dictionary';
import { SubscriptionEvent, SubscriptionEvents } from '../../shared/events/subscription-events';
import { ICellVisibilityData, ISize } from '../shared/interfaces';

/**
 * The CellVisibility class determines cell visibility based on intersection observer state.
 * @export
 * @class CellVisibility
 */
export class CellVisibility {
	private static _data: Dictionary<ICellVisibilityData> = new Dictionary<ICellVisibilityData>();
	private static _margin: number = 15;
	private static _raiseEvent: boolean = false;

	/**
	 * Initializes the static state.
	 * @param size The size of the face.
	 * @static
	 */
	public static initialize = (faceId: string, size: ISize): void => {
		CellVisibility.setData(faceId, [], [], size);
	};

	/**
	 * Sets the visibility of a row.
	 * @param faceId The face id.
	 * @param row The row index.
	 * @param value The visibility.
	 * @static
	 */
	public static setRowVisibility = (faceId: string, row: number, value: boolean): void => {
		const data: Nullable<ICellVisibilityData> = CellVisibility.getData(faceId);

		if (data && row >= 0 && row < data.size.height && data.rows[row] !== value) {
			data.rows[row] = value;

			if (CellVisibility._raiseEvent) {
				SubscriptionEvent.raise(SubscriptionEvents.CellVisibilityChanged);
			}
		}
	};

	/**
	 * Sets the visibility of a column.
	 * @param faceId The face id.
	 * @param row The column index.
	 * @param value The visibility.
	 * @static
	 */
	public static setColumnVisibility = (faceId: string, column: number, value: boolean): void => {
		const data: Nullable<ICellVisibilityData> = CellVisibility.getData(faceId);

		if (data && column >= 0 && column < data.size.width && data.columns[column] !== value) {
			data.columns[column] = value;

			if (CellVisibility._raiseEvent) {
				SubscriptionEvent.raise(SubscriptionEvents.CellVisibilityChanged);
			}
		}
	};

	/**
	 * Gets the visibility of a row.
	 * @param faceId The face id.
	 * @param row The row index.
	 * @returns true if the row is visible; otherwise, false.
	 * @static
	 */
	public static getRowVisibility = (faceId: string, row: number): boolean => {
		const data: Nullable<ICellVisibilityData> = CellVisibility.getData(faceId);

		// the direct result
		let result: boolean = data ? (data.rows[row] || false) : false;

		if (data) {
			// check margin results
			if (!result && CellVisibility._margin > 0) {
				const fromRow: number = Math.max(0, row - CellVisibility._margin);
				const toRow: number = Math.min(data.size.height, row + CellVisibility._margin);
				let rowIndex: number = fromRow;

				// margin row
				while (!result && rowIndex < toRow) {
					result = data.rows[rowIndex] || false;
					rowIndex++;
				}
			}
		}

		return result;
	};

	/**
	 * Gets the visibility of a column.
	 * @param faceId The face id.
	 * @param column The column index.
	 * @returns true if the column is visible; otherwise, false.
	 * @static
	 * @memberof CellVisibility
	 */
	public static getColumnVisibility = (faceId: string, column: number): boolean => {
		const data: Nullable<ICellVisibilityData> = CellVisibility.getData(faceId);

		// the direct result
		let result: boolean = data ? (data.columns[column] || false) : false;

		if (data) {
			// check margin results
			if (!result && CellVisibility._margin > 0) {
				const fromColumn: number = Math.max(0, column - CellVisibility._margin);
				const toColumn: number = Math.min(data.size.width, column + CellVisibility._margin);
				let columnIndex: number = fromColumn;

				// margin column
				while (!result && columnIndex < toColumn) {
					result = data.columns[columnIndex] || false;
					columnIndex++;
				}
			}
		}

		return result;
	};

	/**
	 * Gets the visibility of a row and column.
	 * @param faceId The face id.
	 * @param row The row index.
	 * @param column The column index.
	 * @returns true if the cell is visible; otherwise, false.
	 * @static
	 */
	public static getCellVisibility = (faceId: string, row: number, column: number): boolean => {
		return (
			CellVisibility.getRowVisibility(faceId, row) &&
			CellVisibility.getColumnVisibility(faceId, column)
		);
	};

	/**
	 * Gets the cell visibility data for a given face id.
	 * @param faceId The face id.
	 * @returns The visibility data; otherwise, null.
	 * @private
	 * @static
	 */
	private static getData = (faceId: string): Nullable<ICellVisibilityData> => {
		return CellVisibility._data.get(faceId) || null;
	}

	/**
	 * Sets the cell visibility data for a given face id.
	 * @param faceId The face id.
	 * @param rows The rows.
	 * @param columns The columns.
	 * @param size The size.
	 * @returns The visibility data; otherwise, null.
	 * @private
	 * @static
	 */
	private static setData = (
		faceId: string,
		rows: boolean[],
		columns: boolean[],
		size: ISize): void => {
		
		CellVisibility._data.set(
			faceId,
			{
				rows: rows,
				columns: columns,
				size: size,
			});
	}
}