import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { Events } from '../../../../telemetry/events';
import { ICustomProperties } from '../../../../telemetry/interfaces';
import { Telemetry } from '../../../../telemetry/telemetry';
import { deepcopy } from '../../../../utils';
import { TransactionMode } from '../../../enums';
import { IModelCacheManager, IModelMetadata } from '../../../model-interfaces';
import { IProduct, IProductUpdate } from '../../../views/products';
import { IModelProductValues } from './model';

/**
 * The CacheManager class implements a cache for the Products model.
 * @export
 * @class CacheManager
 * @implements {IModelCache}
 */
export class CacheManager implements IModelCacheManager {
	private _database: IDBPDatabase;
	private _metadata: IModelMetadata<IModelProductValues>;
	private _cache: Record<string, IProduct> = {};

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._metadata.name; }

	/**
	 * Creates an instance of CacheManager.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The model metadata.
	 * @memberof CacheManager
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelProductValues>) {
		this._database = database;
		this._metadata = metadata;
	}

	/**
	 * Loads the model into the cache.
	 * @returns A promise that resolves when the cache is loaded.
	 */
	public load = async (): Promise<Record<string, IProduct>> => {
		const records: IModelProductValues[] = await this._database
			.getAll(this._metadata.name)
			.catch((error) => {

				// initialize cache
				this._cache = {};

				// log
				this.logTelemetryEvent(Events.CacheManagerProductLoadError, { error: error });
				
				// rethrow
				throw error;
			});

		this._cache = records.reduce((acc: Record<string, IProduct>, item: IModelProductValues): Record<string, IProduct> => {
			const restored: Nullable<IProduct> = this.restore(item);

			if (restored) {
				acc[item.id] = restored;
			}

			return acc;
		}, {});

		return this.get();
	};

	/**
	 * Updates a record in the cache and database.
	 * @param product The product.
	 * @returns A promise that resolves when the database was updated.
	 */
	public update = async (product: IProductUpdate): Promise<Record<string, IProduct>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);
	
		const record: IModelProductValues = await store
			.get(product.id)
			.catch((error) => {

				// log
				this.logTelemetryEvent(Events.CacheManagerProductUpdateGetError, { error: error });

				// rethrow
				throw error;
			});
		
		const base: IModelProductValues = this.restore(record) || { ...this._metadata.defaults[0], ...this._metadata.systemValues };

		// initialize update from updated product and base
		const update: IModelProductValues = {
			...{
				id: product.id ? product.id : base.id,
				quantity: product.quantity !== undefined ? product.quantity : base.quantity,
				date: product.date !== undefined ? product.date : base.date,
				data: product.data !== undefined ? product.data : base.data,
			},
			...this._metadata.systemValues,
		};

		// create or update in database
		await store
			.put(this.obfuscate(update))
			.catch((error) => {

				// log
				this.logTelemetryEvent(Events.CacheManagerProductUpdatePutError, { error: error });

				// rethrow
				throw error;
			});

		// refresh cache
		await this
			.load()
			.catch((error) => {

				// log
				this.logTelemetryEvent(Events.CacheManagerProductUpdateLoadError, { error: error });

				// rethrow
				throw error;
			});

		// return the entire product cache
		return this.get();
	};

	/**
	 * Deletes a record in the cache and database.
	 * @param id The id.
	 * @returns A promise that resolves when the database record was deleted.
	 */
	public delete = async (id: string): Promise<Record<string, IProduct>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// create or update in database
		await store
			.delete(id)
			.catch((error) => {

				// log
				this.logTelemetryEvent(Events.CacheManagerProductDeleteError, { error: error });

				// rethrow
				throw error;
			});

		// refresh cache
		await this
			.load()
			.catch((error) => {

				// log
				this.logTelemetryEvent(Events.CacheManagerProductDeleteLoadError, { error: error });

				// rethrow
				throw error;
			});

		// return the entire products cache
		return this.get();
	};

	/**
	 * Gets the products.
	 * @returns The products.
	 */
	public get = (): Record<string, IProduct> => {
		return deepcopy(this._cache) as Record<string, IProduct>;
	};

	/**
	 * Obfuscates the record
	 * @param record The record.
	 * @returns The obfuscated record.
	 * @private
	 */
	private obfuscate = (record: IModelProductValues): IModelProductValues => {
		const data: string = btoa(JSON.stringify(record));
		const date = new Date(record.date);
		date.setDate(date.getDate() + 14);

		return {
			...record,
			date: date,
			quantity: -record.quantity,
			data: data,
		};
	};

	/**
	 * Restores an obfuscated record.
	 * @param record The obfuscated record.
	 * @returns The record or null, if the record was tampered with.
	 * @private
	 */
	private restore = (record: IModelProductValues): Nullable<IModelProductValues> => {
		let result: Nullable<IModelProductValues> = null;

		if (record?.data && record.quantity < 0) {
			const data: string = atob(record.data);
			const product: IModelProductValues = JSON.parse(data);
			product.date = new Date(product.date);
			const compareDate: Date = new Date(product.date);
			compareDate.setDate(compareDate.getDate() + 14);

			if (record.id === product.id &&
				record.date.getTime() === compareDate.getTime() &&
				record.quantity === -product.quantity) {
				result = {
					...product,
				};
			}
		}

		return result;
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}