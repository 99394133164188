import * as Game from '../../../game-to-app/app';
import { CellStatus } from '../../../game-to-app/enums';
import * as GameTypes from '../../../game-to-app/types';
import { Nullable } from '../../../shared/types';
import * as SolverEnums from '../../../solver-to-app/enums';
import * as SolverInterfaces from '../../../solver-to-app/interfaces';
import { SolverFactory } from '../../../solver-to-app/solver-factory';
import { Dictionary } from '../../shared/dictionary';
import { ISolverDataCell, ISolverDataFace } from '../shared/interfaces-solver-data';
import { SolverDataBoard } from '../shared/types';

/**
 * The Assistant class implements functions for the player assistant.
 * @export
 * @class Assistant
 */
export class Assistant {

	/**
	 * Solves the current board.
	 * @param game The game.
	 * @returns The solution data.
	 */
	public solve = (game: Game.Game, options: SolverInterfaces.ISolverOptions): SolverDataBoard => {
		
		const result = {};

		const solverCellMap: Record<string, SolverInterfaces.ICell> = {};
		const gameCellMap: Record<string, Game.Cell> = {};
		const faces: Record<string, Game.Face> = game.faces;

		// initialize all cells without neighbors
		Object.keys(faces).forEach((key) => {
			const face: Game.Face = faces[key];
			face.cells.forEach((row: Game.Cell[]) => {
				row.forEach((cell: Game.Cell) => {
					solverCellMap[cell.id] = this.initializeCell(cell);
					gameCellMap[cell.id] = cell;
				});
			});
		});

		// set neighbors
		const solverCells: SolverInterfaces.ICell[] = [];
		Object.keys(solverCellMap).forEach((key) => {
			const solverCell: SolverInterfaces.ICell = solverCellMap[key];
			const gameCell: Game.Cell = gameCellMap[key];
			solverCell.neighbors = gameCell.neighbors.map((neighbor: Game.Cell) => solverCellMap[neighbor.id]);
			solverCells.push(solverCell);
		});

		const board: SolverInterfaces.IBoard = {
			remainingMines: game.remainingMineCount,
			cells: solverCells,
		};

		// solve
		const solutions: SolverInterfaces.ISolution[] = SolverFactory.get(
			board,
			options).solve();

		solutions.forEach((solution: SolverInterfaces.ISolution): void => {
			const cellId: Nullable<GameTypes.CellId> = game.getCellId(solution.id);

			if (cellId) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				let face: ISolverDataFace = (result as any)[cellId.face];
	
				if (face === undefined) {
					face = {
						cells: new Dictionary<ISolverDataCell>(),
					};
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(result as any)[cellId.face] = face;
				}

				face.cells.add(
					solution.id,
					{
						id: solution.id,
						status: solution.status,
						isMineProbability: solution.isMineProbability,
						method: solution.method,
					}
				);
			}
		});

		return result as SolverDataBoard;
	}

	/**
	 * Initializes a cell for the solver.
	 * @param cell The game cell.
	 * @returns The solver cell.
	 * @private
	 */
	private initializeCell = (cell: Game.Cell): SolverInterfaces.ICell => {
		let status: SolverEnums.CellStatus = SolverEnums.CellStatus.Uncovered;

		switch (cell.status) {
			case CellStatus.Covered:
			case CellStatus.Question:
				status = SolverEnums.CellStatus.Covered;
				break;
			case CellStatus.Flagged:
			case CellStatus.FlaggedByHint:
				status = cell.isMine ? SolverEnums.CellStatus.Flagged : SolverEnums.CellStatus.Covered;
				break;
			default:
				status = SolverEnums.CellStatus.Uncovered;
				break;
		}

		const solverCell: SolverInterfaces.ICell = {
			id: cell.id,
			status: status,
			neighbors: [],
		};

		if (solverCell.status === SolverEnums.CellStatus.Uncovered) {
			solverCell.neighborMineCount = cell.neighborMineCount;
		}

		return solverCell;
	};
}