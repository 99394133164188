import { AdServeResultType, AdServeStatus, AdType } from '../enums';
import { IAdProvider, IAdServeResult } from '../interfaces';

/**
 * The NoOpAdProvider class implements an ad provider that does not serve any ads.
 * @export
 * @class NoOpAdProvider
 * @implements {IAdProvider}
 */
export class NoOpAdProvider implements IAdProvider {

	/**
	 * Gets a Boolean value indicating whether the provider is enabled.
	 * @readonly
	 */
	public get isEnabled(): boolean { return true; }

	/**
	 * Activates or deactivates the ad provider.
	 * @param status The ad serve status.
	 * @returns The ad serve status.
	 * @memberof NoOpAdProvider
	 */
	public activate = (status: AdServeStatus): AdServeStatus => {
		return status;
	};

	/**
	 * Serves an interstitial ad.
	 * @returns A promise that is resolved when the ad is closed.
	 * @memberof NoOpAdProvider
	 */
	public serveInterstitial = (): Promise<IAdServeResult> => {
		const result: IAdServeResult = {
			type: AdType.Interstitial,
			result: AdServeResultType.NoProvider,
			duration: 0,
		};

		return new Promise<IAdServeResult>((resolve) => {
			resolve(result);
		});
	}
}