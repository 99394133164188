
/**
 * The Formatter class implements formatters for showing time and count values.
 * @export
 * @class Formatter
 */
export class Formatter {
	/**
	 * Formats a given time in seconds value.
	 * @param timeInSeconds The time in seconds.
	 * @param maxSeconds The max number of seconds to show.
	 * @param defaultDigit The default digit used for leading values.
	 * @returns The formatted time string.
	 * @static
	 */
	public static time = (timeInSeconds: number, maxSeconds: number, defaultDigit: number): string => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = timeInSeconds % 60;
		const maxMinutes = Math.floor(maxSeconds / 60);
		const minutesLength = maxMinutes.toString().length;
		const zeros = Array(minutesLength + 1).join('0');
		const defaultDigits = Array(minutesLength + 1).join(defaultDigit.toString());

		return timeInSeconds <= maxSeconds
			? `${(zeros + minutes).slice(-minutesLength)}:${('00' + seconds).slice(-2)}`
			: `${defaultDigits}:${Array(3).join(defaultDigit.toString())}`;
	};

	/**
	 * Formats a given count value.
	 * @param count The count.
	 * @param maxValue The max value to show.
	 * @returns The formatted count value string.
	 * @static
	 */
	public static count = (count: number, maxValue: number): string => {
		const length = maxValue.toString().length;
		const zeros = Array(length + 1).join('0');

		return `${(zeros + (Math.min(maxValue, count))).slice(-length)}`;
	};
}