/**
 * Defines the app types.
 * @export
 */
export enum AppType {
	Unknown,
	Web,
	AndroidTrustedWeb,
	Android,
}

/**
 * Defines the app store types.
 * @export
 * @enum {number}
 */
export enum AppStoreType {
	GooglePlay,
}

/**
 * Defines the types of products for in-app purchases.
 */
export enum AppStoreShopProductType {
	NonConsumable,
	Consumable,
	FreeSubscription,
	PaidSubscription,
	NonRenewingSubscription,
}

/**
 * Defines the network statuses.
 * @export
 */
export enum NetworkStatus {
	Offline,
	Online,
}

/**
 * Defines the purchase flow status.
 * @export
 */
export enum PurchaseFlowStatus {
	Idle,
	Started,
	InProgress,
	Cancelled,
	Approved,
	Verified,
	Unverified,
	Finished,
	Owned,
	Refunded,
	Error,
}

/**
 * Defines the shop service status.
 * @export
 */
export enum ShopServiceStatus {
	Unknown,
	Unavailable,
	Available,
}

/**
 * Defines the shop open status.
 * @export
 */
export enum ShopOpenStatus {
	NotStarted,
	Started,
	ProductsRegistered,
	InitializedListeners,
	ProductsInShop,
	RefreshCompleted,
	Failed,
	Open,
}

/**
 * Defines the purchase flow success status.
 * @export
 */
export enum PurchaseFlowSuccess {
	No,
	Yes,
	InProgress,
}

/**
 * Defines the shop product states.
 * @export
 */
export enum ShopProductStatus {
	Approved,
	Downloaded,
	Downloading,
	Finished,
	Initiated,
	Invalid,
	Owned,
	Registered,
	Requested,
	Unknown,
	Valid,
}