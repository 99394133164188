import Observer from '@researchgate/react-intersection-observer';
import * as React from 'react';
import { CellVisibility } from '../../app/cell-visibility';
import { Constants } from '../../shared/constants';
import { IIntersectionObserverEntry } from '../../shared/interfaces';
import { IProps } from './props';

/**
 * The FaceGrid class implements the <FaceGrid/> component.
 * @export
 * @class FaceGrid
 * @extends {React.Component<IProps>}
 */
export class FaceGrid extends React.Component<IProps> {

	/**
	 * Renders the <FaceGrid/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const id: string = `${this.props.faceId}-grid`;

		return (
			<div
				id={id}
				key={id}
				style={{
					position: 'absolute',
					top: '0px',
					left: '0px',
					width: `${this.props.size.width * this.props.columns}px`,
					height: `${this.props.size.height * this.props.rows}px`,
					visibility: 'hidden',
					zIndex: -999
				}}
			>
				{[...Array(this.props.rows)].map((none, y) => {
					return (
						<Observer
							key={`${id}-obsrow-${y}`}
							onChange={this.onVisibilityChangeRow}
							root={`#${Constants.zoomComponentId}`}
						>
							<div
								id={`${id}-row-${y}`}
								key={`${id}-row-${y}`}
								style={{
									position: 'absolute',
									top: `${y * this.props.size.height}px`,
									left: '0px',
									width: `${this.props.size.width * this.props.columns}px`,
									height: `${this.props.size.height}px`,
									visibility: 'hidden',
									zIndex: -999
								}}
							/>
						</Observer>
					)
				})}
				
				{[...Array(this.props.columns)].map((none, x) => {
					return (
						<Observer
							key={`${id}-obscol-${x}`}
							onChange={this.onVisibilityChangeColumn}
							root={`#${Constants.zoomComponentId}`}
						>
							<div
								id={`${id}-col-${x}`}
								key={`${id}-col-${x}`}
								style={{
									position: 'absolute',
									top: '0px',
									left: `${x * this.props.size.width}px`,
									width: `${this.props.size.width}px`,
									height: `${this.props.size.height * this.props.rows}px`,
									visibility: 'hidden',
									zIndex: -999
								}}
							/>
						</Observer>
					)
				})}
			</div>
		);
	};

	/**
	 * The event handler for when the visibility of a row changes.
	 * @param event The intersection observer event.
	 */
	private onVisibilityChangeRow = (event: IIntersectionObserverEntry): void => {
		const row: number = this.parseIndex(event);

		if (row >= 0) {
			CellVisibility.setRowVisibility(this.props.faceId, row, event.isIntersecting);
		}
	};

	/**
	 * The event handler for when the visibility of a column changes.
	 * @param event The intersection observer event.
	 */
	private onVisibilityChangeColumn = (event: IIntersectionObserverEntry): void => {
		const column: number = this.parseIndex(event);
		if (column >= 0) {
			CellVisibility.setColumnVisibility(this.props.faceId, column, event.isIntersecting);
		}
	};

	/**
	 * Parses the row or column index out of the event target id.
	 * @param event The intersection observer event.
	 * @returns The row or column index.
	 */
	private parseIndex = (event: IIntersectionObserverEntry): number => {
		let index: number = -1;

		if (event && event.target && event.target.id) {
			const parsed: string[] = event.target.id.split('-');
			index = parseInt(parsed[parsed.length - 1]);
		}

		return isNaN(index) ? -1 : index;
	};
}