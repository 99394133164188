import { Nullable } from '../shared/types';
import { EnabledBox, EnabledClassic, EnabledPaneFour, EnabledPaneThree } from './constants';
import { Levels, Style } from './enums';
import { ILevelDefinition } from './interfaces';
import { OrientationDimension } from './types';

/**
 * The GameLevels class implements functions to retrieve level definitions.
 * @export
 * @class Level
 */
export class GameLevels {

	/**
	 * Gets the level definition for the given style and level.
	 * @param style The style.
	 * @param level The level.
	 * @returns The level definition; otherwise, null.
	 * @static
	 */
	public static get = (style: Style, level: Levels): Nullable<ILevelDefinition> => {
		
		// default definitions are for landscape orientation
		const filtered: ILevelDefinition[] = GameLevels.getDefinitions().filter((value) => {
			return value?.level === level && value?.style === style;
		});

		return filtered.length > 0 ? filtered[0] : null;
	};

	/**
	 * Gets the level definitions for the given style.
	 * @param style The style.
	 * @returns The level id values.
	 * @static
	 */
	public static getForStyle = (style: Style): ILevelDefinition[] => {
		return GameLevels.getDefinitions().filter((value) => value?.style === style);
	};

	/**
	 * Gets the orientation and dimensions for given width, height and depth.
	 * @param width The width.
	 * @param height The height.
	 * @param depth The depth.
	 * @private
	 * @static
	 */
	public static getOrientationDimension = (
		width: number,
		height: number,
		depth: number): OrientationDimension => {

		const sorted: number[] = [height, width, depth].sort((one, two) => one - two);

		return {
			portrait: {
				width: sorted[1],
				height: sorted[2],
				depth: sorted[0],
			},
			landscape: {
				width: sorted[2],
				height: sorted[1],
				depth: sorted[0],
			}
		};
	};
	
	/**
	 * Gets the array that defines all the level definitions for the game.
	 * @returns The game level definitions.
	 * @private
	 * @static
	 */
	private static getDefinitions = (): ILevelDefinition[] => {

		// MS: 9x9   =  81, 10, 0.123, G: 8x10  =  80, 10, 0.125
		// MS: 16x16 = 256, 40, 0.156, G: 18x14 = 252, 40, 0.158
		// MS: 30x16 = 480, 99, 0.206, G: 24x20 = 480, 99, 0.206

		// Classic: 9x9   =  81, 10, 0.123
		// Classic: 16x16 = 256, 40, 0.156
		// Classic: 30x16 = 480, 99, 0.206

		// Cube: 4x4x6 = 96, 15, 0.156
		// Cube: 6x6x6 = 216, 40, 0.185
		// Cube: 9x9x6 = 486, 99, 0.203

		// Box: W:5, H:4 D:3 , 2xWxH + 2xDxH + 2xDxW =  94, 15, 0.159
		// Box: W:9, H:6 D:5 , 2xWxH + 2xDxH + 2xDxW = 258, 40, 0.155
		// Box: W:11, H:9 D:7, 2xWxH + 2xDxH + 2xDxW = 478, 99, 0.207

		// Pane: 6x6x3   = 108,  6, 0.055
		// Pane: 9x9x3   = 243, 20, 0.082
		// Pane: 11x11x4 = 484, 50, 0.103
		
		const result: ILevelDefinition[] = [
			{
				id: 'Classic-Easy',
				style: Style.Classic,
				level: Levels.Easy,
				name: 'Easy',
				dimensions: GameLevels.getOrientationDimension(9, 9, 0),
				mines: 10,
				enabledFaces: EnabledClassic,
				zeroDimension: 0.3,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Classic-Medium',
				style: Style.Classic,
				level: Levels.Medium,
				name: 'Medium',
				dimensions: GameLevels.getOrientationDimension(16, 16, 0),
				mines: 40,
				enabledFaces: EnabledClassic,
				zeroDimension: 0.3,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Classic-Hard',
				style: Style.Classic,
				level: Levels.Hard,
				name: 'Hard',
				dimensions: GameLevels.getOrientationDimension(30, 16, 0),
				mines: 99,
				enabledFaces: EnabledClassic,
				zeroDimension: 0.3,
				hintLimit: 2,
				enableFlags: true,
			},
			{
				id: 'Cube-Easy',
				style: Style.Cube,
				level: Levels.Easy,
				name: 'Easy',
				dimensions: GameLevels.getOrientationDimension(4, 4, 4),
				enabledFaces: EnabledBox,
				mines: 15,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Cube-Medium',
				style: Style.Cube,
				level: Levels.Medium,
				name: 'Medium',
				dimensions: GameLevels.getOrientationDimension(6, 6, 6),
				enabledFaces: EnabledBox,
				mines: 40,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Cube-Hard',
				style: Style.Cube,
				level: Levels.Hard,
				name: 'Hard',
				dimensions: GameLevels.getOrientationDimension(9, 9, 9),
				enabledFaces: EnabledBox,
				mines: 99,
				hintLimit: 2,
				enableFlags: true,
			},
			{
				id: 'Box-Easy',
				style: Style.Box,
				level: Levels.Easy,
				name: 'Easy',
				dimensions: GameLevels.getOrientationDimension(5, 4, 3),
				enabledFaces: EnabledBox,
				mines: 15,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Box-Medium',
				style: Style.Box,
				level: Levels.Medium,
				name: 'Medium',
				dimensions: GameLevels.getOrientationDimension(9, 6, 5),
				enabledFaces: EnabledBox,
				mines: 40,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Box-Hard',
				style: Style.Box,
				level: Levels.Hard,
				name: 'Hard',
				dimensions: GameLevels.getOrientationDimension(11, 9, 7),
				enabledFaces: EnabledBox,
				mines: 99,
				hintLimit: 2,
				enableFlags: true,
			},
			{
				id: 'Pane-Easy',
				style: Style.Pane,
				level: Levels.Easy,
				name: 'Easy',
				dimensions: GameLevels.getOrientationDimension(6, 6, 3),
				enabledFaces: EnabledPaneThree,
				mines: 6,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Pane-Medium',
				style: Style.Pane,
				level: Levels.Medium,
				name: 'Medium',
				dimensions: GameLevels.getOrientationDimension(9, 9, 3),
				enabledFaces: EnabledPaneThree,
				mines: 20,
				hintLimit: 1,
				enableFlags: true,
			},
			{
				id: 'Pane-Hard',
				style: Style.Pane,
				level: Levels.Hard,
				name: 'Hard',
				dimensions: GameLevels.getOrientationDimension(11, 11, 4),
				enabledFaces: EnabledPaneFour,
				mines: 50,
				hintLimit: 2,
				enableFlags: true,
			},
		];
	
		return result;
	};
}