import { Nullable } from '../../../shared/types';
import { IAudioManager } from '../interfaces';
import { AudioFactory } from './audio-factory';
import { SoundEffect } from './enums';

/**
 * The AudioManager class implements functions to manage audio.
 * @export
 * @class AudioManager
 */
export class AudioManager {
	private static _audioManager: Nullable<IAudioManager> | undefined = undefined;
	
	/**
	 * Plays the requested sound effect.
	 * @param id The sound effect id.
	 * @returns true if the sound is played; otherwise, false.
	 */
	public static playSoundEffect = (id: SoundEffect): boolean => {
		return AudioManager.audioManager()?.playSoundEffect(id) || false;
	};

	/**
	 * Loads the sound effects.
	 * @returns A promise that resolves when all sound effects have loaded.
	 * @static
	 */
	public static load = async (): Promise<boolean> => {
		return AudioManager.audioManager() ? (await AudioManager.audioManager()?.load() || true) : true;
	};

	/**
	 * Get the audio manager.
	 * @private
	 * @static
	 * @memberof AudioManager
	 */
	private static audioManager = (): Nullable<IAudioManager> => {
		if (AudioManager._audioManager === undefined) {
			AudioManager._audioManager = AudioFactory.get();
		}

		return AudioManager._audioManager;
	}
}