import { Nullable } from '../../../../shared/types';
import { ModelNames } from '../enums';
import { IModelCacheManager, IModelCacheManagerAutoSave, IStore } from '../model-interfaces';
import { GameStatsStore } from './gamestats-store';
import { LevelsStore } from './levels-store';
import { ProductsStore } from './products-store';
import { SavedGamesStore } from './savedgames-store';
import { SettingsStore } from './settings-store';
import { UserPreferencesStore } from './userpreferences-store';

/**
 * The StoreFactory class implements a factory for stores.
 * @export
 * @class StoreFactory
 */
export class StoreFactory {

	/**
	 * Gets a store based on the provided cache manager.
	 * @param cacheManager The cache manager.
	 * @returns The store.
	 * @static
	 */
	public static get = (cacheManager: IModelCacheManager | IModelCacheManager & IModelCacheManagerAutoSave): IStore => {
		let store: Nullable<IStore> = null;

		switch (cacheManager.name) {
			case ModelNames.GameStats:
				store = new GameStatsStore(cacheManager);
				break;
			case ModelNames.Levels:
				store = new LevelsStore(cacheManager);
				break;
			case ModelNames.Products:
				store = new ProductsStore(cacheManager);
				break;
			case ModelNames.SavedGames:
				store = new SavedGamesStore(cacheManager as IModelCacheManager & IModelCacheManagerAutoSave);
				break;
			case ModelNames.Settings:
				store = new SettingsStore(cacheManager);
				break;
			case ModelNames.UserPreferences:
				store = new UserPreferencesStore(cacheManager);
				break;
			default:
				throw new Error(`Failed to create store for unknown model ${cacheManager.name}.`);
		}

		return store as IStore;
	};
}