import * as React from 'react';
import { Nullable } from '../../../shared/types';
import { SplashStyle } from '../shared/enums';
import { Spinner } from '../spinner/spinner';
import { IProps } from './props';
import splashStyles from './splash.module.css';
import { IState } from './state';

/**
 * The Splash class implements the <Splash/> component.
 * @export
 * @class Splash
 */
export class Splash extends React.Component<IProps, IState> {
	/**
	 * Creates an instance of Splash.
	 * @param {IProps} props The props.
	 * @memberof Splash
	 */
	constructor(props: IProps) {
		super(props);

		const effectiveProps: IProps = this.getEffectiveProps();
		const delay: number = (effectiveProps.delay || 0);
		let timerId: Nullable<number> = null;

		if (delay > 0) {
			timerId = window.setTimeout(this.onDelayTimerExpired, delay);
		}

		this.state = {
			timerId: timerId,
			show: timerId === null,
		};
	}
	
	/**
	 * A react lifecycle function for the <Splash/> component.
	 */
	public componentWillUnmount = (): void => {
		if (this.state.timerId) {
			window.clearTimeout(this.state.timerId);
		}
	};

	/**
	 * Renders the <Splash/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		let component: React.ReactNode = null;

		if (this.state.show) {
			let messageComponent: React.ReactNode = null;

			const effectiveProps: IProps = this.getEffectiveProps();

			if (effectiveProps.message) {
				const text = effectiveProps.message.map((line: string, index: number): JSX.Element =>
					<div key={index}>{line}</div>
				);

				messageComponent = <div className={splashStyles.message}>
					{text}
				</div>
			}
		
			const spinnerComponent: React.ReactNode = effectiveProps.showSpinner
				? <Spinner />
				: null;
		
			component = (
				<div className={splashStyles.screen}>
					<div className={splashStyles.low}>
						{messageComponent}
						{spinnerComponent}
					</div>
				</div>
			);
		}

		return component;
	};

	/**
	 * The event handler for when the delay timer expires.
	 */
	private onDelayTimerExpired = (): void => {
		this.setState({ show: true });
	}

	/**
	 * Gets the effective props.
	 * @returns The effective props.
	 */
	private getEffectiveProps = (): IProps => {
		let props: IProps = {
			style: this.props.style || SplashStyle.Default
		};

		switch (props.style) {
			case SplashStyle.Default:
			case SplashStyle.Loading:
				props = {
					...props,
					message: ['Loading'],
					showSpinner: true,
					delay: this.props.delay === undefined ? 200 : this.props.delay,
				};
				break;
			case SplashStyle.Custom:
				props = {
					...props,
					message: this.props.message || null,
					showSpinner: this.props.showSpinner || false,
					delay: this.props.delay || 0,
				};
				break;
		}

		return props;
	};
}