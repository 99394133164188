/**
 * Defines the game status.
 * @export
 */
export enum GameStatus {
	New,
	InitialPlaying,
	ActivePlaying,
	Won,
	Lost,
	EndedNoPlay,
}

/**
 * Defines the cell status.
 * @export
 */
export enum CellStatus {
	Covered,
	Flagged,
	FlaggedByHint,
	Question,
	Uncovered,
	UncoveredShowMine,
	UncoveredShowMineHit,
	UncoveredShowMineFlaggedCorrectly,
	UncoveredShowMineFlaggedIncorrectly,
}

/**
 * Defines the 'uncover' result.
 * @export
 */
export enum UncoverResult {
	Boom,
	Continue,
	Stop,
	DenyNotCovered,
	DenyMarked,
	DenyMineShown,
}

/**
 * Defines the 'mark' result.
 * @export
 */
export enum MarkResult {
	FlaggedCorrectly,
	FlaggedIncorrectly,
	ClearedCorrectFlag,
	ClearedIncorrectFlag,
	CellNotCoveredOrMarked,
	FlagsNotEnabled,
}

/**
 * Defines the 'hint' result.
 * @export
 */
export enum HintResult {
	ReadyToUncover,
	Flagged,
	DenyNotQuestionMarked,
	DenyLimitExceeded,
}

/**
 * Defines the move type.
 * @export
 */
export enum Move {
	Hit,
	Mark,
	Discover,
	Hint,
}

/**
 * Defines the move result.
 * @export
 */
export enum MoveResult {
	Accepted,
	Rejected,
	Won,
	Lost,
	EndedNoPlay,
}

/**
 * Defines the reason(s) for the move result.
 * @export
 */
export enum MoveResultReason {
	None,
	NoCell,
	CellUncoveredOrMarked,
	CellNotUncovered,
	CellNotCoveredOrMarked,
	NonMatchingFlaggedCount,
	HitMine,
	IncorrectFlag,
	HintLimitExceeded,
	FlagsNotEnabled,
}

/**
 * Defines increase or decrease in nieghbor mine count.
 * @export
 */
export enum FlaggedNeighborMineCount {
	Decrease,
	Increase,
}

/**
 * Defines if discovery is allowed or not; and why.
 * @export
 */
export enum AllowDiscovery {
	Allow,
	DenyNotUncovered,
	DenyNonMatchingFlaggedCount,
	DenyFlaggedIncorrectly,
}
