import { IGameLevelDefinition } from '../../../game-to-app/interfaces';
import { Dimension } from '../../../game-to-app/types';
import { BoardShape } from './boardshape';
import { IBoardShape, ICellSize, IFaceDefinition } from './interfaces';

/**
 * The BoardShapePane class defines the shape of a pane style game board.
 * @export
 * @class BoardShapePane
 * @implements {IBoardShape}
 */
export class BoardShapePane extends BoardShape implements IBoardShape {
	/**
	 * Creates an instance of BoardShapePane.
	 * @param {IGameLevelDefinition} level The game level.
	 * @param {ICellSize} cellSize The cell size.
	 */
	constructor(level: IGameLevelDefinition, cellSize: ICellSize) {
		super(level, cellSize);
	}

	/**
	 * Gets the dimensions of the board shape.
	 * @returns The dimensions of the board shape.
	 */
	public getSize = (): Dimension => {
		const numberOfPanes: number = this.getEnabledFaceNames()?.length || 0;

		return {
			width: this.getWidth(),
			height: this.getDistance() * (numberOfPanes - 1),
			depth: this.getDepth(),
		};
	};

	/**
	 * Gets the faces of the board shape.
	 * @returns The faces of the board shape.
	 */
	public getFaces = (): Record<string, IFaceDefinition> => {
		const cellClassNames: string[] = this._cellSize?.name ? [this._cellSize.name] : [];
		const size = this.getSize();

		const faces = {};
		this.getEnabledFaceNames().forEach((key: string, index: number) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(faces as any)[key] = {
				translate: {
					y: -size.height / 2 + (index * this.getDistance()),
				},
				rotate: {
					x: 1,
					deg: 90
				},
				size: {
					width: size.width,
					height: size.depth,
				},
				position: {
					top: (size.height - size.depth) / 2,
				},
				cells: {
					count: {
						height: this._level.dimension.height,
						width: this._level.dimension.width,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.width,
					},
					classNames: cellClassNames,
				},
				label: key,
				index: index,
			};
		});

		return faces;
	};

	/**
	 * Gets the initial face when starting a new game.
	 * @remarks This is the face that initially shows in front.
	 * @returns The name of the initial face.
	 */
	public getInitialFace = (): string => {
		return 'one';
	};

	/**
	 * Gets the names of all enabled faces.
	 * @returns An array of enabled face names.
	 * @private
	 */
	private getEnabledFaceNames = (): string[] => {
		return Object
			.keys(this._level.enabledFaces)
			.filter((key) => this._level.enabledFaces[key]);
	}

	/**
	 * Gets the width of the board shape.
	 * @returns The width of the board shape.
	 * @private
	 */
	private getWidth = (): number => {
		return Math.round(
			(this._level.dimension.width || this._level.zeroDimension || 0) *
			this._cellSize.width);
	};

	/**
	 * Gets the depth of the board shape.
	 * @returns The depth of the board shape.
	 * @private
	 */
	private getDepth = (): number => {
		return Math.round(
			(this._level.dimension.height || this._level.zeroDimension || 0) *
			this._cellSize.width);
	};

	/**
	 * Gets the distance between two panes on the board.
	 * @returns The distance between two shapes on the board.
	 * @remarks Calculation is based on the longer side. 0.3 seems like a good factor.
	 * @private
	 */
	private getDistance = (): number => {
		return Math.round(Math.max(this.getWidth(), this.getDepth()) * 0.30);
	}
} 