import { Levels, Style } from '../../../../../../game-to-app/enums';
import { GameLevels } from '../../../../../../game-to-app/game-levels';
import { ILevelDefinition } from '../../../../../../game-to-app/interfaces';
import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelUserPreferenceValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the user preferences model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelUserPreferenceValues> {
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {

		// load user preferences
		const userPreferences: IModelUserPreferenceValues = await this._database.get(
			this._metadata.name,
			this._metadata.name);

		// get the user preference level id
		const levelDefinition: ILevelDefinition = GameLevels.get(
			userPreferences.level.style,
			userPreferences.level.level) || GameLevels.get(Style.Classic, Levels.Easy) as ILevelDefinition;

		// update
		await this._database.put(
			this._metadata.name,
			{
				...userPreferences,
				level: {
					...userPreferences.level,
					id: levelDefinition.id,
				},
				...this._metadata.systemValues,
			}
		);

		return true;
	};
}