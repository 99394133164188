import deepequal from 'deep-equal';
import deepmerge from 'deepmerge';
import { SubscriptionEvent, SubscriptionEvents } from '../../events/subscription-events';
import { deepcopy } from '../../utils';
import { IModelCacheManager, IStore } from '../model-interfaces';
import { ILevel, ILevelUpdate } from '../views/levels';

/**
 * The LevelsStore class implements the store for levels.
 * @export
 * @class LevelsStore
 */
export class LevelsStore implements IStore {
	private _cacheManager: IModelCacheManager;
	private _levels: Record<string, ILevel>;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._cacheManager.name; }

	/**
	 * Creates an instance of LevelsStore.
	 * @param {IModelCacheManager} cacheManager The cache manager.
	 * @memberof LevelsStore
	 */
	constructor(cacheManager: IModelCacheManager) {
		this._cacheManager = cacheManager;
		this._levels = this._cacheManager.get();
	}

	/**
	 * Gets all levels.
	 * @param refresh A Boolean value indicating if the levels should be taken from the most recent database cache.
	 * @returns The levels.
	 */
	public get = (refresh: boolean = false): Record<string, ILevel> => {
		if (refresh) {
			const refreshedLevels: Record<string, ILevel> = this._cacheManager.get() as Record<string, ILevel>;

			if (!deepequal(refreshedLevels, this._levels)) {
				this._levels = refreshedLevels;
				SubscriptionEvent.raise(SubscriptionEvents.LevelsChanged, this.name, this._levels);
			}
		}

		return deepcopy(this._levels);
	};

	/**
	 * Updates a level.
	 * @param level The level to update.
	 * @param refresh A Boolean value indicating if the levels should be taken from the most recent database cache.
	 * @returns The updated level.
	 */
	public update = async (level: ILevelUpdate, refresh: boolean = false): Promise<Record<string, ILevel>> => {
		const previousLevels = this._levels;
		// update all levels, change the values for the specific id
		let updatedLevels: Record<string, ILevel> = deepcopy(this._levels);
		const levelForId: ILevel = updatedLevels[level.id];
		const updatedLevelForId: ILevel = deepmerge(levelForId, level);
		updatedLevels[level.id] = updatedLevelForId;
		
		if (!deepequal(updatedLevels, previousLevels)) {		
			// update the store levels synchronously
			this._levels = updatedLevels;
			
			if (!refresh) {
				SubscriptionEvent.raise(SubscriptionEvents.LevelsChanged, this.name, this._levels);
			}

			// update the database
			updatedLevels = await this._cacheManager.update(level);

			// optionally update the levels asynchronously from the database
			if (refresh && !deepequal(updatedLevels, this._levels)) {
				this._levels = updatedLevels;
				SubscriptionEvent.raise(SubscriptionEvents.LevelsChanged, this.name, this._levels);
			}
		}

		return this._levels;
	};

	/**
	 * Deletes a level.
	 * @param id The level id to delete.
	 * @returns The refreshed levels.
	 */
	public delete = async (id: string): Promise<Record<string, ILevel>> => {
		const updatedLevels: Record<string, ILevel> = await this._cacheManager.delete(id);
		this._levels = updatedLevels;
		SubscriptionEvent.raise(SubscriptionEvents.LevelsChanged, this.name, this._levels);

		return this._levels;
	};
}