import { DBSchema } from 'idb';
import { IModelMetadataVersioned } from '../../model-interfaces';
import { Metadata as gameStatsMetadata } from '../v2/gamestats/metadata';
import { IModelGameStats } from '../v2/gamestats/model';
import { Metadata as levelsMetadata } from '../v2/levels/metadata';
import { IModelLevels } from '../v2/levels/model';
import { Metadata as userPreferencesMetadata } from '../v2/user-preferences/metadata';
import { IModelUserPreferences } from '../v2/user-preferences/model';
import { Metadata as savedGamesMetadata } from './saved-games/metadata';
import { IModelSavedGames } from './saved-games/model';
import { Metadata as settingsMetadata } from './settings/metadata';
import { IModelSettings } from './settings/model';

/**
 * Defines the interface for the v3 application model.
 * @export
 * @interface IModel
 * @extends {DBSchema}
 * @extends {IModelGameStats}
 * @extends {IModelSettings}
 * @extends {IModelUserPreferences}
 * @extends {IModelLevels}
 * @extends {IModelSavedGames}
 */
export interface IModel
	extends DBSchema, IModelGameStats, IModelSettings, IModelUserPreferences, IModelLevels, IModelSavedGames { }

/** 
 * Defines the v3 model metadata.
 */
export const modelMetadata: IModelMetadataVersioned[] = [
	{ metadata: new gameStatsMetadata(), requiresUpgrade: false },
	{ metadata: new settingsMetadata(), requiresUpgrade: true },
	{ metadata: new userPreferencesMetadata(), requiresUpgrade: false },
	{ metadata: new levelsMetadata(), requiresUpgrade: false },
	{ metadata: new savedGamesMetadata(), requiresUpgrade: true },
];