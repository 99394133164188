import { IDBPDatabase } from 'idb';
import { Nullable } from '../../../shared/types';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager, IModelValues } from './model-interfaces';

/**
 * The DefaultMetadata class defines an abstract base class for model metadata.
 * @export
 * @abstract
 * @class DefaultMetadata
 * @implements {IModelMetadata}
 */
export abstract class DefaultMetadata<T extends IModelValues> implements IModelMetadata<T> {
	// metadata
	protected abstract _name: string;
	protected abstract _schemaVersion: number;
	protected abstract _defaults: T[];

	protected _parameters: IDBObjectStoreParameters = {
		keyPath: 'id',
	};

	// manager
	protected _upgradeManager: Nullable<IModelUpgradeManager> = null;
	protected _cacheManager: Nullable<IModelCacheManager> = null;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._name; }

	/**
	 * Gets the schema version.
	 * @readonly
	 */
	public get schemaVersion(): number { return this._schemaVersion; }

	/**
	 * Gets the parameters.
	 * @readonly
	 */
	public get parameters(): IDBObjectStoreParameters { return this._parameters; }

	/**
	 * Gets the system model values.
	 * @readonly
	 */
	public get systemValues(): IModelValues {
		return ({
			meta: {
				schemaVersion: this.schemaVersion,
				modified: new Date(),
			},
		});
	}

	/**
	 * Gets the defaults.
	 * @readonly
	 */
	public get defaults(): T[] { return this._defaults; }

	/**
	 * Gets the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	public getUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		this._upgradeManager = this._upgradeManager || this.initializeUpgradeManager(database);

		return this._upgradeManager;
	};

	/**
	 * Gets the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	public getCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		this._cacheManager = this._cacheManager || this.initializeCacheManager(database);

		return this._cacheManager;
	};

	/**
	 * Initializes the upgrade manager; abstract.
	 * @protected
	 * @abstract
	 * @param {IDBPDatabase} database The database.
	 * @returns {Nullable<IModelUpgradeManager>} The upgrade manager; otherwise, null.
	 */
	protected abstract initializeUpgradeManager(database: IDBPDatabase): Nullable<IModelUpgradeManager>;

	/**
	 * Initializes the cache manager; abstract.
	 * @protected
	 * @abstract
	 * @param {IDBPDatabase} database The database.
	 * @returns {IModelCacheManager} The cache manager.
	 */
	protected abstract initializeCacheManager(database: IDBPDatabase): IModelCacheManager;
}