import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';
import { deepcopy } from '../../../../utils';
import { TransactionMode } from '../../../enums';
import { IModelCacheManager, IModelMetadata } from '../../../model-interfaces';
import { IGameStats, IGameStatsUpdate } from '../../../views/gamestats';
import { IModelGameStatsValues } from './model';

/**
 * The CacheManager class implements a cache for the GameStats model.
 * @export
 * @class CacheManager
 * @implements {IModelCache}
 */
export class CacheManager implements IModelCacheManager {
	private _database: IDBPDatabase;
	private _metadata: IModelMetadata<IModelGameStatsValues>;
	private _cache: Record<string, IGameStats> = {};

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._metadata.name; }

	/**
	 * Creates an instance of CacheManager.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The model metadata.
	 * @memberof CacheManager
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelGameStatsValues>) {
		this._database = database;
		this._metadata = metadata;
	}

	/**
	 * Loads the model into the cache.
	 * @returns A promise that resolves when the cache is loaded.
	 */
	public load = async (): Promise<Record<string, IGameStats>> => {
		const records: IModelGameStatsValues[] = await this._database.getAll(this._metadata.name);
		
		this._cache = records.reduce((acc: Record<string, IGameStats>, item: IModelGameStatsValues): Record<string, IGameStats> => {
			acc[item.id] = item;

			return acc;
		}, {});

		return this.get();
	};

	/**
	 * Updates a record in the cache and database.
	 * @param stats The stats.
	 * @param previous The previous state.
	 * @returns A promise that resolves when the database was updated.
	 */
	public update = async (
		stats: IGameStatsUpdate,
		previous: Record<string, IGameStats> = this._cache): Promise<Record<string, IGameStats>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// net change
		const previousStats: IGameStats = previous[stats.id];

		const netChange: IGameStatsUpdate = previousStats
			? {
				id: stats.id,
				time: stats.time,
				won: stats.won !== undefined ? Math.max(stats.won - previousStats.won, 0) : undefined,
				lost: stats.lost !== undefined ? Math.max(stats.lost - previousStats.lost, 0) : undefined,
				played: stats.played !== undefined ? Math.max(stats.played - previousStats.played, 0) : undefined,
			}
			: stats;

		// get record from database, will be undefined if record does not exist
		const record: IModelGameStatsValues = await store.get(netChange.id);

		// initialize base from record or default values
		const base: IModelGameStatsValues = {
			...{
				id: netChange.id,
				time: record ? record.time : Number.MAX_SAFE_INTEGER,
				won: record ? record.won : 0,
				lost: record ? record.lost : 0,
				played: record ? record.played : 0,
			},
			...this._metadata.systemValues,
		}

		// initialize update from updated stats and base
		const update: IModelGameStatsValues = {
			...{
				id: netChange.id,
				time: netChange.time !== undefined && netChange.time < base.time ? netChange.time : base.time,
				won: netChange.won ? base.won + netChange.won : base.won,
				lost: netChange.lost ? base.lost + netChange.lost : base.lost,
				played: netChange.played ? base.played + netChange.played : base.played,
			},
			...this._metadata.systemValues,
		};

		// create or update in database
		await store.put(update);

		// refresh cache
		await this.load();

		// return the entire stats cache
		return this.get();
	};

	/**
	 * Deletes a record in the cache and database.
	 * @param id The id.
	 * @returns A promise that resolves when the database record was deleted.
	 */
	public delete = async (id: string): Promise<Record<string, IGameStats>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// create or update in database
		await store.delete(id);

		// refresh cache
		await this.load();

		// return the entire levels cache
		return this.get();
	};

	/**
	 * Gets the game stats.
	 * @returns The game stats.
	 */
	public get = (): Record<string, IGameStats> => {
		return deepcopy(this._cache) as Record<string, IGameStats>;
	};
}