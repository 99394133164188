/**
 * Defines an enumeration for Hover states.
 * @export
 * @enum {number}
 */
export enum Hover {
	None,
	Cell,
	Neighbor,
}

/**
 * Defines an enumeration for mouse click states.
 * @export
 * @enum {number}
 */
export enum MouseClick {
	None,
	Left,
	Middle,
	Right,
	Release,
}

/**
 * Defines the social media types.
 * @export
 * @enum {number}
 */
export enum SocialMediaType {
	Email,
	Facebook,
	FacebookMessenger,
	Reddit,
	Telegram,
	Tumblr,
	Twitter,
	WhatsApp,
}

/**
 * Defines the social media share types.
 * @export
 * @enum {number}
 */
export enum SocialMediaShareType {
	Page,
	Game,
	Like,
}

/**
 * Defines the layout style of a notification. 
 * @export
 * @enum {number}
 */
export enum NotificationLayoutStyle {
	Standard,
	Landscape,
	BestFit,
}

/**
 * Defines the content category types.
 * @export
 * @enum {number}
 */
export enum ContentCategory {
	Text,
	Free,
}

/**
 * Defines the help content type.
 * @export
 * @enum {number}
 */
export enum ContentTypeHelp {
	Overview,
	Playing,
	ControlsTouch,
	ControlsMouse,
	Score,
	Levels,
	Assistant,
	Settings,
	Shop,
	Feedback,
}

/**
 * Defines the legal content type.
 * @export
 * @enum {number}
 */
export enum ContentTypeLegal {
	Terms,
	Privacy,
}

/**
 * Defines the settings content type.
 * @export
 * @enum {number}
 */
export enum ContentTypeSettings {
	Game,
	Assistant,
	Sound,
}

/**
 * Defines the levels content type.
 * @export
 * @enum {number}
 */
export enum ContentTypeLevels {
	List,
	New,
	Edit,
}

/**
 * Defines the page types.
 * @export
 * @enum {number}
 */
export enum PageType {
	About,
	AppStore,
	Help,
	Legal,
	Levels,
	Profile,
	Settings,
	Shop,
	Tutorial,
}

/**
 * Defines the control that has its height updated.
 * @export
 * @enum {number}
 */
export enum UpdateHeightControl {
	NavigationBar,
	BottomPanel,
}

/**
 * Defines the player types
 * @export
 * @enum {number}
 */
export enum Player {
	Player,
	Assistant,
}

/**
 * Defines the application status.
 * @export
 * @enum {number}
 */
export enum ApplicationStatus {
	Loading,
	Waiting,
	Running,
	Closing,
}

/**
 * Defines the What's new items.
 * @export
 * @enum {number}
 */
export enum WhatsNew {
	Assistant,
	ClassicStyle,
	CustomLevels,
	GetAndroidApp,
	VisitShop,
}

/**
 * Defines the types of Splash screen styles.
 * @export
 * @enum {number}
 */
export enum SplashStyle {
	Default,
	Loading,
	Custom,
}

/**
 * Defines the types of service worker notifications.
 * @export
 * @enum {number}
 */
export enum ServiceWorkerNotification {
	Installed,
	Update,
}

/**
 * Defines the zoom positioning actions.
 * @export
 * @enum {number}
 */
export enum ZoomPositioning {
	None,
	Save,
	Restore,
}

/**
 * Defines the app store badge size.
 */
export enum AppStoreBadgeSize {
	Small,
	Large,
}