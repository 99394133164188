/**
 * Defines the database transaction modes.
 * @export
 */
export enum TransactionMode {
	ReadOnly = 'readonly',
	ReadWrite = 'readwrite',
	VersionChange = 'versionchange',
}

/**
 * Defines the model names.
 * @export
 */
export enum ModelNames {
	GameStats = 'gameStats',
	Levels = 'levels',
	Products = 'products',
	SavedGames = 'savedgames',
	Settings = 'settings',
	UserPreferences = 'userPreferences',
}