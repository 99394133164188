import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Toast from 'react-bootstrap/Toast';
import { NotificationLayoutStyle } from '../shared/enums';
import notificationStyles from './notification.module.css';
import { IProps } from './props';
import { IState } from './state';

/**
 * The Notification class implements the <Notification/> component.
 * @export
 * @class Notification
 * @extends {React.Component<IProps, IState>}
 */
export class Notification extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();

	/**
	 * Creates an instance of Notification.
	 * @param {IProps} props The props for the <Notification/> component.
	 * @memberof Notification
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			width: 0,
			height: 0.
		};
	}
	
	/**
	 * A react lifecycle function for the <Notification/> component.
	 */
	public componentDidMount = (): void => {
		const width: number = this._ref.current?.getBoundingClientRect().width || 0;
		const height: number = this._ref.current?.getBoundingClientRect().height || 0;
		
		this.setState({
			width: width,
			height: height,
		});
	};

	/**
	 * Renders the <Notification/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { layout, window, title } = this.props.notification;
		let finalLayout = layout;

		const bottomHeight: number = 20 +
			(this.props.notification.buttons.showAgain ? 35 : 0) +
			(this.props.notification.buttons.close ? 35 : 0);

		const landscapeSize = {
			width: 570,
			height: 410 + bottomHeight,
		};
		let toastClassName = notificationStyles.toast;

		switch (layout) {
			case NotificationLayoutStyle.Landscape:
				toastClassName = notificationStyles.toastLandscape;
				break;
			case NotificationLayoutStyle.BestFit:
				if (landscapeSize.width < window.width &&
					landscapeSize.height < window.height) {
					toastClassName = notificationStyles.toastLandscape;
					finalLayout = NotificationLayoutStyle.Landscape;
				}
				break;
		}

		toastClassName = title
			? toastClassName
			: `${toastClassName} ${notificationStyles.transparent}`
		
		let children = React.Children.toArray(this.props.children);
		children = children.map((c) => {
			return React.cloneElement(
				c as React.ReactElement,
				{
					...this.props,
					notification: {
						...this.props.notification,
						layout: finalLayout,
					},
				});
		});

		return (
			<div
				ref={this._ref}
				className={`row w-100 ${notificationStyles.wrapper} align-items-end`}
			>
				<Container className={` ${notificationStyles.notification} d-flex justify-content-center`}>
					<Toast
						className={`${toastClassName} ${this.props.notification.showOverflow ? notificationStyles.toastOverflow : ''}`}
						onClose={this.onClickClose}
						show={this.props.notification.show}
						delay={this.props.notification.closeDelay}
						autohide
					>
						{this.props.notification.title
							? (< Toast.Header >
									<strong className='mr-auto'>{this.props.notification.title}</strong>
								</Toast.Header>)
							: null}
						<Toast.Body>
							{children}
							{this.props.notification.buttons.showAgain
								? <div className={`${notificationStyles.sm} text-center`}>
									<a className={notificationStyles.link} href='#' onClick={this.onClickDontShowAgain}>
										{this.props.notification.buttons.showAgainLabel || 'Don\'t show this again'}
									</a>
								</div>
								: null}
							{this.props.notification.buttons.close
								? <div className={`${notificationStyles.sm} text-center`}>
									<a className={notificationStyles.link} href='#' onClick={this.onClickClose}>
										{this.props.notification.buttons.closeLabel || 'Close'}
									</a>
								</div>
								: null}
						</Toast.Body>
					</Toast>
				</Container>
			</div>
		);
	};

	/**
	 *  The click event handler for the Close button.
	 */
	private onClickClose = (): void => {
		this.props.notification.onClickClose(this.props.notification.showAgain);
	};

	/**
	 *  The click event handler for the Don't Show Again button.
	 */
	private onClickDontShowAgain = (): void => {
		this.props.notification.onClickClose(false);
	};
}