
import { IDBPDatabase } from 'idb';
import { Levels, Style } from '../../../../../../game-to-app/enums';
import { Nullable } from '../../../../../../shared/types';
import { Guid } from '../../../../guid';
import { DefaultMetadata } from '../../../default-metadata';
import { ModelNames } from '../../../enums';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager } from '../../../model-interfaces';
import { CacheManager } from './cache-manager';
import { IModelLevelValues } from './model';
import { UpgradeManager } from './upgrade-manager';

/**
 * The Metadata class defines the levels model metadata.
 */
export class Metadata extends DefaultMetadata<IModelLevelValues> implements IModelMetadata<IModelLevelValues> {
	protected _name: string = ModelNames.Levels;
	protected _schemaVersion: number = 2;
	// a default to use in update(), not to create in the store
	protected _defaults: IModelLevelValues[] = [{
		...{
			id: Guid.newGuid(),
			name: 'Custom level',
			level: Levels.Custom,
			style: Style.Classic,
			dimensions: {
				depth: 0,
				width: 10,
				height: 10,
			},
			mines: 15,
			hintLimit: 1,
			enableFlags: false,
		},
		...this.systemValues
	}];

	/**
	 * Initializes the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	protected initializeUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		return new UpgradeManager(database, this);
	};

	/**
	 * Initializes the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	protected initializeCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		return new CacheManager(database, this);
	};
}