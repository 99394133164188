import { Nullable } from '../../../shared/types';
import { Platform } from '../platform/platform';
import { PlayStoreReviewProvider } from './android/playstore-reviewprovider';
import { IReviewProvider } from './interfaces';
import { NoOpReviewProvider } from './no-op/no-op-reviewprovider';

/**
 * The ReviewFactory class provides functions to create review providers.
 * @export
 * @class ReviewFactory
 */
export class ReviewFactory {
	/**
	 * Gets a review provider.
	 * @returns A review provider.
	 * @static
	 * @memberof ReviewFactory
	 */
	public static getReviewProvider = (): IReviewProvider => {
		let provider: Nullable<IReviewProvider> = null;

		if (Platform.isAndroid()) {
			const playStoreProvider = new PlayStoreReviewProvider();
			provider = playStoreProvider.isEnabled ? playStoreProvider : null;
		}

		return provider ?? new NoOpReviewProvider();
	};
}