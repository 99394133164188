import { Board } from './board';
import { BoardDefinition, FaceDefinition } from './board-definition';
import { Cell } from './cell';
import { Constants } from './constants';
import { GameStatus, Move, MoveResult, MoveResultReason } from './enums';
import { Face } from './face';
import { GameOptions } from './game-options';
import { IGameOptions } from './interfaces';
import { IDeserializedGame, ISerializedGame, ISerializedVersionedGame } from './serialization-interfaces';
import { Timer } from './timer';
import { CellId, MoveResultType, Nullable, TimerIntervalEvent } from './types';

/**
 * The Game class represents the minesweeper game.
 * @export
 * @class Game
 */
export class Game {
	/**
	 * Gets the game status.
	 * @readonly
	 */
	public get status(): GameStatus { return this._status; }

	/**
	 * Gets the remaining mine count.
	 * @readonly
	 */
	public get remainingMineCount(): number { return this._board.remainingMineCount; }

	/**
	 * Gets the timer in seconds.
	 * @readonly
	 */
	public get timer(): number { return this._timer?.seconds || 0; }

	/**
	 * Gets the game board faces.
	 * @readonly
	 */
	public get faces(): Record<string, Face> { return this._board.faces; }

	/**
	 * Gets the game id.
	 * @readonly
	 */
	public get id(): number { return this._id; }
	
	/**
	 * Gets the move id.
	 * @readonly
	 */
	public get moveId(): number { return this._moveId; }

	private readonly _definition: BoardDefinition;
	private _options: GameOptions = new GameOptions(null);
	private _board: Board;
	private _status: GameStatus = GameStatus.New;
	private _timer: Nullable<Timer> = null;
	private _id: number = 0;
	private _moveId: number = 0;

	/**
	 * Creates an instance of Game.
	 * @param definition The board definition.
	 * @param id The game Id.
	 * @param options The game options.
	 * @param deserializedGame State restored from serialization; optional.
	 * @memberof Game
	 */
	constructor(
		definition: BoardDefinition,
		id: number,
		options: Nullable<IGameOptions>,
		deserializedGame: Nullable<IDeserializedGame> = null) {

		this._id = id;
		this._definition = definition;
		
		this._options = deserializedGame?.options || new GameOptions(options);
		this._board = deserializedGame?.board || new Board(this._definition, this._options);
		this._timer = deserializedGame?.timer || new Timer(this._definition.onTimer);
		this._status = deserializedGame?.status !== undefined ? deserializedGame?.status : GameStatus.New;
	}

	/**
	 * Starts a new game.
	 */
	public newGame = (): void => {
		this._timer?.reset();
		this._board = new Board(this._definition, this._options);
	};

	/**
	 * Ends the current game.
	 * @param A Boolean value that indicates whether to reset the timer event handler; optional.
	 */
	public endGame = (resetOnTimer: boolean = true): void => {
		this._timer?.reset(resetOnTimer);
	};

	/**
	 * Executes a player's move.
	 * @param cellId The cell Id.
	 * @param move The move.
	 * @returns The move result.
	 */
	public move = (cellId: string, move: Move): MoveResultType => {
		let result: MoveResultType = { result: MoveResult.Rejected, reason: MoveResultReason.NoCell };
		const id: Nullable<CellId> = this.getCellId(cellId);

		if (id) {
			// increment move id
			this._moveId += 1;
			
			// first move
			if (this._status === GameStatus.New) {
				this._status = GameStatus.InitialPlaying;

				// distribute mines on first move, ensuring that the player hits a clear area, not a mine
				// on the first move
				this._board.initializeMineLocations(this.getCell(cellId));

				// start timer
				this._timer?.start();
			}
			else {
				// when restarting a saved game
				if (!this._timer?.isRunning && !this._timer?.isPaused) {
					this._timer?.start(this._timer.seconds);
				}
			}

			// move
			result = this._board.move(id, move);

			// evaluate result
			switch (result.result) {
				case MoveResult.Lost:
					this._timer?.stop();
					this._status = GameStatus.Lost;
					break;
				case MoveResult.Won:
					this._timer?.stop();
					this._status = GameStatus.Won;
					break;
				case MoveResult.EndedNoPlay:
					this._timer?.stop();
					this._status = GameStatus.EndedNoPlay;
					break;
				case MoveResult.Accepted:
				case MoveResult.Rejected:
					if (this._status === GameStatus.InitialPlaying && this._board.isInActivePlay()) {
						this._status = GameStatus.ActivePlaying;
					}
					break;
			}
		}

		return result;
	};

	/**
	 * Gets a cell.
	 * @param cellId The cell id.
	 * @returns The cell.
	 */
	public getCell = (cellId: string): Nullable<Cell> => {
		return this._board.getCell(this.getCellId(cellId));
	};

	/**
	 * Gets the cell Id of a cell.
	 * @param cellId The cell Id as a string.
	 * @returns The cell Id.
	 * @private
	 */
	public getCellId = (cellId: string): Nullable<CellId> => {
		let result: Nullable<CellId> = null;

		// the id must be a three part string separated by hyphens: face-y-x
		if (cellId) {
			const split: string[] = cellId.split('-');

			if (split && split.length === 3) {
				const id: CellId = {
					face: split[0],
					x: Number(split[2]),
					y: Number(split[1]),
				};

				if (!isNaN(id.x) && !isNaN(id.y) && id.x >= 0 && id.y >= 0) {
					if (Object.prototype.hasOwnProperty.call(this._definition.faces, id.face)) {
						const faceDefinition: FaceDefinition = this._definition.faces[id.face];

						if (id.x < faceDefinition.width && id.y < faceDefinition.height) {
							result = id;
						}
					}
				}
			}
		}

		return result;
	};

	/**
	 * Pauses the timer.
	 * @returns true if the timer was paused; otherwise, false.
	 */
	public pauseTimer = (): boolean => {
		return this._timer?.pause() || false;
	};

	/**
	 * Resumes the timer.
	 * @returns true if the timer was resumed; otherwise, false.
	 */
	public resumeTimer = (): boolean => {
		return this._timer?.resume() || false;
	};

	/**
	 * Gets the currernt game options.
	 * @returns The game options.
	 */
	public getOptions = (): IGameOptions => {
		return this._options.get();
	};

	/**
	 * Updates the game options.
	 * @param options The options.
	 */
	public updateOptions = (options: IGameOptions): void => {
		this._options.update(options);
	};

	/**
	 * Serializes the game into an object.
	 * @returns The serialized game.
	 */
	public serialize = (): ISerializedVersionedGame => {
		const game: ISerializedGame = {
			id: this._id,
			moveId: this._moveId,
			status: this._status,
			timer: this._timer?.serialize() || null,
			options: { ...this._options.get() },
			board: this._board.serialize(),
			definition: { ...this._definition, onTimer: null },
		};

		const result: ISerializedVersionedGame = {
			version: Constants.serializationVersion,
			game: game,
		};

		return result;
	};

	/**
	 * Creates a game instance from a serialized game.
	 * @param serializedVersionedGame The serialized versioned game.
	 * @param onTimer The onTimer function.
	 * @returns The game; otherwise, null.
	 * @static
	 */
	public static createFromSerialization = (
		serializedVersionedGame: ISerializedVersionedGame,
		onTimer: Nullable<TimerIntervalEvent>): Nullable<Game> => {
		
		let game: Nullable<Game> = null;

		// check version
		if (serializedVersionedGame &&
			serializedVersionedGame.version === Constants.serializationVersion) {

			const serializedGame: ISerializedGame = serializedVersionedGame.game;

			// definition
			const definition: BoardDefinition = {
				...serializedGame.definition,
				onTimer: onTimer,
			};

			// timer
			const timer: Timer = serializedGame.timer
				? Timer.createFromSerialization(serializedGame.timer, onTimer)
				: new Timer(onTimer);
			
			// options
			const options: GameOptions = new GameOptions(serializedGame.options);

			// board
			const board: Board = Board.createFromSerialization(
				serializedGame.board,
				serializedGame.definition,
				options);
			
			// construct game
			game = new Game(
				definition,
				serializedGame.id,
				serializedGame.options,
				{
					moveId: serializedGame.moveId,
					board: board,
					timer: timer,
					status: serializedGame.status,
					options: options,
				}
			);
		}

		return game;
	};
}