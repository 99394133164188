import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { Platform } from '../platform/platform';
import { ICustomProperties } from './interfaces';
import { TelemetryService } from './telemetry-service';

/**
 * The Telemetry class provides functions to log messages and events to App Insights.
 * @export
 * @class Telemetry
 */
export class Telemetry {
	/**
	 * Gets the application insights handle.
	 * @readonly
	 * @private
	 * @static
	 */
	private static get _applictionInsights(): Nullable<ApplicationInsights> {
		return TelemetryService.applicationInsights || null;
	}

	/**
	 * Logs an event.
	 * @param event The event.
	 * @param customProperties The custom event properties; optional.
	 * @static
	 */
	public static event = (event: string, customProperties?: ICustomProperties): void => {
		const properties: ICustomProperties = {
			...customProperties,
			version: metadata.version,
			platform: Platform.appType.name,
		};

		Telemetry._applictionInsights?.trackEvent({ name: event }, properties);
	};

	/**
	 * Logs an info message.
	 * @param info The info message.
	 * @static
	 */
	public static info = (info: string): void => {
		Telemetry._applictionInsights?.trackTrace({
			message: info,
			severityLevel: SeverityLevel.Information,
		});
	};

	/**
	 * Logs a warning message.
	 * @param warning The warning message.
	 * @static
	 */
	public static warning = (warning: string): void => {
		Telemetry._applictionInsights?.trackTrace({
			message: warning,
			severityLevel: SeverityLevel.Warning,
		});
	};

	/**
	 * Logs an error message.
	 * @param error The error message.
	 * @static
	 */
	public static error = (error: string): void => {
		Telemetry._applictionInsights?.trackTrace({
			message: error,
			severityLevel: SeverityLevel.Error,
		});
	};
}