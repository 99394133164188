import * as React from 'react';
import { CellVisibility } from '../../app/cell-visibility';
import { Cell } from '../cell/cell';
import { IProps } from './props';
import { IState } from './state';

/**
 * The FaceRow class implements the <FaceRow/> component.
 * @export
 * @class FaceRow
 * @extends {React.Component<IProps>}
 */
export class FaceRow extends React.Component<IProps, IState> {

	/**
	 * Creates an instance of FaceRow.
	 * @param {IProps} props The props for the <FaceRow/> component.
	 */
	constructor(props: IProps) {
		super(props);

		this.state = { isVisible: true };
	}

	/**
	 * Determines if the row component should be rendered.
	 * @param nextProps The next properties.
	 * @returns true if the row should be rendered; otherwise, false.
	 */
	public shouldComponentUpdate = (nextProps: IProps): boolean => {
		return CellVisibility.getRowVisibility(nextProps.faceId, nextProps.y);
	};

	/**
	 * Renders the <FaceRow/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const rows: number = this.props.gameData?.cells?.length || 0;
		const columns: number = rows > 0 ? this.props.gameData.cells[0].length : 0;

		//return (
		const result = (
			<div
				id={`${this.props.id}`}
				key={`${this.props.id}`}
				style={{
					width: `${this.props.cell.size.width * columns}px`,
					height: `${this.props.cell.size.height}px`,
				}}
			>
				{[...Array(columns)].map((none, x) => {
					const style: React.CSSProperties = {
						top: `${this.props.y * this.props.cell.size.height}px`,
						left: `${x * this.props.cell.size.width}px`,
						width: `${this.props.cell.size.width}px`,
						height: `${this.props.cell.size.height}px`,
						lineHeight: `${this.props.cell.size.height}px`,
					};

					const appData = {
						...this.props.appData?.cells[this.props.y][x],
						style: {
							...this.props.appData?.cells[this.props.y][x].style,
							...style,
						},
						classNames: this.props.cell.classNames.concat(this.props.faceId),
					};

					const key: string = `${this.props.faceId}-${this.props.y}-${x}`;

					const solverData = this.props.solverData
						? (this.props.solverData.cells.get(key) || null)
						: null;

					return <Cell
						key={`${this.props.faceId}-${this.props.y}-${x}`}
						appData={appData}
						gameData={this.props.gameData.cells[this.props.y][x]}
						solverData={solverData}
						eventEnabled={this.props.eventEnabled}
						faceId={this.props.faceId}
						position={{ x: x, y: this.props.y }}
						size={this.props.cell.size}
					/>
				})}
			</div>
		);

		return result;
	};
}