import * as History from 'history';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { SubscriptionEvent, SubscriptionEvents } from '../../../shared/events/subscription-events';
import { IProps } from './props';

/**
 * The route logger component.
 * @param props The props.
 */
export const RouteListener: React.FunctionComponent<IProps> = (props: IProps): JSX.Element => {
	const notifyListeners = (): void => {
		const location = useLocation();

		React.useEffect(
			() => {
				SubscriptionEvent.raise(SubscriptionEvents.RouteChange, location);
			},
			[location]
		);
	};

	notifyListeners();

	return (
		<>{props.children}</>
	);
}

/** 
 * Defines a location.
 */
export type RouteLocation = History.Location<History.History.UnknownFacade>;