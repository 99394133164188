/**
 * Defines the available sound effects.
 * @export
 */
export enum SoundEffect {
	Boom = 'boom',
	Click = 'click',
	Win = 'win',
	Loss = 'loss',
}

/**
 * Defines the types of audio failures.
 * @export
 */
export enum AudioFailure {
	Load = 'Load',
	Play = 'Play',
	SetVolume = 'Set Volume',
}