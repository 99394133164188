/**
 * The Constants class defines constant values for ad providers.
 * @export
 * @class Constants
 */
export class Constants {
	/**
	 * The minimum interval in seconds between showing interstitial ads.
	 * @static
	 */
	public static interstitialAdIntervalSeconds: number = 2 * 60;

	/**
	 * The minimum interval in seconds between showing interstitial ads.
	 * @static
	 */
	public static interstitialAdIntervalSecondsTestMode: number = 25;

	/**
	 * The minimum interval between load retries.
	 * @static
	 */
	public static minIntervalSecondsBetweenLoadRetries: number = 2.5;

	/**
	 * The delay before showing an interstitial ad.
	 * @static
	 */
	public static interstitialAdDelayBeforeShowSeconds: number = 1.5;

	/**
	 * The maximum number of retries when an ad fails to load.
	 * @static
	 */
	public static maxFailedToLoadRetries: number = 10;

	/**
	 * The maximum number of retries when an ad fails to show.
	 * @static
	 */
	public static maxFailedToShowRetries: number = 10;
}