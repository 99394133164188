import { Nullable } from '../../../shared/types';
import { Events } from '../events/events';
import { Platform } from '../platform/platform';
import { PlayStoreShopProvider } from './android/playstore-shopprovider';
import { IShopProvider } from './interfaces';
import { NoOpShopProvider } from './no-op/no-op-shopprovider';

/**
 * The ShopFactory class provides functions to create shop providers.
 * @export
 * @class ShopFactory
 */
export class ShopFactory {
	/**
	 * Gets a shop provider.
	 * @param events The shop event handler.
	 * @returns A shop provider.
	 * @static
	 * @memberof ShopFactory
	 */
	public static getStoreProvider = (events: Events): IShopProvider => {
		let provider: Nullable<IShopProvider> = null;

		if (Platform.isAndroid()) {
			const playStoreProvider = new PlayStoreShopProvider(events);
			provider = playStoreProvider.isEnabled ? playStoreProvider : null;
		}

		return provider ?? new NoOpShopProvider(events);
	};
}