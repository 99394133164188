import { IGameOptions } from './interfaces';
import { Nullable } from './types';

/**
 * The GameOptions class defines options for the game.
 * @export
 * @class Options
 */
export class GameOptions {
	// default options
	private static _defaultOptions: IGameOptions = {
		enableQuestionMark: true,
		enableFlags: true,
	};

	// options
	private _options: IGameOptions = GameOptions._defaultOptions;

	/**
	 * Creates an instance of GameOptions.
	 * @param options The game options.
	 */
	constructor(options: Nullable<IGameOptions>) {
		this.setOptions(options || GameOptions._defaultOptions);
	}

	/**
	 * Gets the game options.
	 */
	public get = (): IGameOptions => {
		return this._options;
	};

	/**
	 * Updates the game options.
	 * @param options The new game options.
	 */
	public update = (options: IGameOptions): void => {
		this.setOptions(options);
	};

	/**
	 * Sets the game options.
	 * @param options The game options.
	 * @private
	 */
	private setOptions = (options: IGameOptions): void => {
		this._options = options;

		// question mark can only be enabled if flags is enabled as well
		this._options.enableQuestionMark = this._options.enableQuestionMark && this._options.enableFlags;
	};
}