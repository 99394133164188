import { Constraint } from './constraint';
import { IVariableMap } from './interfaces';

/**
 * The Cluster class implements a cluster of connected constraints.
 * @export
 * @class Cluster
 */
export class Cluster {
	/**
	 * Gets the variables count.
	 * @readonly
	 */
	public get variablesCount(): number { return this._variableIds.length; }

	private _constraints: Constraint[] = [];
	private _variableIds: string[] = [];
	private _referenceCount: Record<string, number> = {};

	/**
	 *Creates an instance of ConstraintCluster.
	 * @param initialConstraint The initial constraint.
	 */
	constructor(initialConstraint: Constraint) {
		this.forceAdd(initialConstraint);
	}

	/**
	 * Determines if a given constraint is connected to the cluster.
	 * @param constraint The constraint.
	 * @returns true if the constraint is connected; otherwise, false.
	 */
	public isConnected = (constraint: Constraint): boolean => {
		let i: number = 0;
		let result: boolean = false;

		while (i < this._variableIds.length && !result) {
			result = constraint.hasVariableId(this._variableIds[i]);
			i++;
		}

		return result;
	};

	/**
	 * Adds a constraint to the cluster, if it is connected and not already in the cluster.
	 * @param constraint The constraint.
	 * @returns true if the constraint was added; otherwise, false.
	 */
	public add = (constraint: Constraint): boolean => {
		const result = this.isConnected(constraint) && !this.hasConstraint(constraint);

		if (result) {
			this.forceAdd(constraint);
		}

		return result;
	};

	/**
	 * Forces the addition of the cluster; bypassing checks.
	 * @param constraint The constraint.
	 * @remarks The assumption is that the constraint has already been validated.
	 */
	public forceAdd = (constraint: Constraint): void => {
		this._constraints.push(constraint);

		const variableIds: string[] = constraint.getVariableIds();

		variableIds.forEach((id: string) => {
			if (this._referenceCount[id] === undefined) {
				this._variableIds.push(id);
				this._referenceCount[id] = 1;
			}
			else {
				this._referenceCount[id] += 1;
			}
		});

		this._variableIds = this._variableIds.sort((a: string, b: string) => {
			return a > b ? 1 : (a < b ? -1 : 0);
		});
	};

	/**
	 * Removes one or more constraints from the cluster.
	 * @param constraints The constraint or constraints to remove.
	 */
	public removeConstraints = (constraints: Constraint | Constraint[]): void => {
		const remove: Constraint[] = constraints instanceof Array
			? constraints
			: [constraints];
		const removeIds: string[] = constraints instanceof Array
			? constraints.map((constraint: Constraint): string => constraint.id)
			: [constraints.id];
		let variableIds: string[] = [];

		// update reference counts
		remove.forEach((constraint: Constraint): void => {
			variableIds = variableIds.concat(constraint.getVariableIds());
		});

		this.updateVariableReferences(variableIds);

		// remove constraints
		this._constraints = this._constraints.filter((constraint: Constraint): boolean => {
			return removeIds.indexOf(constraint.id) < 0;
		});
	};

	/**
	 * Removes a constraint variable from all cluster constraints.
	 * @param variableId The variable Id.
	 * @param value The mine count value to remove.
	 */
	public removeConstraintVariables = (variableId: string, value: number): void => {
		const removeConstraints: Constraint[] = [];

		// update constraints by removing variables
		this._constraints.forEach((constraint: Constraint): void => {
			if (constraint.removeVariableId(variableId, value)) {
			
				this.updateVariableReferences([variableId]);

				if (constraint.variablesCount === 0) {
					removeConstraints.push(constraint);
				}
			}
		});

		// remove any constraints that have been reduced to nothing
		this.removeConstraints(removeConstraints);
	};

	/**
	 * Gets the variable Ids.
	 * @returns The variable Ids.
	 */
	public getVariableIds = (): string[] => {
		return [...this._variableIds];
	};

	/**
	 * Gets the variable map for the constraint.
	 * @returns the variable map.
	 * @private
	 * @memberof Constraint
	 */
	public getVariableMap = (): IVariableMap => {
		const result: IVariableMap = {
			mapByName: {},
			mapByIndex: {},
		};

		for (let i: number = 0; i < this._variableIds.length; i++) {
			result.mapByName[this._variableIds[i]] = i;
			result.mapByIndex[i] = this._variableIds[i];
		}

		return result;
	};

	/**
	 * Gets the constraints.
	 * @returns The variable Ids.
	 */
	public getConstraints = (): Constraint[] => {
		return [...this._constraints];
	};

	/**
	 * Retrieves a string representation of the cluster.
	 * @returns A string representation of the cluster.
	 */
	public toString = (): string => {
		const constraints: string = this._constraints.map((constraint: Constraint) => constraint.toString()).join('\r\n');
		const variables: string = this._variableIds.join('\r\n');
		
		return `${constraints}\r\n${variables}`;
	};

	/**
	 * Checks if the constraint is already in the cluster.
	 * @param constraint The constraint.
	 * @returns true if the constraint is member of the cluster; otherwise, false.
	 * @private
	 */
	private hasConstraint = (constraint: Constraint): boolean => {
		let i: number = 0;
		let result: boolean = false;

		while (i < this._constraints.length && !result) {
			result = constraint.id === this._constraints[i].id;
			i++;
		}

		return result;
	};

	/**
	 * Updates variable references.
	 * @param variableIds The variable Ids.
	 * @private
	 */
	private updateVariableReferences = (variableIds: string[]): void => {
		// update reference counts
		const removeVariables: string[] = [];

		variableIds.forEach((variableId: string): void => {
			if (this._referenceCount[variableId] !== undefined) {
				this._referenceCount[variableId] -= 1;

				if (this._referenceCount[variableId] === 0) {
					removeVariables.push(variableId);
				}
			}
		});

		// remove unused variables
		if (removeVariables.length > 0) {
			this._variableIds = this._variableIds.filter((variableId: string): boolean => {
				return removeVariables.indexOf(variableId) < 0
			});

			removeVariables.forEach((removeVariable: string): void => {
				delete this._referenceCount[removeVariable];
			});
		}
	};
}