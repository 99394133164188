import * as React from 'react';
import { CellStatus } from '../../../../game-to-app/enums';
import { Nullable } from '../../../../shared/types';
import { SolutionStatus } from '../../../../solver-to-app/enums';
import { Database } from '../../../shared/storage/database';
import { Events } from '../../../shared/telemetry/events';
import { ICustomProperties } from '../../../shared/telemetry/interfaces';
import { Telemetry } from '../../../shared/telemetry/telemetry';
import { Constants } from '../../shared/constants';
import { MouseClick, Player } from '../../shared/enums';
import { IAutoPlayerMove, ICellPosition, ICoordinate, IFaceDefinition } from '../../shared/interfaces';
import { AutoPlayer } from './auto-player';
import bottomPanelAssistantStyles from './bottom-panel-assistant.module.css';
import { AutoPlayerMoveResult } from './enums';
import { IAutoPlayerOptions } from './interfaces';
import { IProps } from './props';
import { IState } from './state';

/**
 * The BottomPanelAssistant class implements the <BottomPanelAssistant/> component.
 * @export
 * @class BottomPanelAssistant
 * @extends {React.Component<IProps>}
 */
export class BottomPanelAssistant extends React.Component<IProps, IState> {
	private _firstMoveDelay: number = 100;
	private _moveDelay: number = 500;

	/**
	 * Creates an instance of BottomPanelAssistant.
	 * @param props The properties.
	 * @memberof BottomPanel
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			isPlaying: false,
			pendingMoveId: 0,
			movesMade: 0,
			moveFaceName: null,
			gameId: props.panel.autoPlayerParms?.game?.id || 0,
		};
	}
	
	/**
	 * A react lifecycle function for the <BottomPanelAssistant/> component.
	 * @param nextProps The next props.
	 * @param prevState The previous state.
	 * @returns The new state.
	 */
	public static getDerivedStateFromProps = (nextProps: IProps, prevState: IState): IState => {
		let result: IState = prevState;

		if (nextProps.panel.autoPlayerParms?.game?.id !== prevState.gameId) {
			if (prevState.pendingMoveId) {
				window.clearTimeout(prevState.pendingMoveId);
			}

			result = {
				...prevState,
				isPlaying: false,
				pendingMoveId: 0,
				movesMade: 0,
				gameId: nextProps.panel.autoPlayerParms?.game?.id || 0,
			};
		}

		return result;
	};
	
	/**
	 * A react lifecycle function for the <BottomPanelAssistant/> component.
	 * @param prevProps The previous props.
	 */
	public componentDidUpdate = (prevProps: IProps): void => {
		if (this.props.panel.autoPlayerParms &&
			prevProps.panel.autoPlayerParms?.move?.id !== this.props.panel.autoPlayerParms.move?.id &&
			this.props.panel.autoPlayerParms.move?.id) {

				this.scheduleMove(this._moveDelay);
		}
	};

	/**
	 * A react lifecycle function for the <BottomPanelAssistant/> component.
	 */
	public componentWillUnmount = (): void => {
		const { pendingMoveId, isPlaying } = this.state;
		
		if (pendingMoveId) {
			window.clearTimeout(pendingMoveId);
		}

		if (isPlaying) {
			this.logTelemetryEvent(Events.AutoPlayEnded);
		}
	};

	/**
	 * Renders the <BottomPanelAssistant/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const iconClassName: string = this.state.isPlaying
			? bottomPanelAssistantStyles.pause
			: bottomPanelAssistantStyles.play;
		
		return (
			<div
				className={`bg-dark ${bottomPanelAssistantStyles.panel}`}
			>
				<div className={bottomPanelAssistantStyles.player}>
					<div
						className={iconClassName}
						onClick={this.onClickPlay}
					></div>
				</div>
			</div>
		);
	};

	/**
	 * The click handler for the play / pause button.
	 */
	private onClickPlay = (): void => {
		this.setState((prevState: Readonly<IState>) => {
				if (prevState.pendingMoveId) {
					window.clearTimeout(prevState.pendingMoveId);
				}

				const isPlaying: boolean = !prevState.isPlaying;

				return ({
					isPlaying: isPlaying,
					pendingMoveId: 0,
					movesMade: 0,
				});
			},
			(): void => {
				if (this.state.isPlaying) {
					this.logTelemetryEvent(Events.AutoPlayStarted);
					this.scheduleMove(this._firstMoveDelay);
				}
				else {
					this.logTelemetryEvent(Events.AutoPlayEnded);
				}
			}
		);
	};

	/**
	 * Schedules an auto-move.
	 * @param delay The delay before the move is executed.
	 */
	private scheduleMove = (delay: number): void => {
		this.setState((prevState: Readonly<IState>) => {
			let result = null;

			if (prevState.isPlaying && !prevState.pendingMoveId) {
				const pendingMoveId: number = window.setTimeout(this.makeAutoMove, delay);

				result = {
					pendingMoveId: pendingMoveId
				};
			}
			
			return result;
		});
	};

	/**
	 * Makes an auto-move.
	 */
	public makeAutoMove = (): void => {
		const { autoPlayerParms } = this.props.panel;

		this.setState((prevState: Readonly<IState>) => {
				return ({
					pendingMoveId: 0,
					movesMade: prevState.movesMade + 1,
				});
			},
			(): void => {
				if (this.state.isPlaying && autoPlayerParms) {
					// game options
					const gameFlagCellsEnabled: boolean = autoPlayerParms.game?.getOptions().enableFlags || false;

					// set options
					const options: IAutoPlayerOptions = {
						flagCells: gameFlagCellsEnabled && Database.settings.get().solver.autoPlayer.flagCells,
					};

					// move if not paused
					const result: IAutoPlayerMove = new AutoPlayer(
						autoPlayerParms,
						options).move();
	
					switch (result.result) {
						case AutoPlayerMoveResult.GameOver:
							// stop player at the end of the game
							this.onClickPlay();
							break;
						case AutoPlayerMoveResult.Moved:
							this.makeMove(result.cellId, result.status);
							break;
						case AutoPlayerMoveResult.NoSafeMoveFound:
							// do nothing
							break;
					}
				}
			}
		);
	};

	/**
	 * Makes the move on the board.
	 * @param cellId The id of the cell to click.
	 * @param solutionStatus The solution status.
	 * @returns true if a move was made; otherwise, false.
	 * @private
	 */
	private makeMove = (cellId: Nullable<string>, solutionStatus: Nullable<SolutionStatus>): boolean => {
		let result: boolean = false;

		if (cellId && solutionStatus != null) {
			let centerTargetPosition: Nullable<ICellPosition> = null;
			const faceName = this.props.panel.autoPlayerParms?.game?.getCellId(cellId)?.face || null;
			result = !!faceName;

			if (result) {
				if (!this.state.moveFaceName || faceName !== this.state.moveFaceName) {
					this.rotateFaceToFront(faceName);

					this.setState(({ moveFaceName: faceName }));
				}

				if (this.props.panel.autoPlayerParms?.onPanIntoView) {
					centerTargetPosition = this.getRelativeCenterTargetPosition(cellId);

					// if the cell is not in view, then pan it into the center
					if (centerTargetPosition) {
						this.props.panel.autoPlayerParms.onPanIntoView(centerTargetPosition);
					}
				}

				if (this.props.panel.autoPlayerParms?.game?.getCell(cellId)?.status === CellStatus.Question) {
					// clear the question mark, the next move would then actually uncover it
					this.props.panel.autoPlayerParms?.onClickCell(cellId, MouseClick.Right, Player.Assistant);
				}
				else {
					let click: MouseClick = MouseClick.None;

					switch (solutionStatus) {
						case SolutionStatus.Discover:
							click = MouseClick.Middle;
							break;
						case SolutionStatus.Mine:
							click = MouseClick.Right;
							break;
						case SolutionStatus.Clear:
							click = MouseClick.Left;
							break;
					}

					if (click !== MouseClick.None) {
						this.props.panel.autoPlayerParms?.onClickCell(cellId as string, click, Player.Assistant);
					}
					else {
						result = false;
					}
				}
			}
		}

		return result;
	};

	/**
	 * Rotates the given face to the front.
	 * @param faceName The face name.
	 * @private
	 */
	private rotateFaceToFront = (faceName: Nullable<string>): void => {
		const face: Nullable<IFaceDefinition> = this.props.panel.autoPlayerParms?.boardShape?.getFace(faceName) || null;

		if (face) {
			const spin: ICoordinate = this.props.panel.autoPlayerParms?.spin || { x: 0, y: 0 };

			let rotate = {
				x: (face.rotate.x ? -(face.rotate?.deg || 0) : 0),
				y: (face.rotate.y ? -(face.rotate?.deg || 0) : 0),
				z: 0,
			}

			// spin
			const x: number = (rotate.x || 0) - spin.y;
			const y: number = Math.abs(x % 360) > 90 && Math.abs(x % 360) < 270
				? (rotate?.y || 0) - spin.x
				: (rotate?.y || 0) + spin.x;

			rotate = { ...rotate, x: x, y: y };

			if (this.props.panel.autoPlayerParms?.onRotate) {
				this.props.panel.autoPlayerParms?.onRotate(rotate);
			}
		}
	};

	/**
	 * Gets the center target position.
	 * @param cellId The cell id.
	 * @returns The center target position if the cell is not in view; otherwise, null.
	 */
	private getRelativeCenterTargetPosition = (cellId: string): Nullable<ICellPosition> => {
		let result: Nullable<ICellPosition> = null;
		const position: Nullable<ICellPosition> = this.getCellPosition(cellId);

		if (position) {
			// if the cell is not visible in full, then set the target position
			if (position.relative.x < 0 ||
				position.relative.y < 0 ||
				position.relative.x + position.relative.width > position.zoom.width ||
				position.relative.y + position.relative.height > position.zoom.height) {

				result = position;
			}
		}

		return result;
	};

	/**
	 * Gets the cell position.
	 * @param cellId The cell id.
	 * @returns The cell position.
	 */
	private getCellPosition = (cellId: string): Nullable<ICellPosition> => {
		let result: Nullable<ICellPosition> = null;

		// get references
		const cellRef: Nullable<React.RefObject<HTMLDivElement>> =
			this.props.panel.autoPlayerParms?.components.get(cellId) || null;
		
		const zoomRef: Nullable<React.RefObject<HTMLDivElement>> = cellRef
			? (this.props.panel.autoPlayerParms?.components.get(Constants.zoomComponentId) || null)
			: null;

		// get bounding rects
		const cellRect: Nullable<DOMRect> = cellRef?.current?.getBoundingClientRect() || null;
		const zoomRect: Nullable<DOMRect> = zoomRef?.current?.getBoundingClientRect() || null;

		if (cellRect && zoomRect) {
			// get their relative position
			result = {
				cell: cellRect,
				zoom: zoomRect,
				relative: {
					x: cellRect.x - zoomRect.x,
					y: cellRect.y - zoomRect.y,
					width: cellRect.width,
					height: cellRect.height,
				},
			}
		}

		return result;
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}