import * as React from 'react';
import { Events } from '../../../shared/telemetry/events';
import { ICustomProperties } from '../../../shared/telemetry/interfaces';
import { Telemetry } from '../../../shared/telemetry/telemetry';
import { SplashStyle } from '../../shared/enums';
import { Splash } from '../../splash/splash';
import { IProps } from './props';
import { IState } from './state';

/**
 * The ErrorBoundary class implements an error boundary component.
 */
export class ErrorBoundary extends React.Component<IProps, IState> {
	/**
	 * Creates an instance of ErrorBoundary.
	 * @param {IProps} props The props.
	 * @memberof ErrorBoundary
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			hasError: false,
			error: null,
		};
	}

	/**
	 * A react lifecycle function for the <ErrorBoundary/> component.
	 * @param error The error.
	 * @param info The error info.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @typescript-eslint/explicit-module-boundary-types
	public componentDidCatch = (error: Error | null, info: any): void => {
		this.setState({
			hasError: true,
			error: error,
		});

		this.logTelemetryEvent(
			this.props.event,
			{
				source: this.props.source,
				errorCode: this.props.errorCode,
				info: info,
			}
		)
	};

	/**
	 * Renders the <ErrorBoundary/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		let content: React.ReactNode = <>{this.props.children}</>;

		if (this.state.hasError) {
			content = (
				<Splash
					style={SplashStyle.Custom}
					showSpinner={false}
					message={[
						'Something went wrong. Please restart the application.',
						`(Error code: ${this.props.errorCode})`,
					]}
				/>
			);
		}

		return content;
	}

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}