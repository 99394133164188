import { Solver } from '../solver/solver';
import { IBoard, ISolver, ISolverOptions } from './interfaces';

/**
 * The SolverFactory class implements a factory for minesweeper solvers.
 * @export
 * @class SolverFactory
 */
export class SolverFactory {
	public static get = (board: IBoard, options: ISolverOptions = Solver.defaultOptions): ISolver => {
		return new Solver(board, options);
	}
}