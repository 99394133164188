import deepequal from 'deep-equal';
import deepmerge from 'deepmerge';
import { SubscriptionEvent, SubscriptionEvents } from '../../events/subscription-events';
import { deepcopy } from '../../utils';
import { IModelCacheManager, IStore } from '../model-interfaces';
import { IProduct, IProductUpdate } from '../views/products';

/**
 * The ProductsStore class implements the store for products.
 * @export
 * @class ProductsStore
 */
export class ProductsStore implements IStore {
	private _cacheManager: IModelCacheManager;
	private _products: Record<string, IProduct>;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._cacheManager.name; }

	/**
	 * Creates an instance of ProductsStore.
	 * @param {IModelCacheManager} cacheManager The cache manager.
	 * @memberof ProductsStore
	 */
	constructor(cacheManager: IModelCacheManager) {
		this._cacheManager = cacheManager;
		this._products = this._cacheManager.get();
	}

	/**
	 * Gets all products.
	 * @param refresh A Boolean value indicating if the products should be taken from the most recent database cache.
	 * @returns The products.
	 */
	public get = (refresh: boolean = false): Record<string, IProduct> => {
		if (refresh) {
			const refreshedProducts: Record<string, IProduct> = this._cacheManager.get() as Record<string, IProduct>;

			if (!deepequal(refreshedProducts, this._products)) {
				this._products = refreshedProducts;
				SubscriptionEvent.raise(SubscriptionEvents.ProductsChanged, this.name, this._products);
			}
		}

		return deepcopy(this._products);
	};

	/**
	 * Updates a product.
	 * @param product The product to update.
	 * @param refresh A Boolean value indicating if the product should be taken from the most recent database cache.
	 * @returns The updated product.
	 */
	public update = async (product: IProductUpdate, refresh: boolean = false): Promise<Record<string, IProduct>> => {
		const previousProducts = this._products;
		// update all product, change the values for the specific id
		let updatedProducts: Record<string, IProduct> = deepcopy(this._products);
		const productForId: IProduct = updatedProducts[product.id];
		const updatedProductForId: IProduct = deepmerge(productForId, product);
		updatedProducts[product.id] = updatedProductForId;
		
		if (!deepequal(updatedProducts, previousProducts)) {		
			// update the store products synchronously
			this._products = updatedProducts;
			
			if (!refresh) {
				SubscriptionEvent.raise(SubscriptionEvents.ProductsChanged, this.name, this._products);
			}

			// update the database
			updatedProducts = await this._cacheManager
				.update(product)
				.catch(() => {

					// set updated products in case of update failure
					updatedProducts = this._products;
				});

			// optionally update the products asynchronously from the database
			if (refresh && !deepequal(updatedProducts, this._products)) {
				this._products = updatedProducts;
				SubscriptionEvent.raise(SubscriptionEvents.ProductsChanged, this.name, this._products);
			}
		}

		return this._products;
	};

	/**
	 * Deletes a product.
	 * @param id The product id to delete.
	 * @returns The refreshed products.
	 */
	public delete = async (id: string): Promise<Record<string, IProduct>> => {
		let updatedProducts: Record<string, IProduct> = await this._cacheManager
			.delete(id)
			.catch(() => {
				// remove
				updatedProducts = deepcopy(this._products);
				delete updatedProducts[id];
			});
		
		this._products = updatedProducts;
		SubscriptionEvent.raise(SubscriptionEvents.ProductsChanged, this.name, this._products);

		return this._products;
	};
}