import { Cluster } from './cluster';
import { ClusterBuilder } from './cluster-builder';
import { ClusterSolver } from './cluster-solver';
import { Constraint } from './constraint';
import { CellStatus, SolutionCalculationMethod, SolutionStatus } from './enums';
import { IBoard, ICell, ISolution, ISolverOptions, IUncoveredCell } from './interfaces';
import { Timer } from './timer';

/**
 * The Solver class implements a solver for minesweeper.
 * @export
 * @class Solver
 */
export class Solver {
	// default options
	private static _defaultOptions: ISolverOptions = {
		timeout: 500,
		enableDiscovery: true,
	};

	private readonly _board: IBoard;
	private readonly _options: ISolverOptions = Solver._defaultOptions;

	/**
	 * Gets the default solver options.
	 * @readonly
	 */
	public static get defaultOptions(): ISolverOptions { return Solver._defaultOptions; }

	/**
	 *Creates an instance of Solver.
	 * @param board The minesweeper board.
	 * @param options The solver options.
	 * @memberof Solver
	 */
	constructor(board: IBoard, options: ISolverOptions = Solver._defaultOptions) {
		this._board = board;
		this._options = options;
	}

	/**
	 * Solves minesweeper for a given board.
	 * @returns An array of solutions.
	 */
	public solve = (): ISolution[] => {
		let result: ISolution[] = [];
		
		// start a timer
		const timer: Timer = new Timer(this._options.timeout);
		timer.start();
		
		// get all uncovered cells
		const uncoveredCells: IUncoveredCell[] = this.getUncoveredCells();

		// gets cells ready for discovery
		result = this.getDiscoveryCells(uncoveredCells);
		
		// get constraints based on covered neighbors
		const constraints: Constraint[] = this.getConstraints(uncoveredCells);

		// clusters are formed by chains of connected constraints
		// constraints are connected if they have one or more variables in common
		const clusters: Cluster[] = ClusterBuilder.build(constraints);
		const clusterSolvers = clusters.map((cluster: Cluster) => new ClusterSolver(cluster, timer));

		let remainingMines = this._board.remainingMines;

		// solve each cluster individually (trivial)
		// timer is checked in the cluster solver; which will add the 'unsolved' results when needed
		clusterSolvers.forEach((clusterSolver: ClusterSolver): void => {
			const clusterResult: ISolution[] = clusterSolver.solveTrivial();
			result = result.concat(clusterResult);

			remainingMines -= clusterResult.reduce((accumulator: number, solution: ISolution) =>
				accumulator + (solution.status === SolutionStatus.Mine ? 1 : 0),
				0);
		});

		if (remainingMines > 0) {
			// solve each cluster individually (non-trivial)
			// timer is checked in the cluster solver; which will add the 'unsolved' results when needed
			clusterSolvers.forEach((clusterSolver: ClusterSolver): void => {
				const clusterResult: ISolution[] = clusterSolver.solveNonTrivial(remainingMines);
				result = result.concat(clusterResult);

				remainingMines -= clusterResult.reduce((accumulator: number, solution: ISolution) =>
					accumulator + (solution.status === SolutionStatus.Mine ? 1 : 0),
					0);
			});
		}

		// stop timer
		timer.stop();
		
		return result;
	};

	/**
	 * Gets the uncovered cells that have one or more covered neighbors.
	 * @returns An array of uncovered cells.
	 * @private
	 */
	private getUncoveredCells = (): IUncoveredCell[] => {
		const result: IUncoveredCell[] = [];

		if (this._board?.cells) {
			this._board.cells.forEach((cell: ICell) => {
				if (cell?.status === CellStatus.Uncovered) {
					const neighbors: ICell[] = [];
					let remaining: number = cell.neighborMineCount || 0;

					cell.neighbors.forEach((neighbor: ICell) => {
						switch (neighbor.status) {
							case CellStatus.Flagged:
								remaining -= 1;
								break;
							case CellStatus.Covered:
								neighbors.push(neighbor);
								break;
							default:
								// do nothing
								break;
						}
					});

					if (neighbors.length > 0) {
						result.push({
							...cell,
							remainingMineCount: remaining,
							coveredNeighbors: neighbors,
						});
					}
				}
			});
		}

		return result;
	};

	/**
	 * Gets constraints based on uncovered cells.
	 * @param uncoveredCells The uncovered cells.
	 * @private
	 */
	private getConstraints = (uncoveredCells: IUncoveredCell[]): Constraint[] => {
		const result: Constraint[] = [];

		// each uncovered cell is converted to a constraint
		// the covered neighbors are the variables that need to add up to the remaining (unsatisfied) mine count
		// of the uncovered cell.
		if (uncoveredCells.length > 0) {
			uncoveredCells.forEach((cell: IUncoveredCell) => {
				const variableIds: string[] = cell.coveredNeighbors.map((c: ICell) => c.id);
				
				const constraint: Constraint = new Constraint(
					cell.id,
					variableIds,
					cell.remainingMineCount
				);

				result.push(constraint);
			});
		}

		return result.sort();
	};

	/**
	 * Gets uncovered cells that are available for discovery.
	 * @param uncoveredCells The uncovered cells.
	 * @private
	 */
	private getDiscoveryCells = (uncoveredCells: IUncoveredCell[]): ISolution[] => {
		let result: ISolution[] = [];

		// if enabled, get the cells that have at least one covered neighbor and have all their mines flagged.
		if (this._options.enableDiscovery) {
			result = uncoveredCells
				.filter((uncoveredCell: IUncoveredCell): boolean =>
					uncoveredCell.remainingMineCount === 0 &&
					(uncoveredCell.neighborMineCount || 0) > 0 &&
					uncoveredCell.coveredNeighbors.length > 0)
				.map((uncoveredCell: IUncoveredCell): ISolution => {
					return ({
						id: uncoveredCell.id,
						status: SolutionStatus.Discover,
						isMineProbability: 0,
						method: SolutionCalculationMethod.Trivial,
					});
				});
		}

		return result;
	}
}