import * as React from 'react';
import placeholderStyles from './placeholder.module.css';
import { IProps } from './props';

/**
 * The Placeholder class implements the <Placeholder/> component.
 * @export
 * @class Placeholder
 * @extends {React.Component<IProps>}
 */
export class Placeholder extends React.Component<IProps> {

	/**
	 * Creates an instance of Placeholder.
	 * @param {IProps} props The props for the <Placeholder/> component.
	 * @memberof Placeholder
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <Placeholder/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const maxHeight: number =
			this.props.size.window.boardHeight +
			this.props.size.window.navbarHeight;
		
		return (
			this.props.show
				? <div
					className={`${placeholderStyles.placeholder} row flex-fill`}
					style={{
						paddingTop: `${this.props.size.window.navbarHeight}px`,
						maxHeight: `${maxHeight}px`,
						height: `${maxHeight}px`,
					}}
				>
				</div>
				: null
		);
	};
}