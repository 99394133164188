import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Constants } from '../../shared/constants';
import { Formatter } from '../../shared/formatter';
import { IProps } from './props';
import scoreStyles from './score.module.css';

/**
 * The Score class implements the <Score/> component.
 * @export
 * @class Score
 * @extends {React.Component<IProps>}
 */
export class Score extends React.Component<IProps> {

	/**
	 * Creates an instance of Score.
	 * @param {IProps} props
	 * @memberof Score
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <Score/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const timer: string = Formatter.time(this.props.score.seconds, Constants.maxSeconds, 9);
		const classNames: string = [
			scoreStyles.timer,
			this.props.score.seconds > Constants.maxSeconds ? scoreStyles.expired : scoreStyles.running].join(' ');
		const mines = Formatter.count(this.props.score.mines, Constants.maxMines);

		return (
			<Container
				id='score'
				key='score'
				className={'d-inline-flex'}
			>
				<Col>
					<div className={`${scoreStyles.image} ${scoreStyles.large} ${scoreStyles.stopwatch}`} />
					<div className={classNames}>
						{timer}
					</div>
					<div className={scoreStyles.flags}>
						<div className={`${scoreStyles.image} ${scoreStyles.large} ${scoreStyles.flag}`} />
						<div className={scoreStyles.count}>{mines}</div>
					</div>
				</Col>
			</Container>
		);
	};
}