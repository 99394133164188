import { faExclamationCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Platform } from '../../../shared/platform/platform';
import { WhatsNew } from '../../shared/enums';
import notificationStyles from '../notification.module.css';
import { IWhatsNew } from './interfaces';
import { IProps } from './props';

/**
 * The NotificationQuickStartLaunch class implements the <NotificationQuickStartLaunch/> component.
 * @remarks The control shows a link to launch quick start help.
 * @export
 * @class NotificationQuickStartLaunch
 * @extends {React.Component}
 */
export class NotificationQuickStartLaunch extends React.Component<IProps> {

	/**
	 * Creates an instance of NotificationQuickStartLaunch.
	 * @param {IProps} props The props for the <NotificationQuickStartLaunch/> component.
	 * @memberof NotificationQuickStartLaunch
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <NotificationQuickStartLaunch/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const whatsNew: IWhatsNew[] = Platform.isAndroid()
			? [
				{ description: 'Try the Assistant mode', onClick: this.onClickWhatsNewAssistant },
				{ description: 'Create your own custom levels', onClick: this.onClickWhatsNewCustomLevels },
				{ description: 'Visit the Shop', onClick: this.onClickWhatsNewVisitShop },
			]
			: [
				{ description: 'Get the Android app', onClick: this.onClickWhatsNewGetAndroidApp },
				{ description: 'Try the Assistant mode', onClick: this.onClickWhatsNewAssistant },
				{ description: 'Create your own custom levels', onClick: this.onClickWhatsNewCustomLevels },
			];
		
		return (
			<Container className={notificationStyles.wrapper}>
				<Container className='d-flex justify-content-center'>
					<Row xs={1}>
						<Col>
							<div className={`${notificationStyles.lg} ${notificationStyles.padded} text-center`}>
								<div className={notificationStyles.padded}>
									<div className={notificationStyles.darkText}>
										<FontAwesomeIcon
											className={`${notificationStyles.icon} ${notificationStyles.lg}`}
											icon={faExclamationCircle}
										/>
										What&apos;s New
									</div>
									{whatsNew.map((what: IWhatsNew) => {
										return (<div key={what.description}>
											<a
												className={`${notificationStyles.link} ${notificationStyles.sm}`}
												href='#'
												onClick={what.onClick}
											>
												{what.description}
											</a>
										</div>);
									})}
								</div>
								<div>
									<div className={`${notificationStyles.darkText} ${notificationStyles.lg}`}>
										<FontAwesomeIcon
											className={`${notificationStyles.icon} ${notificationStyles.lg}`}
											icon={faQuestionCircle}
										/>
										Quick Start
									</div>
									<a
										className={`${notificationStyles.link} ${notificationStyles.sm}`}
										href='#'
										onClick={this.props.notification.onClickLaunch}
									>
										Start Tutorial
									</a>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	};

	/**
	 * The event handler for when the user clicks the What's new 'Get Android app' item.
	 */
	private onClickWhatsNewGetAndroidApp = (): void => {
		this.props.notification.onClickWhatsNew(WhatsNew.GetAndroidApp);
	};

	/**
	 * The event handler for when the user clicks the What's new 'Assistant' item.
	 */
	private onClickWhatsNewAssistant = (): void => {
		this.props.notification.onClickWhatsNew(WhatsNew.Assistant);
	};

	/**
	 * The event handler for when the user clicks the What's new 'Custom Levels' item.
	 */
	private onClickWhatsNewCustomLevels = (): void => {
		this.props.notification.onClickWhatsNew(WhatsNew.CustomLevels);
	};

	/**
	 * The event handler for when the user clicks the What's new 'Visit Shop' item.
	 */
	private onClickWhatsNewVisitShop = (): void => {
		this.props.notification.onClickWhatsNew(WhatsNew.VisitShop);
	};
}