import { Nullable } from '../../../../shared/types';
import { IAudio, IAudioManager, IAudioMetadata } from '../../interfaces';
import { Database } from '../../storage/database';
import { ISettings } from '../../storage/views/settings';
import { Events } from '../../telemetry/events';
import { ICustomProperties } from '../../telemetry/interfaces';
import { Telemetry } from '../../telemetry/telemetry';
import { AudioFailure, SoundEffect } from '../enums';
import { AudioSource } from './audio-source';

/**
 * The AudioManagerWeb class implements functions to manage sound effects for the web.
 * @export
 * @class AudioManagerWeb
 */
export class AudioManagerWeb implements IAudioManager {
	private _soundEffects: Record<string, IAudio> = {};

	/**
	 * Plays the requested sound effect.
	 * @param id The sound effect id.
	 * @returns true if the sound is played; otherwise, false.
	 */
	public playSoundEffect = (id: SoundEffect): boolean => {
		const effect: Nullable<IAudio> = this.get(id);
		let result: boolean = false;

		if (effect && effect.audio) {
			AudioManagerWeb.refreshVolume(effect);

			if (!effect.volume.muted) {
				result = true;
				effect.audio.volume = (effect.volume.volume * effect.level);
				effect.audio.play()
					.catch((error) => {
						this.logTelemetryEvent(
							Events.AudioFailure,
							{
								asset: effect.id,
								failure: AudioFailure.Play,
								error: error,
							}
						);
					});
			}
		}

		return result;
	};

	/**
	 * Loads the sound effects.
	 * @returns A promise that resolves when all sound effects have loaded.
	 * @static
	 */
	public load = (): Promise<boolean> => {
		this.initialize();
		
		const keys = Object.keys(this._soundEffects);
		const soundEffectsBySrc: Record<string, IAudio> = {};
		let remaining: number = keys.length;

		return new Promise((resolve) => {
			if (remaining === 0) {
				resolve(true);
			}
			else {
				keys.forEach((key: string) => {
					const soundEffect: IAudio = this._soundEffects[key];

					if (soundEffect.audio?.src) {
						soundEffectsBySrc[soundEffect.audio.src] = soundEffect;
					}

					if (soundEffect.audio) {
						// event fires when enough of the file was loaded to play without pause
						soundEffect.audio.addEventListener(
							'canplaythrough',
							(event) => {
								if (event?.target) {
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									const src: string = (event.target as any).src;

									// mark as loaded
									if (soundEffectsBySrc[src]) {
										soundEffectsBySrc[src].loaded = true;
									}
								}

								// resolve when no effects remain
								remaining -= 1;
								
								if (remaining === 0) {
									resolve(true);
								}
							},
							false);
					
							// fires when the file cannot be loaded
							soundEffect.audio.addEventListener(
								'error',
								(error) => {
									this.logTelemetryEvent(
										Events.AudioFailure,
										{
											failure: AudioFailure.Load,
											error: error,
										}
									);
									remaining -= 1;

									// don't fail, even if not loaded
									if (remaining === 0) {
										resolve(true);
									}
								},
								false);
					}
					else {
						remaining -= 1;

						// don't fail, even if not loaded
						if (remaining === 0) {
							resolve(true);
						}
					}
				});
			}
		});
	};

	/**
	 * Initializes the sound effects.
	 * @returns The sound effects.
	 * @private
	 */
	private initialize = (): void => {
		const settings: ISettings = Database.settings.get();
		const metadata: IAudioMetadata[] = AudioManagerWeb.getMetadata();
		const masterVolume = settings.sound.volume.volume;

		metadata.forEach((meta: IAudioMetadata) => {
			const effectVolume: number = settings.sound.sfx && settings.sound.sfx[meta.id]
				? settings.sound.sfx[meta.id].volume
				: 1;
			const soundVolume = masterVolume * effectVolume;

			this._soundEffects[meta.id] = {
				id: meta.id,
				source: meta.source,
				level: meta.level,
				volume: { volume: soundVolume, muted: soundVolume === 0 },
				audio: new Audio(meta.source),
				loaded: false,
			};
		});
	};

	/**
	 * Gets the requested sound effect.
	 * @param id The sound effect id.
	 * @returns The sound effect.
	 * @memberof SoundEffects
	 */
	private get = (id: SoundEffect): Nullable<IAudio> => {
		const soundEffect: IAudio = this._soundEffects[id];

		return soundEffect && soundEffect.loaded ? soundEffect : null;
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};

	/**
	 * Gets the sound effects metadata.
	 * @returns The sound effects metadata.
	 * @private
	 * @static
	 */
	private static getMetadata = (): IAudioMetadata[] => {
		const audioSource = new AudioSource();

		return [
			{
				id: SoundEffect.Boom,
				source: audioSource.boom,
				level: 0.5,
			},
			{
				id: SoundEffect.Click,
				source: audioSource.click,
				level: 0.1,
			},
			{
				id: SoundEffect.Loss,
				source: audioSource.loss,
				level: 0.5,
			},
			{
				id: SoundEffect.Win,
				source: audioSource.win,
				level: 0.1,
			},
		];
	};

	/**
	 * Gets the volume for the given sound effect.
	 * @param audio The sound effect.
	 * @returns The sound effect volume.
	 * @private
	 * @static
	 */
	private static refreshVolume = (audio: IAudio): void => {
		const settings: ISettings = Database.settings.get();

		// volume
		const masterVolume = settings.sound.volume.volume;
		const effectVolume: number = settings.sound.sfx && settings.sound.sfx[audio.id]
			? settings.sound.sfx[audio.id].volume
			: 1;
		const soundVolume = masterVolume * effectVolume;

		// update
		audio.volume = { volume: soundVolume, muted: soundVolume === 0 }
	};
}