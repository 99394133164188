import * as React from 'react';
import { CellVisibility } from '../../app/cell-visibility';
import { FaceGrid } from '../face-grid/face-grid';
import { FaceRow } from '../face-row/face-row';
import { IProps } from './props';

/**
 * The Face class implements the <Face/> component.
 * @export
 * @class Face
 * @extends {React.Component<IProps>}
 */
export class Face extends React.Component<IProps> {

	/**
	 * Creates an instance of Face.
	 * @param {IProps} props The props for the <Face/> component.
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {};
	}

	/**
	 * A react lifecycle function for the <Face/> component.
	 */
	public componentDidMount = (): void => {
		const rows: number = this.props.gameData?.cells?.length || 0;
		const columns: number = rows > 0 ? this.props.gameData.cells[0].length : 0;

		// for optimized performance, initialize visible rows/columns
		CellVisibility.initialize(this.props.id, { height: rows, width: columns });
	};

	/**
	 * Renders the <Face/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const rows: number = this.props.gameData?.cells?.length || 0;
		const columns: number = rows > 0 ? this.props.gameData.cells[0].length : 0;

		//return (
		const result = (
			<div>
				<div
					id={this.props.id}
					key={this.props.id}
					className={this.props.appData?.classNames.join(' ')}
				>
					{[...Array(rows)].map((none, y) => {
						return (
							<FaceRow
								id={`${this.props.id}-row-${y}`}
								key={`${this.props.id}-row-${y}`}
								faceId={this.props.id}
								appData={this.props.appData}
								gameData={this.props.gameData}
								solverData={this.props.solverData}
								cell={this.props.cell}
								eventEnabled={this.props.eventEnabled}
								y={y}
							/>
						)
					})}
				</div>
				{/* The invisible grid used to control cell rendering */}
				<FaceGrid
					faceId={this.props.id}
					rows={rows}
					columns={columns}
					size={this.props.cell.size}
				/>
			</div>
		);

		return result;
	};
}