import { Style } from './enums';
import { IGameLimits } from './interfaces';

/**
 * Defines enabled faces for a 'cube' or 'box' style game.
 */
export const EnabledBox: Record<string, boolean> = {
	front: true,
	back: true,
	left: true,
	right: true,
	top: true,
	bottom: true,
};

/**
 * Defines enabled faces for a 'classic' style game.
 */
export const EnabledClassic: Record<string, boolean> = {
	front: true,
	back: false,
	left: false,
	right: false,
	top: false,
	bottom: false,
};

/**
 * Defines enabled faces for a 'pane' style game with three panes.
 */
export const EnabledPaneThree: Record<string, boolean> = {
	one: true,
	two: true,
	three: true,
};

/**
 * Defines enabled faces for a 'pane' style game with four panes.
 */
export const EnabledPaneFour: Record<string, boolean> = {
	one: true,
	two: true,
	three: true,
	four: true,
};

/**
 * Defines the min/max limits for all game styles.
 */
export const GameLimits: Record<Style, IGameLimits> = {
	[Style.Cube]: {
		dimensions: {
			width: {
				min: 2,
				max: 20,
			},
			height: {
				min: 2,
				max: 20,
			},
			depth: {
				min: 2,
				max: 20,
			},
		},
		mines: {
			min: 1,
		},
		hintLimit: {
			min: 0,
			max: 10,
		},
	},
	[Style.Box]: {
		dimensions: {
			width: {
				min: 2,
				max: 20,
			},
			height: {
				min: 2,
				max: 20,
			},
			depth: {
				min: 1,
				max: 20,
			},
		},
		mines: {
			min: 1,
		},
		hintLimit: {
			min: 0,
			max: 10,
		},
	},
	[Style.Pane]: {
		dimensions: {
			width: {
				min: 2,
				max: 20,
			},
			height: {
				min: 2,
				max: 20,
			},
			depth: {
				min: 3,
				max: 4,
			},
		},
		mines: {
			min: 1,
		},
		hintLimit: {
			min: 0,
			max: 10,
		},
	},
	[Style.Classic]: {
		dimensions: {
			width: {
				min: 2,
				max: 100,
			},
			height: {
				min: 2,
				max: 100,
			},
			depth: {
				min: 0,
				max: 0,
			},
		},
		mines: {
			min: 1,
		},
		hintLimit: {
			min: 0,
			max: 10,
		},
	},
};