import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { IIdValue, ILevelDefinition } from '../../../../game-to-app/interfaces';
import { SocialMediaShareType } from '../../shared/enums';
import { GameStyle } from '../../shared/game-style';
import { LevelManager } from '../../shared/level-manager';
import notificationStyles from '../notification.module.css';
import { IState } from './state';
import gameoverStyles from './notification-game-over.module.css';
import { IProps } from './props';
import { Levels, Style } from '../../../../game-to-app/enums';
import { SelectStyleLevel } from './enums';
import { Nullable } from '../../../../shared/types';
import { DropdownSelector } from '../../dropdown/dropdown-selector';
import { IDropdownItem } from '../../shared/interfaces';

/**
 * The NotificationGameOver class implements the <NotificationGameOver/> component.
 * @remarks The control shows a game over message.
 * @export
 * @class NotificationGameOver
 * @extends {React.Component}
 */
export class NotificationGameOver extends React.Component<IProps, IState> {
	/**
	 * Creates an instance of NotificationGameOver.
	 * @param {IProps} props The props for the <NotificationGameOver/> component.
	 * @memberof NotificationGameOver
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			level: null,
			selectLevel: SelectStyleLevel.Select,
			changedLevel: false,
		};
	}

	/**
	 * A react lifecycle function for the <NotificationGameOver/> component.
	 * @remarks Do not render if the show property is false.
	 */
	shouldComponentUpdate = (nextProps: IProps): boolean => {
		return nextProps.notification.show;
	}

	/**
	 * Renders the <NotificationGameOver/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { level: stateLevelId, selectLevel, changedLevel } = this.state;
		const { notification } = this.props;
		const playAgain: string = changedLevel
			? 'Play'
			: (notification.lastGameResult?.lost ? 'Try again' : 'Play again');
		
		let icons: string[] = [gameoverStyles.win, gameoverStyles.best, gameoverStyles.loss];
		const filter: boolean[] = [
			!!notification.lastGameResult?.won,
			!!notification.lastGameResult?.won && !!notification.lastGameResult?.bestTime,
			!notification.lastGameResult?.won
		];

		icons = icons.filter((value: string, index: number) => {
			return filter[index];
		});

		const currentLevelId = stateLevelId ?? notification.level;
		const levels: ILevelDefinition[] = LevelManager.getLevelDefinitionsForStyle(currentLevelId.style);
		const styles: IIdValue[] = GameStyle.getStyles();
		const selectedLevel: ILevelDefinition = levels.filter((levelDefinition: ILevelDefinition): boolean =>
			levelDefinition.id === currentLevelId.id)[0];

		return (
			<Container
				className={`${notificationStyles.wrapper} ${notificationStyles.clickable}`}
			>
				<div className='d-flex justify-content-center'>
					<Row xs={1}>
						<Col>
							<div className={`${notificationStyles.darkText} text-center`}>
								{icons.map((value: string, index: number) => {
									return (
										<div
											key={index}
											className={`${gameoverStyles.image} ${value}`}
										/>
									);
								})}
							</div>
						</Col>
					</Row>
				</div>
				<div className={` ${notificationStyles.lg} ${notificationStyles.padded} justify-content-center`}>
					<div className='text-center'>
						<a className={notificationStyles.link} href='#' onClick={this.onClickPlay}>{playAgain}</a>
					</div>
					{selectLevel === SelectStyleLevel.Select ?
						<div className='text-center'>
							<a
								className={notificationStyles.link}
								href='#'
								onClick={() => this.onClickChangeStyleOrLevel(SelectStyleLevel.Style, false)}
							>
								Play a different style or level
							</a>
						</div>
						: null}
					{selectLevel === SelectStyleLevel.Style ?
						<div className={`text-center ${gameoverStyles.dropdownWrapper}`}>
							<DropdownSelector
								id='style-select'
								drop='up'
								show={true}
								onToggle={() => this.onClickChangeStyleOrLevel(SelectStyleLevel.Level, false)}
								onSelect={this.onClickSelectedStyle}
								items={styles.map((style) => { return { id: `${style.id}`, name: style.value }; })}
								defaultItemId={`${selectedLevel.style}`}
								fallbackName='Style'
								dropdownClassName={gameoverStyles.drop}
								toggleClassName={`${notificationStyles.link} ${gameoverStyles.dropdownToggle}`}
								menuClassName={`${gameoverStyles.dropdownMenu} dropdown-menu`}
								itemsClassName={`${gameoverStyles.dropdownItem} ${notificationStyles.lg}`}
							/>

						</div>
						: null}
					{selectLevel === SelectStyleLevel.Level ?
						<div className={`text-center ${gameoverStyles.dropdownWrapper}`}>
							<DropdownSelector
								id='level-select'
								drop='up'
								show={true}
								onToggle={() => this.onClickChangeStyleOrLevel(SelectStyleLevel.Select, true)}
								onSelect={this.onClickSelectedLevel}
								items={levels.map((level) => { return { id: `${level.level}###${level.id}`, name: level.name }; })}
								defaultItemId={`${selectedLevel.level}###${selectedLevel.id}`}
								fallbackName='Level'
								dropdownClassName={gameoverStyles.drop}
								toggleClassName={`${notificationStyles.link} ${gameoverStyles.dropdownToggle}`}
								menuClassName={`${gameoverStyles.dropdownMenu} dropdown-menu`}
								itemsClassName={`${gameoverStyles.dropdownItem} ${notificationStyles.lg}`}
							/>
						</div>
						: null}
					<div className='text-center'>
						<a className={notificationStyles.link} href='#' onClick={this.onClickShareSocial}>
							<FontAwesomeIcon className={`${notificationStyles.icon} ${notificationStyles.lg}`} icon={faShareAlt} />
							Share
						</a>
					</div>
				</div>
			</Container>
		);
	};

	/**
	 * The click event handler for the Play Again link.
	 * @private
	 */
	private onClickPlay = (): void => {
		const { level: stateLevelId } = this.state;
		const currentLevelId = stateLevelId ?? this.props.notification.level;

		this.props.notification.onClickClose(this.props.notification.showAgain);
		this.props.notification.onClickPlay(currentLevelId.style, currentLevelId.level, currentLevelId.id);
	};

	/**
	 * The click event handler for the share social icon.
	 * @param event The mouse event.
	 */
	private onClickShareSocial = (event: React.MouseEvent): void => {
		this.props.notification.onClickShareSocial(SocialMediaShareType.Game, null);
		event.preventDefault();
		event.stopPropagation();
	};

	/**
	 * The click event handler for when the selection moves to the next stage.
	 * @param selectLevel The select level.
	 */
	private onClickChangeStyleOrLevel = (selectLevel: SelectStyleLevel, startGame: boolean): void => {
		this.setState({ selectLevel: selectLevel }, () => startGame ? this.onClickPlay() : null);
	}

	/**
	 * The click event handler for the style dropdown.
	 * @param selectedItem The selected item; otherwise, null.
	 * @private
	 */
	private onClickSelectedStyle = (selectedItem: Nullable<IDropdownItem>): void => {

		const { level: stateLevelId } = this.state;
		const currentLevelId = stateLevelId ?? this.props.notification.level;

		let newStyle: Style = currentLevelId.style;
		let newLevel: Levels = currentLevelId.level;
		let newLevelId: Nullable<string> = currentLevelId.id;

		if (selectedItem?.id) {
			newStyle = parseInt(selectedItem.id);
		}

		if (newStyle !== currentLevelId.style) {
			// switching styles when on custom level -> default to easy
			newLevel = currentLevelId.level === Levels.Custom
				? Levels.Easy
				: currentLevelId.level;

			// get the level id
			newLevelId = LevelManager
				.getLevelDefinitionsForLevel(newStyle, newLevel)?.[0]?.id ?? null;
		}

		this.setState({
			level: {
				...currentLevelId,
				style: newStyle,
				level: newLevel,
				id: newLevelId,
			},
			selectLevel: SelectStyleLevel.Level,
			changedLevel: newStyle !== this.props.notification.level.style,
		});
	};

	/**
	 * The click event handler for the level dropdown.
	 * @param selectedItem The selected item; otherwise, null.
	 * @private
	 */
	private onClickSelectedLevel = (selectedItem : Nullable<IDropdownItem>): void => {
		const { level: stateLevelId } = this.state;
		const currentLevelId = stateLevelId ?? this.props.notification.level;

		const parts: string[] = selectedItem?.id.split('###') || [];
		let newLevel: Levels = currentLevelId.level;
		let newLevelId: Nullable<string> = currentLevelId.id;

		if (parts.length > 1) {
			newLevel = parseInt(parts[0]);
			newLevelId = parts[1];
		}
		else {
			// fallback - should not be needed
			const levels: ILevelDefinition[] = LevelManager
				.getLevelDefinitionsForStyle(currentLevelId.style);

			newLevel = levels[0].level;
			newLevelId = levels[0].id;
		}

		this.setState({
			level: {
				...currentLevelId,
				level: newLevel,
				id: newLevelId,
			},
			selectLevel: SelectStyleLevel.Select,
			changedLevel: (
				newLevel !== this.props.notification.level.level ||
				newLevelId !== this.props.notification.level.id)
		}, () => this.onClickPlay());
	};
}