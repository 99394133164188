import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { IProps } from './props';
import { Score } from './score/score';
import scoreboardStyles from './scoreboard.module.css';
import { Size } from './size/size';
import { Stats } from './stats/stats';

/**
 * The Scoreboard class implements the <Scoreboard/> component.
 * @export
 * @class Scoreboard
 * @extends {React.Component<IProps>}
 */
export class Scoreboard extends React.Component<IProps> {

	/**
	 * Creates an instance of Scoreboard.
	 * @param {IProps} props
	 * @memberof Scoreboard
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <Scoreboard/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		return (
			<div className={scoreboardStyles.scoreboard}>
				<Container className={scoreboardStyles.score}>
					<Row className={scoreboardStyles.scoreRow}>
						<Size level={this.props.stats.level} />
					</Row>
					<Row className={scoreboardStyles.scoreRow}>
						<Score score={this.props.score} />
					</Row>
					<Row className={scoreboardStyles.scoreRow}>
						<Stats stats={this.props.stats} />
					</Row>
				</Container>
			</div>
		);
	};
}