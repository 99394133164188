//import Observer from '@researchgate/react-intersection-observer';
import * as React from 'react';
import { CellStatus, Move } from '../../../../game-to-app/enums';
import { Nullable } from '../../../../shared/types';
import { SolutionCalculationMethod, SolutionStatus } from '../../../../solver-to-app/enums';
import { CellVisibility } from '../../app/cell-visibility';
import { Constants } from '../../shared/constants';
import { Hover, MouseClick, Player } from '../../shared/enums';
import cellStyles from './cell.module.css';
import { IProps } from './props';
import { IState } from './state';

/**
 * The Cell class implements the <Cell/> component.
 * @export
 * @class Cell
 * @extends {React.Component<IProps, IState>}
 */
export class Cell extends React.Component<IProps, IState> {
	private _ref: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
	private static _minLongTouchDelay: number = 300;
	private static _maxDoubleTouchDelay: number = 300;
	private static _probabilityThreshold = [0, 34, 66];

	/**
	 * Defines class names by cell status.
	 * @private
	 * @static
	 */
	private static cellStatusClassNames = {
		[CellStatus.Covered]: [cellStyles.covered],
		[CellStatus.Flagged]: [cellStyles.flagged],
		[CellStatus.FlaggedByHint]: [cellStyles.flaggedByHint],
		[CellStatus.Question]: [cellStyles.question],
		[CellStatus.Uncovered]: [cellStyles.uncovered],
		[CellStatus.UncoveredShowMine]: [cellStyles.uncovered, cellStyles.mine],
		[CellStatus.UncoveredShowMineHit]: [cellStyles.uncovered, cellStyles.mine, cellStyles.hit],
		[CellStatus.UncoveredShowMineFlaggedCorrectly]: [cellStyles.uncovered, cellStyles.mine, cellStyles.flagged],
		[CellStatus.UncoveredShowMineFlaggedIncorrectly]: [cellStyles.uncovered, cellStyles.mine, cellStyles.flagged, cellStyles.incorrect],
	};

	private static solverStatusClassNames = {
		[SolutionStatus.Mine]: [cellStyles.solverMine],
		[SolutionStatus.Clear]: [cellStyles.solverClear],
		[SolutionStatus.Unknown]: [cellStyles.solverUnknown, cellStyles.solverProbability],
		[SolutionStatus.Discover]: [],
	};

	/**
	 * Defines class names by hover status.
	 * @private
	 * @static
	 */
	private static hoverStatusClassNames = {
		[Hover.None]: [],
		[Hover.Cell]: [cellStyles.hover],
		[Hover.Neighbor]: [cellStyles.hover, cellStyles.neighbor],
	};

	/**
	 * Creates an instance of Cell.
	 * @param {IProps} props The props for the <Cell/> component.
	 * @memberof Cell
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			mouse: {
				clicked: MouseClick.None,
			},
			touch: {
				double: {
					start: null,
					target: null,
					timerId: null,
				},
				long: {
					start: null,
					target: null,
					timerId: null,
				}
			},
		};
	}

	/**
	 * A react lifecycle function for the <Cell/> component.
	 */
	public componentDidMount = (): void => {
		this.props.appData.components.set(this.props.gameData.id, this._ref);
	};

	/**
	 * A react lifecycle function for the <Cell/> component.
	 */
	public componentWillUnmount = (): void => {
		this.props.appData.components.remove(this.props.gameData.id);
	};

	/**
	 * Determines if the cell component should be rendered.
	 * @param nextProps The next properties.
	 * @returns true if the cell should be rendered; otherwise, false.
	 */
	public shouldComponentUpdate = (nextProps: IProps): boolean => {
		return CellVisibility.getCellVisibility(nextProps.faceId, nextProps.position.y, nextProps.position.x);
	};

	/**
	 * Renders the <Cell/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const text = !this.props.gameData.isMine &&
			this.props.gameData.status === CellStatus.Uncovered &&
			this.props.gameData.neighborMineCount > 0
			? `${this.props.gameData.neighborMineCount}`
			: '';
		let probability: string = '';

		const textClassNames: string[] = text
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			? [cellStyles.count, (cellStyles as any)[`mine${this.props.gameData.neighborMineCount % 10}`]]
			: [];
		const propClassNames: string[] = this.props.appData.classNames
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			? this.props.appData.classNames.map((className: string) => (cellStyles as any)[`${className}`])
			: [];

		let classNames: string[] = [
			cellStyles.cell,
			this.props.gameData.id,
			...Cell.hoverStatusClassNames[this.props.appData.hover],
			...textClassNames,
			...propClassNames,
		];

		if (this.props.solverData &&
			(this.props.gameData.status === CellStatus.Covered)) {
			classNames = classNames.concat(...Cell.solverStatusClassNames[this.props.solverData.status]);

			if (this.props.solverData.status === SolutionStatus.Unknown) {
				probability = this.props.solverData.method === SolutionCalculationMethod.Unsolved
					? '?'
					: (Math.round((this.props.solverData.isMineProbability || 0))).toString();

				const colorIndex = Cell._probabilityThreshold.reduce((accumulator: number, threshold: number, index: number) =>
					accumulator = (this.props.solverData?.isMineProbability || 0) >= threshold ? index : accumulator, 0);

				classNames = classNames.concat([cellStyles.low, cellStyles.medium, cellStyles.high][colorIndex]);
			}
		}
		else {
			classNames = classNames.concat(...Cell.cellStatusClassNames[this.props.gameData.status]);
		}

		// add animations when move is made by assistant
		if (this.props.gameData.isMostRecentMove.isMostRecent &&
			this.props.gameData.isMostRecentMove.player === Player.Assistant &&
			this.props.gameData.status === CellStatus.Uncovered) {
			switch (this.props.gameData.isMostRecentMove.move) {
				case Move.Hit:
					// fade
					classNames.push(cellStyles.moveHit);
					break;
				case Move.Discover:
					classNames.push(cellStyles.moveDiscover);
					break;
			}
		}

		// in assistant mode show incorrect flags with different style
		if (this.props.appData.assistant.active &&
			!this.props.gameData.isMine &&
			(this.props.gameData.status === CellStatus.Flagged || this.props.gameData.status === CellStatus.FlaggedByHint)) {
			classNames.push(cellStyles.incorrect);
		}

		const className = classNames.join(' ');

		let style: React.CSSProperties = this.props.appData.style;

		// define the solver box-shadow based on the size of the cell
		if (this.props.solverData) {
			const insetSize: number = Math.min(4, Math.max(1,
				Math.round(Math.min(this.props.size.height, this.props.size.width) * 0.05)));

			switch (this.props.solverData?.status) {
				case SolutionStatus.Mine:
					style = {
						...style,
						boxShadow: [
							`inset 0 0 0 ${insetSize + 1}px rgba(0, 0, 0, 0.4)`,
							`inset 0 0 ${insetSize}px ${insetSize * 2}px rgba(255, 123, 0, 0.9)`
						].join(','),
					};
					break;
				case SolutionStatus.Clear:
					style = {
						...style,
						boxShadow: [
							`inset 0 0 0 ${insetSize + 1}px rgba(0, 0, 0, 0.4)`,
							`inset 0 0 ${insetSize}px ${insetSize * 2}px rgba(14, 255, 0, 0.9)`
						].join(','),
					};
					break;
				case SolutionStatus.Unknown:
					style = {
						...style,
						boxShadow: [
							`inset 0 0 0 ${insetSize + 1}px rgba(0, 0, 0, 0.4)`,
							`inset 0 0 ${insetSize}px ${insetSize * 2}px rgba(255, 255, 0, 0.9)`
						].join(','),
					};
					break;
			}
		}

		return (
			<div
				id={this.props.gameData.id}
				key={this.props.gameData.id}
				className={className}
				ref={this._ref}
				style={style}
				onMouseLeave={this.onMouseLeave}
				onMouseUp={this.onMouseUp}
				onMouseDown={this.onMouseDown}
				onContextMenu={this.onContextMenu}
				onTouchStart={this.onTouchStart}
				onTouchEnd={this.onTouchEnd}
				onTouchCancel={this.onTouchEnd}
			>
				{text}
				{probability}
			</div>
		);
	};

	/**
	 * The touch start event handler. 
	 * @param event The touch event.
	 * @private
	 */
	private onTouchStart = (event: React.TouchEvent<HTMLDivElement>): void => {
		if (this.props.eventEnabled) {
			const { touch } = this.state;

			// check if there is a pending double for a different target. If so, clear it,
			if (touch.double.target && touch.double.target && touch.double.target !== event.currentTarget.id) {

				if (touch.double.timerId) {
					window.clearTimeout(touch.double.timerId);
				}

				this.setState({
					touch: {
						...touch,
						double: {
							start: null,
							target: null,
							timerId: null,
						},
					},
				});
			}

			// set timeout. once held long enough, automatically perform click action.
			const timerId = window.setTimeout(() => {
				this.onLongTouch();
				const { touch } = this.state;

				this.setState({
					touch: {
						...touch,
						long: {
							...touch.long,
							timerId: null,
						},
					},
				});
			},
				Cell._minLongTouchDelay);
		
			// start clock for long touch
			this.setState({
				touch: {
					...touch,
					long: {
						...touch.long,
						start: new Date(),
						target: event.currentTarget.id,
						timerId: timerId,
					},
				},
			});
		}
	}

	/**
	 * The touch end event handler.
	 * @param event The touch event.
	 * @private
	 */
	private onTouchEnd = (event: React.TouchEvent<HTMLDivElement>): void => {
		if (this.props.eventEnabled) {
			const { touch } = this.state;
			let isLongTouch: boolean = false;
			let isDoubleTouch = false;

			event.preventDefault();

			// kill pending long touch, since the touch ended before timer expired.
			if (touch.long.start) {
				const duration = new Date().getTime() - touch.long.start.getTime();
				isLongTouch = touch.long.target === event.currentTarget.id &&
					duration >= Cell._minLongTouchDelay;
			
				if (touch.long.timerId) {
					window.clearTimeout(touch.long.timerId);
				}

				this.setState({
					touch: {
						...touch,
						long: {
							start: null,
							target: null,
							timerId: null,
						},
					},
				});
			}

			if (isLongTouch) {
				// this event will be handled by the timeout callback function
			}
			else {
				if (touch.double.start) {
					const interval = new Date().getTime() - touch.double.start.getTime();
					isDoubleTouch = touch.double.target === event.currentTarget.id &&
						interval < Cell._maxDoubleTouchDelay;

					if (touch.double.timerId) {
						window.clearTimeout(touch.double.timerId);
					}

					this.setState({
						touch: {
							...touch,
							double: {
								start: null,
								target: null,
								timerId: null,
							},
						},
					});
				}

				if (isDoubleTouch) {
					this.onDoubleTouch();
				}
				else {
					// start clock for double touch, set timeout for single touch
					const timerId = window.setTimeout(() => this.onSingleTouch(), Cell._maxDoubleTouchDelay);

					this.setState({
						touch: {
							...touch,
							double: {
								start: new Date(),
								target: event.currentTarget.id,
								timerId: timerId,
							},
						},
					});
				}
			}
		}
	}

	/**
	 * The single touch event handler.
	 * @remarks Single touch triggers the same action as a left mouse click.
	 * @private
	 */
	private onSingleTouch = (): void => {
		// raise the click event
		this.onClickCell(MouseClick.Left);
	}

	/**
	 * The double touch event handler.
	 * @remarks Double touch triggers the same action as a left + right (or middle) mouse click.
	 * @private
	 */
	private onDoubleTouch = (): void => {
		// raise the click event
		this.onClickCell(MouseClick.Middle);

		// stop highlighting after the given period
		window.setTimeout(() => this.onClickCell(MouseClick.Release), Constants.hoverDuration);
	}

	/**
	 * The long touch event handler.
	 * @remarks Long touch triggers the same action as a right mouse click.
	 * @private
	 * @memberof Cell
	 */
	private onLongTouch = (): void => {
		// raise the click event
		this.onClickCell(MouseClick.Right);
	}

	/**
	 * The event handler for the mouse down event.
	 * @param event The mouse event.
	 * @private
	 */
	private onMouseDown = (event: React.MouseEvent<HTMLDivElement>): void => {
		if (this.props.eventEnabled) {
			if (event) {
				const { mouse } = this.state;
				let click: Nullable<MouseClick> = null;

				switch (event.button) {
					case 0:
						if (mouse.clicked === MouseClick.Right) {
							click = MouseClick.Middle;
						}
						else {
							click = MouseClick.Left;
						}
						break;
					case 1:
						click = MouseClick.Middle;
						break;
					case 2:
						click = mouse.clicked === MouseClick.Left ? MouseClick.Middle : MouseClick.Right;
						break;
				}

				if (click !== null) {
					mouse.clicked = click;

					this.setState({
						mouse: mouse,
					});

					// handle highlight neighbors scenario
					if (this.state.mouse.clicked === MouseClick.Middle) {
						this.onClickCell();
					}
				}
			}
		}
	};

	/**
	 * The event handler for the mouse up event.
	 * @param event The mouse event.
	 * @private
	 */
	private onMouseUp = (event: React.MouseEvent): void => {
		if (this.props.eventEnabled) {
			if (event) {
				const { mouse } = this.state;

				// handle only middle and right clicks here
				if (mouse.clicked === MouseClick.Left ||
					mouse.clicked === MouseClick.Right) {
					this.onClickCell();
				}
				else {
					mouse.clicked = MouseClick.Release;

					this.setState({
						mouse: mouse,
					});

					this.onClickCell();
				}
			}
		}
	};

	/**
	 * The event handler for the mouse leave event.
	 * @private
	 */
	private onMouseLeave = (): void => {
		if (this.props.eventEnabled) {
			this.resetMouse();
		}
	};

	/**
	 * An click event handler for a right click mouse event.
	 * @param event The mouse event.
	 * @remarks Prevents the browser context menu from opening.
	 * @private
	 */
	private onContextMenu = (event: React.MouseEvent): void => {
		// prevent the browser context menu on right click
		event.preventDefault();
		event.stopPropagation();
	};

	/**
	 * The event handler for when the player clicks on a cell.
	 * @param clicked The clicked button; optional.
	 * @private
	 */
	private onClickCell = (clicked: Nullable<MouseClick> = null): void => {
		let click: Nullable<MouseClick> = clicked;

		if (!click) {
			const { mouse } = this.state;
			click = mouse.clicked;
		}
		
		if (click !== null && click !== MouseClick.None) {

			this.props.appData.onClickCell(this.props.gameData.id, click, Player.Player);
			this.resetMouse();
		}
	};

	/**
	 * Resets the mouse status.
	 * @private
	 */
	private resetMouse = (): void => {
		this.setState({
			mouse: {
				clicked: null,
			}
		});
	};
}