import { Nullable } from '../../shared/types';
import { Dictionary } from './dictionary';

/**
 * The QueryString class provides functions to retrieve query string values. *
 * @export
 * @class QueryString
 */
export class QueryString {
	/**
	 * Gets the requested query string.
	 * @param locationSearch The query string part of the url.
	 * @param name The query string name.
	 * @returns The query string value; otherwise undefined.
	 * @static
	 */
	public static getQueryString = (locationSearch: Nullable<string>, name: Nullable<string>): Nullable<string> | undefined => {
		const dictionary: Dictionary<Nullable<string>> = QueryString.getQueryStrings(locationSearch);

		return name ? dictionary.get(name) : undefined;
	};

	/**
	 * Parses the query string into a dictionary.
	 * @param locationSearch The query string part of the url.
	 * @returns A dictionary of query string keys and values.
	 * @static
	 */
	public static getQueryStrings = (locationSearch: Nullable<string>): Dictionary<Nullable<string>> => {
		const dictionary: Dictionary<Nullable<string>> = new Dictionary<Nullable<string>>();

		if (locationSearch) {
			const search: string = locationSearch[0] === '?' ? locationSearch.substring(1) : locationSearch;

			const queryStrings: string[] = search.split('&');

			queryStrings.forEach((queryString: string): void => {
				const keyValue: string[] = queryString.split('=');

				if (keyValue && keyValue.length > 0) {
					dictionary.add(keyValue[0], keyValue.length > 1 ? decodeURI(keyValue[1]) : null);
				}
			});
		}

		return dictionary;
	};
}