import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Motion, PlainStyle, spring } from 'react-motion';
import { IGameStats } from '../../../shared/storage/views/gamestats';
import { Constants } from '../../shared/constants';
import { Formatter } from '../../shared/formatter';
import { ILastGameResult } from '../../shared/interfaces';
import { IProps } from './props';
import statsStyles from './stats.module.css';

/**
 * The Stats class implements the <Stats/> component.
 * @export
 * @class Stats
 * @extends {React.Component<IProps>}
 */
export class Stats extends React.Component<IProps> {
	/**
	 * Creates an instance of Stats.
	 * @param {IProps} props
	 * @memberof Stats
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <Stats/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const stats: IGameStats = this.props.stats.get();
		const lastGameResult: ILastGameResult = this.props.stats.lastGameResult;

		const bestTime: string = Formatter.time(stats.time, Constants.maxSeconds, 0);
		const bestTimeClassNames: string = [
			statsStyles.time,
			stats.time >= Constants.maxSeconds ? statsStyles.disabled : null].join(' ');

		const won = Formatter.count(stats.won, Constants.maxWinLose);
		const lost = Formatter.count(stats.lost, Constants.maxWinLose);

		return (
			<Container
				id='stats'
				key='stats'
				className={'d-inline-flex'}>
				<Col >
					<div className={statsStyles.stat}>
						<div className={`${statsStyles.image} ${statsStyles.small} ${statsStyles.best}`} />
						<Motion
							key={bestTime}
							defaultStyle={lastGameResult.bestTime ? { scale: 0, color: 0 } : { scale: 100, color: 211 }}
							style={{
								scale: spring(100),
								color: spring(211)
							}}>
							{(interpolatingStyle: PlainStyle): JSX.Element => <div
								className={bestTimeClassNames}
								style={{
									transform: `scale(${interpolatingStyle.scale / 100})`,
									color: `rgba(${interpolatingStyle.color}, 211, ${interpolatingStyle.color}, 1)`
								}}
							>
								{bestTime}
							</div>}
						</Motion>
					</div>
					<div className={`${statsStyles.stat} ${statsStyles.spacer}`}>
						<div className={`${statsStyles.image} ${statsStyles.small} ${statsStyles.win}`} />
						<Motion
							key={won}
							defaultStyle={ lastGameResult.won ? { scale: 0, color: 0 } : { scale: 100, color: 211 } }
							style={{
								scale: spring(100),
								color: spring(211)
							}}>
							{(interpolatingStyle: PlainStyle): JSX.Element => <div
								className={statsStyles.count}
								style={{
									transform: `scale(${interpolatingStyle.scale / 100})`,
									color: `rgba(${interpolatingStyle.color}, 211, ${interpolatingStyle.color}, 1)`
								}}
							>
								{won}
							</div>}
						</Motion>
					</div>
					<div className={`${statsStyles.stat} ${statsStyles.spacer}`}>
						<div className={`${statsStyles.image} ${statsStyles.small} ${statsStyles.loss}`} />
						<Motion
							key={lost}
							defaultStyle={ lastGameResult.lost ? { scale: 0, color: 0 } : { scale: 100, color: 211 } }
							style={{
								scale: spring(100),
								color: spring(211)
							}}>
							{(interpolatingStyle: PlainStyle): JSX.Element => <div
								className={statsStyles.count}
								style={{
									transform: `scale(${interpolatingStyle.scale / 100})`,
									color: `rgba(211, ${interpolatingStyle.color}, ${interpolatingStyle.color}, 1)`
								}}
							>
								{lost}
							</div>}
						</Motion>
					</div>
				</Col>
			</Container>
		);
	};
}