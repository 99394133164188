
import { IDBPDatabase } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { DefaultMetadata } from '../../../default-metadata';
import { ModelNames } from '../../../enums';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager } from '../../../model-interfaces';
import { CacheManager } from './cache-manager';
import { IModelProductValues } from './model';
import { UpgradeManager } from './upgrade-manager';

/**
 * The Metadata class defines the product model metadata.
 */
export class Metadata extends DefaultMetadata<IModelProductValues> implements IModelMetadata<IModelProductValues> {
	protected _name: string = ModelNames.Products;
	protected _schemaVersion: number = 5;
	// a default to use in update(), not to create in the store
	protected _defaults: IModelProductValues[] = [{
		...{
			id: '',
			date: new Date(1980, 1, 1),
			quantity: 0,
			data: null,
		},
		...this.systemValues
	}];

	/**
	 * Initializes the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	protected initializeUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		return new UpgradeManager(database, this);
	};

	/**
	 * Initializes the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	protected initializeCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		return new CacheManager(database, this);
	};
}