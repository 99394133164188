import * as React from 'react';
import { Motion, spring } from 'react-motion';
import { Style } from '../../../../game-to-app/enums';
import { ICoordinate, IFaceDefinition, IRect, ITranslate } from '../../shared/interfaces';
import { IAppDataFace } from '../../shared/interfaces-app-data';
import { Face } from '../face/face';
import boxStyles from './box.module.css';
import { IProps } from './props';
import { IState } from './state';

/**
 * The Box class implements the <Box/> compoenent. 
 * @export
 * @class Box
 * @extends {React.Component<IProps, IState>}
 */
export class Box extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _faceRefs: Record<string, React.RefObject<HTMLDivElement>> = {};

	/**
	 * Creates an instance of Box.
	 * @param {IProps} props The props for the <Box/> component.
	 * @memberof Box
	 */
	constructor(props: IProps) {
		super(props);

		const faces: Record<string, IFaceDefinition> = this.props.shape.getFaces();

		this.state = {
			faces: faces,
			rect: {
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				width: 0,
				height: 0,
			}
		};
		
		Object.keys(faces).map((face, key) => {
			this._faceRefs[key] = React.createRef<HTMLDivElement>();
		});
	}

	/**
	 * A react lifecycle function for the <Box/> component.
	 */
	public componentDidMount = (): void => {
		let { rotate } = this.props.appData.app;

		if (!rotate) {
			const { faces } = this.state;
			const spin: ICoordinate = {
				x: this.props.appData.app.spin?.x || 0,
				y: this.props.appData.app.spin?.y || 0,
			};

			// initial face is front and center
			const initialFace = this.props.shape.getInitialFace();

			rotate = {
				x: (faces[initialFace].rotate.x ? - (faces[initialFace]?.rotate?.deg || 0) : 0),
				y: (faces[initialFace].rotate.y ? - (faces[initialFace]?.rotate?.deg || 0) : 0),
				z: 0,
			}

			// spin
			const x: number = (rotate.x || 0) - spin.y;
			const y: number = Math.abs(x % 360) > 90 && Math.abs(x % 360) < 270
				? (rotate?.y || 0) - spin.x
				: (rotate?.y || 0) + spin.x;

			rotate = { ...rotate, x: x, y: y };
		}

		if (this.props.appData.app.events?.rotate?.onRotate) {
			this.props.appData.app.events.rotate.onRotate(rotate);
		}
	};
	
	/**
	 * A react lifecycle function for the <Board/> component.
	 */
	public componentDidUpdate = (): void => {
		this.onUpdateBoundingBox();
	};

	/**
	 * Renders the <Box/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { faces } = this.state;
		const { level } = this.props.appData.app;
		const { shape, size } = this.props;

		const translate: ITranslate = {
			z: -shape.getSize().depth / 2,
		};

		const shapeFrameStyle: React.CSSProperties = {
			top: size.frame.top,
			left: size.frame.left,
			width: `${size.frame.width}px`,
			height: `${size.frame.height}px`,
			borderWidth: `${size.frame.borderWidth}px`,
		};

		const shapeStyle: React.CSSProperties = {
			top: size.shape.top,
			left: size.shape.left,
			perspectiveOrigin:
				`${((size.frame.width / 2) + size.frame.borderWidth)}px` +
				`${((size.frame.height / 2) + size.frame.borderWidth)}px`,
			width: `${size.shape.width}px`,
			height: `${size.shape.height}px`,
			borderWidth: `${size.shape.borderWidth}px`,
		};

		const shapeClassNames = [boxStyles.wrapper, boxStyles.wrapperBorder];

		return (
			<div
				id='shape-frame'
				className={size.frame.borderWidth > 0 ? boxStyles.shapeFrame : boxStyles.shapeFrameNone}
				style={shapeFrameStyle}
			>
				<div
					id='shape'
					className={shapeClassNames.join(' ')}
					style={shapeStyle}
				>
					<Motion
						onRest={this.onAnimationRest}
						style={{
							rotateX: spring(this.props.appData.app.rotate?.x || 0),
							rotateY: spring(this.props.appData.app.rotate?.y || 0),
							rotateZ: spring(this.props.appData.app.rotate?.z || 0),
							flexGrow: 1,
						}}
					>
						{({ rotateX, rotateY, rotateZ }): JSX.Element => {
							return (
								<div
									ref={this._ref}
									className={boxStyles.shape}
									style={{
										transform: `translate3d(
											${translate?.x || 0}px,
											${translate?.y || 0}px,
											${translate?.z || 0}px) 
											rotateX(${rotateX}deg)
											rotateY(${rotateY}deg)
											rotateZ(${rotateZ}deg)`
									}}
								>
									{Object.keys(faces).map((face, key) => {
										const { translate, rotate, position, size, cells } = faces[face];

										const solverData = this.props.solverData
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											? (this.props.solverData as any)[face]
											: null;
										
										const classNames: string[] = [
											boxStyles.face,
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											(boxStyles as any)[face],
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											(boxStyles as any)[Style[this.props.appData.app.level.style].toLowerCase()]
										];
										
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										const appData: IAppDataFace = (this.props.appData as any)[face];

										const cellClassNames: string[] = cells.classNames.concat(appData?.classNames || []); 

										return (
											<div
												className={classNames.join(' ')}
												ref={this._faceRefs[key]}
												key={key}
												style={{
													transform: `translate3d(
														${translate?.x || 0}px,
														${translate?.y || 0}px,
														${translate?.z || 0}px)
														rotate3d(
															${rotate?.x || 0},
															${rotate?.y || 0},
															${rotate?.z || 0},
															${rotate?.deg || 0}deg)`,
													width: size.width,
													height: size.height,
													top: (position.top || 0),
													left: (position.left || 0),
												}}
												onMouseDown={this.props.panel.events.mouse.onMouseDown}
												onMouseMove={this.props.panel.events.mouse.onMouseMove}
												onMouseLeave={this.props.panel.events.mouse.onMouseLeave}
												onMouseUp={this.props.panel.events.mouse.onMouseUp}
												onTouchStart={this.props.panel.events.mouse.onTouchStart}
												onTouchEnd={this.props.panel.events.mouse.onTouchEnd}
												onTouchCancel={this.props.panel.events.mouse.onTouchEnd}
												onTouchMove={this.props.panel.events.mouse.onTouchMove}
											>
												<div
													className={level.enabledFaces[face] ? boxStyles.enabled : boxStyles.disabled}
												>
													<Face
														id={face}
														appData={appData}
														// eslint-disable-next-line @typescript-eslint/no-explicit-any
														gameData={(this.props.gameData as any)[face]}
														// eslint-disable-next-line @typescript-eslint/no-explicit-any
														solverData={solverData}
														cell={{
															size: cells.cellSize,
															classNames: cellClassNames,
														}}
														eventEnabled={!this.props.appData.app.lock.locked}
													/>
												</div>
											</div>
										);
									})}
								</div>
							);
						}}
					</Motion>
				</div>
			</div>
		);
	};

	/**
	 * The event handler for when the box animation rests
	 */
	private onAnimationRest = (): void => {
		// final bounding box update
		this.onUpdateBoundingBox();

		// invoke rested handler, if any
		if (this.props.appData.app.events?.rotate?.onRotateAnimationRested) {
			this.props.appData.app.events?.rotate?.onRotateAnimationRested();
		}
	};

	/**
	 * The event handler for when the bounding box is updated.
	 */
	private onUpdateBoundingBox = (): void => {
		const { rect } = this.state;
		const currentRect: IRect = this.calculateBoundingBox();

		if (rect.left !== currentRect.left ||
			rect.top !== currentRect.top ||
			rect.right !== currentRect.right ||
			rect.bottom !== currentRect.bottom) {

			this.setState({ rect: currentRect });

			this.props.appData.app.events.boundingBox.onUpdateBoundingBox(currentRect);
		}
	};

	/**
	 * Calculates the bounding box rect.
	 * @returns The bounding box rect.
	 */
	private calculateBoundingBox = (): IRect => {
		const currentRect: IRect = {
			left: Number.MAX_VALUE,
			right: Number.MIN_VALUE,
			top: Number.MAX_VALUE,
			bottom: Number.MIN_VALUE,
			width: 0,
			height: 0,
		};
	
		Object.keys(this.state.faces).map((face, key) => {
			const rect = this._faceRefs[key].current?.getBoundingClientRect();

			if (rect) {
				currentRect.left = Math.min(currentRect.left, Math.min(rect.left, rect.right));
				currentRect.top = Math.min(currentRect.top, Math.min(rect.top, rect.bottom));
				currentRect.right = Math.max(currentRect.right, Math.max(rect.left, rect.right));
				currentRect.bottom = Math.max(currentRect.bottom, Math.max(rect.top, rect.bottom));
			}
		});

		currentRect.width = Math.abs(currentRect.right - currentRect.left);
		currentRect.height = Math.abs(currentRect.bottom - currentRect.top);

		return currentRect;
	};
}