/**
 * The Timer class implements a countdown timer.
 * @export
 * @class Timer
 */
export class Timer {
	private _time: number = 0;
	private _started: Date | null = null;
	private _stopped: Date | null = null;

	/**
	 * Gets a boolean value that indicates whether the timer has expired.
	 * @readonly
	 */
	public get isExpired(): boolean { return this.elapsed() >= this._time; }

	/**
	 * Creates an instance of Timer.
	 * @param time The time in milliseconds.
	 */
	constructor(time: number) {
		this._time = time;
	}

	/**
	 * Starts the timer.
	 */
	public start = (): void => {
		this._started = this._started || new Date();
	};

	/**
	 * Stops the timer.
	 */
	public stop = (): void => {
		this._stopped = this._stopped || new Date();
	};

	/**
	 * Gets the elapsed time.
	 * @returns The elapsed time.
	 */
	public elapsed = (): number => {
		let result: number = 0;

		if (this._started) {
			const stopped: Date = this._stopped || new Date();

			result = stopped.getTime() - this._started.getTime();
		}

		return result;
	};
}