import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Nullable } from '../../../shared/types';
import { IDropdownItem } from '../shared/interfaces';
import { ICustomMenuProps, ICustomToggleProps, IProps } from './props';

// arrow symbols for dropdown
const symbol: Record<string, string> = {
	'up': '\u25B2',
	'down': '\u25BC',
	'left': '\u25C4',
	'right': '\u25BA',
};

// custom dropdown toggle
const CustomToggle = React.forwardRef(
	(props: ICustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {

		return (
			<a
				href=""
				ref={ref}
				className={props.className}
				onClick={e => {
					e.preventDefault();
					if (props.onClick) props.onClick(e);
				}}
			>
				<span style={{ paddingRight: '5px' }}>{symbol[props.drop || ''] || ''}</span>
				{props.children}
			</a>
		);
	}
);
CustomToggle.displayName = 'custom toggle';

// custom dropdown menu
const CustomMenu = React.forwardRef(
	(props: ICustomMenuProps, ref: React.Ref<HTMLDivElement>) => {
		const [value] = useState('');

		return (
			<div
				ref={ref}
				style={props.style}
				className={props.className}
				aria-labelledby={props.labeledBy}
			>
				<div>
					{React.Children.toArray(props.children).filter(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						(child: any) =>
							!value || child.props.children.toLowerCase().startsWith(value)
					)}
				</div>
			</div>
		);
	}
);
CustomMenu.displayName = 'Custom menu';

/**
 * The dropdown selector component.
 * @param props The selector props.
 * @returns The <DropdownSelector/> component.
 */
export const DropdownSelector = (props: IProps): JSX.Element => {
	// the currently selected dropdown item id
	const [selectedItemId, setSelectedItemId] = useState(props.defaultItemId || '');

	// the selected item
	const getSelectedItem = (id: string = selectedItemId): Nullable<IDropdownItem> => {
		return props.items.find(item => item.id === id) || null;
	};

	// the selected item name
	const getSelectedItemName = (): string => {
		const selected: Nullable<IDropdownItem> = getSelectedItem();

		return selected
			? selected.name
			: props.fallbackName || '';
	};

	/**
	 * An event handler for when an item is selected in the dropdown.
	 * @param eventKey The selected item id.
	 */
	const onSelect = (eventKey: string | null): void => {
		setSelectedItemId(eventKey || '');
		props.onSelect?.(getSelectedItem(eventKey || ''));
	}

	return (
		<Dropdown
			show={props.show}
			className={props.dropdownClassName}
			onToggle={props.onToggle}
		>
			<Dropdown.Toggle
				as={CustomToggle}
				id={props.id || 'dropdown'}
				drop={props.drop}
				className={props.toggleClassName}
			>
				{getSelectedItemName()}
			</Dropdown.Toggle>

			<Dropdown.Menu
				as={CustomMenu}
				className={props.menuClassName}
			>
				{props.items.map(item => {
					return (
						<Dropdown.Item
							key={item.id}
							eventKey={item.id}
							className={props.itemsClassName}
							onSelect={onSelect}
						>
							{item.name}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};