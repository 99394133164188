/**
 * Defines telemetry events.
 * @export
 */
export enum Events {
	AdLoaded = 'AdLoaded',
	AdShown = 'AdShown',
	AdFailedToLoad = 'AdFailedToLoad',
	AdFailedToShow = 'AdFailedToShow',
	AdDismissed = 'AdDismissed',
	ApplicationError = 'ApplicationError',
	AssistantActivated = 'AssistantActivated',
	AssistantDeactivated = 'AssistantDeactivated',
	AudioFailure = 'AudioFailure',
	AutoSavedGameLoaded = 'AutoSavedGameLoaded',
	AutoPlayEnded = 'AutoPlayEnded',
	AutoPlayStarted = 'AutoPlayStarted',
	CacheManagerProductDeleteError = 'CacheManagerProductDeleteError',
	CacheManagerProductDeleteLoadError = 'CacheManagerProductDeleteLoadError',
	CacheManagerProductLoadError = 'CacheManagerProductLoadError',
	CacheManagerProductUpdateGetError = 'CacheManagerProductUpdateGetError',
	CacheManagerProductUpdatePutError = 'CacheManagerProductUpdatePutError',
	CacheManagerProductUpdateLoadError = 'CacheManagerProductUpdateLoadError',
	CacheManagerUserPreferenceDeleteError = 'CacheManagerUserPreferenceDeleteError',
	CacheManagerUserPreferenceDeleteLoadError = 'CacheManagerUserPreferenceDeleteLoadError',
	CacheManagerUserPreferenceLoadGetError = 'CacheManagerUserPreferenceLoadGetError',
	CacheManagerUserPreferenceLoadPutError = 'CacheManagerUserPreferenceLoadPutError',
	CacheManagerUserPreferenceUpdateGetError = 'CacheManagerUserPreferenceUpdateGetError',
	CacheManagerUserPreferenceUpdatePutError = 'CacheManagerUserPreferenceUpdatePutError',
	CacheManagerUserPreferenceUpdateLoadError = 'CacheManagerUserPreferenceUpdateLoadError',
	CustomLevelCreated = 'CustomLevelCreated',
	CustomLevelDeleted = 'CustomLevelDeleted',
	ContentShown = 'ContentShown',
	DatabaseError = 'DatabaseError',
	DatabaseMigration = 'DatabaseMigration',
	DatabaseStoreChanged = 'DatabaseStoreChanged',
	DirectionalPadActivatedAuto = 'DirectionalPadActivatedAuto',
	DirectionalPadActivatedManual = 'DirectionalPadActivatedManual',
	DirectionalPadDeactivatedAuto = 'DirectionalPadDeactivatedAuto',
	DirectionalPadDeactivatedManual = 'DirectionalPadDeactivatedManual',
	ExitApplication = 'ExitApplication',
	GameActivePlay = 'GameActivePlay',
	GameEndedNoPlay = 'GameEndedNoPlay',
	GameLost = 'GameLost',
	GameStarted = 'GameStarted',
	GameWon = 'GameWon',
	PageView = 'PageView',
	PlatformError = 'PlatformError',
	QuickStartTutorial = 'QuickStartTutorial',
	QuickStartWhatsNew = 'QuickStartWhatsNew',
	ReviewPromptRequested = 'ReviewPromptRequested',
	ReviewStoreListingOpened = 'ReviewStoreListingOpened',
	RouteNavigation = 'RouteNavigation',
	ServiceWorkerInstalled = 'ServiceWorkerInstalled',
	ServiceWorkerUpdated = 'ServiceWorkerUpdated',
	ShareSocial = 'ShareSocial',
	StoreError = 'StoreError',
	StoreManagerPurchase = 'StoreManagerPurchase',
	StoreManagerPurchaseRemoved = 'StoreManagerPurchaseRemoved',
	StoreProductInvalid = 'StoreProductInvalid',
	StoreProductInvalidType = 'StoreProductInvalidType',
	StorePurchaseApproved = 'StorePurchaseApproved',
	StorePurchaseCancelled = 'StorePurchaseCancelled',
	StorePurchaseFinished = 'StorePurchaseFinished',
	StorePurchaseOwned = 'StorePurchaseOwned',
	StorePurchaseRefunded = 'StorePurchaseRefunded',
	StorePurchaseVerificationFailed = 'StorePurchaseVerificationFailed',
	StorePurchaseVerified = 'StorePurchaseVerified',
	StoreRefreshActionCancelled = 'StoreRefreshActionCancelled',
	StoreRefreshActionCompleted = 'StoreRefreshActionCompleted',
	StoreRefreshActionFailed = 'StoreRefreshActionFailed',
	StoreRefreshActionFinished = 'StoreRefreshActionFinished',
	StoreRefreshStatusApproved = 'StoreRefreshStatusApproved',
	StoreRefreshStatusCancelled = 'StoreRefreshStatusCancelled',
	StoreRefreshStatusFinished = 'StoreRefreshStatusFinished',
	StoreRefreshStatusOwned = 'StoreRefreshStatusOwned',
	StoreRefreshStatusRefunded = 'StoreRefreshStatusRefunded',
	StoreRefreshStatusVerificationFailed = 'StoreRefreshStatusVerificationFailed',
	StoreRefreshStatusVerified = 'StoreRefreshStatusVerified',
	TelemetryDefault = 'TelemetryDefault',
	ZoomModeActivated = 'ZoomModeActivated',
	ZoomModeDeactivated = 'ZoomModeDeactivated',
}