import deepequal from 'deep-equal';
import deepmerge from 'deepmerge';
import { Nullable } from '../../../../shared/types';
import { SubscriptionEvent, SubscriptionEvents } from '../../events/subscription-events';
import { deepcopy } from '../../utils';
import { IModelCacheManager, IModelCacheManagerAutoSave, IStore } from '../model-interfaces';
import { ISavedGame, ISavedGameUpdate } from '../views/savedgames';

/**
 * The SavedGamesStore class implements the store for saved games.
 * @export
 * @class SavedGamesStore
 */
export class SavedGamesStore implements IStore {
	private _cacheManager: IModelCacheManager & IModelCacheManagerAutoSave;
	private _savedGames: Record<string, ISavedGame>;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._cacheManager.name; }

	/**
	 * Creates an instance of SavedGamesStore.
	 * @param cacheManager The cache manager.
	 * @memberof SavedGamesStore
	 */
	constructor(cacheManager: IModelCacheManager & IModelCacheManagerAutoSave) {
		this._cacheManager = cacheManager;
		this._savedGames = this._cacheManager.get();
	}

	/**
	 * Gets all saved games.
	 * @param refresh A Boolean value indicating if the saved games should be taken from the most recent database cache.
	 * @returns The saved games.
	 */
	public get = (refresh: boolean = false): Record<string, ISavedGame> => {
		let result: Record<string, ISavedGame> = {};
		
		if (refresh) {
			const refreshedSavedGames: Record<string, ISavedGame> =
				this._cacheManager.get() as Record<string, ISavedGame>;

			if (!deepequal(refreshedSavedGames, this._savedGames)) {
				this._savedGames = refreshedSavedGames;
				SubscriptionEvent.raise(SubscriptionEvents.SavedGamesChanged, this.name, this._savedGames);
			}
		}
		else {
			result = this._savedGames;
		}
		
		return deepcopy(result);
	};

	/**
	 * Gets the auto saved game.
	 * @returns The saved game; otherwise, null.
	 */
	public getAutoSaved = (): Nullable<ISavedGame> => {
		return this._cacheManager.getAutoSaved();
	};

	/**
	 * Gets the 'auto saved' game asynchronously.
	 * @returns A promise that resolves with the auto saved game or null.
	 */
	public getAutoSavedAsync = async (): Promise<Nullable<ISavedGame>> => {
		return this._cacheManager.getAutoSavedAsync();
	};

	/**
	 * Auto-saves the game.
	 * @param savedGame The saved game.
	 */
	public autoSave = (savedGame: Nullable<ISavedGame>): void => {
		this._cacheManager.autoSave(savedGame);
	};

	/**
	 * Auto saves the game asynchronously.
	 * @param savedGame The saved game.
	 * @returns A promise that resolves when the game is saved or queued to save.
	 */
	public autoSaveAsync = async (savedGame: Nullable<ISavedGame>): Promise<boolean> => {
		return this._cacheManager.autoSaveAsync(savedGame);
	};

	/**
	 * Deletes all auto saved games.
	 * @returns A promise that resolves when the deletion is complete.
	 */
	public deleteAllAutoSavedGames = async (): Promise<void> => {
		return this._cacheManager.deleteAllAutoSavedGames();
	};

	/**
	 * Updates a saved game.
	 * @param savedGame The saved game to update.
	 * @param refresh A Boolean value indicating if the saved games should be taken from the most recent database cache.
	 * @returns The updated saved game.
	 */
	public update = async (savedGame: ISavedGameUpdate, refresh: boolean = false): Promise<Record<string, ISavedGame>> => {
		const previousSavedGames = this._savedGames;
		// update all saved games, change the values for the specific id
		let updatedSavedGames: Record<string, ISavedGame> = deepcopy(this._savedGames);
		const savedGameForId: ISavedGame = updatedSavedGames[savedGame.id];
		const updatedSavedGameForId: ISavedGame = deepmerge(savedGameForId, savedGame);
		updatedSavedGames[savedGame.id] = updatedSavedGameForId;
		
		if (!deepequal(updatedSavedGames, previousSavedGames)) {		
			// update the store saved games synchronously
			this._savedGames = updatedSavedGames;
			
			if (!refresh) {
				SubscriptionEvent.raise(SubscriptionEvents.SavedGamesChanged, this.name, this._savedGames);
			}
	
			// update the database
			updatedSavedGames = await this._cacheManager.update(savedGame);

			// optionally update the saved games asynchronously from the database
			if (refresh && !deepequal(updatedSavedGames, this._savedGames)) {
				this._savedGames = updatedSavedGames;
				SubscriptionEvent.raise(SubscriptionEvents.SavedGamesChanged, this.name, this._savedGames);
			}
		}

		return this._savedGames;
	};

	/**
	 * Deletes a saved game.
	 * @param id The saved game id to delete.
	 * @returns The refreshed saved games.
	 */
	public delete = async (id: string): Promise<Record<string, ISavedGame>> => {
		const updatedSavedGames: Record<string, ISavedGame> = await this._cacheManager.delete(id);
		this._savedGames = updatedSavedGames;
		SubscriptionEvent.raise(SubscriptionEvents.SavedGamesChanged, this.name, this._savedGames);

		return this._savedGames;
	};
}