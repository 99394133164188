import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ServiceWorkerNotification } from '../../shared/enums';
import notificationStyles from '../notification.module.css';
import { IProps } from './props';

/**
 * The NotificationServiceWorker class implements the <NotificationServiceWorker/> component.
 * @remarks The control shows quick start help.
 * @export
 * @class NotificationServiceWorker
 * @extends {React.Component}
 */
export class NotificationServiceWorker extends React.Component<IProps> {

	/**
	 * Creates an instance of NotificationServiceWorker.
	 * @param {IProps} props The props for the <NotificationServiceWorker/> component.
	 * @memberof NotificationZoomMode
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <NotificationServiceWorker/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		let title: string = '';
		let message: string[] = [];

		switch (this.props.notification.type) {
			case ServiceWorkerNotification.Installed:
				title = 'Ready To Go';
				message = [
					'No matter where you are.',
					'This app now works even when you are offline.',
				];
				break;
			case ServiceWorkerNotification.Update:
				title = 'Update';
				message = [
					'There is an update available for the app.',
					'The update will be activated once all instances of the app have been closed and reopened.',
				];
				break;
		}
		return (
			<Container className={notificationStyles.wrapper}>
				<Container className='d-flex justify-content-center'>
					<Row xs={1}>
						<Col>
							<div className={`${notificationStyles.lg} ${notificationStyles.padded} ${notificationStyles.darkText} text-center`}>
								<div className={notificationStyles.darkText}>
									<FontAwesomeIcon
										className={`${notificationStyles.icon} ${notificationStyles.lg}`}
										icon={faExclamationCircle}
									/>
									{title}
								</div>
								{message.map((line, index) => <div className={notificationStyles.sm} key={index}>{line}</div>)}
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	};
}