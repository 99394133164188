import { Nullable } from '../../../shared/types';
import { Platform } from '../platform/platform';
import { AdMobAdProvider } from './admob/admob-adprovider';
import { IAdProvider } from './interfaces';
import { NoOpAdProvider } from './no-op/no-op-adprovider';

/**
 * The AdFactory class provides functions to create ad providers.
 * @export
 * @class AdFactory
 */
export class AdFactory {
	/**
	 * Gets an ad provider.
	 * @returns An ad provider.
	 * @static
	 * @memberof AdFactory
	 */
	public static getAdProvider = (): IAdProvider => {
		let provider: Nullable<IAdProvider> = null;

		if (Platform.isAndroid()) {
			const adMobProvider = new AdMobAdProvider();
			provider = adMobProvider.isEnabled ? adMobProvider : null;
		}

		return provider ?? new NoOpAdProvider();
	};
}