import { GameStatus } from '../../../game-to-app/app';
import { Levels, Style } from '../../../game-to-app/enums';
import { IGameLevelDefinition } from '../../../game-to-app/interfaces';
import { Nullable } from '../../../shared/types';
import { ReviewManager } from '../../shared/reviews/review-manager';
import { Database } from '../../shared/storage/database';
import { IGameStats, IGameStatsUpdate } from '../../shared/storage/views/gamestats';
import { Events } from '../../shared/telemetry/events';
import { ICustomProperties } from '../../shared/telemetry/interfaces';
import { Telemetry } from '../../shared/telemetry/telemetry';
import { ObjectUtils } from '../../shared/utils';
import { ILastGameResult } from './interfaces';
import { LevelManager } from './level-manager';

/**
 * The GameStats class of play counts and best times.
 *
 * @export
 * @class GameStats
 */
export class GameStats {
	/**
	 * Gets the total number of played games.
	 * @readonly
	 */
	public get playedTotal(): number { return this._playedTotal; }

	/**
	 * Gets all stats.
	 * @readonly
	 */
	public get allStats(): Record<string, IGameStats> { return this._stats; }

	/**
	 * Gets the total number of started games.
	 * @readonly
	 */
	public get startedTotal(): number { return this._startedTotal; }

	/**
	 * Gets the last game result.
	 * @readonly
	 */
	public get lastGameResult(): ILastGameResult { return this._lastGameResult; }

	/**
	 * Gets the current level.
	 * @readonly
	 */
	public get level(): IGameLevelDefinition { return this._level; }

	private _level: IGameLevelDefinition;
	private _assistantEnabled: boolean;
	private _stats: Record<string, IGameStats>;
	private _playedTotal: number = 0;
	private _startedTotal: number = 0;
	private _lastGameResult: ILastGameResult = {};
	private _startedNew: boolean = false;

	/**
	 * Creates an instance of GameStats.
	 * @param level The game level.
	 * @param stats The game stats; optional.
	 * @memberof GameStats
	 */
	constructor(level: IGameLevelDefinition) {
		this._level = level;
		this._stats = Database.gameStats.get();
		this._assistantEnabled = false;
	}

	/**
	 * Sets the game level.
	 * @param level The level.
	 */
	public setLevel = (level: IGameLevelDefinition): void => {
		this._level = level;
		this._lastGameResult = {};
	};

	/**
	 * Sets the 'assistant enabled' value.
	 * @param assistantEnabled A Boolean value indicating whether the assistant was enabled.
	 */
	public setAssistantEnabled = (assistantEnabled: boolean): void => {
		this._assistantEnabled = assistantEnabled;
	};

	/**
	 * Gets the current game stats.
	 * @returns The current game stats.
	 */
	public get = (): IGameStats => {
		const id: string = this.getId();

		return this._stats[id] || {
			id: id,
			time: Number.MAX_SAFE_INTEGER,
			won: 0,
			lost: 0,
			played: 0
		};
	};

	/**
	 * Updates the number of started games.
	 */
	public startNew = (): void => {
		this._startedTotal += 1;
		this._startedNew = true;
		this.logTelemetryEvent(Events.GameStarted);
	};

	/**
	 * Updates the stats by adding the given values.
	 * @param status The game status.
	 * @param time The game time.
	 * @returns The updated game stats.
	 */
	public update = (status: GameStatus, time: number): IGameStats => {
		const id: string = this.getId();

		// the local stats
		// if a player has the app open on multiple tabs, then the results from other tabs will not
		// be reflectced on the current tab.
		const stats = this.get();

		// the update object for the database
		// the eventual database update will take games played on other tabs into account.
		let update: Nullable<IGameStatsUpdate> = null;
		let updateSessionGameResult: Nullable<GameStatus> = null;

		switch (status) {
			case GameStatus.EndedNoPlay:
				stats.played += 1;
				this._playedTotal += 1;
				this._lastGameResult = { level: this._level, assistantEnabled: this._assistantEnabled };
				update = {
					id: id,
					played: stats.played,
				};
				this.logTelemetryEvent(Events.GameEndedNoPlay);
				break;
			case GameStatus.Won:
				stats.played += 1;
				stats.won += 1;
				this._playedTotal += 1;
				this._lastGameResult = {
					level: this._level,
					won: true,
					bestTime: stats.time > time,
					time: time,
					assistantEnabled: this._assistantEnabled,
				};
				stats.time = Math.min(stats.time, time);
				update = {
					id: id,
					played: stats.played,
					won: stats.won,
					time: time,
				};
				updateSessionGameResult = status;
				this.logTelemetryEvent(Events.GameWon, { bestTime: this._lastGameResult.bestTime || false });
				break;
			case GameStatus.Lost:
				stats.played += 1;
				stats.lost += 1;
				this._playedTotal += 1;
				this._lastGameResult = {
					level: this._level,
					lost: true,
					assistantEnabled: this._assistantEnabled,
				};
				update = {
					id: id,
					played: stats.played,
					lost: stats.lost,
				};
				updateSessionGameResult = status;
				this.logTelemetryEvent(Events.GameLost);
				break;
			case GameStatus.ActivePlaying:
				if (this._startedNew) {
					this._startedNew = false;
					this.logTelemetryEvent(Events.GameActivePlay);
				}
				break;
		}

		this._stats[this.getId()] = stats;

		if (update) {
			Database.gameStats.update(update);
		}

		if (updateSessionGameResult) {
			ReviewManager.addSessionGameResult(updateSessionGameResult === GameStatus.Won);
		}

		return stats;
	};

	/**
	 * Gets the id used to access the internal stats object.
	 * @returns The id.
	 * @private
	 */
	private getId = (): string => {
		return LevelManager.getGameLevelIdentifier(this._level, this._assistantEnabled);
	};

	/**
	 * Gets the telemetry event data.
	 * @returns The tlemetry event data.
	 * @private
	 */
	private getTelemetryEventData = (): ICustomProperties => {
		return ({
			level: Levels[this._level.level],
			style: Style[this._level.style],
			assistantEnabled: this._assistantEnabled,
			startedTotal: this._startedTotal,
			playedTotal: this._playedTotal,
			stats: this._stats,
		});
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		const properties: ICustomProperties = {
			...this.getTelemetryEventData(),
			...customProperties,
		};

		if (!ObjectUtils.isNullOrUndefined(properties.level) &&
			!ObjectUtils.isNullOrUndefined(properties.style)) {

			Telemetry.event(
				event.toString(),
				properties);
		}
	};
}