import { IDBPDatabase } from 'idb';
import { IModelMetadata, IModelUpgradeManager, IModelValues } from './model-interfaces';

/**
 * The class implements a default upgrade.
 * @export
 * @class DefaultUpgrade
 * @implements {IModelUpgradeManager}
 */
export class DefaultUpgrade<T extends IModelValues> implements IModelUpgradeManager {
	protected _database: IDBPDatabase;
	protected _metadata: IModelMetadata<T>;

	/**
	 * Creates an instance of DefaultUpgrade.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The metadata.
	 * @memberof DefaultUpgrade
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<T>) {
		this._database = database;
		this._metadata = metadata;
	}

	/**
	 * Updates the schema before any data migration.
	 * @param oldVersion The old database version.
	 * @param newVersion The new database version.
	 * @returns true if any schema change was made; otherwise, false.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public preUpgrade = (oldVersion: number, newVersion: number): boolean => {
		return this.createModelIfNotAlreadyExists();
	};

	/**
	 * Updates the schema after all data migration has completed.
	 * @param oldVersion The old database version.
	 * @param newVersion The new database version.
	 * @returns true if any schema change was made; otherwise, false.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public postUpgrade = (oldVersion: number, newVersion: number): boolean => {
		return false;
	};

	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {
		return true;
	};

	/**
	 * Creates a model store if it does not already exists.
	 * @returns true if the store was created; otherwise, false.
	 */
	protected createModelIfNotAlreadyExists = (): boolean => {
		const result: boolean = !this._database.objectStoreNames.contains(this._metadata.name);

		if (result) {
			this._database.createObjectStore(this._metadata.name, this._metadata.parameters);
		}

		return result;
	}
}