import { CellStatus, Move } from '../game/enums';

/**
 * Defines the style of a game.
 * @export
 */
export enum Style {
	Classic,
	Cube,
	Box,
	Pane,
}

/**
 * Defines the level of a game.
 * @export
 */
export enum Levels {
	Easy,
	Medium,
	Hard,
	Custom,
}

/**
 * Defines the orientation of the screen / game.
 * @export
 */
export enum Orientation {
	Portrait,
	Landscape,
}

export { CellStatus, Move };
