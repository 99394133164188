import { BoardDefinition, FaceDefinition } from './board-definition';
import { Cell } from './cell';
import { AllowDiscovery, CellStatus, HintResult, MarkResult, Move, MoveResult, MoveResultReason, UncoverResult } from './enums';
import { Face } from './face';
import { GameOptions } from './game-options';
import { IFlaggedMineCount } from './interfaces';
import { Neighbors } from './neighbors';
import { IDeserializedBoard, ISerializedBoard, ISerializedCell, ISerializedFace } from './serialization-interfaces';
import { CellId, MoveResultType, Nullable } from './types';

/**
 * The Board class represents the game board.
 * @export
 * @class Board
 */
export class Board {

	/**
	 * Gets the total cell count.
	 * @readonly
	 */
	public get totalCellCount(): number { return this._cellCount; }

	/**
	 * Gets the total mine count.
	 * @readonly
	 */
	public get totalMineCount(): number { return this._mineCount; }

	/**
	 * Gets the remaining, unflagged mine count.
	 * @readonly
	 */
	public get remainingMineCount(): number { return this._mineCount - this._minesFlaggedCorrectly - this._minesFlaggedIncorrectly; }

	/**
	 * Gets the game board faces.
	 * @readonly
	 */
	public get faces(): Record<string, Face> { return this._faces; }

	private readonly _options: GameOptions;
	private _faces: Record<string, Face> = {};
	private _mineCount: number = 0;
	private _minesFlaggedCorrectly: number = 0;
	private _minesFlaggedIncorrectly: number = 0;
	private _cellCount: number = 0;
	private _uncoveredCellCount: number = 0;
	private _remainingHints: number = 0;
	private _didUncoverNeighbors: boolean = false;

	// temporary holder of a reference to the cells. Will be null after mines have been distributed.
	private _cells: Nullable<Cell[]> = [];

	/**
	 * Creates an instance of Board.
	 * @param definition The board definition.
	 * @param options The game options.
	 * @param deserializedBoard State restored from serialization; optional.
	 * @memberof Board
	 */
	public constructor(
		definition: BoardDefinition,
		options: GameOptions,
		deserializedBoard: Nullable<IDeserializedBoard> = null) {
		
		// options
		this._options = options;
		
		if (!deserializedBoard) {
			// mine count
			this._mineCount = definition.mineCount;

			// hints
			this._remainingHints = definition.hintLimit;
		
			// get the total number of cells
			this._cellCount = this.getTotalCellCount(definition);

			// ... and use it to create an array of boolean values indicating mine locations
			// initially all cells will be declared as non-mines. The actual distribution of mines
			// will happen after the player uncovers the first cell.
			const mineLocations: number[] = this.getMineLocations(this._cellCount, 0, []);
			let rank: number = 0;

			// iterate all the faces
			for (const key in definition.faces) {
				const face: FaceDefinition = definition.faces[key];
				const cells: Cell[][] = [];

				// create cells in 3d array per face
				for (let y: number = 0; y < face.height; y++) {
					const row: Cell[] = [];
					cells.push(row);

					for (let x: number = 0; x < face.width; x++) {
						rank += 1;
					
						const cell: Cell = new Cell(
							`${key}-${y}-${x}`,
							rank,
							mineLocations.indexOf(rank) >= 0,
							this._options);
					
						this._cells?.push(cell);
						row.push(cell);

						// neighbors
						Neighbors.addSameFaceNeighbors(
							cell,
							cells,
							x,
							y,
							{ width: face.width, height: face.height });
					}
				}

				this._faces[key] = new Face(cells, this._options);
			}

			// finally link the faces by setting their neighbors
			Neighbors.addOtherFaceNeighbors(this._faces, definition);
		}
		else {
			this._mineCount = deserializedBoard.mineCount;
			this._remainingHints = deserializedBoard.remainingHints;
			this._cellCount = deserializedBoard.cellCount;
			this._faces = deserializedBoard.faces;

			this._minesFlaggedCorrectly = deserializedBoard.minesFlaggedCorrectly;
			this._minesFlaggedIncorrectly = deserializedBoard.minesFlaggedIncorrectly;
			this._uncoveredCellCount = deserializedBoard.uncoveredCellCount;
			this._didUncoverNeighbors = deserializedBoard.didUncoverNeighbors;
		}
	}


	/**
	 * Initializes mine locations.
	 * @param excluded A cell that should not hide a mine.
	 */
	public initializeMineLocations = (excluded: Nullable<Cell>): void => {
		if (this._cells) {
			const excludedRanks: number[] = [];

			if (excluded) {
				excludedRanks.push(excluded.rank);
				excluded.neighbors?.forEach((cell: Cell) => {
					excludedRanks.push(cell.rank);
				});
			}

			const mineLocations: number[] = this.getMineLocations(this._cellCount, this._mineCount, excludedRanks);

			for (let i = 0; i < mineLocations.length; i++) {
				const cell: Nullable<Cell> = this._cells[mineLocations[i] - 1];
				cell?.declareAsMine();
			}

			// the board shouldn't have a direct reference to all the cells, it should go through the faces
			this._cells = null;
		}
	};

	/**
	 * Executes a player's move.
	 * @param cellId The cell that the player chose.
	 * @param move The type of move.
	 * @returns A move result.
	 */
	public move = (cellId: CellId, move: Move): MoveResultType => {
		let result: MoveResultType = { result: MoveResult.Rejected, reason: MoveResultReason.NoCell };
		const cell: Nullable<Cell> = this.getCell(cellId);

		if (cell) {
			switch (move) {
				case Move.Hit:
					result = this.moveHit(cell);
					break;
				case Move.Mark:
					result = this.moveMark(cell);
					break;
				case Move.Discover:
					result = this.moveDiscover(cell);
					break;
				case Move.Hint:
					result = this.moveHint(cell);
					break;
			}

			// check if game is over
			result = this.finalizeMove(cell, result);
		}

		return result;
	};

	/**
	 * Gets a cell by its Id.
	 * @param cellId The cell Id.
	 */
	public getCell = (cellId: Nullable<CellId>): Nullable<Cell> => {
		let cell: Nullable<Cell> = null;

		if (cellId &&
			cellId.face &&
			Object.prototype.hasOwnProperty.call(this._faces, cellId.face)) {
			cell = this._faces[cellId.face].getCell(cellId.x, cellId.y);
		}

		return cell;
	};

	/**
	 * Determines if the game is in 'active' play.
	 * @returns A Boolean value indicating whether the game is in 'active' play.
	 * @remarks In active play means the player has either uncovered at least one cell or has marked
	 * one or more cells as mines.
	 */
	public isInActivePlay = (): boolean => {
		return this.totalMineCount !== this.remainingMineCount || this._didUncoverNeighbors;
	}

	/**
	 * Handles the move where a player 'hits' (uncovers) a cell.
	 * @param cell The cell.
	 * @returns The move result.
	 * @private
	 */
	private moveHit = (cell: Cell): MoveResultType => {
		let result: MoveResultType = { result: MoveResult.Accepted, reason: MoveResultReason.None };

		// uncover the directly hit cell
		const uncoverResult: UncoverResult = cell.uncover();

		switch (uncoverResult) {
			case UncoverResult.Boom:
				// game over, hit a mine
				this._uncoveredCellCount += 1;
				result = {
					result: this.isInActivePlay() ? MoveResult.Lost : MoveResult.EndedNoPlay,
					reason: MoveResultReason.HitMine
				};
				break;
			case UncoverResult.Continue:
				// uncover neighbors
				this._uncoveredCellCount += 1;
				this.uncoverNeighbors(cell);
				break;
			case UncoverResult.Stop:
				// uncover just the hit cell
				this._uncoveredCellCount += 1;
				break;
			case UncoverResult.DenyNotCovered:
			case UncoverResult.DenyMarked:
			case UncoverResult.DenyMineShown:
				result = { result: MoveResult.Rejected, reason: MoveResultReason.CellUncoveredOrMarked }
				break;
		}

		return result;
	};

	/**
	 * Handles the move where the player marks a cell.
	 * @param cell The cell.
	 * @returns The move result.
	 * @private
	 */
	private moveMark = (cell: Cell): MoveResultType => {
		let result: MoveResultType = { result: MoveResult.Accepted, reason: MoveResultReason.None };

		const markResult: MarkResult = cell.mark();

		switch (markResult) {
			case MarkResult.FlaggedCorrectly:
				this._minesFlaggedCorrectly += 1;
				this.updateFlaggedNeighborMineCount(cell, { correct: 1 });
				break;
			case MarkResult.FlaggedIncorrectly:
				this._minesFlaggedIncorrectly += 1;
				this.updateFlaggedNeighborMineCount(cell, { incorrect: 1 });
				break;
			case MarkResult.ClearedCorrectFlag:
				this._minesFlaggedCorrectly -= 1;
				this.updateFlaggedNeighborMineCount(cell, { correct: -1 });
				break;
			case MarkResult.ClearedIncorrectFlag:
				this._minesFlaggedIncorrectly -= 1;
				this.updateFlaggedNeighborMineCount(cell, { incorrect: -1 });
				break;
			case MarkResult.CellNotCoveredOrMarked:
				result = {
					result: MoveResult.Rejected,
					reason: MoveResultReason.CellNotCoveredOrMarked,
				};
				break;
			case MarkResult.FlagsNotEnabled:
				result = {
					result: MoveResult.Rejected,
					reason: MoveResultReason.FlagsNotEnabled,
				};
				break;
		}

		return result;
	};

	/**
	 * Handles the move where a player 'discovers' the neighbor cells (clears an area).
	 * @param cell The cell.
	 * @returns The move result.
	 * @private
	 */
	private moveDiscover = (cell: Cell): MoveResultType => {
		let result: MoveResultType = { result: MoveResult.Accepted, reason: MoveResultReason.None };

		// check if the field is uncovered and the expected number of mines has been flagged.
		const allowDiscovery: AllowDiscovery = cell.allowDiscovery();

		if (allowDiscovery === AllowDiscovery.Allow) {

			// uncover the neighbors 
			this.uncoverNeighbors(cell);

			// if a flag was set incorrectly, the game is over
			if (cell.neighbors) {
				cell.neighbors.forEach((neighbor: Cell) => {
					if ((neighbor.status === CellStatus.Flagged || neighbor.status === CellStatus.FlaggedByHint) &&
						!neighbor.isMine) {
						result = { result: MoveResult.Lost, reason: MoveResultReason.IncorrectFlag };
					}
				});
			}
		}
		else {
			// deny discovery
			result.result = MoveResult.Rejected;

			switch (allowDiscovery) {
				case AllowDiscovery.DenyNotUncovered:
					result.reason = MoveResultReason.CellNotUncovered;
					break;
				case AllowDiscovery.DenyNonMatchingFlaggedCount:
					result.reason = MoveResultReason.NonMatchingFlaggedCount;
					break;
			}
		}

		return result;
	};

	/**
	 * Handles the move where a player requests a hint.
	 * @param cell The cell.
	 * @returns The move result.
	 * @private
	 */
	private moveHint = (cell: Cell): MoveResultType => {
		const result: MoveResultType = this._remainingHints > 0
			? { result: MoveResult.Accepted, reason: MoveResultReason.None }
			: { result: MoveResult.Rejected, reason: MoveResultReason.HintLimitExceeded };

		if (result.result === MoveResult.Accepted) {
			// hint
			const hintResult: HintResult = cell.hint();

			switch (hintResult) {
				case HintResult.Flagged:
					this._minesFlaggedCorrectly += 1;
					this.updateFlaggedNeighborMineCount(cell, { correct: 1 });
					this._remainingHints -= 1;
					break;
				case HintResult.ReadyToUncover:
					this.moveHit(cell);
					this._remainingHints -= 1;
					break;
				case HintResult.DenyLimitExceeded:
				case HintResult.DenyNotQuestionMarked:
					// do nothing
					break;
			}
		}

		return result;
	};

	/**
	 * Finalizes a move.
	 * @param cell The cell.
	 * @param moveResult The move result.
	 * @returns The move result.
	 * @remarks Checks if the player has won the game. If the game is over, uncovers mine cells.
	 * @private
	 */
	private finalizeMove = (cell: Cell, moveResult: MoveResultType): MoveResultType => {
		let result: MoveResultType = moveResult;

		if (cell && moveResult) {
			// check if the player has won
			// the game is won if 
			// a) all mines have been placed correctly and no other fields remain covered or
			// b) all flagged cells are flagged correctly and all the non-uncovered fields must be mines
			// this can be simplified as: 
			// no mines have been placed incorrectly and the remaining non-uncovered field count matches the total mine count.
			if (moveResult.result === MoveResult.Accepted) {
				if (this._minesFlaggedIncorrectly === 0 &&
					this._mineCount === (this._cellCount - this._uncoveredCellCount)) {
					result = { result: MoveResult.Won, reason: MoveResultReason.None };
				}
			}

			// game over - show mine locations
			if (result.result === MoveResult.Won || result.result === MoveResult.Lost || result.result === MoveResult.EndedNoPlay) {
				for (const key in this._faces) {
					const face: Face = this._faces[key];
					face.showMines();
				}
			}
		}

		return result;
	};

	/**
	 * Uncovers neighbor cells.
	 * @param cell The cell.
	 * @private
	 */
	private uncoverNeighbors = (cell: Cell): void => {
		if (cell && cell.neighbors) {
			this._didUncoverNeighbors = true;

			const stack: Cell[] = [...cell.neighbors];

			// use an iterative algorithm
			// a recursive algorithm will hit stack overflows for large boards and/or low mine counts.
			while (stack.length > 0) {
				const cell = stack.pop();

				if (cell && cell.status === CellStatus.Covered && !cell.isMine) {
					// increment uncovered count
					this._uncoveredCellCount += 1;

					// keep going as needed
					if (cell.uncover() === UncoverResult.Continue) {
						if (cell.neighbors) {
							cell.neighbors.forEach((neighbor: Cell) => stack.push(neighbor));
						}
					}
				}
			}
		}
	};

	/**
	 * Updates the flagged mine neighbor count for neighboring cells.
	 * @param cell The cell.
	 * @param flagged The correct/incorrect mine count.
	 * @private
	 */
	private updateFlaggedNeighborMineCount = (cell: Cell, flagged: IFlaggedMineCount): void => {
		if (cell && cell.neighbors) {
			cell.neighbors.forEach((neighbor: Cell) => {
				neighbor.updateFlaggedNeighborMineCount(flagged);
			});
		}
	};

	/**
	 * Gets the total cell count.
	 * @returns The total cell count.
	 * @private
	 */
	private getTotalCellCount = (definition: BoardDefinition): number => {
		let result: number = 0;

		if (definition) {
			for (const key in definition.faces) {
				const face: FaceDefinition = definition.faces[key];
				result += face.height * face.width;
			}
		}

		return result;
	};

	/**
	 * Gets the mine locations.
	 * @param totalCellCount The total number of cells.
	 * @param mineCount The total number of mines to distribute.
	 * @param excluded An array of cell indexes that should not receive mines.
	 * @returns An array of indexes for cells that should receive mines.
	 * @private
	 */
	private getMineLocations = (totalCellCount: number, mineCount: number, excluded: number[]): number[] => {
		// make sure the length of the excluded array does not conflict with the number of mines that need to be placed
		const maxExcludedCount = Math.max(0, Math.min(totalCellCount - mineCount, excluded?.length || 0));
		const effectiveExcluded: number[] = maxExcludedCount > 0
			? excluded.slice(0, maxExcludedCount)
			: [];

		// initialize the array; cell Ids start at 1
		const result: number[] = Array.from(new Array(totalCellCount), (value, index) => index + 1).filter((value) => {
			return effectiveExcluded.indexOf(value) < 0;
		});

		// Fisher-Yates shuffle
		for (let i: number = result.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[result[i], result[j]] = [result[j], result[i]];
		}

		// declare the beginning of the shuffled array as mine locations
		return result.slice(0, mineCount);
	};

	/**
	 * Serializes the board into an object.
	 * @returns The serialized board.
	 */
	public serialize = (): ISerializedBoard => {
		const faces: Record<string, ISerializedFace> = {};
		const cells: Record<string, ISerializedCell> = {};

		// serialize faces
		Object.keys(this._faces).forEach((key) => {
			faces[key] = this._faces[key].serialize();

			// add all face cells to the board cells
			this._faces[key].cells.forEach((faceCells: Cell[]) => faceCells.forEach((cell: Cell) => {
				cells[cell.id] = cell.serialize();
			}));
		});

		const result: ISerializedBoard = {
			mineCount: this._mineCount,
			minesFlaggedCorrectly: this._minesFlaggedCorrectly,
			minesFlaggedIncorrectly: this._minesFlaggedIncorrectly,
			cellCount: this._cellCount,
			uncoveredCellCount: this._uncoveredCellCount,
			remainingHints: this._remainingHints,
			didUncoverNeighbors: this._didUncoverNeighbors,
			faces: faces,
			cells: cells,
		};

		return result;
	};

	/**
	 * Creates a board instance from a serialized board.
	 * @param serializedBoard The serialized board.
	 * @param definition The board definition.
	 * @param options The game options.
	 * @returns The board.
	 * @static
	 */
	public static createFromSerialization = (
		serializedBoard: ISerializedBoard,
		definition: BoardDefinition,
		options: GameOptions): Board => {

		// cells
		const cells: Record<string, Cell> = {};
		Object.keys(serializedBoard.cells).forEach((key: string) => {
			const cell: ISerializedCell = serializedBoard.cells[key];
			cells[cell.id] = Cell.createFromSerialization(cell, options);
		});

		// restore cell neighbors
		Object.keys(serializedBoard.cells).forEach((key: string) => {
			const cell: ISerializedCell = serializedBoard.cells[key];
			cell.neighborCellIds.forEach((cellId: string) => cells[cell.id].addNeighbor(cells[cellId]));
		});

		// restore faces
		const faces: Record<string, Face> = {};
		Object.keys(serializedBoard.faces).forEach((key) => {
			faces[key] = Face.createFromSerialization(
				serializedBoard.faces[key],
				options,
				cells);
		});

		const board: Board = new Board(
			definition,
			options,
			{
				mineCount: serializedBoard.mineCount,
				remainingHints: serializedBoard.remainingHints,
				cellCount: serializedBoard.cellCount,
				faces: faces,
				minesFlaggedCorrectly: serializedBoard.minesFlaggedCorrectly,
				minesFlaggedIncorrectly: serializedBoard.minesFlaggedIncorrectly,
				uncoveredCellCount: serializedBoard.uncoveredCellCount,
				didUncoverNeighbors: serializedBoard.didUncoverNeighbors,
			});

		return board;
	};
}