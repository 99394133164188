import { Nullable } from '../../../shared/types';
import { IAudioManager } from '../interfaces';
import { Platform } from '../platform/platform';
import { AudioManagerAndroid } from './android/audio-manager-android';
import { AudioManagerWeb } from './web/audio-manager-web';

/**
 * The AudioFactory class implements a factory for audio managers.
 * @export
 * @class AudioFactory
 */
export class AudioFactory {
	/**
	 * Gets the platform specific audio manager.
	 * @returns The audio manager.
	 * @static
	 */
	public static get = (): Nullable<IAudioManager> => {
		return Platform.isAndroid()
			? new AudioManagerAndroid()
			: new AudioManagerWeb();
	}
}