import * as React from 'react';
import { Splash } from '../splash/splash';
import { IProps } from './props';

const DeferredCSSLoader = React.lazy(() => import('./deferred-css-loader'));

/**
 * The DeferredCSS class implements the <DeferredCSS/> component.
 * @export
 * @class DeferredCSS
 */
export class DeferredCSS extends React.Component<IProps> {

	/**
	 * Renders the <DeferredCSS/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		return (
			this.props.load
				? <React.Suspense fallback={<Splash />}>
					<DeferredCSSLoader/>
				</React.Suspense>
				: null
		);
	};
}