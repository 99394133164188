import { Nullable } from '../shared/types';
import { GameLimits } from './constants';
import { Orientation, Style } from './enums';
import { IGameLevelDefinition, IGameLimits, ILevelDefinition } from './interfaces';
import { Dimension } from './types';

/**
 * The LevelDefinitionManager class manages the limits of a level definition.
 * @export
 * @class LevelDefinitionManager
 */
export class LevelDefinitionManager {
	private _levelDefinition: ILevelDefinition;
	private _gameDefinitions: Record<Orientation, Nullable<IGameLevelDefinition>> = {
		[Orientation.Portrait]: null,
		[Orientation.Landscape]: null,
	};

	/**
	 * Gets the level definition.
	 * @readonly
	 */
	public get definition(): ILevelDefinition { return this._levelDefinition; }

	/**
	 * Creates an instance of LevelDefinitionManager.
	 * @param levelDefinition The level definition.
	 * @memberof LevelDefinitionManager
	 */
	constructor(levelDefinition: ILevelDefinition) {
		this._levelDefinition = { ...levelDefinition };
	}

	/**
	 * Gets the dimension for a given orientation.
	 * @param orientation The orientation.
	 */
	public getGameDefinition = (orientation: Orientation): IGameLevelDefinition => {
		if (this._gameDefinitions[orientation] === null) {
			const dimension: Dimension = this.getDimension(orientation);
			const mineCount: number = this.getMineCount(dimension);
			const hintLimit: number = this.getHintLimit(dimension);

			// cache
			this._gameDefinitions[orientation] = {
				...this._levelDefinition,
				dimension: dimension,
				mines: mineCount,
				hintLimit: hintLimit,
			};
		}

		return this._gameDefinitions[orientation] as IGameLevelDefinition;
	};

	/**
 * Gets the mine count.
 * @param dimension The dimension.
 * @returns The hint limit that respects the limits.
 */
	private getHintLimit = (dimension: Dimension): number => {
		const limits: IGameLimits = GameLimits[this._levelDefinition.style];
		const cells: number = LevelDefinitionManager.getCellCount(dimension, this._levelDefinition.style);

		return Math.min(
			Math.max(
				Math.min(this._levelDefinition.hintLimit, cells),
				limits.hintLimit.min),
			limits.hintLimit.max);
	}

	/**
	 * Gets the cell count.
	 * @param dimension The dimension.
	 * @param style The style.
	 * @returns The total cell count.
	 */
	public static getCellCount = (dimension: Dimension, style: Style): number => {
		let result: number = 0;

		switch (style) {
			case Style.Cube:
			case Style.Box:
				result = (
					2 * (dimension.width * dimension.height) +
					2 * (dimension.width * dimension.depth) +
					2 * (dimension.height * dimension.depth)
				);
				break;
			case Style.Classic:
			case Style.Pane:
				result = (
					dimension.width *
					dimension.height *
					Math.max(dimension.depth, 1)
				);
				break;
		}

		return result;
	};

	/**
	 * Gets the dimension.
	 * @param orientation The orientation.
	 * @returns The mine count that respects the limits.
	 */
	private getDimension = (orientation: Orientation): Dimension => {
		return this.applyDimensionLimits(
				orientation === Orientation.Portrait
					? { ...this._levelDefinition.dimensions.portrait }
					: { ...this._levelDefinition.dimensions.landscape }
			);
	};

	/**
	 * Gets the mine count.
	 * @param dimension The dimension.
	 * @returns The mine count that respects the limits.
	 */
	private getMineCount = (dimension: Dimension): number => {
		const limits: IGameLimits = GameLimits[this._levelDefinition.style];
		const cells: number = LevelDefinitionManager.getCellCount(dimension, this._levelDefinition.style);

		return Math.min(Math.max(this._levelDefinition.mines, limits.mines.min), cells - 1);
	};

	/**
	 * Applies dimension limits to a given dimension.
	 * @param dimension The dimension.
	 * @returns A dimension object that respects the limits.
	 * @private
	 */
	private applyDimensionLimits = (dimension: Dimension): Dimension => {
		const limits: IGameLimits = GameLimits[this._levelDefinition.style];

		return {
			width: Math.min(
				Math.max(dimension.width, limits.dimensions.width.min),
				limits.dimensions.width.max),
			height: Math.min(
				Math.max(dimension.height, limits.dimensions.height.min),
				limits.dimensions.height.max),
			depth: Math.min(
				Math.max(dimension.depth, limits.dimensions.depth.min),
				limits.dimensions.depth.max),
		};
	};
}