import * as React from 'react';
import { Link } from 'react-router-dom';
import metadata from '../../../metadata/metadata.json';
import { IAbout } from '../shared/interfaces';
import { Routes } from '../shared/routes';
import footerStyles from './footer.module.css';
import { IProps } from './props';

/**
 * The Footer class implements the <Footer/> component.
 * @export
 * @class Footer
 * @extends {React.Component<IProps>}
 */
export class Footer extends React.Component<IProps> {
	private _minWidthForContact: number = 350;
	private _minWidthForPrivacyPolicy = 292;

	/**
	 * Creates an instance of Footer.
	 * @param {IProps} props The props for the <Footer/> component.
	 * @memberof Footer
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <Footer/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const about: IAbout = this.getAbout();
		const width = window.innerWidth;

		const contact: JSX.Element | null = this.props.showContact && width >= this._minWidthForContact
			? <span><a className={footerStyles.link} href={`mailto:${about.contact}`}>Contact</a></span>
			: null;

		const privacyPolicy: JSX.Element | null = this.props.showPrivacy && width >= this._minWidthForPrivacyPolicy
			? <span><Link className={footerStyles.link} to={Routes.Privacy}>Privacy Policy</Link></span>
			: null;

		return (
			<footer className={`footer bg-dark ${footerStyles.footer}`}>
				<div className={footerStyles.content}>
					{contact}
					{privacyPolicy}
					{this.props.showTerms
						? <span><Link className={footerStyles.link} to={Routes.Terms}>Terms of Use</Link></span>
						: null}
					{this.props.showCopyright
						? < span className={footerStyles.text}>
							&copy; {about.copyright.year}{' '}
							{about.copyright.name}
						</span>
						: null}
				</div>
			</footer>
		);
	};

	/**
	 * Gets 'about' information for the footer.
	 */
	private getAbout = (): IAbout => {
		return metadata;
	};
}