import { IDBPDatabase } from 'idb';
import { Levels, Orientation, Style } from '../../../../../../game-to-app/enums';
import { IGameLevelDefinition } from '../../../../../../game-to-app/interfaces';
import { LevelManager } from '../../../../../components/shared/level-manager';
import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelMetadata } from '../../../model-interfaces';
import { IModelGameStatsValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the GameStats model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelGameStatsValues> {

	/**
	 * Creates an instance of GameStatsUpgrade.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The metadata.
	 * @memberof GameStatsUpgrade
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelGameStatsValues>) {
		super(database, metadata);
	}

	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {

		// load game stats
		const records: IModelGameStatsValues[] = await this._database.getAll(this._metadata.name);
		
		for (let i = 0; i < records.length; i++) {
			const key: string = records[i].id;

			// add the key part for 'enable flags'
			const keyParts: string[] = key.split('-');		

			if (keyParts.length === 3) {
				const level: Levels = parseInt(keyParts[0], 10);
				const style: Style = parseInt(keyParts[1], 10);
				const assistantEnabled: boolean = keyParts[2] === '1';

				// get the definition
				const gameLevelDefinition: IGameLevelDefinition = LevelManager.getGameLevelDefinition(
					style,
					level,
					null,
					Orientation.Portrait
				);

				// get the identifier
				const updatedKey = LevelManager.getGameLevelIdentifier(
					gameLevelDefinition,
					assistantEnabled);
				
				const updatedGameStats: IModelGameStatsValues = { ...records[i], id: updatedKey };

				// add the record with the new key
				await this._database.add(
					this._metadata.name,
					{
						...updatedGameStats,
						...this._metadata.systemValues,
					}
				);

				// remove the same record with the old key
				await this._database.delete(this._metadata.name, key);
			}
		}

		return true;
	};
}