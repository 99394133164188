import { Events } from './events';

export class SubscriptionEvent {
	private static _instance: Events = new Events();

	/**
	 * Creates an instance of SubscriptionEvent.
	 * @memberof SubscriptionEvent
	 */
	private constructor() {
		// singleton, should only be instantiated from this class.
	}

	/**
	* Subscribes a listener for an event.
	* @param event The event.
	* @param listener The listener.
	* @returns true if the listener was subscribed; otherwise, false.
	*/
	public static subscribe = (event: SubscriptionEvents, listener: SubscriptionEventListener): boolean => {
		return SubscriptionEvent._instance.addListener(event, listener);
	};

	/**
	 * Unsubscribes a listener for an event.
	 * @param event The event.
	 * @param listener The listener.
	 * @returns true if the listener was unsubscribed; otherwise, false.
	 */
	public static unsubscribe = (event: SubscriptionEvents, listener: SubscriptionEventListener): boolean => {
		return SubscriptionEvent._instance.removeListener(event, listener);
	};

	/**
	 * Raises an event.
	 * @param event The event.
	 * @param args The event parameters.
	 * @static
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static raise = (event: SubscriptionEvents, ...args: any[]): void => {
		SubscriptionEvent._instance.raise(event, ...args);
	};
}

/**
 * Defines the types of subcription events.
 */
export enum SubscriptionEvents {
	AdServeStatusChanged = 'AdServeStatusChanged',
	AppBackButtonClicked = 'AppBackButtonClicked',
	AppStateChanged = 'AppStateChanged',
	CellVisibilityChanged = 'CellVisibilityChanged',
	ExitApplication = 'ExitApplication',
	GameStatsChanged = 'GameStatsChanged',
	LevelsChanged = 'LevelsChanged',
	OwnsAllProductsStatusChanged = 'OwnsAllProductsStatusChanged',
	ProductsChanged = 'ProductsChanged',
	RouteChange = 'RouteChange',
	SavedGamesChanged = 'SavedGamesChanged',
	ShopOpened = 'ShopOpened',
	ShopFailedToOpen = 'ShopFailedToOpen',
	ShopProductUpdated = 'ShopProductUpdated',
	ShopPurchaseFlowProgress = 'ShopPurchaseFlowProgress',
	ServiceWorkerInstalled = 'ServiceWorkerInstalled',
	ServiceWorkerUpdated = 'ServiceWorkerUpdated',
	SettingsChanged = 'SettingsChanged',
	UserPreferencesChanged = 'UserPreferencesChanged',
	ZoomPositioning = 'ZoomPositioning',
}

/** 
 * Defines the type for a subscription event listener.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SubscriptionEventListener = (...args: any[]) => void;