import { BoolArray } from './bool-array';
import { Cluster } from './cluster';
import { Constraint } from './constraint';
import { SolutionCalculationMethod, SolutionStatus } from './enums';
import { IClusterSolverSolution, IMatrixCluster, IMatrixClusterRow, IMatrixFreeVariableCalculationResult, ISolution } from './interfaces';
import { Matrix } from './matrix';
import { MatrixClusterBuilder } from './matrix-cluster-builder';
import { Timer } from './timer';
import { NumberOrUndefined } from './types';

/**
 * The ClusterSolver class implements a minesweeper solver for a single cluster.
 * @export
 * @class ClusterSolver
 */
export class ClusterSolver {
	private _cluster: Cluster;
	private _matrix: Matrix | null = null;
	private _timer: Timer = new Timer(0);

	// the timer controls what is solved. Keeping this as a backup control
	private _maxClusterRowsToSolve = Number.MAX_VALUE;

	/**
	 *Creates an instance of ClusterSolver.
	 * @param cluster The cluster.
	 * @param timer The timer.
	 * @param maxMineCount The max number of mines in the cluster.
	 */
	constructor(cluster: Cluster, timer: Timer) {
		this._cluster = cluster;
		this._timer = timer;
	}

	/**
	 * Solves the trivial minesweeper constraints for a given cluster of cells.
	 * @returns An array of solutions.
	 */
	public solveTrivial = (): ISolution[] => {	
		// get trivial solutions
		const trivial: IClusterSolverSolution = this.extractTrivialSolutions();

		// save matrix
		this._matrix = trivial.matrix;

		return this.addUnsolvedResultsIfTimedOut(trivial.solutions);
	};

	/**
	 * Solves the non-trivial minesweeper constraints for a given cluster of cells.
	 * @param remainingMineCount The number of remaining mines.
	 * @returns An array of solutions.
	 */
	public solveNonTrivial = (remainingMineCount: number): ISolution[] => {
		let result: ISolution[] = [];

		if (this._matrix) {
			result = this.solveMatrixNonTrivial(this._matrix, remainingMineCount);
		}
		
		return this.addUnsolvedResultsIfTimedOut(result);
	};

	/**
	 * Extracts trivial solutions.
	 * @returns An array of solutions.
	 * @private
	 */
	private extractTrivialSolutions = (): IClusterSolverSolution => {
		let result: IClusterSolverSolution | null = null;
		let done: boolean = false;

		// keep solving trivial constraints, until no more solutions are found, or timer expired
		while (!done) {
			const before = result?.solutions.length || 0;

			// get trivial solutions from matrix algorithm
			const results: IClusterSolverSolution = this.solveMatrix();

			if (result === null) {
				result = results;
			}
			else {
				result.solutions = result.solutions.concat(results.solutions);
				result.matrix = results.matrix;
			}

			// done when no new result was found or timer expired
			done = result.solutions.length === before || this._timer.isExpired;
		}
		
		return result as IClusterSolverSolution;
	};
	
	/**
	 * Solves the constraints by converting them into a matrix and applying Gauss - Jordan elimination.
	 * @returns The solutions.
	 * @private
	 */
	private solveMatrix = (): IClusterSolverSolution => {
		const variableIds: string[] = this._cluster.getVariableIds();
		const constraints: Constraint[] = this._cluster.getConstraints();	
		const raw: number[][] = [];

		constraints.forEach((constraint: Constraint): void => {
			// regular constraints
			raw.push(variableIds.map((variableId: string): number =>
				constraint.hasVariableId(variableId) ? 1 : 0).concat(constraint.mineCount)
			);

			// add constraints for trivial cases where no variable has a mine or all do
			if (constraint.mineCount === 0 || constraint.mineCount === constraint.variablesCount) {
				const constraintVariableIds = constraint.getVariableIds();

				constraintVariableIds.forEach((constraintVariableId: string): void => {
					raw.push(variableIds.map((variableId: string): number =>
						variableId === constraintVariableId ? 1 : 0).concat(constraint.mineCount === 0 ? 0 : 1)
					);
				});
			}
		});

		// create a matrix
		const matrix: Matrix = new Matrix(raw, variableIds);
		
		// solve the matrix
		matrix.toReducedRowEchelonForm();

		// get results from matrix
		const results: ISolution[] = this.analyzeMatrix(matrix);

		// update constraints by removing solved variables
		results.forEach((result: ISolution): void => {
			this._cluster.removeConstraintVariables(
				result.id,
				result.status === SolutionStatus.Mine ? 1 : 0
			);
		});

		return ({
			solutions: results,
			matrix: matrix,
		});
	};

	/**
	 * Analyzes the reduced matrix.
	 * @param matrix The reduced matrix.
	 * @returns The solutions.
	 * @private
	 */
	private analyzeMatrix = (matrix: Matrix): ISolution[] => {
		let result: ISolution[] = [];
		const coefficients: number[][] = matrix.coefficients();
		const constants: number[] = matrix.constants();
		const variableIds: string[] = matrix.names();

		// store in map, so that the result will be unique solutions.
		const uniqueResults: Record<string, boolean> = {};

		coefficients.forEach((row: number[], index: number) => {
			// compute the min and max values that the coefficients can add up to
			const lowerBound: number = row.reduce((accumulator: number, value: number) =>
				accumulator += value < 0 ? value : 0, 0);
			const upperBound: number = row.reduce((accumulator: number, value: number) =>
				accumulator += value > 0 ? value : 0, 0);

			if (constants[index] === lowerBound) {
				row.forEach((value: number, index: number): void => {
					if (value !== 0) {
						// at the lower bound, negative coefficients represent mines
						uniqueResults[variableIds[index]] = value < 0;
					}
				});
			}
			else {
				if (constants[index] === upperBound) {
					row.forEach((value: number, index: number): void => {
						if (value !== 0) {
							// at the upper bound, positive coefficients represent mines
							uniqueResults[variableIds[index]] = value > 0;
						}
					});
				}
			}
		});

		// convert unique results to solutions
		result = Object.keys(uniqueResults).map((variableId: string): ISolution => {
			return ({
				id: variableId,
				status: uniqueResults[variableId] ? SolutionStatus.Mine : SolutionStatus.Clear,
				isMineProbability: uniqueResults[variableId] ? 100 : 0,
				method: SolutionCalculationMethod.Trivial,
			});
		});

		return result;
	};

	/**
	 * Solves the matrix non-trivial solutions.
	 * @param reducedMatrix The reduced matrix.
	 * @param remainingMineCount The remaining mine count.
	 * @returns The non-trivial solutions.
	 * @private
	 */
	private solveMatrixNonTrivial = (reducedMatrix: Matrix, remainingMineCount: number): ISolution[] => {
		const result: ISolution[] = [];
		const names: string[] = reducedMatrix.names();
		const rows: IMatrixClusterRow[] = this.matrixToClusterRows(reducedMatrix);
		const clusters: IMatrixCluster[] = MatrixClusterBuilder.build(rows);
		const solveClusters: IMatrixCluster[] = [];
		const noSolveClusters: IMatrixCluster[] = [];
		let remainingMines: number = remainingMineCount;
		
		// split by size
		clusters.forEach((cluster: IMatrixCluster): void => {
			if (cluster.rows.length > this._maxClusterRowsToSolve) {
				noSolveClusters.push(cluster);
			}
			else {
				solveClusters.push(cluster);
			}
		});

		// sort by ascending rows count, then by ascending indices count
		// solve the small clusters first, before time runs out
		solveClusters.sort((a: IMatrixCluster, b: IMatrixCluster): number => {
			let result: number = 1;

			if (a.rows.length < b.rows.length) {
				result = -1;
			}
			else {
				if (a.rows.length === b.rows.length) {
					result = a.indices.length < b.indices.length
						? -1
						: (a.indices.length === b.indices.length ? 0 : 1);
				}
			}

			return result;
		});
		
		// solve
		let i: number = 0;
		while (i < solveClusters.length && !this._timer.isExpired) {
			const cluster: IMatrixCluster = clusters[i];
			const solutions: NumberOrUndefined[][] = this.solveMatrixFreeVariables(
				cluster,
				remainingMines
			);
			
			// if not timed out
			if (solutions.length > 0 && !this._timer.isExpired) {
				cluster.indices.forEach((index: number) => {
					const countWhereIsMine: number = solutions.reduce((accumulator: number, row: NumberOrUndefined[]) =>
						accumulator += row[index] !== undefined && row[index] as number > 0 ? 1 : 0, 0);
				
					const newResult: ISolution = {
						id: names[index],
						status: countWhereIsMine === solutions.length
							? SolutionStatus.Mine
							: ((countWhereIsMine === 0) ? SolutionStatus.Clear : SolutionStatus.Unknown),
						// round to 4 digits precision
						isMineProbability: Math.floor(((countWhereIsMine / solutions.length) * 100) * 10000) / 10000,
						method: SolutionCalculationMethod.Calculation,
					};

					result.push(newResult);
					remainingMines -= newResult.status === SolutionStatus.Mine ? 1 : 0;
				});
			}

			i++;
		}

		// mark as unknown with 'unsolved' method
		noSolveClusters.forEach((cluster: IMatrixCluster): void => {
			cluster.indices.forEach((index: number): void => {
				const newResult: ISolution = {
					id: names[index],
					status:  SolutionStatus.Unknown,
					isMineProbability: -1,
					method: SolutionCalculationMethod.Unsolved,
				};

				result.push(newResult);
			});
		})

		return result;
	};

	/**
	 * Solves the matrix cluster for free variables.
	 * @param cluster The matrix cluster.
	 * @param remainingMineCount The remaining mine count.
	 * @returns All possible solutions for the free variables (and pivots).
	 * @private
	 */
	private solveMatrixFreeVariables = (
		cluster: IMatrixCluster,
		remainingMineCount: number): NumberOrUndefined[][] => {
	
		const coefficients: number[][] = cluster.rows.map((row: IMatrixClusterRow): number[] => row.coefficients);
		const constants: number[] = cluster.rows.map((row: IMatrixClusterRow): number => row.constant);
		let results: IMatrixFreeVariableCalculationResult | null = null;
		let done: boolean = false;
		let i: number = 0;

		while (!done && i < coefficients.length) {
			// get indices of columns that have values
			const indices: number[] = coefficients[i].reduce((accumulator: number[], value: number, index: number) => {
				if (value !== 0) {
					accumulator.push(index);
				}
				return accumulator;
			}, []);

			const rowResults: IMatrixFreeVariableCalculationResult = this.solveMatrixFreeVariableRow(
				coefficients[i],
				constants[i],
				indices,
				remainingMineCount);
			
			if (!this._timer.isExpired) {
				if (results === null) {
					// first row
					results = rowResults;
				}
				else {
					const merged: IMatrixFreeVariableCalculationResult = {
						rows: [],
						mineCountRows: [],
					};

					let rowResultsIndex: number = 0;
					while (rowResultsIndex < rowResults.rows.length && !this._timer.isExpired) {
						const row: NumberOrUndefined[] = rowResults.rows[rowResultsIndex];
						let resultsIndex: number = 0;

						while (results && resultsIndex < results.rows.length && !this._timer.isExpired) {
							const result: NumberOrUndefined[] = results?.rows[resultsIndex];

							let canMerge: boolean = true;
							let j: number = 0;
							const mergeRow: NumberOrUndefined[] = [...result];

							while (canMerge && j < indices.length) {
								// can merge if columns don't overlap or overlapping columns have the same values
								const index: number = indices[j];
								canMerge =
									row[index] === undefined ||
									result[index] === undefined ||
									row[index] === result[index];
						
								if (canMerge) {
									mergeRow[indices[j]] = row[indices[j]];
								}

								j++;
							}
							
							if (canMerge) {
								// get the mine count for the row
								const mineCount: number = mergeRow.reduce((accumulator: number, currentValue: NumberOrUndefined) =>
									accumulator += currentValue !== undefined && currentValue > 0 ? 1 : 0, 0);

								// add merged row
								if (mineCount <= remainingMineCount) {
									merged.rows.push(mergeRow);
									merged.mineCountRows.push(mineCount);
								}
							}

							resultsIndex++;
						}

						rowResultsIndex++;
					}

					results = merged;
				}
			}

			done = results === null || results.rows.length === 0 || this._timer.isExpired;
			i++;
		}

		return results?.rows || [];
	};

	/**
	 * Gets all possible solutions for a single matrix row.
	 * @param coefficients The coefficient columns.
	 * @param constant the constant column.
	 * @param indices The indices that have non-zero coefficients.
	 * @param remainingMineCount The number of remaining mines.
	 * @returns The possible solutions for a row. 
	 * @private
	 */
	private solveMatrixFreeVariableRow = (
		coefficients: number[],
		constant: number,
		indices: number[],
		remainingMineCount: number): IMatrixFreeVariableCalculationResult => {
		
		const result: NumberOrUndefined[][] = [];
		const mineCountRows: number[] = [];

		const variableStates: BoolArray = new BoolArray(indices.length);
		let done = false;

		while (!done) {
			let match: boolean = variableStates.trueCount <= remainingMineCount;
			const currentStates: number[] = variableStates.currentNumeric();

			
			if (match) {
				// check if the current state represents a solution for the row
				const currentStateResult: number =
					currentStates.reduce((accumulator: number, currentValue: number, index: number) =>
						accumulator += currentValue * coefficients[indices[index]], 0);
				
				match = currentStateResult === constant;
			}

			if (match) {
				// initialize a row with all 'undefined' columns, 
				// except at the indices where the pivot and free variables are
				const row: NumberOrUndefined[] = [];
				for (let i = 0; i < coefficients.length; i++) {
					row[i] = undefined;
				}

				indices.forEach((value: number, index: number) => {
					row[value] = currentStates[index];
				});

				result.push(row);
				mineCountRows.push(variableStates.trueCount);
			}

			// try next state
			// when it goes around to all zeros again, then we're done; unless the timer expires beforehand
			done = variableStates.next().trueCount === 0 || this._timer.isExpired;
		}

		return ({
			rows: result,
			mineCountRows: mineCountRows,
		});
	};

	/**
	 * Converts a matrix into an array of matrix cluster rows.
	 * @param matrix The matrix.
	 * @returns An array of matrix cluster rows.
	 * @private
	 */
	private matrixToClusterRows = (matrix: Matrix): IMatrixClusterRow[] => {
		// new matrix, removing zeroed out rows
		const nonZeroMatrix: Matrix = new Matrix(
			matrix.toArray().filter((row: number[]) => row.some((value: number) => value !== 0)),
			matrix.names()
		);

		const coefficients = nonZeroMatrix.coefficients();
		const constants = nonZeroMatrix.constants();

		// get cluster rows
		const rows: IMatrixClusterRow[] = coefficients.map((row: number[], index: number): IMatrixClusterRow => {
			const indices: number[] = coefficients[index].reduce((accumulator: number[], value: number, idx: number) => {
				if (value !== 0) {
					accumulator.push(idx);
				}
				return accumulator;
			}, []);

			return ({
				indices: [...indices],
				coefficients: [...row],
				constant: constants[index],
			});
		});

		return rows;
	};

	/**
	 * Adds the unsolved results for the cluster, if the solver timed out.
	 * @param solutions The computed solutions.
	 * @returns All solutions.
	 * @private
	 */
	private addUnsolvedResultsIfTimedOut = (solutions: ISolution[]): ISolution[] => {
		const result: ISolution[] = [];

		if (this._timer.isExpired) {
			const expectedVariableIds: string[] = this._cluster.getVariableIds();

			if (solutions.length < expectedVariableIds.length) {
				const keyed: Record<string, ISolution> = {};

				solutions.forEach((solution: ISolution): void => {
					keyed[solution.id] = solution;
				});

				expectedVariableIds.forEach((expectedVariableId: string): void => {
					if (keyed[expectedVariableId] === undefined) {
						keyed[expectedVariableId] = {
							id: expectedVariableId,
							status: SolutionStatus.Unknown,
							isMineProbability: -1,
							method: SolutionCalculationMethod.Unsolved,
						}
					}
				});

				Object.keys(keyed).forEach((key: string): void => {
					result.push(keyed[key]);
				});
			}
		}

		return result.length === 0 ? solutions : result;
	};
}