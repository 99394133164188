import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { AppStoreType } from '../enums';
import { IAppStore } from '../interfaces';

/**
 * The Store class provides functions to retrieve store information.
 */
export class Store {
	/**
	 * Gets the app store info.
	 * @param type The app store type.
	 * @returns The app store info; otherwise, null.
	 */
	public static getAppStore = (type: AppStoreType): Nullable<IAppStore> => {
		const store = metadata.appstore.stores
			.filter((store): boolean => store.enabled && store.type === type);

		return store && store.length > 0 ? store[0] as IAppStore : null;
	};
}