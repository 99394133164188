import * as React from 'react';
import { Style } from '../../../../game-to-app/enums';
import { NotificationGameOver } from '../../notification/game-over/notification-game-over';
import { Notification } from '../../notification/notification';
import { NotificationQuickStartLaunch } from '../../notification/quick-start-launch/notification-quick-start-launch';
import { NotificationServiceWorker } from '../../notification/service-worker/notification-service-worker';
import { NotificationZoomMode } from '../../notification/zoom-mode/notification-zoom-mode';
import { BoardShapeBox } from '../../shared/boardshape-box';
import { BoardShapePane } from '../../shared/boardshape-pane';
import { Constants } from '../../shared/constants';
import { IBoardShape, IZoomProps } from '../../shared/interfaces';
import { Spinner } from '../../spinner/spinner';
import { Zoom } from '../../zoom/zoom';
import { Box } from '../box/box';
import { DirectionalPad } from '../directional-pad/directional-pad';
import { IPanel } from '../shared/interfaces';
import boardStyles from './board.module.css';
import { IProps } from './props';
import { IState } from './state';

// lazy load components
const NotificationShareSocial = React.lazy(() => import('../../notification/share-social/notification-share-social'));

/**
 * The Board class implements the <Board/> component.
 * @export
 * @class Board
 * @extends {React.Component<IProps, IState>}
 */
export class Board extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;

	/**
	 * Creates an instance of Board.
	 * @param {IProps} props The props for the <Board/> component.
	 */
	constructor(props: IProps) {
		super(props);
		
		this.state = {
			client: {
				width: 0,
				height: 0,
			},
			mouse: {
				enabled: true,
				pressed: false,
				position: {
					x: 0,
					y: 0,
				},
				delta: {
					x: 0,
					y: 0,
				},
			},
			rerender: true,
			zoomState: {
				container: {
					width: 0,
					height: 0,
				},
				content: {
					width: 0,
					height: 0,
				},
				contentIsWiderThanContainer: false,
				contentIsHigherThanContainer: false,
				containerBorderLeftRightSize: 0,
				containerBorderTopBottomSize: 0,
			},
		};
	}

	/**
	 * The mouse down event handler.
	 * @param event The mouse event.
	 */
	public onMouseDown = (event: React.MouseEvent): void => {
		const { mouse } = this.state;

		this.setState({
			mouse: {
				...mouse,
				pressed: true,
				position: {
					x: event.pageX,
					y: event.pageY,
				},
			},
		});

		event.preventDefault();
	};

	/**
	 * The mouse up event handler.
	 */
	public onMouseUp = (): void => {
		const { mouse } = this.state;

		if (mouse.pressed) {
			this.setState({
				mouse: {
					...mouse,
					pressed: false,
					position: {
						x: 0,
						y: 0,
					},
					delta: {
						x: 0,
						y: 0,
					},
				},
			});
		}
	};

	/**
	 * The mouse move event handler.
	 * @param event The mouse event.
	 */
	public onMouseMove = (event: React.MouseEvent): void => {
		const { rotate, zoom } = this.props.appData.app;
		let { mouse } = this.state;

		if (!zoom.active && mouse.pressed) {
			// update mouse state
			mouse = {
				...mouse,
				position: {
					x: event.pageX,
					y: event.pageY,
				},
				delta: {
					x: event.pageX - mouse.position.x,
					y: event.pageY - mouse.position.y
				},
			}

			// set state
			this.setState({ mouse });

			// rotate 
			const x: number = (rotate?.x || 0) - mouse.delta.y;
			const y: number = Math.abs(x % 360) > 90 && Math.abs(x % 360) < 270
				? (rotate?.y || 0) - mouse.delta.x
				: (rotate?.y || 0) + mouse.delta.x;

			if (this.props.appData.app.events.rotate?.onRotate) {
				this.props.appData.app.events.rotate.onRotate({ ...rotate, x: x, y: y });
			}
		}
	};

	/**
	 * The mouse leave event handler.
	 * @param event The mouse event.
	 */
	public onMouseLeave = (event: React.MouseEvent): void => {
		let targetIsBox = false;

		if (event && event.relatedTarget) {
			let parent = (event.relatedTarget as HTMLElement).parentElement;
			targetIsBox = (event.relatedTarget as HTMLElement).id === 'panel';

			while (!targetIsBox && parent) {
				targetIsBox = parent.id === 'panel';
				parent = parent.parentElement;
			}
		}

		if (!targetIsBox) {
			this.onMouseUp();
		}
	};

	/**
	 * The touch start event handler.
	 * @param event The touch event.
	 * @private
	 */
	private onTouchStart = (event: React.TouchEvent<HTMLDivElement>): void => {
		event.stopPropagation();

		const mouseEvent = {
			pageX: event.changedTouches[0].pageX,
			pageY: event.changedTouches[0].pageY,
			preventDefault: event.preventDefault,
		};

		// forward the event as mouse event
		this.onMouseDown(mouseEvent as React.MouseEvent);
	}

	/**
	 * The touch end event handler.
	 * @param event The touch event.
	 * @private
	 */
	private onTouchEnd = (event: React.TouchEvent<HTMLDivElement>): void => {
		event.stopPropagation();

		// forward the event as mouse event
		this.onMouseUp();
	}

	/**
	 * The touch mmove event handler.
	 * @param event The touch event.
	 * @private
	 */
	private onTouchMove = (event: React.TouchEvent<HTMLDivElement>): void => {
		event.stopPropagation();

		const mouseEvent = {
			pageX: event.changedTouches[0].pageX,
			pageY: event.changedTouches[0].pageY,
		};

		// forward the event as mouse event
		this.onMouseMove(mouseEvent as React.MouseEvent);
	}

	/**
	 * A react lifecycle function for the <Board/> component.
	 * @param nextProps The next props.
	 * @param prevState The previous state.
	 * @returns The new state.
	 */
	public static getDerivedStateFromProps = (nextProps: IProps, prevState: IState): IState => {
		const { size } = nextProps.appData.app;
		const { zoom } = nextProps.appData.app;

		let zoomContainerSize = {
			width: size.window.width,
			height: size.window.boardHeight,
		};

		// content fills the entire board (or more)
		const zoomContentSize = {
			width: Math.max(zoomContainerSize.width, size.board.frame.width),
			height: Math.max(zoomContainerSize.height, size.board.frame.height),
		};

		const contentIsWiderThanContainer = (zoomContentSize.width * zoom.scale) > zoomContainerSize.width;
		const contentIsHigherThanContainer = (zoomContentSize.height * zoom.scale) > zoomContainerSize.height;

		const containerBorderLeftRightSize: number = contentIsWiderThanContainer ? 5 : 0;
		const containerBorderTopBottomSize: number = contentIsHigherThanContainer ? 5 : 0;

		zoomContainerSize = {
			width: zoomContainerSize.width - (2 * containerBorderLeftRightSize),
			height: zoomContainerSize.height - (2 * containerBorderTopBottomSize),
		};

		const mouseEnabled = !containerBorderLeftRightSize && !contentIsHigherThanContainer && !zoom.active;

		return ({
			...prevState,
			mouse: {
				...prevState.mouse,
				enabled: mouseEnabled,
				delta: mouseEnabled ? prevState.mouse.delta : { x: 0, y: 0 },
				pressed: mouseEnabled ? prevState.mouse.pressed : false,
			},
			zoomState: {
				container: zoomContainerSize,
				content: zoomContentSize,
				contentIsWiderThanContainer: contentIsWiderThanContainer,
				contentIsHigherThanContainer: contentIsHigherThanContainer,
				containerBorderLeftRightSize: containerBorderLeftRightSize,
				containerBorderTopBottomSize: containerBorderTopBottomSize,
			},
		});
	};

	/**
	 * A react lifecycle function for the <Board/> component.
	 */
	public componentDidMount = (): void => {
		this._isMounted = true;
		this.setClientSize();
	};

	/**
	 * A react lifecycle function for the <Board/> component.
	 */
	public componentDidUpdate = (): void => {
		const { rerender } = this.state;

		// this is required to avoid flashing with the zoom component
		if (rerender) {
			this.setState({ rerender: false });
		}
	};

	/**
	 * A react lifecycle function for the <Board/> component.
	 */
	public componentWillUnmount = (): void => {
		this._isMounted = false;
	};
	
	/**
	 * Renders the <Board/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { client } = this.state;
		const { zoomState } = this.state;

		const boardShape: IBoardShape =
			this.props.appData.app.level.style === Style.Pane
				? new BoardShapePane(
					this.props.appData.app.level,
					this.props.appData.app.size.cell || { width: 0, height: 0, name: '' })
				: new BoardShapeBox(
					this.props.appData.app.level,
					this.props.appData.app.size.cell || { width: 0, height: 0, name: '' });

		const maxBoardHeight: number =
			this.props.appData.app.size.window.boardHeight +
			this.props.appData.app.size.window.navbarHeight;

		const panel: IPanel = {
			events: {
				mouse: {
					onMouseDown: this.onMouseDown,
					onMouseUp: this.onMouseUp,
					onMouseMove: this.onMouseMove,
					onMouseLeave: this.onMouseLeave,
					onTouchStart: this.onTouchStart,
					onTouchEnd: this.onTouchEnd,
					onTouchMove: this.onTouchMove,
				},
			},
			client: client,
		};

		const panelComponent = (
			<div
				ref={this._ref}
				id='panel'
				className={`${boardStyles.board} ${boardStyles.panel} row flex-fill`}
				onMouseDown={this.onMouseDown}
				onMouseUp={this.onMouseUp}
				onMouseMove={this.onMouseMove}
				onMouseLeave={this.onMouseLeave}
				onTouchStart={this.onTouchStart}
				onTouchEnd={this.onTouchEnd}
				onTouchCancel={this.onTouchEnd}
				onTouchMove={this.onTouchMove}
				style={{
					width: zoomState.content.width,
					height: zoomState.content.height,
				}}
			>
				<Box
					appData={this.props.appData}
					gameData={this.props.gameData}
					solverData={this.props.solverData}
					panel={panel}
					shape={boardShape}
					size={this.props.appData.app.size.board}
				/>
			</div>
		);
		
		const zoomComponent: JSX.Element = <div
			className={boardStyles.edge}
			style={{
				paddingLeft: `${zoomState.containerBorderLeftRightSize}px`,
				paddingRight: `${zoomState.containerBorderLeftRightSize}px`,
				paddingTop: `${zoomState.containerBorderTopBottomSize}px`,
				paddingBottom: `${zoomState.containerBorderTopBottomSize}px`,
				transform: 'scale(1)',
				transition: '1s all ease',
			}}
		>
			<div
				className={boardStyles.edgeTop}
				style={{
					width: zoomState.contentIsHigherThanContainer ? '100%' : '0',
					height: `${zoomState.containerBorderTopBottomSize}px`,
				}}
			/>
			<div
				className={boardStyles.edgeRight}
				style={{
					height: zoomState.contentIsWiderThanContainer ? '100%' : '0',
					width: `${zoomState.containerBorderLeftRightSize}px`,
				}}
			/>
			<div
				className={boardStyles.edgeBottom}
				style={{
					width: zoomState.contentIsHigherThanContainer ? '100%' : '0',
					height: `${zoomState.containerBorderTopBottomSize}px`,
				}}
			/>
			<div
				className={boardStyles.edgeLeft}
				style={{
					height: zoomState.contentIsWiderThanContainer ? '100%' : '0',
					width: `${zoomState.containerBorderLeftRightSize}px`,
				}}
			/>
			<div>
				<Zoom
					id={Constants.zoomComponentId}
					container={{
						width: zoomState.container.width,
						height: zoomState.container.height,
					}}
					content={{
						width: zoomState.content.width,
						height: zoomState.content.height,
					}}
					options={{
						scale: this.props.appData.app.zoom.scale || 1,
						panEnabled: zoomState.contentIsWiderThanContainer || zoomState.contentIsHigherThanContainer,
						zoomEnabled: this.props.appData.app.zoom.active,
						minScale: 1,
						maxScale: Constants.maxZoomFactor,
						onZoomChange: this.onZoomChange,
					}}
					relativeTargetPosition={this.props.appData.app.zoom.relativeTargetPosition ?? undefined}
					components={this.props.appData.app.components}
				>
					{panelComponent}
				</Zoom>
			</div>
		</div>;
		
		const component: JSX.Element = (
			<div
				id={this.props.id}
				key={this.props.id}
				className={`${boardStyles.board} row flex-fill`}
				style={{
					paddingTop: `${this.props.appData.app.size.window.navbarHeight}px`,
					maxHeight: `${maxBoardHeight}px`,
					width: this.props.appData.app.size.window.width,
					height: `${maxBoardHeight}px`,
				}}
			>
				<DirectionalPad
					id='pad'
					appData={this.props.appData}
				>
					{zoomComponent}
				</DirectionalPad>
				
				<Notification notification={this.props.appData.app.quickStartLaunch}>
					<NotificationQuickStartLaunch notification={this.props.appData.app.quickStartLaunch} />
				</Notification>

				<Notification notification={this.props.appData.app.gameOver}>
					<NotificationGameOver notification={this.props.appData.app.gameOver} />
				</Notification>

				<Notification notification={this.props.appData.app.shareSocial}>
					<React.Suspense fallback={<Spinner/>}>
						<NotificationShareSocial notification={this.props.appData.app.shareSocial} />
					</React.Suspense>
				</Notification>

				<Notification notification={this.props.appData.app.zoomMode}>
					<NotificationZoomMode notification={this.props.appData.app.zoomMode} />
				</Notification>

				<Notification notification={this.props.appData.app.serviceWorker}>
					<NotificationServiceWorker notification={this.props.appData.app.serviceWorker} />
				</Notification>
			</div>
		);

		return component;
	};

	/**
	 * The event handler for when the zoom scale changes.
	 * @param props: The zoom props.
	 * @private
	 */
	private onZoomChange = (props: IZoomProps): void => {
		const { zoom } = this.props.appData.app;

		if (props.scale !== props.previousScale &&
			zoom.scale !== props.scale) {
			
			const rounded = {
				active: zoom.active,
				scale: Math.round(props.scale * 100) / 100,
				previousScale: Math.round(props.previousScale * 100) / 100,
			};
			
			if (rounded.scale !== zoom.scale || rounded.previousScale !== zoom.previousScale) {
				window.setTimeout(() => this.props.appData.app.events.zoom.onZoomChange(rounded), 100);
			}
		}
	};

	/**
	 * Sets the component client size.
	 */
	private setClientSize = (): void => {
		if (this._isMounted) {
			const { current } = this._ref;

			if (current) {
				const { client } = this.state;
				const width = current.clientWidth || 0;
				const height = current.clientHeight || 0;
				
				if (client.height !== height || client.width !== width) {
					this.setState({
						client: {
							width: width,
							height: height,
						}
					});
				}
			}
		}
	};
}