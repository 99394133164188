import * as React from 'react';
import Button from 'react-bootstrap/Button';
import { UpdateHeightControl } from '../shared/enums';
import bottomPanelStyles from './bottom-panel.module.css';
import { IProps } from './props';
import { IState } from './state';

/**
 * The BottomPanel class implements the <BottomPanel/> component.
 * @export
 * @class BottomPanel
 * @extends {React.Component<IProps, IState>}
 */
export class BottomPanel extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;

	/**
	 * Creates an instance of BottomPanel.
	 * @param props The properties.
	 * @memberof BottomPanel
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			heightObserverId: 0,
			height: 0,
		};
	}

	/**
	 * A react lifecycle function for the <BottomPanel/> component.
	 */
	public componentDidMount = (): void => {
		this._isMounted = true;
		const heightObserverId = window.setInterval(this.updateHeight, 50);
		this.setState({ heightObserverId: heightObserverId });
	};

	/**
	 * A react lifecycle function for the <BottomPanel/> component.
	 */
	public componentWillUnmount = (): void => {
		this._isMounted = false;
		
		if (this.state.heightObserverId) {
			window.clearInterval(this.state.heightObserverId);
		}
	};

	/**
	 * Renders the <BottomPanel/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		return (
			this.props.panel.show
				? <div
					ref={this._ref}
					className={`bg-dark ${bottomPanelStyles.panel}`}
				>
					{this.props.children}
					<Button
						className={bottomPanelStyles.close}
						size='sm'
						onClick={this.onClickClose}>
						Close
					</Button>
				</div>
				: null
		);
	};

	/**
	 * Updates the height of the component and propagates the value if changed.
	 * @private
	 */
	private updateHeight = (): void => {
		if (this._isMounted && this._ref) {
			const { height } = this.state;
			const elementHeight: number = this._ref.current?.getBoundingClientRect()?.height || 0;

			if (elementHeight !== height) {
				this.setState({ height: elementHeight });

				this.props.panel.onUpdateHeight(UpdateHeightControl.BottomPanel, elementHeight, true);
			}
		}
	};

	/**
	 * The event handler for the click event on the Close button.
	 */
	private onClickClose = (): void => {
		this.props.panel.onClickClose();
	};
}