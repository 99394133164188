import { IAPProduct, InAppPurchase2 as Store } from '@ionic-native/in-app-purchase-2';
import metadata from '../../../../metadata/metadata.json';
import { Nullable } from '../../../../shared/types';
import { AppStoreShopProductType, AppStoreType, PurchaseFlowStatus, PurchaseFlowSuccess, ShopOpenStatus, ShopServiceStatus } from '../../enums';
import { Events as ShopEventManager } from '../../events/events';
import { IAppStore, IPurchaseFlow, IShop, IShopProduct, IShopProductDeclaration } from '../../interfaces';
import { Events } from '../../telemetry/events';
import { ICustomProperties } from '../../telemetry/interfaces';
import { Telemetry } from '../../telemetry/telemetry';
import { IShopProvider } from '../interfaces';
import { ShopEvents } from '../shop-manager';
import { IStoreError } from './interfaces';
import { ProductConverter } from './product-converter';

/**
 * The PlayStoreShopProvider class implements a store provider for the Google Play Store.
 * @export
 * @class PlayStoreShopProvider
 * @implements {IShopProvider}
 */
export class PlayStoreShopProvider implements IShopProvider {
	// supported product types
	private static _supportedTypes: string[] = [
		Store.NON_CONSUMABLE,
	];

	private _events: ShopEventManager = new ShopEventManager();
	private _shop: Nullable<IShop> = null;
	private _products: Record<string, IAPProduct> = {};
	private _productIds: string[] = [];
	private _adRemovingProductIds: string[] = [];
	private _serviceStatus = ShopServiceStatus.Unknown;
	private _isReady: boolean = false;
	private _isRefreshing: boolean = false;
	private _isOpen: boolean = false;
	private _isPurchasing: boolean = false;
	private _openingStatus: ShopOpenStatus = ShopOpenStatus.NotStarted;
	private _openingStatusSequence: ShopOpenStatus[] = [ShopOpenStatus.NotStarted];
	private _purchaseFlowStatus: PurchaseFlowStatus = PurchaseFlowStatus.Idle;

	/**
	 * Gets the provider name.
	 * @readonly
	 */
	public get name(): string { return 'Play'; }

	/**
	 * Gets a Boolean value indicating whether the provider is enabled.
	 * @readonly
	 */
	public get isEnabled(): boolean { return !!this._shop; }

	/**
	 * Gets a Boolean value indicating whether the provider is open.
	 * @readonly
	 */
	public get isOpen(): boolean { return this._isOpen; }

	/**
	 * Gets an enum value indicating the shop service status.
	 * @readonly
	 */
	public get serviceStatus(): ShopServiceStatus { return this._serviceStatus; }

	/**
	 * Creates an instance of PlayStoreShopProvider.
	 * @param events The shop event handler.
	 * @memberof PlayStoreShopProvider
	 */
	public constructor(events: ShopEventManager) {
		// events
		this._events = events;

		// get an enabled Google Play store with an enabled shop from metadata
		const shops: IShop[] = metadata.appstore.stores
			.filter((store: IAppStore) => store.type === AppStoreType.GooglePlay && store.enabled && store.shop.enabled)
			.map((store: IAppStore) => store.shop);
		
		// take the first shop
		this._shop = shops && shops.length > 0 ? shops[0] : null;

		// products
		this._products = {};

		// log
		this.logConsole(`The shop is ${this.isEnabled ? 'enabled.' : 'not enabled.'}`);
	}

	/**
	 * Initializes the shop.
	 */
	public initialize = (): void => {
		if (this.isEnabled) {

			// start shop open process
			this.updateOpeningStatus(ShopOpenStatus.Started);

			// enable high verbosity in test mode
			if (this._shop?.testMode) {
				Store.verbosity = Store.DEBUG;
			}

			// listeners
			this.initializeListeners();
			
			// register products
			this.registerProducts();
			
			// refresh
			this.refresh();
		}
	};

	/**
	 * Gets the shop products.
	 * @returns The products.
	 */
	public getProducts = (): Record<string, IShopProduct> => {
		const result: Record<string, IShopProduct> = {};

		if (this.isEnabled) {
			Object.keys(this._products).forEach((id: string) => {
				
				const shopProduct: Nullable<IShopProduct> = ProductConverter.toInternal(
					this._products[id],
					this._shop?.products || null);

				if (shopProduct) {
					result[shopProduct.id] = shopProduct;
				}
			});
		}
		
		return result;
	};

	/**
	 * Gets all product ids.
	 * @returns The product ids.
	 */
	public getProductIds = (): string[] => {
		return this._productIds;
	};

	/**
	 * Gets the ad removing product ids.
	 * @returns The ad removing product ids.
	 */
	public getAdRemovingProductIds = (): string[] => {
		return this._adRemovingProductIds;
	};

	/**
	 * Initiates the purchase order.
	 * @param id The product id to purchase.
	 */
	public purchase = (id: string): void => {
		if (this.isOpen && this._purchaseFlowStatus == PurchaseFlowStatus.Idle) {

			// start flow
			this._isPurchasing = true;

			// initiate purchase
			Store.order(id)
				.then(() => {
					// console
					this.logConsole(`Purchase initiated for product with id ${id}.`);

					// purchase in progress
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.InProgress,
							success: PurchaseFlowSuccess.InProgress,
							message: 'In progress...',
						},
						this._products[id]);
				},
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(error: any) => {
						// console
						this.logConsole(`Failed to initiate purchase. Product id: ${id}. Error: ${error}`);

						this.onPurchaseFlowProgress(
							{
								status: PurchaseFlowStatus.Error,
								success: PurchaseFlowSuccess.No,
								message: 'The purchase could not be initiated. Make sure that you are online and the Google Play store is available. Please try again.',
							},
							this._products[id]);
					});
		}
		else {
			// console
			this.logConsole(`Failed to initiate purchase. Product id: ${id}. Purchase is already in progress.`);

			this.onPurchaseFlowProgress(
				{
					status: PurchaseFlowStatus.Error,
					success: PurchaseFlowSuccess.No,
					message: 'A purchase order in already in progress.',
				},
				this._products[id]);
		}
	};

	/**
	 * The purchase flow event handler.
	 * @param flow The flow event.
	 * @private
	 */
	private onPurchaseFlowProgress = (flow: IPurchaseFlow, flowProduct: Nullable<IAPProduct>): void => {
		if (this._isPurchasing) {
			// get the shop product
			const product = flowProduct ? ProductConverter.toInternal(flowProduct, this._shop?.products || null) : null;

			// update the local product store
			if (flowProduct && this._products) {
				this._products[flowProduct.id] = flowProduct;
			}

			// update the flow status
			this._purchaseFlowStatus = flow.status;

			// notify flow
			const progressFlow: IPurchaseFlow = product ? { ...flow, product: product } : { ...flow };
			this._events.raise(ShopEvents.PurchaseFlow, progressFlow);

			// terminal status
			if (flow.status === PurchaseFlowStatus.Idle ||
				flow.status === PurchaseFlowStatus.Error ||
				flow.status === PurchaseFlowStatus.Cancelled ||
				flow.status === PurchaseFlowStatus.Unverified ||
				(flow.status === PurchaseFlowStatus.Finished && product?.type !== AppStoreShopProductType.NonConsumable) ||
				(flow.status === PurchaseFlowStatus.Owned && product?.type === AppStoreShopProductType.NonConsumable)) {

				// reset
				this._isPurchasing = false;
				this._purchaseFlowStatus = PurchaseFlowStatus.Idle;
			}
		}
	};

	/**
	 * Registers products.
	 * @private
	 */
	private registerProducts = (): void => {
		if (this.isEnabled) {
			// console
			this.logConsole('Registering shop products');

			// register products defined in shop metadata
			const ids = Object.keys(this._shop?.products || {});

			this.logConsole(JSON.stringify(ids));

			ids.forEach((id: string) => {
				// get declaration
				const product: Nullable<IShopProductDeclaration> = this._shop ? this._shop.products[id] : null;

				if (product?.enabled && (this._shop?.testMode || !product.testProduct)) {
					this.logConsole(`register ${product.type}`);
				
					// get registration type
					const registrationType: Nullable<string> = ProductConverter.productTypeToExternal(product.type);
					this.logConsole(`external ${registrationType}`);
				
					// register
					if (registrationType !== null) {
						this.logConsole(`Registering shop product with id ${id}, removes ads: ${product.removesAds}`);

						if (product.removesAds) {
							this._adRemovingProductIds.push(id);
						}
						
						Store.register({
							id: id,
							type: registrationType,
						});
					}
				}
			});

			// update shop open status
			this.updateOpeningStatus(ShopOpenStatus.ProductsRegistered);
		}
	};

	/**
	 * Initializes store event listeners.
	 * @private
	 */
	private initializeListeners = (): void => {

		// do this only once
		if (this.isEnabled && this._openingStatusSequence.indexOf(ShopOpenStatus.InitializedListeners) < 0) {
			// console
			this.logConsole('Initializing listeners');
			
			// store error
			Store.error((error: IStoreError) => {
				// console
				this.logConsole(`Store error event: ${JSON.stringify(error)}`);

				// update shop open status
				if (!this.isOpen) {
					this.updateOpeningStatus(ShopOpenStatus.Failed);
				}

				const message: string = this.getErrorMessage(error);

				// notify flow
				this.onPurchaseFlowProgress(
					{
						status: PurchaseFlowStatus.Error,
						success: PurchaseFlowSuccess.No,
						message: message,
					},
					null);
				
				// log
				this.logTelemetryEvent(
					Events.StoreError,
					{
						level: 'store',
						purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
						code: error.code - Store.ERR_SETUP,
						message: error.message,
						userMessage: this.getErrorMessage(error),
					});
			});

			// product error
			Store.when('product')
				.error((error: IStoreError) => {
					// console
					this.logConsole(`Product error event: ${JSON.stringify(error)}`);

					const message: string = this.getErrorMessage(error);

					// notify flow
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.Error,
							success: PurchaseFlowSuccess.No,
							message: message,
						},
						null);

					// log
					this.logTelemetryEvent(
						Events.StoreError,
						{
							level: 'product',
							purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
							code: error.code - Store.ERR_SETUP,
							message: error.message,
							userMessage: message,
						});
				});

			// purchase cancelled 
			Store.when('product')
				.cancelled((product: IAPProduct) => {
					// console
					this.logConsole(`Product cancelled event: ${JSON.stringify(product)}`);

					// notify flow
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.Cancelled,
							success: PurchaseFlowSuccess.No,
							message: 'The purchase was canceled.',
						},
						product);

					// log
					this.logTelemetryEvent(
						this._isRefreshing ? Events.StoreRefreshStatusCancelled : Events.StorePurchaseCancelled,
						{
							product: product.id,
							purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
						});
				});

			// purchase approved 
			Store.when('product')
				.approved((product: IAPProduct) => {
					// console
					this.logConsole(`Product approved event: ${JSON.stringify(product)}`);

					// notify flow
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.Approved,
							success: PurchaseFlowSuccess.InProgress,
							message: 'The purchase was approved.',
						},
						product);

					// log
					this.logTelemetryEvent(
						this._isRefreshing ? Events.StoreRefreshStatusApproved : Events.StorePurchaseApproved,
						{
							product: product.id,
							purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
						});

					// verify
					product.verify();
				});

			// purchase verified
			Store.when('product')
				.verified((product: IAPProduct) => {
					// console
					this.logConsole(`Product verified event: ${JSON.stringify(product)}`);

					// notify flow
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.Verified,
							success: PurchaseFlowSuccess.InProgress,
							message: 'The purchase was verified.',
						},
						product);

					// log
					this.logTelemetryEvent(
						this._isRefreshing ? Events.StoreRefreshStatusVerified : Events.StorePurchaseVerified,
						{
							product: product.id,
							purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
						});

					// verify
					product.finish();
				});

			// purchase unverified
			Store.when('product')
				.unverified((product: IAPProduct) => {
					// console
					this.logConsole(`Product unverified event: ${JSON.stringify(product)}`);

					// notify flow
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.Unverified,
							success: PurchaseFlowSuccess.No,
							message: 'The purchase could not be verified.',
						},
						product);

					// log
					this.logTelemetryEvent(
						this._isRefreshing
							? Events.StoreRefreshStatusVerificationFailed
							: Events.StorePurchaseVerificationFailed,
						{
							product: product.id,
							purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
						});
				});

			// purchase finished
			Store.when('product')
				.finished((product: IAPProduct) => {
					// console
					this.logConsole(`Product finished event: ${JSON.stringify(product)}`);

					// notify flow
					this.onPurchaseFlowProgress(
						{
							status: PurchaseFlowStatus.Finished,
							success: PurchaseFlowSuccess.Yes,
							message: 'The purchase is finished.',
						},
						product);

					// log
					this.logTelemetryEvent(
						this._isRefreshing ? Events.StoreRefreshStatusFinished : Events.StorePurchaseFinished,
						{
							product: product.id,
							purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
						});
				});

			// product owned
			Store.when('product')
				.owned((product: IAPProduct) => {
					// console
					this.logConsole(`Product owned event: ${JSON.stringify(product)}`);

					// if called in purchase flow
					if (this._purchaseFlowStatus !== PurchaseFlowStatus.Idle) {
						// notify flow
						this.onPurchaseFlowProgress(
							{
								status: PurchaseFlowStatus.Owned,
								success: PurchaseFlowSuccess.Yes,
								message: 'The product is owned.',
							},
							product);

						// log
						this.logTelemetryEvent(
							this._isRefreshing ? Events.StoreRefreshStatusOwned : Events.StorePurchaseOwned,
							{
								product: product.id,
								purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
							});
					}
				});

			// product refunded
			Store.when('product')
				.refunded((product: IAPProduct) => {
					// console
					this.logConsole(`Product refunded event: ${JSON.stringify(product)}`);

					// if called in purchase flow
					if (this._purchaseFlowStatus !== PurchaseFlowStatus.Idle) {
						// notify flow
						this.onPurchaseFlowProgress(
							{
								status: PurchaseFlowStatus.Refunded,
								success: PurchaseFlowSuccess.No,
								message: 'The product was refunded.',
							},
							product);

						// log
						this.logTelemetryEvent(
							this._isRefreshing ? Events.StoreRefreshStatusRefunded : Events.StorePurchaseRefunded,
							{
								product: product.id,
								purchaseFlowStatus: PurchaseFlowStatus[this._purchaseFlowStatus],
							});
					}
				});

			// product updated
			Store.when('product')
				.updated(this.onStoreProductUpdated);

			// store ready
			Store.ready(() => {
				// console
				this.logConsole('The store is ready');

				// set ready
				this._isReady = true;

				// get the product information from the store
				if (Store.products) {
					const products: IAPProduct[] = Store.products.filter(p => !!p);
					this._products = {};

					this.logConsole(`The store has ${products.length} products.`)

					products.forEach((product: IAPProduct) => {

						// add products, filter out invalid
						if (product.state !== Store.INVALID) {

							// purchase flow is implemented only for non-consumable products
							if (PlayStoreShopProvider._supportedTypes.indexOf(product.type) >= 0) {
								this._products[product.id] = product;
								this._productIds.push(product.id);
							}
							else {
								this.logConsole(`The product with id ${product.id} is not a non-consumable product.`);

								this.logTelemetryEvent(
									Events.StoreProductInvalidType,
									{
										id: product.id,
										type: product.type,
									}
								);
							}
						}
						else {
							this.logConsole(`The product with id ${product.id} is invalid.`);

							this.logTelemetryEvent(
								Events.StoreProductInvalid,
								{ id: product.id }
							);
						}
					});
				}
				else {
					this.logConsole('The store has no products');
				}

				// update shop open status
				this.updateOpeningStatus(ShopOpenStatus.ProductsInShop);

				// try opening the store
				this.open();
			});

			// update shop open status
			this.updateOpeningStatus(ShopOpenStatus.InitializedListeners);
		}
	};

	/**
	 * Refreshes the store.
	 * @private
	 */
	private refresh = (): void => {
		this._isRefreshing = true;
		const refreshResult = Store.refresh();

		refreshResult.failed(() => {
			// console
			this.logConsole('Store refresh failed.');

			// log
			this.logTelemetryEvent(Events.StoreRefreshActionFailed);

			// reset
			this._isRefreshing = false;

			// update shop open status
			if (!this.isOpen) {
				this.updateOpeningStatus(ShopOpenStatus.Failed);
			}
		});

		refreshResult.cancelled(() => {
			// console
			this.logConsole('Store refresh was cancelled');

			// log
			this.logTelemetryEvent(Events.StoreRefreshActionCancelled);

			// reset
			this._isRefreshing = false;

			// update shop open status
			if (!this.isOpen) {
				this.updateOpeningStatus(ShopOpenStatus.Failed);
			}
		});

		refreshResult.completed(() => {
			// console
			this.logConsole('Store refresh was completed.');

			// log
			this.logTelemetryEvent(Events.StoreRefreshActionCompleted);

			// update shop open status
			if (!this.isOpen) {
				this.updateOpeningStatus(ShopOpenStatus.RefreshCompleted);
			}
		});

		refreshResult.finished(() => {
			// console
			this.logConsole('Store refresh was finished.');

			// log
			this.logTelemetryEvent(Events.StoreRefreshActionFinished);

			// reset
			this._isRefreshing = false;

			// try opening the store
			this.open();
		});
	};

	/**
	 * Opens the store when initialization has completed.
	 * @private
	 * @memberof PlayStoreShopProvider
	 */
	private open = (): void => {
		if (this.isEnabled) {
			const openNow = this._isReady && !this._isRefreshing && this._openingStatus !== ShopOpenStatus.Failed;
			const failNow = this._openingStatus === ShopOpenStatus.Failed;

			if (openNow) {
				this._isOpen = true;
				this._serviceStatus = ShopServiceStatus.Available;

				this.logConsole(`shop opened`);
				
				// raise event
				this._events.raise(ShopEvents.Open);
			}
			else {
				if (failNow) {
					this._serviceStatus = ShopServiceStatus.Unavailable;

					this.logConsole(`shop failed to open`);

					// raise event
					this._events.raise(ShopEvents.FailedToOpen);
				}
			}
		}
	};

	/**
	 * Updates the shop opening status
	 * @param openingStatus The new opening status.
	 * @private
	 */
	private updateOpeningStatus = (openingStatus: ShopOpenStatus): void => {

		// if already failed, ignore update
		if (this.isEnabled && this._openingStatus !== ShopOpenStatus.Failed) {
			this._openingStatus = openingStatus;
			this._openingStatusSequence.push(openingStatus);
		}
	};

	/**
	 * Gets a user-friendly error message.
	 * @param error The error.
	 * @returns An error message.
	 * @private
	 */
	private getErrorMessage = (error: IStoreError): string => {
		const inPurchaseFlow = this._purchaseFlowStatus !== PurchaseFlowStatus.Idle;
		const defaultMessage: string = 'An error occurred.';
		const defaultPurchaseMessage: string = 'The purchase could not be completed.';
		const defaultResult = inPurchaseFlow ? defaultPurchaseMessage : defaultMessage;
		let result: string = defaultResult;

		switch (error.code) {
			case Store.ERR_BAD_RESPONSE:
			case Store.ERR_CLIENT_INVALID:
			case Store.ERR_DOWNLOAD:
			case Store.ERR_LOAD:
			case Store.ERR_LOAD_RECEIPTS:
			case Store.ERR_MISSING_TOKEN:
			case Store.ERR_PURCHASE:
			case Store.ERR_REFRESH_RECEIPTS:
			case Store.ERR_SETUP:
			case Store.ERR_SUBSCRIPTION_UPDATE_NOT_AVAILABLE:
			case Store.ERR_SUBSCRIPTIONS_NOT_AVAILABLE:
			case Store.ERR_UNKNOWN:
			case Store.ERR_VERIFICATION_FAILED:
				result = defaultResult;
				break;
			case Store.ERR_COMMUNICATION:
				result = 'The Google Play store could not be reached.';
				break;
			case Store.ERR_FINISH:
				result = inPurchaseFlow ? 'The purchase could not be finalized. Please restart the app.' : defaultMessage;
				break;
			case Store.ERR_INVALID_PRODUCT_ID:
				result = inPurchaseFlow ? 'The product is not available' : defaultMessage;
				break;
			case Store.ERR_PAYMENT_CANCELLED:
				result = inPurchaseFlow ? 'The payment was canceled.' : defaultMessage;
				break;
			case Store.ERR_PAYMENT_EXPIRED:
				result = inPurchaseFlow ? 'The payment method is expired.' : defaultMessage;
				break;
			case Store.ERR_PAYMENT_INVALID:
				result = inPurchaseFlow ? 'The payment was not accepted.' : defaultMessage;
				break;
			case Store.ERR_PAYMENT_NOT_ALLOWED:
				result = inPurchaseFlow ? 'The payment was not allowed.' : defaultMessage;
				break;
			case Store.ERR_REFRESH:
				result = inPurchaseFlow ? defaultPurchaseMessage : 'The store could not be refreshed.';
				break;
		}

		return result;
	};

	/**
	 * An event handler for when a product is updated.
	 * @param product The updated product.
	 * @private
	 */
	private onStoreProductUpdated = (product: IAPProduct): void => {
		// console
		this.logConsole(`Product updated event: ${JSON.stringify(product)}`);

		if (this.isEnabled && PlayStoreShopProvider._supportedTypes.indexOf(product.type) >= 0) {
			const shopProduct: Nullable<IShopProduct> = ProductConverter.toInternal(
				product,
				this._shop?.products || null);
		
			if (shopProduct !== null) {
				// event
				this._events.raise(ShopEvents.ShopProductUpdated, shopProduct);
			}
		}
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		// augment properties
		const properties = {
			provider: this.name,
			...customProperties
		};

		Telemetry.event(event.toString(), properties);
	};

	/**
	 * Logs messages to the console when in test mode.
	 * @param message The message to log.
	 * @private
	 */
	private logConsole = (message: string) => {
		if (this._shop?.testMode) {
			console.log(`[===> ${message} <===]`);
		}
	};
}

