import { AppType, NetworkStatus } from '../enums';

/**
 * The Constants class defines constant values for the platform.
 * @export
 * @class Constants
 */
export class Constants {
	/**
	 * The default app name by type
	 * @static
	 */
	public static defaultNames: { [key in AppType]: string } = {
		[AppType.Android]: 'Android',
		[AppType.AndroidTrustedWeb]: 'Android Web',
		[AppType.Web]: 'Web',
		[AppType.Unknown]: 'Unknown',
	};

	/**
	 * The default app availability by type
	 * @static
	 */
	public static defaultAvailability: { [key in AppType]: NetworkStatus[] } = {
		// JavaScript is bundled in the app
		[AppType.Android]: [NetworkStatus.Offline, NetworkStatus.Online],
		// offline status depends on service worker
		[AppType.AndroidTrustedWeb]: [NetworkStatus.Online],
		// offline status depends on service worker
		[AppType.Web]: [NetworkStatus.Online],
		// no availability
		[AppType.Unknown]: [],
	};

	/**
	 * Gets the network status names.
	 * @static
	 */
	public static networkStatusNames: { [key in NetworkStatus]: string } = {
		[NetworkStatus.Offline]: 'Offline',
		[NetworkStatus.Online]: 'Online',
	};

	/**
	 * The development version suffix.
	 * @static
	 */
	public static developmentVersionSuffix: string = '+';
}