import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Motion, PlainStyle, spring } from 'react-motion';
import { Style } from '../../../../game-to-app/enums';
import { IProps } from './props';
import sizeStyles from './size.module.css';

/**
 * The Size class implements the <Size/> component.
 * @export
 * @class Size
 * @extends {React.Component<IProps>}
 */
export class Size extends React.Component<IProps> {
	/**
	 * Creates an instance of Size.
	 * @param {IProps} props
	 * @memberof Size
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <Size/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { level } = this.props;
		const name: string = level.style === Style.Classic
			? `${level.dimension.width} x ${level.dimension.height} : ${level.mines}`
			: `${level.dimension.width} x ${level.dimension.height} x ${level.dimension.depth} : ${level.mines}`;

		return (
			<Container
				id='scoreboard-game-size'
				key='scoreboard-game-size'
				className={'d-inline-flex'}>
				<Col>
					<div className={sizeStyles.size}>
						<Motion
							key={name}
							defaultStyle={{ scale: 0, color: 0 }}
							style={{
								scale: spring(100),
								color: spring(211)
							}}>
							{(interpolatingStyle: PlainStyle): JSX.Element => <div
								className={sizeStyles.name}
								style={{
									transform: `scale(${interpolatingStyle.scale / 100})`,
									color: `rgba(${interpolatingStyle.color}, 211, ${interpolatingStyle.color}, 1)`
								}}
							>
								{name}
							</div>}
						</Motion>
					</div>
				</Col>
			</Container>
		);
	};
}