import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { deepcopy } from '../../../../utils';
import { TransactionMode } from '../../../enums';
import { IModelCacheManager, IModelMetadata } from '../../../model-interfaces';
import { IUserPreferences, IUserPreferencesUpdate } from '../../../views/userpreferences';
import { IModelUserPreferenceValues } from './model';

/**
 * The CacheManager class implements a cache for the user preferences model.
 * @export
 * @class CacheManager
 * @implements {IModelCache}
 */
export class CacheManager implements IModelCacheManager {
	private _database: IDBPDatabase;
	private _metadata: IModelMetadata<IModelUserPreferenceValues>;
	private _cache: Nullable<IUserPreferences> = null;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._metadata.name; }

	/**
	 * Creates an instance of CacheManager.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The model metadata.
	 * @memberof CacheManager
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelUserPreferenceValues>) {
		this._database = database;
		this._metadata = metadata;
	}

	/**
	 * Loads the model into the cache.
	 * @returns A promise that resolves when the cache is loaded.
	 */
	public load = async (): Promise<IUserPreferences> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		let cache: IModelUserPreferenceValues = await store.get(this._metadata.name);

		// if the record does not exist for some reason, recreate it
		if (!cache) {
			cache = {
				...this._metadata.defaults[0],
				...this._metadata.systemValues,
			};

			await store.put(cache);
		}

		this._cache = this.toUserPreferences(cache);

		return this.get();
	};

	/**
	 * Updates a record in the cache and database.
	 * @param userPreferences The user preferences.
	 * @returns A promise that resolves when the database was updated.
	 */
	public update = async (userPreferences: IUserPreferencesUpdate): Promise<IUserPreferences> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		const record: IModelUserPreferenceValues = await store.get(this._metadata.name);
		const base: IModelUserPreferenceValues = record || { ...this._metadata.defaults[0], ...this._metadata.systemValues };

		// initialize update from updated stats and base
		const update: IModelUserPreferenceValues = {
			...{
				id: this._metadata.name,
				level: {
					level: userPreferences?.level?.level !== undefined
						? userPreferences.level.level
						: base.level.level,
					style: userPreferences?.level?.style !== undefined
						? userPreferences.level.style
						: base.level.style,
					id: userPreferences?.level?.id !== undefined
						? userPreferences.level.id
						: base.level.id,
				},
				notifications: {
					quickStart: userPreferences?.notifications?.quickStart !== undefined
						? userPreferences.notifications.quickStart
						: base.notifications.quickStart,
					zoomMode: userPreferences?.notifications?.zoomMode !== undefined
						? userPreferences.notifications.zoomMode
						: base.notifications.zoomMode,
				},
			},
			...this._metadata.systemValues,
		};

		// create or update in database
		await store.put(update);

		// refresh cache
		await this.load();

		// return the entire stats cache
		return this.get();
	};

	/**
	 * Deletes a record in the cache and database.
	 * @param id The id.
	 * @returns A promise that resolves when the database record was deleted.
	 */
	public delete = async (id: string): Promise<IUserPreferences> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// create or update in database
		await store.delete(id);

		// refresh cache
		await this.load();

		// return the entire levels cache
		return this.get();
	};

	/**
	 * Gets the user preferences.
	 * @returns The user preferences.
	 */
	public get = (): IUserPreferences => {
		const cache: IUserPreferences = this._cache || this.toUserPreferences(this._metadata.defaults[0]);

		return deepcopy(cache) as IUserPreferences;
	};

	/**
	 * Converts model values to user preferences.
	 * @param modelValues The model values.
	 * @returns The user preferences.
	 * @private
	 */
	private toUserPreferences = (modelValues: IModelUserPreferenceValues): IUserPreferences => {
		return ({
			level: {
				level: modelValues.level.level,
				style: modelValues.level.style,
				id: modelValues.level.id,
			},
			notifications: {
				quickStart: modelValues.notifications.quickStart,
				zoomMode: modelValues.notifications.zoomMode,
			},
			review: {
				installDate: null,
				promptDate: null,
				postponePromptUntil: null,
				sessions: [],
			},
		});
	};
}