import { DBSchema } from 'idb';
import { IModelMetadataVersioned } from '../../model-interfaces';
import { Metadata as gameStatsMetadata } from '../v2/gamestats/metadata';
import { IModelGameStats } from '../v2/gamestats/model';
import { Metadata as levelsMetadata } from '../v2/levels/metadata';
import { IModelLevels } from '../v2/levels/model';
import { Metadata as savedGamesMetadata } from '../v3/saved-games/metadata';
import { IModelSavedGames } from '../v3/saved-games/model';
import { Metadata as settingsMetadata } from '../v4/settings/metadata';
import { IModelSettings } from '../v4/settings/model';
import { Metadata as productsMetadata } from '../v5/products/metadata';
import { IModelProducts } from '../v5/products/model';
import { Metadata as userPreferencesMetadata } from './user-preferences/metadata';
import { IModelUserPreferences } from './user-preferences/model';

/**
 * Defines the interface for the v6 application model.
 * @export
 * @interface IModel
 * @extends {DBSchema}
 * @extends {IModelGameStats}
 * @extends {IModelSettings}
 * @extends {IModelUserPreferences}
 * @extends {IModelLevels}
 * @extends {IModelSavedGames}
  * @extends {IModelProducts}
 */
export interface IModel
	extends
	DBSchema,
	IModelGameStats,
	IModelSettings,
	IModelUserPreferences,
	IModelLevels,
	IModelSavedGames,
	IModelProducts { }

/** 
 * Defines the v6 model metadata.
 */
export const modelMetadata: IModelMetadataVersioned[] = [
	{ metadata: new gameStatsMetadata(), requiresUpgrade: false },
	{ metadata: new settingsMetadata(), requiresUpgrade: false },
	{ metadata: new userPreferencesMetadata(), requiresUpgrade: true },
	{ metadata: new levelsMetadata(), requiresUpgrade: false },
	{ metadata: new savedGamesMetadata(), requiresUpgrade: false },
	{ metadata: new productsMetadata(), requiresUpgrade: false },
];