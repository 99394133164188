import { Cluster } from './cluster';
import { Constraint } from './constraint';

/**
 * The ClusterBuilder class implements methods to build clusters of connected constraints.
 * @export
 * @class ClusterBuilder
 */
export class ClusterBuilder {

	/**
	 * Builds clusters of connected constraints.
	 * @param constraints The constraints.
	 * @returns An array of constraint clusters.
	 * @static
	 * @memberof ClusterBuilder
	 */
	public static build = (constraints: Constraint[]): Cluster[] => {
		const result: Cluster[] = [];

		if (constraints.length > 0) {
			// sort by ascending length
			let sorted = constraints.sort((a: Constraint, b: Constraint): number => {
				return (
					a.variablesCount > b.variablesCount
						? 1
						: (a.variablesCount < b.variablesCount ? -1 : 0)
				);
			});

			let current: Constraint = sorted.pop() as Constraint;

			if (current) {
				result.push(new Cluster(current));

				while (sorted.length > 0) {
					sorted = ClusterBuilder.tryAdd(result[result.length - 1], sorted);

					if (sorted.length > 0) {
						current = sorted.pop() as Constraint;
						result.push(new Cluster(current));
					}
				}
			}
		}

		return result;
	};

	/**
	 * Adds constraints to a given cluster; if connected.
	 * @param cluster The cluster.
	 * @param constraints The constraints.
	 * @private
	 * @static
	 */
	private static tryAdd = (cluster: Cluster, constraints: Constraint[]): Constraint[] => {
		let remaining: Constraint[] = [];

		// try adding all the constraints
		constraints.forEach((constraint: Constraint): void => {
			if (cluster.isConnected(constraint)) {
				cluster.forceAdd(constraint);
			}
			else {
				remaining.push(constraint);
			}
		});

		// if there are any left and at least one constraint was added, then call recurively
		if (remaining.length > 0 && remaining.length < constraints.length) {
			remaining = ClusterBuilder.tryAdd(cluster, remaining);
		}

		return remaining;
	};
}