import { IGameLevelDefinition } from '../../../game-to-app/interfaces';
import { Nullable } from '../../../shared/types';
import { ICellSize, IFaceDefinition } from './interfaces';

export abstract class BoardShape {
	protected _level: IGameLevelDefinition;
	protected _cellSize: ICellSize;

	/**
	 * Creates an instance of BoardShapeBox.
	 * @param {LevelDefinition} level The game level.
	 * @param {ICellSize} cellSize The cell size.
	 */
	constructor(level: IGameLevelDefinition, cellSize: ICellSize) {
		this._level = level;
		this._cellSize = cellSize;
	}

	/**
	 * Gets the face for the given name.
	 * @param faceName The face name.
	 * @returns The face definition.
	 */
	public getFace = (faceName: Nullable<string>): Nullable<IFaceDefinition> => {
		let result: Nullable<IFaceDefinition> = null;

		if (faceName) {
			const faces: Record<string, IFaceDefinition> = this.getFaces() || {};
			result = faces[faceName] || null;
		}

		return result;
	};

	/**
	 * Gets the face name for the given index.
	 * @param index The face index.
	 * @returns The face name.
	 * @private
	 */
	public getFaceNameByIndex = (index: number): Nullable<string> => {
		const faces: Record<string, IFaceDefinition> = this.getFaces() || {};

		const filteredName: string[] = Object.keys(faces)
			.filter((key: string): boolean => faces[key].index === index);

		return filteredName.length > 0 ? filteredName[0] : null;
	};

	/**
	 * Gets the number of faces.
	 * @returns The number of faces.
	 * @private
	 */
	public getFaceCount = (): number => {
		const faces: Record<string, IFaceDefinition> = this.getFaces() || {};

		return Object.keys(faces).length;
	};

	/**
	 * Gets the faces of the board shape.
	 * @returns The faces of the board shape.
	 */
	public abstract getFaces(): Record<string, IFaceDefinition>;
}