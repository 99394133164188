import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { IEnvironment } from './interfaces';

export class TelemetryService {
	/**
	 * Gets the singleton instance.
	 * @readonly
	 */
	public static get insatnce(): Nullable<TelemetryService> {
		return TelemetryService._instance || null;
	}

	/**
	 * Gets the plugin.
	 * @readonly
	 */
	public static get reactPlugin(): Nullable<ReactPlugin> {
		return TelemetryService._instance?._reactPlugin || null;
	}

	/**
	 * Gets the app insights.
	 * @readonly
	 */
	public static get applicationInsights(): Nullable<ApplicationInsights> {
		return TelemetryService._instance?._applicationInsights || null;
	}

	private static _instance: Nullable<TelemetryService> = null;
	private _reactPlugin: Nullable<ReactPlugin> = null;
	private _applicationInsights: Nullable<ApplicationInsights> = null;
	
	/**
	 * Creates the instance of TelemetryService.
	 * @param instrumentationKey The intrumentation key.
	 * @param browserHistory The browser history.
	*/
	public static create = (instrumentationKey: string, browserHistory: unknown): TelemetryService => {
		TelemetryService._instance = (
			TelemetryService._instance ||
			new TelemetryService(instrumentationKey, browserHistory)
		);

		return TelemetryService._instance;
	};

	/**
	 * Creates the instance of TelemetryService.
	 * @param instrumentationKey The intrumentation key.
	 * @param browserHistory The browser history.
	 */
	private constructor(instrumentationKey: string, browserHistory: unknown)  {
		if (!browserHistory) {
			throw new Error('Failed to initialize service.');
		}
		if (!instrumentationKey) {
			throw new Error('The service requires a key.')
		}

		this._reactPlugin = new ReactPlugin();

		this._applicationInsights = new ApplicationInsights({
			config: {
				instrumentationKey: instrumentationKey,
				maxBatchInterval: 0,
				disableFetchTracking: false,
				extensions: [this._reactPlugin],
				extensionConfig: {
					[this._reactPlugin.identifier]: {
						history: browserHistory
					}
				}
			}
		});
		
		this._applicationInsights.loadAppInsights();
		this._applicationInsights.context.application.ver = metadata.version;
	}

	/**
	 * Gets the telemetry environment.
	 * @param url The url.
	 * @param platform The platform.
	 * @param version The version.
	 * @static
	 */
	public static getEnvironment = (url: string, platform: string, version: string): Nullable<IEnvironment> => {
		const testUrl: string = url?.toLowerCase();
		const testPlatform: string = platform?.toLowerCase();
		const testVersion: string = version?.toLowerCase();
		let result: Nullable<IEnvironment> = null;

		if (metadata.telemetry?.environments?.length > 0) {
			
			// url
			const urlPass: IEnvironment[] = metadata.telemetry.environments.filter((environment: IEnvironment) => {
				return environment.urls?.filter((url: string) => {
					return new RegExp(url, 'i').test(testUrl);
				}).length > 0;
			});

			// platform
			const platformPass: IEnvironment[] = urlPass.filter((environment: IEnvironment) => {
				return environment.platforms?.filter((platform: string) => {
					return new RegExp(platform, 'i').test(testPlatform);
				}).length > 0;
			});

			// version
			const versionPass: IEnvironment[] = platformPass.filter((environment: IEnvironment) => {
				return environment.versions?.filter((version: string) => {
					return new RegExp(version, 'i').test(testVersion);
				}).length > 0;
			});

			// passes all three filters
			result = versionPass && versionPass.length > 0 ? versionPass[0] : null;
		}
		
		return result;
	};

	/**
	 * Gets the default telemetry environment.
	 * @static
	 */
	public static getDefaultEnvironment = (): Nullable<IEnvironment> => {
		let result: Nullable<IEnvironment> = null;

		if (metadata.telemetry?.environments?.length > 0) {

			const defaults: IEnvironment[] = metadata.telemetry.environments.filter((environment: IEnvironment) => {
				return environment.default;
			});

			result = defaults?.length > 0 ? defaults[0] : null;
		}

		return result;
	};
}