/**
 * Defines error codes for the application.
 * @export
 * @enum {number}
 */
export enum ErrorCodes {
	AboutError = 5001,
	ApplicationError = 5101,
	ApplicationInitialization = 5102,
	DatabaseBlocked = 5201,
	DatabaseBlocking = 5202,
	DatabaseTerminated = 5203,
	DatabaseOpenFailed = 5204,	
	HelpError = 5301,
	HelpQuickStartError = 5302,
	LegalError = 5401,
	PlatformError = 5601,
	PlatformInitialization = 5602,
	SettingsError = 5501,
}

/**
 * Defines the error source.
 * @export
 * @enum
 */
export enum ErrorSource {
	About = 'About',
	Application = 'Application',
	Database = 'Database',
	Help = 'Help',
	Legal = 'Legal',
	Platform = 'Platform',
	Route = 'Route',
	Settings = 'Settings',
}