import { IDBPDatabase } from 'idb';
import { CookieProvider } from '../../../cookie-provider';
import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelMetadata } from '../../../model-interfaces';
import { IGameStats } from '../../../views/gamestats';
import { IModelGameStatsValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the GameStats model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelGameStatsValues> {

	/**
	 * Creates an instance of GameStatsUpgrade.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The metadata.
	 * @memberof GameStatsUpgrade
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelGameStatsValues>) {
		super(database, metadata);
	}
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {
		const stats: Record<string, IGameStats> = new CookieProvider().getGameStats({});
		const records: IModelGameStatsValues[] = [];

		// update keys from older version where assistant was not available
		const updatedStats: Record<string, IGameStats> = {};

		Object.keys(stats).forEach((key: string): void => {
			const keyParts: string[] = key.split('-');		
			const updatedKey: string = keyParts.length === 2 ? `${key}-0` : key;

			updatedStats[updatedKey] = stats[key];
		});

		// convert cookie values to model
		Object.keys(updatedStats).forEach((key: string): void => {
			const stat: IGameStats = stats[key];

			records.push({
				...{
					id: key,
					time: stat.time,
					won: stat.won,
					lost: stat.lost,
					played: stat.played,
				},
				...this._metadata.systemValues
			});
		});

		// create records
		for (let i: number = 0; i < records.length; i++) {
			await this._database.add(this._metadata.name, records[i]);
		}

		return true;
	};
}