import * as React from 'react';
import { Nullable } from '../../../../shared/types';
import { ICoordinate } from '../../shared/interfaces';
import directionalPadStyles from './directional-pad.module.css';
import { IProps } from './props';
import { IState } from './state';

/**
 * The DirectionalPad class implements the <DirectionalPad/> component.
 * @export
 * @class DirectionalPad
 * @extends {React.Component<IProps, IState>}
 */
export class DirectionalPad extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _refParent = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;

	/**
	 * Creates an instance of DirectionalPad.
	 * @param {IProps} props The props for the <DirectionalPad/> component.
	 */
	constructor(props: IProps) {
		super(props);
		
		this.state = {
			mouse: {
				pressed: false,
				position: {
					x: 0,
					y: 0,
				},
				delta: {
					x: 0,
					y: 0,
				},
			},
			interval: {
				accumulated: {
					x: 0,
					y: 0,
				},
				id: 0,
			},
			sensitivity: {
				frequency: 50,
				speed: 10,
			}
		};
	}

	/**
	 * The mouse down event handler.
	 * @param event The mouse event.
	 */
	public onMouseDown = (event: React.MouseEvent): void => {
		const { mouse, sensitivity, interval } = this.state;

		if (event) {
			const delta: Nullable<ICoordinate> = this.getDirection(event.clientX, event.clientY);

			if (delta) {
				// clear any hanging interval
				if (interval.id) {
					window.clearInterval(interval.id);
				}

				// set up new interval
				const id: number = window.setInterval(
					this.onRotateInterval,
					sensitivity.frequency,
				);

				// state
				this.setState({
					mouse: {
						...mouse,
						pressed: true,
						position: {
							x: event.pageX,
							y: event.pageY,
						},
						delta: delta,
					},
					interval: {
						...interval,
						id: id,
					},
				});

				event.preventDefault();
			}
		}
	};

	/**
	 * The mouse up event handler.
	 */
	public onMouseUp = (): void => {
		const { mouse, interval } = this.state;

		if (mouse.pressed) {

			// clear any hanging interval
			if (interval.id) {
				window.clearInterval(interval.id);
			}	

			this.setState({
				mouse: {
					...mouse,
					pressed: false,
					position: {
						x: 0,
						y: 0,
					},
					delta: {
						x: 0,
						y: 0,
					},
				},
				interval: {
					accumulated: {
						x: 0,
						y: 0,
					},
					id: 0,
				},
			});
		}
	};

	/**
	 * The touch start event handler.
	 * @param event The touch event.
	 * @private
	 */
	private onTouchStart = (event: React.TouchEvent<HTMLDivElement>): void => {
		event.stopPropagation();

		const mouseEvent = {
			pageX: event.changedTouches[0].pageX,
			pageY: event.changedTouches[0].pageY,
			clientX: event.changedTouches[0].clientX,
			clientY: event.changedTouches[0].clientY,
			preventDefault: event.preventDefault,
		};

		// forward the event as mouse event
		this.onMouseDown(mouseEvent as React.MouseEvent);
	}

	/**
	 * The touch end event handler.
	 * @param event The touch event.
	 * @private
	 */
	private onTouchEnd = (event: React.TouchEvent<HTMLDivElement>): void => {
		event.stopPropagation();

		// forward the event as mouse event
		this.onMouseUp();
	}

	/**
	 * A react lifecycle function for the <DirectionalPad/> component.
	 */
	public componentDidMount = (): void => {
		this._isMounted = true;
	};

	/**
	 * A react lifecycle function for the <DirectionalPad/> component.
	 */
	public componentWillUnmount = (): void => {
		const { interval } = this.state;

		this._isMounted = false;
		
		if (interval.id) {
			window.clearInterval(interval.id);
		}
	};

	/**
	 * Renders the <DirectionalPad/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const className = [
			`${directionalPadStyles.dpad}`,
			this.props.appData.app.directionalPad.active ? '' : `${directionalPadStyles.hide}`
		].join(' ');

		return (
			<div
				id={this.props.id}
				ref={this._refParent}
				className={`${directionalPadStyles.pad} row flex-fill`}
				style={{
					maxHeight: `${this.props.appData.app.size.window.boardHeight}px`,
					maxWidth: `${this.props.appData.app.size.window.width}px`,
					width: this.width(),
					height: this.height(),
				}}
			>
				<div
					ref={this._ref}
					className={className}
					style={{ bottom: this.bottom() }}
					onMouseDown={this.onMouseDown}
					onMouseUp={this.onMouseUp}
					onTouchStart={this.onTouchStart}
					onTouchEnd={this.onTouchEnd}
					onTouchCancel={this.onTouchEnd}
				>
				</div>
				{this.props.children}
			</div>
		);
	};

	/**
	 * Determines the direction to rotate in.
	 * @param clientX The client X position.
	 * @param clientY The client Y position.
	 * @returns The direction to rotate in.
	 */
	private getDirection = (clientX: number, clientY: number): ICoordinate => {
		const rect: DOMRect | undefined = this._ref.current?.getBoundingClientRect();
		let delta: ICoordinate = { x: 0, y: 0 };

		if (rect) {
			const midHeight = Math.round(rect.height / 2);
			const midWidth = Math.round(rect.width / 2);
			const x: number = clientX - rect.left;
			const y: number = clientY - rect.top;
		

			delta = { x: (x - midWidth) / midWidth, y: (y - midHeight) / midHeight };
		}
		
		return delta;
	};

	/**
	 * The rotate interval callback function.
	 */
	private onRotateInterval = (): void => {
		if (this._isMounted) {
			const { rotate } = this.props.appData.app;
			const { interval, sensitivity, mouse } = this.state;

			if (mouse.delta.x !== 0 || mouse.delta.y !== 0) {
				let rotateDelta: ICoordinate = {
					x: interval.accumulated.x + (mouse.delta.x * sensitivity.speed),
					y: interval.accumulated.y + (mouse.delta.y * sensitivity.speed),
				};

				const flooredRotateDelta = {
					x: Math.floor(Math.abs(rotateDelta.x)) * (rotateDelta.x >= 0 ? 1 : -1),
					y: Math.floor(Math.abs(rotateDelta.y)) * (rotateDelta.y >= 0 ? 1 : -1),
				};

				rotateDelta = {
					x: rotateDelta.x = flooredRotateDelta.x,
					y: rotateDelta.y = flooredRotateDelta.y,
				};

				// set state
				this.setState({
					interval: {
						...interval,
						accumulated: rotateDelta,
					},
				});
			
				if (flooredRotateDelta.x !== 0 || flooredRotateDelta.y !== 0) {
					// rotate 
					const x: number = (rotate?.x || 0) - flooredRotateDelta.y;
					const y: number = Math.abs(x % 360) > 90 && Math.abs(x % 360) < 270
						? (rotate?.y || 0) - flooredRotateDelta.x
						: (rotate?.y || 0) + flooredRotateDelta.x;

					if (this.props.appData.app.events.rotate?.onRotate) {
						this.props.appData.app.events.rotate.onRotate({ ...rotate, x: x, y: y });
					}
				}
			}
		}
	};

	/**
	 * Gets the height.
	 * @returns The height.
	 */
	private height = (): number => {
		return this.props.appData.app.size.window.boardHeight;
	};

	/**
	 * Gets the width.
	 * @returns The width.
	 */
	private width = (): number => {
		return this.props.appData.app.size.window.width;
	};

	/**
	 * Gets the bottom position.
	 * @returns The bottom position.
	 */
	private bottom = (): number => {
		return this.props.appData.app.size.window.bottomPanelHeight + 20;
	};
}