
import { Nullable } from '../../../shared/types';
import { SubscriptionEvent, SubscriptionEvents } from '../events/subscription-events';
import { ShopManager } from '../shop/shop-manager';
import { AdFactory } from './ad-factory';
import { AdServeStatus } from './enums';
import { IAdProvider, IAdServeResult, IServeInterstitialOptions } from './interfaces';

/**
 * The AdManager class manages the serving of ads in the application.
 * @export
 * @class AdManager
 */
export class AdManager {
	// the singleton platform instance
	private static _instance: Nullable<AdManager> = null;
	private _adProvider: IAdProvider = AdFactory.getAdProvider();

	/**
	 * Creates an instance of AdManager.
	 * @memberof AdManager
	 */
	private constructor() {
		// singleton, instance should only be created from this class.
		SubscriptionEvent.subscribe(SubscriptionEvents.AdServeStatusChanged, AdManager.onAdServeStatusChanged);
	}

	/**
	 * Initializes the ad manager.
	 * @static
	 */
	public static initialize = (): void => {
		if (AdManager._instance === null) {
			AdManager._instance = new AdManager();

			AdManager._instance._adProvider.activate(
				ShopManager.ownsAdRemovalProduct ? AdServeStatus.InActive : AdServeStatus.Active);
		}
	};

	/**
	 * Serves an interstitial ad.
	 * @param options The server options; optional.
	 * @returns A promise that resolves with the serve result.
	 * @static
	 */
	public static serveInterstitial = (options?: IServeInterstitialOptions): Promise<IAdServeResult> => {
		// make sure the manager is initialized.
		AdManager.initialize();

		// serve
		return (AdManager._instance as AdManager)._adProvider.serveInterstitial(options);
	};

	/**
	 * An event handler for when the ad serve status changes.
	 * @param serve A Boolean value indicating whether ads should be served.
	 * @private
	 * @static
	 */
	private static onAdServeStatusChanged = (status: AdServeStatus): void => {
		// make sure the manager is initialized.
		AdManager.initialize();

		// activate or deactivate provider
		(AdManager._instance as AdManager)._adProvider.activate(status);
	}
}