import deepequal from 'deep-equal';
import deepmerge from 'deepmerge';
import { SubscriptionEvent, SubscriptionEvents } from '../../events/subscription-events';
import { deepcopy } from '../../utils';
import { IModelCacheManager, IStore } from '../model-interfaces';
import { IGameStats, IGameStatsUpdate } from '../views/gamestats';

/**
 * The GameStatsStore class implements the store for game stats.
 * @export
 * @class GameStatsStore
 */
export class GameStatsStore implements IStore {
	private _cacheManager: IModelCacheManager;
	private _stats: Record<string, IGameStats>;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._cacheManager.name; }

	/**
	 * Creates an instance of GameStatsStore.
	 * @param {IModelCacheManager} cacheManager The cache manager.
	 * @memberof GameStatsStore
	 */
	constructor(cacheManager: IModelCacheManager) {
		this._cacheManager = cacheManager;
		this._stats = this._cacheManager.get();
	}

	/**
	 * Gets all game stats.
	 * @param refresh A Boolean value indicating if the settings should be taken from the most recent database cache.
	 * @returns The game stats.
	 */
	public get = (refresh: boolean = false): Record<string, IGameStats> => {
		if (refresh) {
			const refreshedStats: Record<string, IGameStats> = this._cacheManager.get() as Record<string, IGameStats>;

			if (!deepequal(refreshedStats, this._stats)) {
				this._stats = refreshedStats;
				SubscriptionEvent.raise(SubscriptionEvents.GameStatsChanged, this.name, this._stats);
			}
		}

		return deepcopy(this._stats);
	};

	/**
	 * Updates game stats.
	 * @param stats The stats to update.
	 * @param refresh A Boolean value indicating if the settings should be taken from the most recent database cache.
	 * @returns The updated game stats.
	 */
	public update = async (stats: IGameStatsUpdate, refresh: boolean = false): Promise<Record<string, IGameStats>> => {
		const previousStats = this._stats;
		
		// update the entire stats, change the values for the specific id
		let updatedStats: Record<string, IGameStats> = deepcopy(this._stats);
		const statsForId: IGameStats = updatedStats[stats.id];
		const updatedStatsForId: IGameStats = deepmerge(statsForId, stats);
		updatedStats[stats.id] = updatedStatsForId;

		if (!deepequal(updatedStats, previousStats)) {		
			// update the store settings synchronously
			this._stats = updatedStats;
			
			if (!refresh) {
				SubscriptionEvent.raise(SubscriptionEvents.GameStatsChanged, this.name, this._stats);
			}

			// update the database
			updatedStats = await this._cacheManager.update(stats, previousStats);

			// optionally update the settings asynchronously from the database
			if (refresh && !deepequal(updatedStats, this._stats)) {
				this._stats = updatedStats;
				SubscriptionEvent.raise(SubscriptionEvents.GameStatsChanged, this.name, this._stats);
			}
		}

		return this._stats;
	};
}