import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { deepcopy } from '../../../../utils';
import { TransactionMode } from '../../../enums';
import { IModelCacheManager, IModelMetadata } from '../../../model-interfaces';
import { ISettings, ISettingsUpdate } from '../../../views/settings';
import { IModelSettingsValues } from './model';

/**
 * The CacheManager class implements a cache for the game stats model.
 * @export
 * @class CacheManager
 * @implements {IModelCache}
 */
export class CacheManager implements IModelCacheManager {
	private _database: IDBPDatabase;
	private _metadata: IModelMetadata<IModelSettingsValues>;
	private _cache: Nullable<ISettings> = null;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._metadata.name; }

	/**
	 * Creates an instance of CacheManager.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The model metadata.
	 * @memberof CacheManager
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelSettingsValues>) {
		this._database = database;
		this._metadata = metadata;
	}
	
	/**
	 * Loads the model into the cache.
	 * @returns A promise that resolves when the cache is loaded.
	 */
	public load = async (): Promise<ISettings> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		let cache: IModelSettingsValues = await store.get(this._metadata.name);
		
		// if the record does not exist for some reason, recreate it
		if (!cache) {
			cache = {
				...this._metadata.defaults[0],
				...this._metadata.systemValues,
			};

			await store.put(cache);
		}

		this._cache = this.toSettings(cache);

		return this.get();
	};

	/**
	 * Updates a record in the cache and database.
	 * @param settings The settings.
	 * @returns A promise that resolves when the database was updated.
	 */
	public update = async (settings: ISettingsUpdate): Promise<ISettings> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		const record: IModelSettingsValues = await store.get(this._metadata.name);
		const base: IModelSettingsValues = record || { ...this._metadata.defaults[0], ...this._metadata.systemValues };

		// initialize update from updated stats and base
		const update: IModelSettingsValues = {
			...{
				id: this._metadata.name,
				game: {
					enableQuestionMark: settings?.game?.enableQuestionMark !== undefined
						? settings.game.enableQuestionMark
						: base.game.enableQuestionMark,
					enableAutoSave: settings?.game?.enableAutoSave !== undefined
						? settings.game.enableAutoSave
						: base.game.enableAutoSave,
				},
				solver: {
					timeout: settings?.solver?.timeout !== undefined
						? settings.solver.timeout
						: base.solver.timeout,
					enableDiscovery: settings?.solver?.enableDiscovery !== undefined
						? settings.solver.enableDiscovery
						: base.solver.enableDiscovery,
					autoPlayer: {
						flagCells: settings?.solver?.autoPlayer?.flagCells !== undefined
							? settings.solver.autoPlayer.flagCells
							: base.solver.autoPlayer.flagCells,
					},
				},
			},
			...this._metadata.systemValues,
		};

		// create or update in database
		await store.put(update);

		// refresh cache
		await this.load();

		// return the entire stats cache
		return this.get();
	};

	/**
	 * Deletes a record in the cache and database.
	 * @param id The id.
	 * @returns A promise that resolves when the database record was deleted.
	 */
	public delete = async (id: string): Promise<ISettings> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// create or update in database
		await store.delete(id);

		// refresh cache
		await this.load();

		// return the entire levels cache
		return this.get();
	};

	/**
	 * Gets the settings.
	 * @returns The settings.
	 */
	public get = (): ISettings => {
		const cache: ISettings = this._cache || this.toSettings(this._metadata.defaults[0]);

		return deepcopy(cache) as ISettings;
	};

	/**
	 * Converts model values to settings.
	 * @param modelValues The model values.
	 * @returns The settings.
	 * @private
	 */
	private toSettings = (modelValues: IModelSettingsValues): ISettings => {
		return ({
			game: {
				enableQuestionMark: modelValues.game.enableQuestionMark,
				enableAutoSave: modelValues.game.enableAutoSave,
			},
			solver: {
				autoPlayer: {
					flagCells: modelValues.solver.autoPlayer.flagCells,
				},
				enableDiscovery: modelValues.solver.enableDiscovery,
				timeout: modelValues.solver.timeout,
			},
			sound: {
				volume: { muted: true, volume: 0 },
				sfx: {},
			},
		});
	};
}