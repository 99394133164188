import { BoardDefinition, FaceDefinition, NeighborDefinition } from './board-definition';
import { Cell } from './cell';
import { Face } from './face';
import { Nullable, Position, Size } from './types';

/**
 * The Neighbors class provides functions to calculate cell neighbors across all faces of a game board.
 * @export
 */
export class Neighbors {

	/**
	 * Gets the backwards neighbor positions.
	 * @private
	 * @static
	 */
	private static neighborPositionsBackward: Position[] = [
		{ x: -1, y: -1 },
		{ x: 0, y: -1 },
		{ x: 1, y: -1 },
		{ x: -1, y: 0 },
	];

	/**
	 * Gets the forward neighbor positions.
	 * @private
	 * @static
	 */
	private static neighborPositionsForward: Position[] = [
		{ x: 1, y: 0 },
		{ x: -1, y: 1 },
		{ x: 0, y: 1 },
		{ x: 1, y: 1 },
	];

	/**
	 * For a given cell, adds neighbors on the same face.
	 * @param cell The cell.
	 * @param cells The face cells.
	 * @param x The x coordinate of the cell.
	 * @param y The y coordinate of the cell.
	 * @param size The face size.
	 * @static
	 */
	public static addSameFaceNeighbors = (
		cell: Cell,
		cells: Cell[][],
		x: number,
		y: number,
		size: Size): void => {
		Neighbors.neighborPositionsBackward.forEach((position: Position) => {
			const neighborPosition = { x: position.x + x, y: position.y + y };

			if (Neighbors.isInLimits(neighborPosition, size)) {

				// backward: add earlier created cells as neighbors of the newly created cell
				cell.addNeighbor(cells[neighborPosition.y][neighborPosition.x]);

				// forward: add newly created cell as neighbor to earlier created cells
				cells[neighborPosition.y][neighborPosition.x].addNeighbor(cell);
			}
		});
	};

	/**
	 * Adds other face neighbors.
	 * @param faces The faces.
	 * @definition The board definition.
	 * @static
	 */
	public static addOtherFaceNeighbors = (
		faces: Record<string, Face>,
		definition: BoardDefinition): void => {
		
		const aroundCenterPositions: Position[] = Neighbors.neighborPositionsBackward.concat(Neighbors.neighborPositionsForward);

		if (faces && definition) {
			for (const key in definition.faces) {
				const faceDefinition: FaceDefinition = definition.faces[key];
				const face: Face = faces[key];

				if (faceDefinition && face) {
					for (const neighborKey in faceDefinition.neighbors) {
						const neighborDefinition: NeighborDefinition = faceDefinition.neighbors[neighborKey];
						const neighborDefinitionSize: Size = {
							width: neighborDefinition.width,
							height: neighborDefinition.height,
						};

						const neighborFaceDefinition: FaceDefinition = definition.faces[neighborKey];
						const neighborFace: Face = faces[neighborKey];

						if (neighborDefinition && neighborFace) {
							const ownPositions = Neighbors.positions(
								{ width: faceDefinition.width, height: faceDefinition.height },
								neighborDefinition.rotateOwn || 0);

							const ownSize: Size = {
								width: ownPositions.length > 0 ? ownPositions[0].length : 0,
								height: ownPositions.length,
							};

							const neighborPositions = Neighbors.positions(
								{ width: neighborFaceDefinition.width, height: neighborFaceDefinition.height },
								neighborDefinition.rotateNeighbor || 0);

							const neighborSize: Size = {
								width: neighborPositions.length > 0 ? neighborPositions[0].length : 0,
								height: neighborPositions.length,
							};

							// get the overlap
							for (let y: number = 0; y < neighborDefinitionSize.height; y++) {
								const own: Position = { y: neighborDefinition.own.y + y, x: 0 };
								const neighbor: Position = { y: neighborDefinition.neighbor.y + y, x: 0 };

								if (Neighbors.isInLimits(own, ownSize) &&
									Neighbors.isInLimits(neighbor, neighborSize)) {

									for (let x: number = 0; x < neighborDefinitionSize.width; x++) {
										own.x = neighborDefinition.own.x + x;
										neighbor.x = neighborDefinition.neighbor.x + x;

										if (Neighbors.isInLimits(own, ownSize) &&
											Neighbors.isInLimits(neighbor, neighborSize)) {

											// center
											if (Neighbors.addNeighbor(
												face,
												neighborFace,
												ownPositions[own.y][own.x],
												neighborPositions[neighbor.y][neighbor.x])) {

												// loop positions around center
												aroundCenterPositions.forEach((position: Position) => {
													const overlapPosition = {
														x: position.x + x,
														y: position.y + y,
													};
													const ownPosition: Position = {
														x: own.x + position.x,
														y: own.y + position.y,
													};
													const neighborPosition: Position = {
														x: neighbor.x + position.x,
														y: neighbor.y + position.y,
													};

													// if all positions are in bounds, then add neighbors
													if (Neighbors.isInLimits(overlapPosition, neighborDefinitionSize) &&
														Neighbors.isInLimits(ownPosition, ownSize) &&
														Neighbors.isInLimits(neighborPosition, neighborSize)) {

														Neighbors.addNeighbor(
															face,
															neighborFace,
															ownPositions[own.y][own.x],
															neighborPositions[neighborPosition.y][neighborPosition.x]);
													}
												});
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	};

	/**
	 * Adds a given position as a neighbor.
	 * @param ownFace The own face.
	 * @param neighborFace The neighbor face.
	 * @param own The own position.
	 * @param neighbor The neighbor position.
	 * @returns true if the neighbor was added; otherwise, false.
	 * @private
	 * @static
	 */
	private static addNeighbor = (
		ownFace: Face,
		neighborFace: Face,
		own: Position,
		neighbor: Position): boolean => {
		
		const ownCell: Nullable<Cell> = ownFace.getCell(own.x, own.y);
		const neighborCell: Nullable<Cell> = neighborFace.getCell(neighbor.x, neighbor.y);
		let result: boolean = false;

		if (ownCell && neighborCell) {
			ownCell.addNeighbor(neighborCell);
			neighborCell.addNeighbor(ownCell);
			result = true;
		}

		return result;
	};

	/**
	 * Determines if a given position is in the limits of a face size.
	 * @param position The position.
	 * @param size The size.
	 * @returns true if the position is within the limits; otherwise, false.
	 * @private
	 * @static
	 */
	private static isInLimits = (position: Position, size: Size): boolean => {
		return position.x >= 0 && position.x < size.width &&
			position.y >= 0 && position.y < size.height;
	};

	/**
	 * Gets rotated positions.
	 * @param size The face size.
	 * @param rotations The number of clockwise rotations.
	 * @returns The rotated positions.
	 * @private
	 * @static
	 */
	private static positions = (size: Size, rotations: number): Position[][] => {
		let result: Position[][] = [];

		for (let y: number = 0; y < size.height; y++) {
			const row: Position[] = [];
			for (let x: number = 0; x < size.width; x++) {
				row.push({ x: x, y: y });
			}

			result.push(row);
		}

		for (let i: number = 0; i < rotations; i++) {
			result = Neighbors.rotateClockWise(result);
		}

		return result;
	};

	/**
	 * Roates a matrix of positions clock wise.
	 * @param matrix The original positions.
	 * @returns The roated matrix of positions.
	 * @private
	 * @static
	 */
	private static rotateClockWise = (matrix: Position[][]): Position[][] => {
		const result: Position[][] = [];

		if (matrix.length > 0) {
			for (let i: number = 0; i < matrix[0].length; i++) {
				const row: Position[] = matrix.map(m => m[i]).reverse();
				result.push(row);
			}
		}

		return result;
	};
}