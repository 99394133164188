import { AppLauncher } from '@capacitor/app-launcher';
import { RateApp } from 'capacitor-rate-app';
import { Nullable } from '../../../../shared/types';
import { AppStoreType } from '../../enums';
import { IAppStore } from '../../interfaces';
import { Platform } from '../../platform/platform';
import { Store } from '../../shop/store';
import { Database } from '../../storage/database';
import { IUserPreferences } from '../../storage/views/userpreferences';
import { Events } from '../../telemetry/events';
import { ICustomProperties } from '../../telemetry/interfaces';
import { Telemetry } from '../../telemetry/telemetry';
import { IReviewProvider } from '../interfaces';

/**
 * The PlayStoreReviewProvider class implements functions to prompt users for a review.
 * @export
 * @class PlayStoreReviewProvider
 */
export class PlayStoreReviewProvider implements IReviewProvider {
	private _store: Nullable<IAppStore> = Store.getAppStore(AppStoreType.GooglePlay);
	private _minPromptIntervalDays = this._store?.review.minPromptIntervalDays ?? 14;
	private _minPromptIntervalSessions = this._store?.review.minPromptIntervalSessions ?? 7;

	/**
	 * Gets a Boolean value indicating whether the provider is enabled.
	 * @readonly
	 */
	public get isEnabled(): boolean { return this._store?.review.enabled || false; }

	/**
	 * Initializes the provider on app start
	 */
	public initialize = (): void => {
		if (this.isEnabled) {
			const userPreferences: IUserPreferences = Database.userPreferences.get();

			// keep only the most recent sessions
			const sessions = (userPreferences.review.sessions || [])
				.slice(-Math.min(userPreferences.review.sessions?.length ?? 0, this._minPromptIntervalSessions + 1));

			// add current session
			sessions.push({
				date: new Date(),
				won: 0,
				lost: 0,
			});

			// update preferences
			Database.userPreferences.update({
				review: {
					sessions: sessions,
				}
			});
		}
	};

	/**
	 * Opens the review prompt if eligible.
	 * @param checkEligibility A Boolean value indicating whether to check for eligibility.
	 */
	public requestPrompt = async (checkEligibility: boolean): Promise<void> => {
		if (this.isEnabled) {
			// using the native control to request a review
			// note: Google prevents frequent prompting. The prompt may not actually be allowed to show.
			if (!checkEligibility || this.isEligibleForPrompt()) {
				await RateApp.requestReview();

				// update review preferences
				Database.userPreferences.update({
					review: {
						promptDate: new Date(),
						postponePromptUntil: null,
					}
				});

				// log
				this.logTelemetryEvent(Events.ReviewPromptRequested);
			}
		}
	};

	/**
	 * Determines if the store listing can be opened.
	 * @returns A Boolean value indicating whrther the store listing can be opened.
	 */
	public canOpenStoreListing = (): boolean => {
		return this.isEnabled && !!this._store?.review.reviewUrl;
	};

	/**
	 * Opens the store listing.
	 */
	public openStoreListing = async (): Promise<void> => {
		if (this.isEnabled && this._store?.review.reviewUrl) {
			// log
			this.logTelemetryEvent(Events.ReviewStoreListingOpened);

			// go to store
			await AppLauncher.openUrl({ url: this._store.review.reviewUrl });
		}
	};

	/**
	 * Determines whether the app is currently eligible to prompt for a review.
	 * @returns A Boolean value indicating whether the app is currently eligible to prompt for a review.
	 */
	public isEligibleForPrompt = (): boolean => {
		let result: boolean = false;

		// if enabled
		if (this.isEnabled) {
			if (Platform.isProductionApplication()) {
				const userPreferences: IUserPreferences = Database.userPreferences.get();
				const now = new Date().getTime();

				// not set to postpone to a future date
				if (userPreferences.review.postponePromptUntil &&
					userPreferences.review.postponePromptUntil.getTime() <= now) {

					// first condition: not prompted for a configured interval
					let dateConditionMet: boolean = false;
					if (userPreferences.review.promptDate) {
						const earliestPrompt: Date = new Date(userPreferences.review.promptDate);
						earliestPrompt.setDate(earliestPrompt.getDate() + this._minPromptIntervalDays);

						dateConditionMet = earliestPrompt.getTime() <= now;
					}

					// second condition: opened the app at least a configured number of times
					const sessionConditionMet: boolean = (
						userPreferences.review.sessions &&
						userPreferences.review.sessions.length >= this._minPromptIntervalSessions
					);

					result = dateConditionMet && sessionConditionMet;
				}
			}
		}
		else {
			// developer build: bypass checks
			result = true;
		}

		return result;
	};

	/**
	 * Updates the current session with a win or loss.
	 * @param win A Boolean value indicating whether the game was won.
	 */
	public addSessionGameResult = (win: boolean): void => {
		if (this.isEnabled) {
			const userPreferences: IUserPreferences = Database.userPreferences.get();
			const session = userPreferences?.review.sessions?.length > 0
				? userPreferences.review.sessions[userPreferences.review.sessions.length - 1]
				: null;

			if (session) {
				if (win) {
					session.won += 1;
				}
				else {
					session.lost += 1;
				}

				Database.userPreferences.update({
					review: {
						sessions: userPreferences.review.sessions
					}
				});
			}
		}
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}