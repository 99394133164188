
import { Nullable } from '../../../shared/types';
import { AdServeStatus } from '../ads/enums';
import { PurchaseFlowSuccess, ShopProductStatus, ShopServiceStatus } from '../enums';
import { Events } from '../events/events';
import { SubscriptionEvent, SubscriptionEvents } from '../events/subscription-events';
import { IPurchaseFlow, IShopProduct } from '../interfaces';
import { Database } from '../storage/database';
import { IProduct } from '../storage/views/products';
import { Events as TelemetryEvents } from '../telemetry/events';
import { ICustomProperties } from '../telemetry/interfaces';
import { Telemetry } from '../telemetry/telemetry';
import { IShopProvider } from './interfaces';
import { ShopFactory } from './shop-factory';

/**
 * The ShopManager class manages in-app store products and purchases.
 * @export
 * @class ShopManager
 */
export class ShopManager {

	// the singleton platform instance
	private static _instance: Nullable<ShopManager> = null;
	private _events: Events = new Events();
	private _shopProvider: IShopProvider = ShopFactory.getStoreProvider(this._events);
	private _isOpen: boolean = false;
	private _ownsAllProducts: boolean = false;
	private _ownsAdRemovalProduct: boolean = false;

	/**
	 * Gets a Boolean value indicating whether the shop is enabled.
	 * @readonly
	 */
	public static get isEnabled(): boolean { return ShopManager.getProvider().isEnabled; }

	/**
	 * Gets a Boolean value indicating whether the shop is open.
	 * @readonly
	 */
	public static get isOpen(): boolean { return ShopManager.getInstance()._isOpen; }

	/**
	 * Gets an enum value indicating the shop service status.
	 * @readonly
	 */
	public static get serviceStatus(): ShopServiceStatus { return ShopManager.getProvider().serviceStatus; }

	/**
	 * Gets a Boolean value indicating whether an ad removal product was purchased.
	 * @readonly
	 */
	public static get ownsAdRemovalProduct(): boolean { return ShopManager.getInstance()._ownsAdRemovalProduct; }

	/**
	 * Gets a Boolean value indicating whether all products were purchased.
	 * @readonly
	 */
	public static get ownsAllProducts(): boolean { return ShopManager.getInstance()._ownsAllProducts; }

	/**
	 * Creates an instance of ShopManager.
	 * @memberof Platform
	 */
	private constructor() {
		// singleton, instance should only be created from this class.
		this._shopProvider.initialize();
		
		this._events.addListener(ShopEvents.Open, this.onOpen);
		this._events.addListener(ShopEvents.FailedToOpen, this.onFailedToOpen);
		this._events.addListener(ShopEvents.PurchaseFlow, this.onPurchaseFlowProgress);
		this._events.addListener(ShopEvents.ShopProductUpdated, this.onShopProductUpdated);
	}

	/**
	 * Initializes the shop manager.
	 * @static
	 */
	public static initialize = (): void => {
		if (ShopManager._instance === null) {
			ShopManager._instance = new ShopManager();

			ShopManager._instance._ownsAdRemovalProduct = ShopManager._instance.checkOwnsAdRemovingProduct();
		}
	};

	/**
	 * Gets the store products.
	 * @returns The products.
	 * @static
	 */
	public static getProducts = (): Record<string, IShopProduct> => {
		return ShopManager.getProvider().getProducts();
	};

	/**
	 * Initializes a purchase flow.
	 * @param id The product id.
	 * @param onProgress A callback function that is called with state changes of the purchase flow.
	 * @memberof ShopManager
	 */
	public static purchase = (id: string): void => {
		ShopManager.getProvider().purchase(id);
	};

	/**
	 * Gets the shop provider.
	 * @private
	 * @static
	 */
	private static getProvider = (): IShopProvider => {
		return ShopManager.getInstance()._shopProvider;
	};

	/**
	 * Gets the shop manager instance.
	 * @private
	 * @static
	 */
	private static getInstance = (): ShopManager => {
		// make sure the manager is initialized.
		ShopManager.initialize();

		return (ShopManager._instance as ShopManager);
	};

	/**
	 * An event handler for the store open event.
	 */
	private onOpen = (): void => {
		const products: Record<string, IShopProduct> = ShopManager.getProducts();
		const savedProducts: Record<string, IProduct> = Database.products.get();
		const ownedProducts: Record<string, IShopProduct> = {};
		const promises: Promise<Record<string, IProduct>>[] = [];

		let keys: string[] = Object.keys(products);

		// get all owned products
		keys.forEach((id: string) => {
			const product: IShopProduct = products[id];

			if (product.status === ShopProductStatus.Owned) {
				ownedProducts[product.id] = product;
			}
		});

		keys = Object.keys(savedProducts);

		// remove all existing products that are no longer owned
		keys.forEach((id: string) => {
			const product: IProduct = savedProducts[id];

			// not in owned products
			if (ownedProducts[product.id]?.status !== ShopProductStatus.Owned) {
				promises.push(Database.products.delete(product.id));

				// telemetry
				this.logTelemetryEvent(
					TelemetryEvents.StoreManagerPurchaseRemoved,
					{ id: product.id }
				);
			}
		});

		keys = Object.keys(ownedProducts);

		// create or update all owned products
		keys.forEach((id: string) => {
			const product: IShopProduct = ownedProducts[id];

			promises.push(Database.products.update({
				id: product.id,
				date: new Date(),
				quantity: 1,
				data: product.token,
			}));
		});

		// raise events
		Promise.all(promises)
			.then(this.open)
			.catch(this.open);
	};

	/**
	 * An event handler for when the shop failed to open.
	 * @private
	 */
	private onFailedToOpen = (): void => {
		this._isOpen = false;

		// raise event
		SubscriptionEvent.raise(SubscriptionEvents.ShopFailedToOpen);
	};

	/**
	 * An event handler for purchase flow events.
	 * @param flow The flow.
	 */
	private onPurchaseFlowProgress = (flow: IPurchaseFlow): void => {
		// raise subscription event
		// can be done here before the internal event was processed
		SubscriptionEvent.raise(SubscriptionEvents.ShopPurchaseFlowProgress, flow);

		if (flow.product) {
			if (flow.success === PurchaseFlowSuccess.Yes) {
				// store successful purchase
				Database.products
					.update({
						id: flow.product.id,
						date: new Date(),
						quantity: 1,
						data: flow.product.token,
					})
					.finally(() => {

						// raise events as needed
						this.updateProductStatus();

						// telemetry
						this.logTelemetryEvent(
							TelemetryEvents.StoreManagerPurchase,
							{ id: flow?.product?.id }
						);
					});
			}
		}
	};

	/**
	 * An event handler for when a product is updated.
	 * @param product The updated product.
	 * @private
	 */
	private onShopProductUpdated = (product: IShopProduct): void => {
		// raise event.
		SubscriptionEvent.raise(SubscriptionEvents.ShopProductUpdated, product);
	};

	/**
	 * Opens the shop.
	 * @private
	 */
	private open = (): void => {
		if (ShopManager.isEnabled) {
			this.updateProductStatus();
			this._isOpen = true;

			// raise event
			SubscriptionEvent.raise(SubscriptionEvents.ShopOpened);
		}
	};

	/**
	 * Updates product statuses as needed and raises events on change.
	 * @private
	 */
	private updateProductStatus = (): void => {

		// ad removal product
		const ownsAdRemovalProduct = this.checkOwnsAdRemovingProduct();

		if (ownsAdRemovalProduct !== this._ownsAdRemovalProduct) {
			this._ownsAdRemovalProduct = ownsAdRemovalProduct;

			SubscriptionEvent.raise(
				SubscriptionEvents.AdServeStatusChanged,
				ownsAdRemovalProduct ? AdServeStatus.InActive : AdServeStatus.Active);
		}

		// all products
		const ownsAllProducts = this.checkOwnsAllProducts();

		if (ownsAllProducts !== this._ownsAllProducts) {
			this._ownsAllProducts = ownsAllProducts;

			SubscriptionEvent.raise(SubscriptionEvents.OwnsAllProductsStatusChanged, this._ownsAllProducts);
		}
	};

	/**
	 * Checks if an ad removing product is owned.
	 * @returns true if an ad removing product is owned; otherwise, false.
	 */
	private checkOwnsAdRemovingProduct = (): boolean => {
		const ids: string[] = Object.keys(Database.products.get());

		const result = ids.filter((id: string) => ShopManager
			.getProvider()
			.getAdRemovingProductIds()
			.indexOf(id) >= 0).length > 0;
		
		return result;
	};

	/**
	 * Checks if all products are owned.
	 * @returns true if all products are owned; otherwise, false.
	 */
	private checkOwnsAllProducts = (): boolean => {
		const ids: string[] = Object.keys(Database.products.get());
		const allProductIds: string[] = ShopManager
			.getProvider()
			.getProductIds();
		
		return allProductIds.filter((id: string) => ids.indexOf(id) < 0).length === 0;
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: TelemetryEvents, customProperties?: ICustomProperties): void => {
		// augment properties
		const properties = {
			provider: ShopManager.getProvider().name,
			...customProperties
		};

		Telemetry.event(event.toString(), properties);
	};
}

/**
 * Defines the shopevents.
 * @export
 */
export enum ShopEvents {
	Open = 'Open',
	FailedToOpen = 'FailedToOpen',
	PurchaseFlow = 'PurchaseFlow',
	ShopProductUpdated = 'StoreProductUpdated',
}