import { IMatrixCluster, IMatrixClusterRow } from './interfaces';

/**
 * The MatrixClusterBuilder class implements methods to build clusters of connected matrix rows.
 * @export
 * @class ClusterBuilder
 */
export class MatrixClusterBuilder {

	/**
	 * Builds clusters of connected matrix rows.
	 * @param matrixRows The matrix rows.
	 * @returns An array of matrix clusters.
	 * @static
	 * @memberof MatrixClusterBuilder
	 */
	public static build = (matrixRows: IMatrixClusterRow[]): IMatrixCluster[] => {
		const result: IMatrixCluster[] = [];

		if (matrixRows.length > 0) {
			// sort by ascending length
			let sorted = matrixRows.sort((a: IMatrixClusterRow, b: IMatrixClusterRow): number => {
				return (
					a.indices.length > b.indices.length
						? 1
						: (a.indices.length < b.indices.length ? -1 : 0)
				);
			});

			// remove last row
			let current: IMatrixClusterRow = sorted.pop() as IMatrixClusterRow;

			if (current) {
				// add to cluster
				result.push({
					indices: [...current.indices],
					rows: [current],
				});

				while (sorted.length > 0) {
					// add all connected rows
					sorted = MatrixClusterBuilder.tryAdd(result[result.length - 1], sorted);

					if (sorted.length > 0) {
						// remove last row
						current = sorted.pop() as IMatrixClusterRow;

						// add new cluster
						result.push({
							indices: [...current.indices],
							rows: [current],
						});
					}
				}
			}
		}

		return result;
	};

	/**
	 * Adds rows to a given cluster; if connected.
	 * @param cluster The cluster.
	 * @param matrixRows The matrix rows.
	 * @private
	 * @static
	 */
	private static tryAdd = (cluster: IMatrixCluster, matrixRows: IMatrixClusterRow[]): IMatrixClusterRow[] => {
		let remaining: IMatrixClusterRow[] = [];

		// try adding all the constraints
		matrixRows.forEach((matrixRow: IMatrixClusterRow): void => {
			if (MatrixClusterBuilder.isConnected(cluster, matrixRow)) {
				// create union of indices
				cluster.indices = cluster.indices.concat(matrixRow.indices.filter((index: number): boolean =>
					cluster.indices.indexOf(index) === -1)
				);

				// add row
				cluster.rows.push(matrixRow);
			}
			else {
				remaining.push(matrixRow);
			}
		});

		// if there are any left and at least one row was added, then call recurively
		if (remaining.length > 0 && remaining.length < matrixRows.length) {
			remaining = MatrixClusterBuilder.tryAdd(cluster, remaining);
		}

		return remaining;
	};

	/**
	 * Determines if the given matrix row is connected to the cluster.
	 * @param cluster The matrix cluster.
	 * @param matrixRow The matrix cluster row.
	 * @returns true if the row is connected to the cluster; otherwise, false.
	 * @private
	 * @static
	 */
	private static isConnected = (cluster: IMatrixCluster, matrixRow: IMatrixClusterRow): boolean => {
		let i: number = 0;
		let result: boolean = false;

		while (i < cluster.indices.length && !result) {
			result = matrixRow.indices.indexOf(cluster.indices[i]) >= 0;
			i++;
		}

		return result;
	};
}