import { Nullable } from '../../../../../../shared/types';
import { AppStoreType } from '../../../../enums';
import { IAppStore } from '../../../../interfaces';
import { Store } from '../../../../shop/store';
import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelUserPreferenceValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the user preferences model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelUserPreferenceValues> {
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {
		// load user preferences
		const userPreferences: IModelUserPreferenceValues = await this._database.get(
			this._metadata.name,
			this._metadata.name);

		// get earliest prompt date
		const earliestDate: Date = new Date();
		const store: Nullable<IAppStore> = Store.getAppStore(AppStoreType.GooglePlay);
		earliestDate.setDate(earliestDate.getDate() + (store?.review.minPromptIntervalDays ?? 14));

		// if the record was just modified within a short tolerance, then it was created in a previous upgrade step
		// in that case, it is a new install
		const modified: Nullable<number> = userPreferences?.meta?.modified?.getTime();
		const isNewInstall: boolean = (modified ? new Date().getTime() - modified : Number.MAX_SAFE_INTEGER) < 2000;

		// update
		await this._database.put(
			this._metadata.name,
			{
				...userPreferences,
				review: {
					installDate: isNewInstall ? new Date() : null,
					promptDate: null,
					postponePromptUntil: earliestDate,
					sessions: [],
				},
				...this._metadata.systemValues,
			}
		);

		return true;
	};
}