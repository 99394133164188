import { BoardDefinition, NeighborDefinition } from '../game/board-definition';
import { Cell } from '../game/cell';
import { GameStatus, Move, MoveResult } from '../game/enums';
import { Face } from '../game/face';
import { Game } from '../game/game';
import { IGameOptions } from '../game/interfaces';
import { Nullable, TimerIntervalEvent } from '../game/types';
import { Style } from './enums';
import { IGameLevelDefinition } from './interfaces';
import { Dimension } from './types';

/**
 * The GameFactory class implements a factory for creating new games.
 */
class GameFactory {
	
	/**
	 * The factory method for creating a new game.
	 * @param gameLevelDefinition The game level definition.
	 * @param id The game id.
	 * @param onTimer The timer callback function for the game.
	 * @returns The new game.
	 * @static
	 */
	public static get = (
		gameLevelDefinition: IGameLevelDefinition,
		id: number,
		onTimer: Nullable<TimerIntervalEvent>,
		options: IGameOptions): Nullable<Game> => {
		
		let boardDefinition: Nullable<BoardDefinition> = null;

		switch (gameLevelDefinition.style) {
			case Style.Box:
			case Style.Cube:
				boardDefinition = GameFactory.getBoxBoardDefinition(
					gameLevelDefinition,
					onTimer);
				break;
			case Style.Pane:
				boardDefinition = GameFactory.getPaneBoardDefinition(
					gameLevelDefinition,
					onTimer);
				break;
			case Style.Classic:
				boardDefinition = GameFactory.getClassicBoardDefinition(
					gameLevelDefinition,
					onTimer);
				break;
		}

		return boardDefinition ? new Game(boardDefinition, id, options) : null;
	}

	/**
	 * Gets a board definition for a classic style game.
	 * @param gameLevelDefinition The game level definition.
	 * @param onTimer The timer callback function for the game.
	 * @returns The board definition.
	 * @private
	 * @static
	 */
	private static getClassicBoardDefinition = (
		gameLevelDefinition: IGameLevelDefinition,
		onTimer: Nullable<TimerIntervalEvent>): Nullable<BoardDefinition> => {
		
		let result: Nullable<BoardDefinition> = null;

		if (gameLevelDefinition.style === Style.Classic) {
			result = {
				onTimer: onTimer,
				mineCount: gameLevelDefinition.mines,
				hintLimit: gameLevelDefinition.hintLimit,
				faces: {
					front: {
						width: gameLevelDefinition.dimension.width,
						height: gameLevelDefinition.dimension.height,
						neighbors: {
						},
					},
				},
			};
		}

		return result;
	}

	/**
	 * Gets a board definition for a 'pane' style game.
	 * @param gameLevelDefinition The game level definition.
	 * @param onTimer The timer callback function for the game.
	 * @returns The board definition.
	 * @private
	 * @static
	 */
	private static getPaneBoardDefinition = (
		gameLevelDefinition: IGameLevelDefinition,
		onTimer: Nullable<TimerIntervalEvent>): Nullable<BoardDefinition> => {

		let result: Nullable<BoardDefinition> = null;

		if (gameLevelDefinition.style === Style.Pane) {
			// all the face names, enabled or not
			const faces: string[] = Object.keys(gameLevelDefinition.enabledFaces).map((key: string) => {
				return key;
			});

			result = {
				onTimer: onTimer,
				mineCount: gameLevelDefinition.mines,
				hintLimit: gameLevelDefinition.hintLimit,
				faces: {},
			};

			// all neightbors completely overlap
			const neighbor: NeighborDefinition = {
				own: {
					x: 0,
					y: 0,
				},
				neighbor: {
					x: 0,
					y: 0,
				},
				width: gameLevelDefinition.dimension.width,
				height: gameLevelDefinition.dimension.height,
			};

			for (let i = 0; i < faces.length; i++) {
				const key: string = faces[i];

				// add face if enabled
				if (gameLevelDefinition.enabledFaces[key]) {
					result.faces[key] = {
						width: gameLevelDefinition.dimension.width,
						height: gameLevelDefinition.dimension.height,
						neighbors: {},
					};

					// set previous as neighbor if enabled
					if (i > 0 && gameLevelDefinition.enabledFaces[faces[i - 1]]) {
						result.faces[key].neighbors[faces[i - 1]] = neighbor;
					}

					// set next as neighbor if enabled
					if (i < (faces.length - 1) && gameLevelDefinition.enabledFaces[faces[i + 1]]) {
						result.faces[key].neighbors[faces[i + 1]] = neighbor;
					}
				}
			}
		}

		return result;
	};

	/**
	 * Gets a board definition for a 'cube' or 'box' style game.
	 * @param gameLevelDefinition The game level definition.
	 * @param onTimer The timer callback function for the game.
	 * @returns The board definition.
	 * @private
	 * @static
	 */
	private static getBoxBoardDefinition = (
		gameLevelDefinition: IGameLevelDefinition,
		onTimer: Nullable<TimerIntervalEvent>): Nullable<BoardDefinition> => {
		
		let result: Nullable<BoardDefinition> = null;

		if (gameLevelDefinition.style === Style.Box || gameLevelDefinition.style === Style.Cube) {
			const dimension: Dimension = gameLevelDefinition.dimension;

			result = {
				onTimer: onTimer,
				mineCount: gameLevelDefinition.mines,
				hintLimit: gameLevelDefinition.hintLimit,
				faces: {
					front: {
						width: dimension.width,
						height: dimension.height,
						neighbors: {
							left: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.depth - 1,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							right: {
								own: {
									x: dimension.width - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							top: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: dimension.depth - 1,
								},
								width: dimension.width,
								height: 1,
							},
							bottom: {
								own: {
									x: 0,
									y: dimension.height - 1,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								width: dimension.width,
								height: 1,
							},
						},
					},
					back: {
						width: dimension.width,
						height: dimension.height,
						neighbors: {
							left: {
								own: {
									x: dimension.width - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							right: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.depth - 1,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							top: {
								own: {
									x: 0,
									y: dimension.height - 1,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateOwn: 2,
								width: dimension.width,
								height: 1,
							},
							bottom: {
								own: {
									x: 0,
									y: dimension.height - 1,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateNeighbor: 2,
								width: dimension.width,
								height: 1,
							},
						},
					},
					left: {
						width: dimension.depth,
						height: dimension.height,
						neighbors: {
							back: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.width - 1,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							front: {
								own: {
									x: dimension.depth - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							top: {
								own: {
									x: dimension.height - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateOwn: 1,
								width: 1,
								height: dimension.depth,
							},
							bottom: {
								own: {
									x: dimension.height - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateOwn: 3,
								width: 1,
								height: dimension.depth,
							},
						},
					},
					right: {
						width: dimension.depth,
						height: dimension.height,
						neighbors: {
							back: {
								own: {
									x: dimension.depth - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							front: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.width - 1,
									y: 0,
								},
								width: 1,
								height: dimension.height,
							},
							top: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.width - 1,
									y: 0,
								},
								rotateOwn: 3,
								width: 1,
								height: dimension.width,
							},
							bottom: {
								own: {
									x: dimension.height - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateOwn: 3,
								rotateNeighbor: 2,
								width: 1,
								height: dimension.depth,
							},
						},
					},
					top: {
						width: dimension.width,
						height: dimension.depth,
						neighbors: {
							front: {
								own: {
									x: 0,
									y: dimension.depth - 1,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								width: dimension.width,
								height: 1,
							},
							back: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: dimension.height - 1,
								},
								rotateNeighbor: 2,
								width: dimension.width,
								height: 1,
							},
							left: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.height - 1,
									y: 0,
								},
								rotateNeighbor: 1,
								width: 1,
								height: dimension.depth,
							},
							right: {
								own: {
									x: dimension.width - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateNeighbor: 3,
								width: 1,
								height: dimension.depth,
							},
						},
					},
					bottom: {
						width: dimension.width,
						height: dimension.depth,
						neighbors: {
							front: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: dimension.height - 1,
								},
								width: dimension.width,
								height: 1,
							},
							back: {
								own: {
									x: 0,
									y: dimension.depth - 1,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateNeighbor: 2,
								width: dimension.width,
								height: 1,
							},
							left: {
								own: {
									x: 0,
									y: 0,
								},
								neighbor: {
									x: dimension.height - 1,
									y: 0,
								},
								rotateNeighbor: 3,
								width: 1,
								height: dimension.depth,
							},
							right: {
								own: {
									x: dimension.width - 1,
									y: 0,
								},
								neighbor: {
									x: 0,
									y: 0,
								},
								rotateNeighbor: 1,
								width: 1,
								height: dimension.depth,
							},
						},
					},
				}
			}
		}

		return result;
	};
}

export { GameFactory, Game, Face, Cell, Move, MoveResult, GameStatus };

