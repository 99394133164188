import { AllowDiscovery, CellStatus, HintResult, MarkResult, UncoverResult } from './enums';
import { GameOptions } from './game-options';
import { IFlaggedMineCount } from './interfaces';
import { IDeserializedCell, ISerializedCell } from './serialization-interfaces';
import { Nullable } from './types';

/**
 * THe Cell class represents an individual cell on a game board face.
 * @export
 * @class Cell
 */
export class Cell {

	/**
	 * Gets the status.
	 * @readonly
	 */
	public get status(): CellStatus { return this._status; }

	/**
	 * Gets the neighbor mine count.
	 * @readonly
	 */
	public get neighborMineCount(): number { return this._neighborMineCount; }

	/**
	 * Gets the neigbors.
	 * @readonly
	 */
	public get neighbors(): ReadonlyArray<Cell> { return Array.from(this._neighbors); }

	/**
	 * Gets a Boolean value indicating whether the cell holds a mine.
	 * @readonly
	 */
	public get isMine(): boolean { return this._isMine; }

	/**
	 * Gets the id.
	 * @readonly
	 */
	public get id(): string { return this._id; }

	/**
	 * Gets the rank.
	 * @readonly
	 */
	public get rank(): number { return this._rank; }

	private readonly _options: GameOptions;
	private _status: CellStatus = CellStatus.Covered;
	private _neighborMineCount: number = 0;
	private _neighbors: Cell[] = [];
	private _isMine: boolean = false;
	private _id: string = '';
	private _rank: number = 0;
	private _flaggedNeighborMineCount: IFlaggedMineCount = {
		correct: 0,
		incorrect: 0,
	};

	/**
	 * Creates an instance of Cell.
	 * @param id The cell Id.
	 * @param rank The cell rank.
	 * @param isMine A Boolean value indicating whether the cells holds a mine.
	 * @param options The game options.
	 * @param deserializedCell State restored from serialization; optional.
	 * @memberof Cell
	 */
	public constructor(
		id: string,
		rank: number,
		isMine: boolean,
		options: GameOptions,
		deserializedCell: Nullable<IDeserializedCell> = null) {
		
		this._id = id;
		this._rank = rank;
		this._isMine = isMine;
		this._options = options;

		if (deserializedCell) {
			this._status = deserializedCell.status;
			this._flaggedNeighborMineCount = { ...deserializedCell.flaggedNeighborMineCount };
		}
	}
	
	/**
	 * Declare the cell as a mine.
	 * @returns A Boolean value indicating whether the operation was successful.
	 * @remarks Call this only once per game when the mines are distributed.
	 */
	public declareAsMine = (): boolean => {
		const result: boolean = !this._isMine;

		if (result) {
			this._isMine = true;
			this._neighbors.forEach((neighbor: Cell): void => {
				neighbor.addMineCount(1);
			})
		}

		return result;
	};

	/**
	 * Uncovers the cell.
	 * @returns The uncover result.
	 */
	public uncover = (): UncoverResult => {
		let result: UncoverResult = UncoverResult.Stop;

		switch (this.status) {
			case CellStatus.Covered:
				if (this._isMine) {
					result = UncoverResult.Boom;
				}
				else {
					result = this.neighborMineCount === 0 ? UncoverResult.Continue : UncoverResult.Stop;
				}

				this._status = CellStatus.Uncovered;
				break;
			case CellStatus.Uncovered:
				result = UncoverResult.DenyNotCovered;
				break;
			case CellStatus.Flagged:
			case CellStatus.FlaggedByHint:
			case CellStatus.Question:
				result = UncoverResult.DenyMarked;
				break;
			case CellStatus.UncoveredShowMine:
			case CellStatus.UncoveredShowMineFlaggedCorrectly:
			case CellStatus.UncoveredShowMineFlaggedIncorrectly:
				result = UncoverResult.DenyMineShown;
				break;
		}

		return result;
	};

	/**
	 * Marks or unmarks a cell as flagged or question mark.
	 * @returns The mark result.
	 */
	public mark = (): MarkResult => {
		let result: MarkResult = MarkResult.CellNotCoveredOrMarked;

		if (this._status !== CellStatus.Uncovered) {
			switch (this._status) {
				case CellStatus.Covered:
					if (this._options.get().enableFlags) {
						// currently not marked
						this._status = CellStatus.Flagged;
						result = this._isMine
							? MarkResult.FlaggedCorrectly
							: MarkResult.FlaggedIncorrectly;
					}
					else {
						result = MarkResult.FlagsNotEnabled;
					}
					break;
				case CellStatus.Flagged:
				case CellStatus.FlaggedByHint:
					// already flagged, mark as question
					this._status = this._options.get().enableQuestionMark ? CellStatus.Question : CellStatus.Covered;
					result = this._isMine
						? MarkResult.ClearedCorrectFlag
						: MarkResult.ClearedIncorrectFlag;
					break;
				case CellStatus.Question:
					// clear marking
					this._status = CellStatus.Covered;
					break;
			}
		}

		return result;
	};


	/**
	 * Provides a hint by flagging or uncovering the question marked cell.
	 * @returns The hint result.
	 * @memberof Cell
	 */
	public hint = (): HintResult => {
		let result = HintResult.DenyNotQuestionMarked;
		
		if (this._status === CellStatus.Question) {
			if (this._isMine) {
				this._status = CellStatus.FlaggedByHint;
				result = HintResult.Flagged;
			}
			else {
				this.mark();
				result = HintResult.ReadyToUncover;
			}
		}
		
		return result;
	};

	/**
	 * Updates the flagged neighbor count, correct or incorrect.
	 */
	public updateFlaggedNeighborMineCount = (flagged: IFlaggedMineCount): void => {
		const { correct, incorrect } = this._flaggedNeighborMineCount;

		this._flaggedNeighborMineCount = {
			correct: (correct || 0) + (flagged.correct || 0),
			incorrect: (incorrect || 0) + (flagged.incorrect || 0),
		};
	};

	/**
	 * Checks if 'discovery' is allowed.
	 * @returns The 'allow discovery' result.
	 * @memberof Cell
	 */
	public allowDiscovery = (): AllowDiscovery => {
		let result = AllowDiscovery.Allow;

		if (this._status !== CellStatus.Uncovered) {
			result = AllowDiscovery.DenyNotUncovered;
		}
		else {
			const flagged =
				(this._flaggedNeighborMineCount.correct || 0) +
				(this._flaggedNeighborMineCount.incorrect || 0);

			if (this._neighborMineCount !== flagged) {
				result = AllowDiscovery.DenyNonMatchingFlaggedCount;
			}
		}
		
		return result;
	};

	/**
	 * Updates the cell status to reveal correctly or incorrectly flagged mines.
	 */
	public updateStatusShowMine = (): void => {
		if (this._isMine) {
			switch (this._status) {
				case CellStatus.Flagged:
				case CellStatus.FlaggedByHint:
					this._status = CellStatus.UncoveredShowMineFlaggedCorrectly;
					break;
				case CellStatus.Uncovered:
					this._status = CellStatus.UncoveredShowMineHit;
					break;
				default:
					this._status = CellStatus.UncoveredShowMine;
					break;
			}
		}
		else {
			this._status = (this._status === CellStatus.Flagged || this._status === CellStatus.FlaggedByHint)
				? CellStatus.UncoveredShowMineFlaggedIncorrectly
				: this._status;
		}
	};

	/**
	 * Adds a neighbor.
	 * @param neighbor The neighbor cell.
	 */
	public addNeighbor = (neighbor: Cell): void => {
		if (neighbor && this._neighbors.indexOf(neighbor) < 0) {
			this._neighbors.push(neighbor);
			this.addMineCount(neighbor.isMine ? 1 : 0);
		}
	};

	/**
	 * Adds a number to the current neighbor mine count.
	 * @param count The number to add.
	 * @protected
	 */
	protected addMineCount = (count: number): void => {
		this._neighborMineCount += count;
	};

	/**
	 * Serializes the cell into an object.
	 * @returns The serialized cell.
	 */
	public serialize = (): ISerializedCell => {
		const result: ISerializedCell = {
			id: this._id,
			status: this._status,
			isMine: this._isMine,
			rank: this._rank,
			neighborMineCount: this._neighborMineCount,
			flaggedNeighborMineCount: { ...this._flaggedNeighborMineCount },
			neighborCellIds: this._neighbors.map((cell: Cell) => cell.id),
		};

		return result;
	};

	/**
	 * Creates a cell instance from a serialized cell.
	 * @param serializedCell The serialized cell.
	 * @returns The cell.
	 * @static
	 */
	public static createFromSerialization = (
		serializedCell: ISerializedCell,
		options: GameOptions): Cell => {

		const cell: Cell = new Cell(
			serializedCell.id,
			serializedCell.rank,
			serializedCell.isMine,
			options,
			{
				status: serializedCell.status,
				flaggedNeighborMineCount: serializedCell.flaggedNeighborMineCount,
			});
		

		// this does not initialize the neighbors or the neighbor mine count
		// that must be done by calling addNeighbor() after all cells have been deserialized.
		
		return cell;
	};
}