import { Style } from '../../../game-to-app/enums';
import { IGameLevelDefinition } from '../../../game-to-app/interfaces';
import { Nullable } from '../../../shared/types';
import { ICellSize } from '../shared/interfaces';

/**
 * The CellSize class provides functions to calculate the size of a cell based on available space.
 * @export
 * @class CellSize
 */
export class CellSize {
	/**
	 * The min cell size.
	 * @private
	 * @static
	 */
	private static _minSize: number = 30;

	/**
	 * The max cell size.
	 * @private
	 * @static
	 */
	private static _maxSize: number = 50;

	/**
	 * Gets the cell size.
	 * @param width The available width.
	 * @param height The available height.
	 * @param gameLevelDefinition The level definition.
	 * @returns The cell size.
	 * @static
	 * @memberof CellSize
	 */
	public static get = (
		width: number,
		height: number,
		gameLevelDefinition: IGameLevelDefinition): Nullable<ICellSize> => {
		
		const idealFrameSize = gameLevelDefinition.style === Style.Classic ? 30 : 0;
		let requestedFrameSize = 0;
		let factor: number = 1.0;
		let size: number = 0;
		let done: boolean = false;
		let result: Nullable<ICellSize> = null;

		while (!done) {
			switch (gameLevelDefinition.style) {
				case Style.Box:
				case Style.Cube:
					factor = 0.8;
					break;
				case Style.Pane:
					factor = 1.1;
					break;
				case Style.Classic:
					factor = 1.0;
					break;
			}

			if (gameLevelDefinition.style === Style.Classic) {
				// check width and height separately, save some space for a frame
				const frameSize: number = requestedFrameSize;

				size = Math.min(
					gameLevelDefinition.dimension.width > 0 ? (width - frameSize) / gameLevelDefinition.dimension.width : 0,
					gameLevelDefinition.dimension.height > 0 ? (height - frameSize) / gameLevelDefinition.dimension.height : 0);
			}
			else {
				const dimRequired = Math.max(gameLevelDefinition.dimension.width, gameLevelDefinition.dimension.height, gameLevelDefinition.dimension.depth) / factor;
				const dimAvailable = Math.min(width, height);

				size = dimRequired > 0 ? dimAvailable / dimRequired : 0;
			}

			// round down and apply max size
			size = Math.min(Math.floor(size), CellSize._maxSize);

			// the name determines the font size
			const name: Nullable<string> = CellSize.getName(size);

			result = (
				size >= CellSize._minSize && name
					? {
						width: size,
						height: size,
						name: name,
					}
					: null);
			
			done = true;

			// for the classic style, leave some room for a frame
			if (gameLevelDefinition.style === Style.Classic && result !== null && requestedFrameSize === 0) {
				if (width - (result.width * gameLevelDefinition.dimension.width) < idealFrameSize ||
					height - (result.height * gameLevelDefinition.dimension.height) < idealFrameSize) {
					requestedFrameSize = idealFrameSize;
					done = false;
				}
			}
		}

		if (result === null) {
			const minSizeName: Nullable<string> = CellSize.getName(CellSize._minSize);

			result = {
				width: CellSize._minSize,
				height: CellSize._minSize,
				name: minSizeName || '',
			};
		}

		return result;
	};

	/**
	 * Gets the best fit name for a given size.
	 * @remarks The name will be used to find a matching css class that defines the font size.
	 * @private
	 * @static
	 */
	private static getName = (size: number): Nullable<string> => {
		const ranges = [
			{
				min: 10,
				name: 'xxxs',
			},
			{
				min: 15,
				name: 'xxs',
			},
			{
				min: 20,
				name: 'xs',
			},
			{
				min: 25,
				name: 'sm',
			},
			{
				min: 30,
				name: 'md',
			},
			{
				min: 35,
				name: 'lg',
			},
			{
				min: 40,
				name: 'xl',
			},
			{
				min: 45,
				name: 'xxl',
			},
		];

		const options = ranges.filter((value) => {
			return value.min <= size;
		});

		return options.length > 0 ? options[options.length - 1].name : null;
	};
}