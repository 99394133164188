
import { IDBPDatabase } from 'idb';
import { Levels, Style } from '../../../../../../game-to-app/enums';
import { GameLevels } from '../../../../../../game-to-app/game-levels';
import { ILevelDefinition } from '../../../../../../game-to-app/interfaces';
import { Nullable } from '../../../../../../shared/types';
import { DefaultMetadata } from '../../../default-metadata';
import { ModelNames } from '../../../enums';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager } from '../../../model-interfaces';
import { CacheManager } from './cache-manager';
import { IModelUserPreferenceValues } from './model';
import { UpgradeManager } from './upgrade-manager';

/**
 * The Metadata class defines the model metadata.
 */
export class Metadata extends DefaultMetadata<IModelUserPreferenceValues> implements IModelMetadata<IModelUserPreferenceValues> {
	protected _name: string = ModelNames.UserPreferences;
	protected _schemaVersion: number = 6;
	protected _defaultGameLevel = GameLevels.get(Style.Classic, Levels.Easy) as ILevelDefinition;
	protected _defaults: IModelUserPreferenceValues[] = [
		{
			...{
				id: this.name,
				notifications: {
					quickStart: true,
					zoomMode: true,
				},
				level: {
					style: this._defaultGameLevel.style,
					level: this._defaultGameLevel.level,
					id: this._defaultGameLevel.id,
				},
				review: {
					installDate: null,
					promptDate: null,
					postponePromptUntil: null,
					sessions: [],
				},
			},
			...this.systemValues,
		},
	];

	/**
	 * Initializes the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	protected initializeUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		return new UpgradeManager(database, this);
	};

	/**
	 * Initializes the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	protected initializeCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		return new CacheManager(database, this);
	};
}