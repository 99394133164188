import { Dimension } from '../../../game-to-app/types';
import { IBoardShape, IBoardSize, ISize } from '../shared/interfaces';

/**
 * The BoardSize class provides functions to calculate the size of the board.
 * @export
 * @class BoardSize
 */
export class BoardSize {
	/**
	 * Calculates the size of the board.
	 * @param shape The shape.
	 * @param client The client size.
	 * @param hasFrame A Boolean value indicating whether the shape has a frame.
	 * @returns The board size.
	 * @static
	 */
	public static get = (shape: IBoardShape, client: ISize, hasFrame: boolean): IBoardSize => {
		const size: Dimension = shape.getSize();
		const top: number = Math.floor((client.height - size.height) / 2);
		const left: number = Math.floor((client.width - size.width) / 2);

		const shapeFrameTotalBorderWidth = 2 * 4;
		let shapeFrameWidthExclusiveBorder: number = 0;
		let borderWidth: number = 0;

		if (hasFrame &&
			((top >= shapeFrameTotalBorderWidth + 2 &&
			left >= shapeFrameTotalBorderWidth + 2) ||
			top < 0 ||
			left < 0)) {

			// if no space available, then take the full width, since it will be scrollable anyway
			let available: number = 0; // Math.max(top, left) <= 0 ? 30 : Math.min(top, left);

			// both positions are negative, area will be scrollable horizontally and vertically
			// use max border width
			if (top < 0 && left < 0) {
				available = 30;
			}

			// one of the positions is negative, the other is not
			// only one direction will be scrollable
			// use the positive border size
			if ((top >= 0 && left < 0) || (top < 0 && left >= 0)) {
				available = Math.max(top, left);
			}

			// both positions are positive, area will not be scrollable
			// use the minimum as border size
			if (top >= 0 && left >= 0) {
				available = Math.min(top, left);
			}

			shapeFrameWidthExclusiveBorder = Math.min(30, available);
			shapeFrameWidthExclusiveBorder = shapeFrameWidthExclusiveBorder % 2 === 0
				? shapeFrameWidthExclusiveBorder
				: Math.max(shapeFrameWidthExclusiveBorder - 1, 0);

			borderWidth = shapeFrameWidthExclusiveBorder > 0 ? 2 : 0;
		}

		const calcShapeFrameStyleTop: number =
			(Math.floor((client.height - size.height) / 2)) - (2 * borderWidth) - (shapeFrameWidthExclusiveBorder / 2);
		const calcShapeFrameStyleLeft: number =
			(Math.floor((client.width - size.width) / 2)) - (2 * borderWidth) - (shapeFrameWidthExclusiveBorder / 2);

		const result: IBoardSize = ({
			frame: {
				top: Math.max(calcShapeFrameStyleTop, 0),
				left: Math.max(calcShapeFrameStyleLeft, 0),
				width: size.width + 2 * (2 * borderWidth) + shapeFrameWidthExclusiveBorder,
				height: size.height + 2 * (2 * borderWidth) + shapeFrameWidthExclusiveBorder,
				borderWidth: borderWidth,
			},
			shape: {
				top: shapeFrameWidthExclusiveBorder / 2,
				left: shapeFrameWidthExclusiveBorder / 2,
				width: size.width + (borderWidth * 2),
				height: size.height + (borderWidth * 2),
				borderWidth: borderWidth,
			}
		});

		return result;
	}
}