
import { IDBPDatabase } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { DefaultMetadata } from '../../../default-metadata';
import { ModelNames } from '../../../enums';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager } from '../../../model-interfaces';
import { CacheManager } from './cache-manager';
import { IModelSettingsValues } from './model';
import { UpgradeManager } from './upgrade-manager';

/**
 * The Metadata class defines the settings model metadata.
 */
export class Metadata extends DefaultMetadata<IModelSettingsValues> implements IModelMetadata<IModelSettingsValues> {
	protected _name: string = ModelNames.Settings;
	protected _schemaVersion: number = 4;
	protected _defaults: IModelSettingsValues[] = [
		{
			...{
				id: this.name,
				game: {
					enableQuestionMark: true,
					enableAutoSave: true,
				},
				solver: {
					timeout: 500,
					enableDiscovery: true,
					autoPlayer: {
						flagCells: true,
					},
				},
				sound: {
					volume: {
						muted: false,
						volume: 0.5,
					},
					sfx: {},
				}
			},
			...this.systemValues,
		}
	];

	/**
	 * Initializes the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	protected initializeUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		return new UpgradeManager(database, this);
	};

	/**
	 * Initializes the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	protected initializeCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		return new CacheManager(database, this);
	};
}