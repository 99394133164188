import { EnabledBox, EnabledClassic, EnabledPaneFour, EnabledPaneThree } from '../../../game-to-app/constants';
import { Levels, Orientation, Style } from '../../../game-to-app/enums';
import { GameLevels } from '../../../game-to-app/game-levels';
import { IGameLevelDefinition, ILevelDefinition } from '../../../game-to-app/interfaces';
import { LevelDefinitionManager } from '../../../game-to-app/level-definition-manager';
import { Nullable } from '../../../shared/types';
import { Database } from '../../shared/storage/database';
import { ILevel } from '../../shared/storage/views/levels';

export class LevelManager {
	private static _levels: Record<string, ILevel>;

	/**
	 * Gets the game level definition for the given style and level.
	 * @returns The game level definition.
	 * @static
	 */
	public static getGameLevelDefinition = (
		style: Style,
		level: Levels,
		id: Nullable<string>,
		orientation: Orientation): IGameLevelDefinition => {

		let levelDefinition: Nullable<ILevelDefinition> = null;

		if (level === Levels.Custom) {
			levelDefinition = LevelManager
				.getCustomForStyle(style)
				.filter((levelDefinition: ILevelDefinition): boolean => levelDefinition.id === id)[0];
		}
		else {
			levelDefinition = GameLevels.get(style, level);
		}

		levelDefinition = levelDefinition || GameLevels.get(Style.Classic, Levels.Easy) as ILevelDefinition;

		return new LevelDefinitionManager(levelDefinition).getGameDefinition(orientation);
	};

	/**
	 * An event handler for when levels change.
	 * @param name The levels model name.
	 * @param settings The changed levels.
	 * @static
	 */
	public static onLevelsChange = (name: string, levels: Record<string, ILevel>): void => {
		LevelManager._levels = levels;
	};

	/**
	 * Gets a string identifier for the level.
	 * @param gameLevelDefinition The game level definition.
	 * @param assistantEnabled A Boolean value indicating if the assistant is enabled.
	 * @returns The game level identifier.
	 * @static
	 * @memberof LevelManager
	 */
	public static getGameLevelIdentifier = (
		gameLevelDefinition: IGameLevelDefinition,
		assistantEnabled: boolean): string => {
		
		const dimensionOne: number = Math.max(
			gameLevelDefinition.dimension.width,
			gameLevelDefinition.dimension.height);
		const dimensionTwo: number = Math.min(
			gameLevelDefinition.dimension.width,
			gameLevelDefinition.dimension.height);
		
		return (
			`${gameLevelDefinition.level}-` +
			`${gameLevelDefinition.style}-` +
			`${dimensionOne}-` +
			`${dimensionTwo}-` +
			`${gameLevelDefinition.dimension.depth}-` +
			`${gameLevelDefinition.mines}-` +
			`${gameLevelDefinition.hintLimit}-` +
			`${gameLevelDefinition.enableFlags ? 1 : 0}-` +
			`${assistantEnabled ? 1 : 0}`
		);
	};

	/**
	 * Gets the level definitions for the given style.
	 * @param style The style.
	 * @returns The leveldefinitions for the style.
	 * @static
	 */
	public static getLevelDefinitionsForStyle = (style: Style): ILevelDefinition[] => {
		return GameLevels.getForStyle(style).concat(LevelManager.getCustomForStyle(style));
	};

	/**
	 * Gets the level definitions for the given style and level.
	 * @param style The style.
	 * @param level The level.
	 * @returns The level definitions for the style and level.
	 * @static
	 */
	public static getLevelDefinitionsForLevel = (style: Style, level: Levels): ILevelDefinition[] => {
		const levelDefinition: Nullable<ILevelDefinition> = GameLevels.get(style, level);
		const result = (levelDefinition ? [levelDefinition] : []).concat(LevelManager.getCustomForStyle(style));

		return result;
	};

	/**
	 * Gets the custom level definition for the given style and id.
	 * @param style The style.
	 * @returns The custom level definition; otherwise, null.
	 * @private
	 * @static
	 */
	private static getCustomForStyle = (style: Style): ILevelDefinition[] => {
		let result: ILevelDefinition[] = [];

		LevelManager._levels = LevelManager._levels || Database.levels.get();

		if (LevelManager._levels) {
			const keys: string[] = Object.keys(LevelManager._levels);

			keys.forEach((key: string): void => {
				const level: ILevel = LevelManager._levels[key];

				if (level.style === style) {
					result.push(LevelManager.levelToLevelDefinition(level));
				}
			});
		}

		// sort by name
		result = result.sort((a: ILevelDefinition, b: ILevelDefinition) =>
			a.name?.toLocaleUpperCase() < b.name?.toLocaleUpperCase()
				? -1
				: (a.name?.toLocaleUpperCase() === b.name?.toLocaleUpperCase() ? 0 : 1));
		
		return result;
	}

	/**
	 * Converts a level to a complete level definition.
	 * @param level The level.
	 * @returns The level definition.
	 * @private
	 * @static
	 */
	private static levelToLevelDefinition = (level: ILevel): ILevelDefinition => {
		let enabledFaces: Nullable<Record<string, boolean>> = null;

		switch (level.style) {
			case Style.Cube:
			case Style.Box:
				enabledFaces = EnabledBox;
				break;
			case Style.Pane:
				enabledFaces = level.dimensions.depth <= 3
					? EnabledPaneThree
					: EnabledPaneFour;
				break;
			case Style.Classic:
				enabledFaces = EnabledClassic;
				break;
		}

		return {
			id: level.id,
			style: level.style,
			level: level.level,
			name: level.name,
			dimensions: GameLevels.getOrientationDimension(
				level.dimensions.width,
				level.dimensions.height,
				level.dimensions.depth,
			),
			mines: level.mines,
			enabledFaces: enabledFaces,
			zeroDimension: 0.3,
			hintLimit: level.hintLimit,
			enableFlags: level.enableFlags,
		};
	};
}