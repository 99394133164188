/**
 * The BoolArray class implements an array of boolean values that can be incremented like a bit representation of a number.
 * Use this class to work around the bit length limitations of numbers.
 * @export
 * @class BoolArray
 */
export class BoolArray {
	private _length: number;
	private _array: boolean[] = [];
	private _trueCount: number = 0;

	/**
	 * Gets the length of the array.
	 * @readonly
	 */
	public get length(): number { return this._length; }

	/**
	 * Gets the number of 'true' elements.
	 * @readonly
	 */
	public get trueCount(): number { return this._trueCount; }

	/**
	 * Gets the number of 'false' elements.
	 * @readonly
	 */
	public get falseCount(): number { return this._length - this._trueCount; }

	/**
	 * Creates an instance of BoolArray.
	 * @param {number} length The array length.
	 * @memberof BoolArray
	 */
	constructor(length: number) {
		this._length = length;
		this.reset();
	}

	/**
	 * Resets all array values to 'false'.
	 */
	public reset = (): void => {
		this._array = [];

		for (let i: number = 0; i < this._length; i++) {
			this._array.push(false);
		}
	};

	/**
	 * Gets the value at a given index.
	 * @param The index.
	 * @returns The value at the index.
	 */
	public get = (index: number): boolean => {
		return this._array[index];
	};

	/**
	 * Sets the value at a given index.
	 * @param index The index.
	 * @param value The value.
	 * @returns The BoolArray instance.
	 */
	public set = (index: number, value: boolean): BoolArray => {
		this._trueCount += this._array[index] !== value ? (value ? 1 : -1) : 0;
		this._array[index] = value;

		return this;
	};

	/**
	 * Increments the value of the array 'bitwise'.
	 * @returns The instance of the BoolArray.
	 */
	public next = (): BoolArray => {
		let i: number = this._length - 1; 
		let done: boolean = false;

		while (!done && i >= 0) {
			done = !this._array[i];
			this._array[i] = done;
			this._trueCount += done ? 1 : -1;
			i--;
		}

		return this;
	};

	/**
	 * Gets the current state of the array.
	 * @returns The current state.
	 */
	public current = (): boolean[] => {
		return { ...this._array };
	};

	/**
	 * Gets the current state of the array as numeric values.
	 * @returns The current state as ones and zeros.
	 */
	public currentNumeric = (): number[] => {
		return this._array.map((value: boolean): number => value ? 1 : 0);
	};

	/**
	 * Gets a string representation of the array.
	 * @returns A string representation of the array.
	 */
	public toString = (): string => {
		return this._array.map((value: boolean) => value ? '1' : '0').join('');
	};
}