import { IFooter } from "./interfaces";

/**
 * The Constants class defines constant values.
 * @export
 * @class Constants
 */
export class Constants {
	/**
	 * The max number of seconds that will show on the scoreboard.
	 * @static
	 */
	public static maxSeconds: number = 9999 * 60;

	/**
	 * The max number of mines that will show on the scoreboard.
	 * @static
	 */
	public static maxMines: number = 9999;

	/**
	 * The max number of wins or loses that will show on the scoreboard.
	 * @static
	 */
	public static maxWinLose = 9999;

	/**
	 * The minimum space available on left/right and top/bottom to not automatically show the directional pad.
	 * @static
	 */
	public static minTouchSpace = 100;

	/**
	 * The max zoom factor.
	 * @static
	 */
	public static maxZoomFactor = 4;

	/**
	 * The zoom component id.
	 * @static
	 */
	public static zoomComponentId = 'zoom';

	/**
	 * Auto-save every nth move.
	 * @static
	 */
	public static autoSaveMoveInterval = 0;

	/**
	 * The duration for highlighting neighbor cells in milliseconds.
	 * @static
	 */
	public static hoverDuration = 500;

	/**
	 * The duration for showing the game over notification.
	 * @static
	 */
	public static gameOverNotificationDuration = {
		default: 30000,
		review: 90000,
	};

	/**
	 * The default footer.
	 */
	public static defaultFooter: IFooter = {
		showContact: true,
		showCopyright: true,
		showPrivacy: true,
		showTerms: true,
	};

	/**
	 * The minimal footer.
	 */
	public static minimalFooter: IFooter = {
		showContact: false,
		showCopyright: true,
		showPrivacy: false,
		showTerms: false,
	};

}