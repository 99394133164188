import * as Game from '../../../../game-to-app/app';
import { CellStatus } from '../../../../game-to-app/enums';
import * as GameTypes from '../../../../game-to-app/types';
import { Nullable } from '../../../../shared/types';
import { SolutionStatus } from '../../../../solver-to-app/enums';
import { IAutoPlayerMove, IAutoPlayerParms, IFaceDefinition } from '../../shared/interfaces';
import { ISolverDataCell, ISolverDataFace } from '../../shared/interfaces-solver-data';
import { AutoPlayerMoveResult } from './enums';
import { IAutoPlayerMoveCandidate, IAutoPlayerOptions } from './interfaces';

/**
 * The AutoPlayer class implements an automated player for the game.
 * @export
 * @class AutoPlayer
 */
export class AutoPlayer {
	// default options
	private static _defaultOptions: IAutoPlayerOptions = {
		flagCells: true,
	};

	private _parms: IAutoPlayerParms;
	private _options: IAutoPlayerOptions;

	/**
	 * Gets the default auto player options.
	 * @readonly
	 */
	public static get defaultOptions(): IAutoPlayerOptions { return AutoPlayer._defaultOptions; }

	/**
	 * Creates an instance of AutoPlayer.
	 * @param parms The auto-player parameters.
	 * @param options The auto-player options.
	 * @memberof AutoPlayer
	 */
	constructor(parms: IAutoPlayerParms, options: IAutoPlayerOptions = AutoPlayer._defaultOptions) {
		this._parms = parms;

		// game options
		const gameFlagCellsEnabled: boolean = this._parms.game?.getOptions().enableFlags || false;
		
		// auto player options
		this._options = {
			...options,
			flagCells: gameFlagCellsEnabled && options.flagCells,
		};
	}

	/**
	 * Makes an auto-move.
	 * @returns The move.
	 */
	public move = (): IAutoPlayerMove => {
		// assume no move can be made
		let result: IAutoPlayerMove = {
			result: AutoPlayerMoveResult.NoSafeMoveFound,
			cellId: null,
			status: null,
		};

		if (this._parms.game) {
			// new game
			if (this._parms.game.status === Game.GameStatus.New) {
				const faceName = this._parms.boardShape?.getInitialFace() || null;
				const cellId: Nullable<string> = this.getFirstMoveCandidate(faceName);

				// make the move
				result = {
					result: AutoPlayerMoveResult.Moved,
					cellId: cellId,
					status: SolutionStatus.Clear,
				};
			}
			else {
				if (this.isGameOver()) {
					// there was a previous move and the game is not over
					// set result
					result = {
						result: AutoPlayerMoveResult.GameOver,
						cellId: null,
						status: null,
					};
				}
				else {
					if (this._parms.move &&
						this._parms.move.cellId &&
						this._parms.solution) {

						// the cell from the previous move
						const previousCell: Nullable<Game.Cell> =
							this._parms.game.getCell(this._parms.move.cellId);
					
						const previousCellId: Nullable<GameTypes.CellId> =
							this._parms.game.getCellId(this._parms.move.cellId);
					
						const previousFaceName: Nullable<string> = previousCellId?.face || null;
					
						// get the candidate closest to the previous move
						const candidate: Nullable<IAutoPlayerMoveCandidate> = this.getClosestCandidate(
							previousFaceName,
							previousCell);

						if (candidate) {
							// set result
							result = {
								result: AutoPlayerMoveResult.Moved,
								cellId: candidate.cell.id,
								status: candidate.status,
							};
						}
					}
				}
			}
		}

		return result;
	};

	/**
	 * Gets a random candidate for the first move from the given face.
	 * @param faceName The face name.
	 * @returns The candidate cell Id.
	 * @private
	 */
	private getFirstMoveCandidate = (faceName: Nullable<string>): Nullable<string> => {	
		const face: Nullable<IFaceDefinition> = this._parms.boardShape?.getFace(faceName) || null;

		return face
			? `${faceName}-${this.getRandom(face.cells.count.height)}-${this.getRandom(face.cells.count.width)}`
			: null;
	};

	/**
	 * Gets the closest candidate for a given cell.
	 * @param preferredFace The preferred face for the candidate.
	 * @param cell The cell.
	 * @returns The closest candidate; otherwise, null.
	 * @private
	 * @memberof AutoPlayer
	 */
	private getClosestCandidate = (
		preferredFace: Nullable<string>,
		cell: Nullable<Game.Cell>): Nullable<IAutoPlayerMoveCandidate> => {

		const status: SolutionStatus[] = this.getEnabledSolutionStatus();
		let result: Nullable<IAutoPlayerMoveCandidate> = null;

		if (preferredFace && cell) {
			const faceCount = this._parms.boardShape?.getFaceCount() || 0;
			let faceName: Nullable<string> = preferredFace;
			let face: Nullable<IFaceDefinition> = this._parms.boardShape?.getFace(faceName) || null;		
			
			if (face) {
				let candidate: Nullable<Game.Cell> = null;
				let closeTo: Nullable<Game.Cell> = cell;
				let index: number = face.index;
				let noCandidateFaces: number = 0;

				while (!result && face && noCandidateFaces <= faceCount) {
					let statusIndex: number = 0;

					while (!result && face && statusIndex < status.length) {
						const candidates: string[] = this.getFaceCandidates(faceName, status[statusIndex]);

						// find the closest
						candidate = this.findClosestCandidate(face, closeTo, candidates);
					
						result = candidate
							? {
								cell: candidate,
								status: status[statusIndex],
							}
							: null;

						statusIndex += 1;
					}

					if (!result) {
						// keep track of how many faces didn't yield any candidates
						noCandidateFaces += 1;

						// next face
						index = index + 1 < faceCount ? index + 1 : 0;
						faceName = this._parms.boardShape?.getFaceNameByIndex(index) || null;
						face = this._parms.boardShape?.getFace(faceName) || null;

						if (face) {
							const center: string =
								`${faceName}-${Math.floor(face.cells.count.height / 2)}-${Math.floor(face.cells.count.width / 2)}`;

							closeTo = this._parms.game?.getCell(center) || null;
						}
					}
				}
			}
		}

		return result;
	};

	/**
	 * Finds the closest candidate to the gicen cell.
	 * @param face The face.
	 * @param cell The cell.
	 * @param candidateCellIds The candidate cell ids.
	 * @returns The closest candidate; otherwise, null.
	 * @private
	 */
	private findClosestCandidate = (
		face: Nullable<IFaceDefinition>,
		cell: Nullable<Game.Cell>,
		candidateCellIds: string[]): Nullable<Game.Cell> => {
		
		let result: Nullable<Game.Cell> = null;

		if (face && cell) {
			const startId: Nullable<GameTypes.CellId> = this._parms.game?.getCellId(cell.id) || null;

			if (startId) {
				let minDistance: number = Number.MAX_SAFE_INTEGER;
				let minDistanceCellId: Nullable<string> = null;

				candidateCellIds.forEach((cellId: string): void => {
					const endId: Nullable<GameTypes.CellId> = this._parms.game?.getCellId(cellId) || null;

					if (endId) {
						// Manhattan distance
						const distance = Math.abs(startId.y - endId.y) + Math.abs(startId.x - endId.x);

						if (distance < minDistance) {
							minDistance = distance;
							minDistanceCellId = cellId;
						}
					}
				});

				if (minDistanceCellId) {
					result = this._parms.game?.getCell(minDistanceCellId) || null;
				}
			}
		}

		return result;
	};

	/**
	 * Determines if the current game is over.
	 * @returns true if the game is over; otherwise, false.
	 * @private
	 */
	private isGameOver = (): boolean => {
		return (
			this._parms.move?.result?.result === Game.MoveResult.Won ||
			this._parms.move?.result?.result === Game.MoveResult.Lost ||
			this._parms.move?.result?.result === Game.MoveResult.EndedNoPlay
		);
	};

	/**
	 * Gets the candidates for a given face name.
	 * @param faceName The face name.
	 * @param status The candidate solution status.
	 * @returns The cell ids of candidates.
	 * @private
	 */
	private getFaceCandidates = (faceName: Nullable<string>, status: SolutionStatus): string[] => {
		let result: string[] = [];

		if (faceName) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const solverDataFace = (this._parms.solution as any)[faceName] as Nullable<ISolverDataFace>;

			if (solverDataFace) {
				// the highest priority: discover cells
				result = solverDataFace.cells
					.keys()
					.filter((key: string): boolean =>
						// candidate must be 'discover', 'mine' or 'clear', no guessing
						(solverDataFace.cells.get(key) as ISolverDataCell).status === status &&
						(status !== SolutionStatus.Clear ||
							// the actual cell status must be 'covered' or 'question mark' 
							// the solver sees question marks and incorrect flags as 'covered'
							(
								this._parms.game?.getCell(key)?.status === CellStatus.Covered ||
								this._parms.game?.getCell(key)?.status === CellStatus.Question
							)
						)
					);
			}
		}

		return result;
	};

	/**
	 * Gets the enabled solutions status array.
	 * @returns The enabled solution status array.
	 * @private
	 */
	private getEnabledSolutionStatus = (): SolutionStatus[] => {
		return this._options.flagCells
			? [SolutionStatus.Discover, SolutionStatus.Mine, SolutionStatus.Clear]
			: [SolutionStatus.Clear];
	};
	
	/**
	 * Returns a random number between 0 and the max value (max excluded).
	 * @private
	 * @param max The max value.
	 * @returns A random number in the range.
	 */
	private getRandom = (max: number): number => {	
		return Math.floor(Math.random() * Math.floor(max));
	};
}