/**
 * The Matrix class implements a matrix to be used for Gauss - Jordan elimintation.
 *
 * @export
 * @class Matrix
 */
export class Matrix {
	private _names: string[] = [];
	private _matrix: number[][] = [];

	/**
	 * Gets the matrix row count.
	 * @readonly
	 */
	public get rowCount(): number { return this._matrix.length; } 

	/**
	 * Gets the column count.
	 * @readonly
	 */
	public get columnCount(): number { return this._names.length + 1; }

	/**
	 *Creates an instance of Matrix.
	 * @param matrix The matrix, consisting of coefficients and constants.
	 * @param names The column names.
	 * @memberof Matrix
	 */
	constructor(matrix: number[][], names: string[]) {
		this._matrix = matrix;
		this._names = names;
	}

	/**
	 * Gets a row of the matrix.
	 * @param row The row index.
	 * @returns The row.
	 */
	public row = (row: number): number[] => {
		return this._matrix[row];
	};

	/**
	 * Gets a column of the matrix.
	 * @param column The column index.
	 * @returns The column.
	 */
	public column = (column: number): number[] => {
		return this._matrix.map((row: number[]): number => row[column]);
	};

	/**
	 * Gets a value from the matrix.
	 * @param row The row index.
	 * @param column The column index.
	 * @returns The value.
	 */
	public value = (row: number, column: number): number => {
		return this._matrix[row][column];
	};

	/**
	 * Gets the matrix coefficients.
	 * @returns The matrix coefficients.
	 */
	public coefficients = (): number[][] => {
		return this._matrix.map((row: number[]) => row.slice(0, row.length - 1));
	};

	/**
	 * Gets the matrix constants.
	 * @returns The matrix constants.
	 */
	public constants = (): number[] => {
		return this.column(this.columnCount - 1);
	};

	/**
	 * Gets the matrix column names.
	 * @returns The matrix column names.
	 */
	public names = (): string[] => {
		return [...this._names];
	};

	/**
	 * Swaps two rows in the matrix.
	 * @param rowOne The first row index.
	 * @param rowTwo The second row index.
	 */
	public swapRows = (rowOne: number, rowTwo: number): void => {
		const temp: number[] = this._matrix[rowOne];
		this._matrix[rowOne] = this._matrix[rowTwo];
		this._matrix[rowTwo] = temp;
	};

	/**
	 * Multiplies a row with by a given factor.
	 * @param row The row index.
	 * @param factor The factor.
	 */
	public multiplyRow = (row: number, factor: number): void => {
		this._matrix[row] = this._matrix[row].map((value: number) => value * factor);
	};

	/**
	 * Divides a row by a given divisor.
	 * @param rwo The row index.
	 * @param divisor The divisor.
	 */
	public divideRow = (row: number, divisor: number): void => {
		if (divisor === 0) {
			throw new Error(`division by zero at row ${row}`);
		}

		this._matrix[row] = this._matrix[row].map((value: number) => value / divisor);
	};

	/**
	 * Adds a matrix row to another after multiplying it with a given factor.
	 * @param row The row index.
	 * @param toRow The row index to add the row to.
	 * @param rowFactor The factor to multiple the source row with.
	 */
	public addRow = (row: number, toRow: number, rowFactor: number): void => {
		this._matrix[toRow] = this._matrix[toRow].map((value: number, index: number) =>
			value + (rowFactor * this.value(row, index))
		);
	};

	/**
	 * Substracts a matrix row from another after multiplying it with a given factor.
	 * @param row The row index.
	 * @param fromRow The row index to substract the row from.
	 * @param rowFactor The factor to multiple the source row with.
	 */
	public substractRow = (row: number, fromRow: number, rowFactor: number): void => {
		this._matrix[fromRow] = this._matrix[fromRow].map((value: number, index: number) =>
			value - (rowFactor * this.value(row, index))
		);
	};

	/**
	 * Transforms the matrix into reduced row echelon form.
	 */
	public toReducedRowEchelonForm = (): void => {
		const rowCount: number = this.rowCount;
		const columnCount: number = this.columnCount;
		let done: boolean = columnCount <= 0 || rowCount <= 0;
		let pivotColumnIndex: number = 0;
		let rowIndex: number = 0;

		while (!done && rowIndex < rowCount) {
			let pivotRowIndex = rowIndex;

			while (!done && this.value(pivotRowIndex, pivotColumnIndex) === 0) {
				pivotRowIndex += 1;
				if (rowCount === pivotRowIndex) {
					pivotRowIndex = rowIndex;
					pivotColumnIndex += 1;
					done = columnCount === pivotColumnIndex;
				}
			}

			if (!done) {
				if (pivotRowIndex !== rowIndex) {
					this.swapRows(pivotRowIndex, rowIndex);
				}

				this.divideRow(rowIndex, this.value(rowIndex, pivotColumnIndex));

				for (let j = 0; j < rowCount; j++) {
					if (j !== rowIndex) {
						this.substractRow(rowIndex, j, this.value(j, pivotColumnIndex));
					}
				}

				rowIndex += 1;
				pivotColumnIndex += 1;
				done = columnCount <= pivotColumnIndex;
			}
		}
	};

	/**
	 * Gets the matrix as an array.
	 * @returns The array representation of the matrix.
	 */
	public toArray = (): number[][] => {
		return this._matrix.map((row: number[]) => [...row]);
	};

	/**
	 * Gets a string representation of the matrix.
	 * @returns A string representation of the matrix.
	 */
	public toString = (): string => {
		return (
			this._names.join(' ') + '\r\n' + 
			this._matrix.map((row: number[]) => row.map((value: number) => value.toFixed(0)).join(' ')).join('\r\n')
		);
	};
}