import { IGameLevelDefinition } from '../../../game-to-app/interfaces';
import { Dimension } from '../../../game-to-app/types';
import { BoardShape } from './boardshape';
import { IBoardShape, ICellSize, IFaceDefinition } from './interfaces';

/**
 * The BoardShapeBox class defines the shape of a cube, box or classic style game board.
 * @export
 * @class BoardShapeBox
 * @implements {IBoardShape}
 */
export class BoardShapeBox extends BoardShape implements IBoardShape {
	/**
	 * Creates an instance of BoardShapeBox.
	 * @param {IGameLevelDefinition} level The game level.
	 * @param {ICellSize} cellSize The cell size.
	 */
	constructor(level: IGameLevelDefinition, cellSize: ICellSize) {
		super(level, cellSize);
	}

	/**
	 * Gets the dimensions of the board shape.
	 * @returns The dimensions of the board shape.
	 */
	public getSize = (): Dimension => {
		return {
			width:
				Math.round(
					(this._level.dimension.width || this._level.zeroDimension || 0) *
					this._cellSize.width),
			height:
				Math.round(
					(this._level.dimension.height || this._level.zeroDimension || 0) *
					this._cellSize.height),
			depth:
				Math.round(
					(this._level.dimension.depth || this._level.zeroDimension || 0) *
					this._cellSize.width),
		};	
	};

	/**
	 * Gets the faces of the board shape.
	 * @returns The faces of the board shape.
	*/
	public getFaces = (): Record<string, IFaceDefinition> => {
		const cellClassNames: string[] = this._cellSize?.name ? [this._cellSize.name] : [];
		const size = this.getSize();

		return {
			front: {
				label: 'front',
				translate: {
					z: size.depth / 2,
				},
				rotate: {
					y: 1,
				},
				size: {
					width: size.width,
					height: size.height,
				},
				position: {
				},
				cells: {
					count: {
						height: this._level.enabledFaces.front ? this._level.dimension.height : 0,
						width: this._level.enabledFaces.front ? this._level.dimension.width : 0,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.height,
					},
					classNames: cellClassNames,
				},
				index: 0,
			},
			right: {
				label: 'right',
				translate: {
					x: size.width / 2,
				},
				rotate: {
					y: 1,
					deg: 90
				},
				size: {
					width: size.depth,
					height: size.height,
				},
				position: {
					left: (size.width - size.depth) / 2,
				},
				cells: {
					count: {
						height: this._level.enabledFaces.right ? this._level.dimension.height : 0,
						width: this._level.enabledFaces.right ? this._level.dimension.depth : 0,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.height,
					},
					classNames: cellClassNames,
				},
				index: 1,
			},
			back: {
				label: 'back',
				translate: {
					z: -size.depth / 2,
				},
				rotate: {
					y: 1,
					deg: 180
				},
				size: {
					width: size.width,
					height: size.height,
				},
				position: {
				},
				cells: {
					count: {
						height: this._level.enabledFaces.back ? this._level.dimension.height : 0,
						width: this._level.enabledFaces.back ? this._level.dimension.width : 0,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.height,
					},
					classNames: cellClassNames,
				},
				index: 2,
			},
			left: {
				label: 'left',
				translate: {
					x: -size.width / 2,
				},
				rotate: {
					y: 1,
					deg: -90
				},
				size: {
					width: size.depth,
					height: size.height,
				},
				position: {
					left: (size.width - size.depth) / 2,
				},
				cells: {
					count: {
						height: this._level.enabledFaces.left ? this._level.dimension.height : 0,
						width: this._level.enabledFaces.left ? this._level.dimension.depth : 0,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.height,
					},
					classNames: cellClassNames,
				},
				index: 3,
			},
			top: {
				label: 'top',
				translate: {
					y: -size.height / 2,
				},
				rotate: {
					x: 1,
					deg: 90
				},
				size: {
					width: size.width,
					height: size.depth,
				},
				position: {
					top: (size.height - size.depth) / 2,
				},
				cells: {
					count: {
						height: this._level.enabledFaces.top ? this._level.dimension.depth : 0,
						width: this._level.enabledFaces.top ? this._level.dimension.width : 0,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.width,
					},
					classNames: cellClassNames,
				},
				index: 4,
			},
			bottom: {
				label: 'bottom',
				translate: {
					y: size.height / 2,
				},
				rotate: {
					x: 1,
					deg: -90
				},
				size: {
					width: size.width,
					height: size.depth,
				},
				position: {
					top: (size.height - size.depth) / 2,
				},
				cells: {
					count: {
						height: this._level.enabledFaces.bottom ? this._level.dimension.depth : 0,
						width: this._level.enabledFaces.bottom ? this._level.dimension.width : 0,
					},
					cellSize: {
						width: this._cellSize.width,
						height: this._cellSize.width,
					},
					classNames: cellClassNames,
				},
				index: 5,
			}
		};
	};

	/**
	 * Gets the initial face when starting a new game.
	 * @remarks This is the face that initially shows in front.
	 * @returns The name of the initial face.
	 */
	public getInitialFace = (): string => {
		return 'front';
	};
} 