import * as Game from '../../../game-to-app/app';
import { ISerializedVersionedGame } from '../../../game/serialization-interfaces';
import { TimerIntervalEvent } from '../../../game/types';
import { Nullable } from '../../../shared/types';
import { Guid } from '../../shared/guid';
import { ISavedGameInfo } from '../../shared/interfaces';
import { Database } from '../../shared/storage/database';
import { ISavedGame, ISavedGameUpdate } from '../../shared/storage/views/savedgames';
import { Constants } from './constants';
import { IRestoredGame } from './interfaces';

/**
 * The SavedGameManager class implements functions to persist, retrieve and delete saved games.
 * @export
 * @class SavedGameManager
 */
export class SavedGameManager {
	// the loaded saved game
	private static _loadedSavedGame: Nullable<ISavedGame> = null;
	private static _lastSavedGame = {
		gameId: -1,
		moveId: -1,
	};

	/**
	 * Auto-saves a game.
	 * @param saveGameInfo The game info.
	 * @param game The game to save.
	 * @param saveSynchronously A Boolean value indicating whether to save to the synchronous store.
	 * @returns A promise that resolves to a Boolean value indicating whether the save operation was successful.
	 * @static
	 */
	public static autoSaveGame = (
		saveGameInfo: ISavedGameInfo,
		game: Nullable<Game.Game>,
		saveSynchronously: boolean): Promise<boolean> => {
		
		return new Promise<boolean>((resolve) => {

			// check all conditions for save are met
			const saveNow: boolean = (
				// game
				((!!game) &&
				// game is ongoing
				(game.status === Game.GameStatus.ActivePlaying ||
				game.status === Game.GameStatus.InitialPlaying)) &&
				// check if already saved
				(saveSynchronously ||
				game.id !== SavedGameManager._lastSavedGame.gameId ||
				game.moveId !== SavedGameManager._lastSavedGame.moveId) &&
				// not auto-save or at right move interval
				(saveSynchronously ||
				Constants.autoSaveMoveInterval === 0 ||
				game.moveId % Constants.autoSaveMoveInterval === 0) &&
				// not auto-save or auto-save is enabled
				(Database.settings.get().game.enableAutoSave)
			);

			if (saveNow) {

				// update last saved
				SavedGameManager._lastSavedGame = {
					gameId: (game as Game.Game).id,
					moveId: (game as Game.Game).moveId,
				};
				
				// serialize
				const savedGame: ISavedGame = SavedGameManager.serializeGame(saveGameInfo, game as Game.Game);

				// save
				if (saveSynchronously) {
					Database.savedGames.autoSave(savedGame);
					resolve(true);
				}
				else {
					Database.savedGames.autoSaveAsync(savedGame)
						.then(() => resolve(true))
						.catch(() => resolve(false));
				}
			}
			else {
				resolve(false);
			}
		});
	};

	/**
	 * Restore the latest saved game.
	 * @param onTimer The onTimer function for the restore game.
	 * @static
	 */
	public static restoreAutoSavedGame = (onTimer: Nullable<TimerIntervalEvent>): Nullable<IRestoredGame> => {
		// get get if auto-save is enabled
		// the game must already have been loaded by calling loadAutoSavedGame()
		const savedGame: Nullable<ISavedGame> = Database.settings.get().game.enableAutoSave
			? SavedGameManager._loadedSavedGame
			: null;

		let game: Nullable<Game.Game> = null;
		let result: Nullable<IRestoredGame> = null;

		if (savedGame) {
	
			// deserialize
			game = Game.Game.createFromSerialization(
				((savedGame as ISavedGame).game as ISerializedVersionedGame),
				onTimer);
			
			if (game) {
				// prepare result
				result = {
					info: savedGame.info,
					game: game,
				}
			}
		}

		return result;
	};

	/**
	 * Load the latest saved game.
	 * @static
	 */
	public static loadAutoSavedGame = async (): Promise<boolean> => {

		// get get if auto-save is enabled
		if (Database.settings.get().game.enableAutoSave) {
			SavedGameManager._loadedSavedGame = await Database.savedGames.getAutoSavedAsync();
		}

		// save again in case user closes the game without making a move
		if (SavedGameManager._loadedSavedGame?.game?.game) {

			// update last saved
			SavedGameManager._lastSavedGame = {
				gameId: SavedGameManager._loadedSavedGame.game.game.id,
				moveId: SavedGameManager._loadedSavedGame.game.game.moveId,
			};

			// save
			await Database.savedGames.autoSaveAsync(SavedGameManager._loadedSavedGame);
		}

		return !!SavedGameManager._loadedSavedGame;
	};

	/**
	 * Deletes all auto-saved games.
	 * @returns A promise that resolves when the auto-saved games have been deleted.
	 * @static
	 * @memberof SavedGameManager
	 */
	public static deleteAutoSavedGames = async (): Promise<void> => {
		return Database.savedGames.deleteAllAutoSavedGames();
	};

	/**
	 * Saves a game.
	 * @param saveGameInfo The game info.
	 * @param game The game to save.
	 * @returns A promise that resolves to a Boolean value indicating whether the save operation was successful.
	 * @static
	 * @memberof SavedGameManager
	 */
	public static saveGame = (
		saveGameInfo: ISavedGameInfo,
		game: Nullable<Game.Game>): Promise<boolean> => {

		return new Promise<boolean>((resolve) => {

			// check all conditions for save are met
			const saveNow: boolean = (
				// game
				((!!game) &&
				// game is ongoing
				(game.status === Game.GameStatus.ActivePlaying ||
				game.status === Game.GameStatus.InitialPlaying))
			);

			if (saveNow) {
				// serialize
				const savedGame: ISavedGame = SavedGameManager.serializeGame(saveGameInfo, game as Game.Game);

				// save
				Database.savedGames.update(savedGame as ISavedGameUpdate)
					.then(() => resolve(true))
					.catch(() => resolve(false));
			}
			else {
				resolve(false);
			}
		});
	};

	/**
	 * Serializes the game.
	 * @param saveGameInfo The game info.
	 * @param game The game to save.
	 * @private
	 * @static
	 */
	private static serializeGame = (saveGameInfo: ISavedGameInfo, game: Game.Game): ISavedGame => {
		// serialize
		const serializedGame: ISerializedVersionedGame = game.serialize();

		return {
			id: Guid.newGuid(),
			info: saveGameInfo,
			game: serializedGame,
			date: new Date(),
		};
	};
}