import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelLevelValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the levels model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelLevelValues> {
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {
		// do not create any default records.

		return true;
	};
}