import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { deepcopy } from '../../../../utils';
import { TransactionMode } from '../../../enums';
import { IModelCacheManager, IModelCacheManagerAutoSave, IModelMetadata } from '../../../model-interfaces';
import { ISavedGame, ISavedGameUpdate } from '../../../views/savedgames';
import { IAutoSaveInfo } from './interfaces';
import { IModelSavedGameValues } from './model';

/**
 * The CacheManager class implements a cache for the Saved Games model.
 * @export
 * @class CacheManager
 * @implements {IModelCache}
 */
export class CacheManager implements IModelCacheManager, IModelCacheManagerAutoSave {
	private _database: IDBPDatabase;
	private _metadata: IModelMetadata<IModelSavedGameValues>;
	private _cache: Record<string, ISavedGame> = {};
	private static _autoSaveInfo: IAutoSaveInfo = {
		maxParallelSaveOperations: 2,
		maxGamesToKeep: 3,
		inProgressSaveOperations: [],
		savedGames: [],
		deleteGames: [],
	};
	private static _queuedAutoSaveGame: Nullable<ISavedGame> = null;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._metadata.name; }

	/**
	 * Creates an instance of CacheManager.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The model metadata.
	 * @memberof CacheManager
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelSavedGameValues>) {
		this._database = database;
		this._metadata = metadata;
	}

	/**
	 * Loads the model into the cache.
	 * @returns A promise that resolves when the cache is loaded.
	 */
	public load = async (): Promise<Record<string, ISavedGame>> => {
		const records: IModelSavedGameValues[] = await this._database.getAll(this._metadata.name);

		this._cache = records.reduce((acc: Record<string, ISavedGame>, item: IModelSavedGameValues): Record<string, ISavedGame> => {
			acc[item.id] = item;

			return acc;
		}, {});

		return this.get();
	};

	/**
	 * Updates a record in the cache and database.
	 * @param savedGame The saved game.
	 * @returns A promise that resolves when the database was updated.
	 */
	public update = async (savedGame: ISavedGameUpdate): Promise<Record<string, ISavedGame>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		const record: IModelSavedGameValues = await store.get(savedGame.id);
		const base: IModelSavedGameValues = record || { ...this._metadata.defaults[0], ...this._metadata.systemValues };

		// initialize update from updated saved game and base
		const update: IModelSavedGameValues = {
			...{
				id: savedGame.id ? savedGame.id : base.id,
				info: savedGame.info !== undefined ? savedGame.info : base.info,
				date: savedGame.date !== undefined ? savedGame.date : base.date,
				game: savedGame.game !== undefined ? savedGame.game : base.game,
			},
			...this._metadata.systemValues,
		};
		
		await store.put(update);
		
		// refresh cache
		await this.load();

		// return the entire saved game cache
		return this.get();
	};

	/**
	 * Deletes a record in the cache and database.
	 * @param id The id.
	 * @returns A promise that resolves when the database record was deleted.
	 */
	public delete = async (id: string): Promise<Record<string, ISavedGame>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// create or update in database
		await store.delete(id);

		// refresh cache
		await this.load();

		// return the entire saved game cache
		return this.get();
	};

	/**
	 * Gets the saved games.
	 * @returns The saved games.
	 */
	public get = (): Record<string, ISavedGame> => {
		return deepcopy(this._cache) as Record<string, ISavedGame>;
	};

	/**
	 * Writes the 'auto save' game to local storage.
	 * When the application exits, cannot write to indexed db, because the API is async and the page will
	 * not wait for the response.
	 * @param savedGame The game to save.
	 * @private
	 */
	public autoSave = (savedGame: Nullable<ISavedGame>): void => {
		if (savedGame) {
			const key: string = this.getAutoSavedGameKey();
			const value: string = JSON.stringify(savedGame);

			localStorage.setItem(key, value);
		}
	};

	/**
	 * Auto saves the game asynchronously.
	 * @param savedGame The saved game.
	 * @returns A promise that resolves when the game is saved or queued to save.
	 */
	public autoSaveAsync = async (savedGame: Nullable<ISavedGame>): Promise<boolean> => {
		let result: boolean = false;

		if (savedGame) {
			if (CacheManager._autoSaveInfo.maxParallelSaveOperations -
				CacheManager._autoSaveInfo.inProgressSaveOperations.length > 0) {
				
				const id: string = this.getAutoSavedGameKey(savedGame.game?.game?.id || null);
				CacheManager._autoSaveInfo.inProgressSaveOperations.push(id);

				// transaction
				const transaction: IDBPTransaction = this._database.transaction(
					[this._metadata.name],
					TransactionMode.ReadWrite);

				// store
				const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

				const record: IModelSavedGameValues = {
					...{
						id: id,
						info: savedGame.info,
						date: savedGame.date,
						game: savedGame.game,
					},
					...this._metadata.systemValues,
				};

				await store.put(record);

				// is delete pending?
				const index = CacheManager._autoSaveInfo.deleteGames.indexOf(record.id);

				// update save info arrays
				if (index >= 0) {
					CacheManager._autoSaveInfo.deleteGames.splice(index, 1);
					await store.delete(record.id);
				}
				else {
					CacheManager._autoSaveInfo.savedGames.push(record.id);
					result = true;
				}

				// remove oldest if needed (not waiting)
				if (CacheManager._autoSaveInfo.savedGames.length > CacheManager._autoSaveInfo.maxGamesToKeep) {
					const id: Nullable<string> = CacheManager._autoSaveInfo.savedGames.shift() || null;

					if (id) {
						store.delete(id);
					}
				}
				
				// free up a parallel save slot
				const inOperationIndex = CacheManager._autoSaveInfo.inProgressSaveOperations.indexOf(record.id);
				if (inOperationIndex >= 0) {
					CacheManager._autoSaveInfo.inProgressSaveOperations.splice(inOperationIndex, 1);
				}

				// save queued up game (not waiting)
				if (CacheManager._queuedAutoSaveGame) {

					// clear queued value
					const queued: ISavedGame = CacheManager._queuedAutoSaveGame;
					CacheManager._queuedAutoSaveGame = null;

					// save queued value
					this.autoSaveAsync(queued);
				}
			}
			else {
				// add the latest in the queue; overwrite any older version that may already be waiting
				CacheManager._queuedAutoSaveGame = savedGame;
			}
		}

		return result;
	};

	/**
	 * Deletes all auto saved games.
	 * @param deleteFromLocalStorage A Boolean value indicating whether to delete the saved game from local storage as well; optional.
	 * @returns A promise that resolves when the deletion is complete.
	 */
	public deleteAllAutoSavedGames = async (deleteFromLocalStorage: boolean = true): Promise<void> => {
		// discard any queued up game
		CacheManager._queuedAutoSaveGame = null;

		// mark any ongoing save for deletion
		CacheManager._autoSaveInfo.deleteGames.push(...CacheManager._autoSaveInfo.inProgressSaveOperations);

		// transaction
		const transaction: IDBPTransaction = this._database.transaction(
			[this._metadata.name],
			TransactionMode.ReadWrite);

		// store
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		const range: IDBKeyRange = this.getAutoSavedGameKeyRange();

		// delete already saved games
		let cursor = await store.openCursor(range);
		const deletePromises: Promise<void>[] = [];
		const maxDate: number = new Date().getTime();

		while (cursor) {
			if (cursor?.value) {
				const record: IModelSavedGameValues = cursor.value;

				if (record.date.getTime() <= maxDate) {
					deletePromises.push(cursor?.delete());
				}
			}

			cursor = await cursor.continue();
		}

		// reset
		CacheManager._autoSaveInfo.inProgressSaveOperations = [];
		CacheManager._autoSaveInfo.deleteGames = [];
		CacheManager._autoSaveInfo.savedGames = [];

		// remove the synchronously saved game from storage
		if (deleteFromLocalStorage) {
			localStorage.removeItem(this.getAutoSavedGameKey());
		}

		// wait for all deletes to finish
		await Promise.all(deletePromises);
	};

	/**
	 * Reads the 'auto saved' game from local storage.
	 * The game will be automatically deleted from storage.
	 * @param deleteAfterRead A Boolean value indicating whether to delete the saved game after read; optional.
	 * @returns The previously saved game; otherwise, null.
	 */
	public getAutoSaved = (deleteAfterRead: boolean = true): Nullable<ISavedGame> => {
		let result: Nullable<ISavedGame> = null;
		const key: string = this.getAutoSavedGameKey();
		const json: Nullable<string> = localStorage.getItem(key);

		if (json) {
			// deserialize the string
			result = JSON.parse(json);

			// json parser does not deserialize dates to Date objects, but to a string
			if (result?.date) {
				result.date = new Date(result.date);
			}

			// remove the game from storage
			if (deleteAfterRead) {
				localStorage.removeItem(key);
			}
		}

		return result;
	};

	/**
	 * Gets the 'auto saved' game asynchronously.
	 * @returns A promise that resolves with the auto saved game or null.
	 */
	public getAutoSavedAsync = async (): Promise<Nullable<ISavedGame>> => {
		let result: Nullable<ISavedGame> = null;

		// try get game from local storage
		const localStorageGame: Nullable<ISavedGame> = this.getAutoSaved(false);

		// try get game from database
		// transaction
		const transaction: IDBPTransaction = this._database.transaction(
			[this._metadata.name],
			TransactionMode.ReadOnly);

		// store
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		const range: IDBKeyRange = this.getAutoSavedGameKeyRange();
		const cursor = await store.openCursor(range, 'prev');
		const record: IModelSavedGameValues = cursor?.value;
		
		const databaseGame: Nullable<ISavedGame> = record
			? {
				id: record.id,
				date: record.date,
				info: record.info,
				game: record.game,
			}
			: null;

		if (localStorageGame && databaseGame) {
			// if both are present, take the one with the more recent timestamp
			result = localStorageGame.date.getTime() >= databaseGame.date.getTime()
				? localStorageGame
				: databaseGame;
		}
		else {
			// take whichever one is not null (if any)
			result = localStorageGame || databaseGame;
		}

		// clear all auto saved games from storage
		await this.deleteAllAutoSavedGames(false);

		return result;
	};

	/**
	 * Gets the key for the 'auto saved' game.
	 * @param generation A Boolean value indicating whether to use generational keys.
	 * @returns The key.
	 * @private
	 */
	private getAutoSavedGameKey = (id: Nullable<number> = null) => {
		const result: string = `${this._metadata.name}_autoSaved`;

		return id ? `${result}-${new Date().toISOString()}#${id}#` : result;
	};

	/**
	 * Gets the key range for 'auto saved' games.
	 * @private
	 */
	private getAutoSavedGameKeyRange = (): IDBKeyRange => {
		return IDBKeyRange.bound(
			`${this.getAutoSavedGameKey()}`,
			`${this.getAutoSavedGameKey()}ZZZ`,
			true,
			true);
	}
}