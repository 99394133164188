import deepmerge from 'deepmerge';
import { Orientation } from '../../game-to-app/enums';
import { Position } from './types';

/**
 * The WindowUtils class provides utility functions for handling the window object.
 * @export
 * @class WindowUtils
 */
export class WindowUtils {

	/**
	 * Gets the orientation of the window.
	 * @returns The orientation.
	 */
	public static getOrientation = (): Orientation => {
		const bottomRight: Position = WindowUtils.getBottomRight();

		return bottomRight.x > bottomRight.y ? Orientation.Landscape : Orientation.Portrait;
	};

	/**
	 * Gets the bottom right position of the window.
	 * @returns The center position.
	 * @static
	 */
	public static getBottomRight = (): Position => {
		const documentElement = document.documentElement;
		const body = document.getElementsByTagName('body')[0];

		return {
			x: window.innerWidth || documentElement.clientWidth || body.clientWidth,
			y: window.innerHeight || documentElement.clientHeight || body.clientHeight,
		};
	};
}

/**
 * The EnumToClassName class provides utility functions for converting enum values to css class names.
 * @export
 * @class EnumToClassName
 */
export class EnumToClassName {

	/**
	 * Converts and enum types to an array of css class names.
	 * @param enumType The enum type.
	 * @returns The array of css class names.
	 * @static
	 */
	public static enumToClassNames = (enumType: Record<string, unknown>): string[] => {
		return Object.keys(enumType).reduce((accumulator: string[], key: string) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if (typeof (enumType as any)[key] === 'number') {
				accumulator.push(EnumToClassName.keyToClassName(key));
			}

			return accumulator;
		},
		[]);
	};

	/**
	 * Converts an enum value to a css class name string.
	 * @param enumType The enum type.
	 * @param enumValue The enum value.
	 * @returns The css class name.
	 * @static
	 */
	public static enumValueToClassName = (enumType: Record<string, unknown>, enumValue: number): string => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return EnumToClassName.keyToClassName((enumType as any)[enumValue]);
	};

	/**
	 * Converts a given key string to a css class name.
	 * @param key The key.
	 * @returns The css class name.
	 * @private
	 * @static
	 */
	private static keyToClassName = (key: string): string => {
		return `${key[0].toLowerCase()}${key.slice(1)}`;
	};
}

/**
 * The ObjectUtils class provides utility functions for objects.
 * @export
 * @class ObjectUtils
 */
export class ObjectUtils {
	/**
	 * Determines if the given object is null or undefined.
	 * @returns true if the object is null or undefined; otherwise, false.
	 * @static
	 */
	public static isNullOrUndefined = (object: unknown): boolean => {
		return object === null || object === undefined;
	}
}


/**
 * Creates a deep copy of an object.
 * @export
 * @param {Partial<any>} obj The object to copy.
 * @returns The copied object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepcopy(obj: Partial<any>): any {
	return deepmerge(obj, {});
}

/**
 * The DateUtils class provides utility methods for dates.
 * @export
 * @class DateUtils
 */
export class DateUtils {
	/**
	 * Gets a date representing the given date plus the given number of seconds.
	 * @param date The date.
	 * @param seconds The number of seconds.
	 * @returns The new date.
	 * @static
	 * @memberof DateUtils
	 */
	public static addSeconds = (date: Date, seconds: number): Date => {
		return new Date(date.getTime() + (seconds * 1000));
	}
}

