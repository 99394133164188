import Cookies from 'universal-cookie';
import { ILevelPreference, IVersionedCookie, IVersionedCookieDefinition } from '../../components/shared/interfaces';
import { IGameStats } from './views/gamestats';

/**
 * The CookieProvider class is the central class that handles all cookies for the application.
 * The provider is a read-only operation. No cookies are written by the application.
 * @export
 * @class CookieProvider
 */
export class CookieProvider {
	/**
	 * The cookie handle.
	 * @private
	 * @static
	 */
	private static _cookies: Cookies = new Cookies();

	/**
	 * Defines the cookie name for the 'Quick Start Show again' cookie.
	 * @private
	 * @static
	 */
	private static _quickStartShowAgain: IVersionedCookieDefinition = {
		name: 'quickStartShowAgain',
		version: '2.0.0',
	};

	/**
	 * Defines the cookie name for the 'Zoom Mode Show again' cookie.
	 * @private
	 * @static
	 */
	private static _zoomModeShowAgain = 'zoomModeShowAgain';

	/**
	 * Defines the cookie name for the 'Game Stats' cookie.
	 * @private
	 * @static
	 */
	private static _gameStats = 'gameStats';

	/**
	 * Defines the cookie name for the 'Level Preference' cookie.
	 * @private
	 * @static
	 */
	private static _levelPreference = 'levelPreference';

	/**
	 * Gets the 'Quick Start Show Again' cookie.
	 * @param defaultValue The default value if the cookie does not exist.
	 * @returns true if the quick start should show again; otherwise, false.
	 */
	public getQuickStartShowAgain = (defaultValue: boolean): boolean => {
		const cookie = this.get(CookieProvider._quickStartShowAgain.name);
		let result = defaultValue;

		if (cookie !== undefined) {
			// if it is not a versioned cookie, then the quick start did not have the What's New section
			// in this case, ignore the existing cookie
			result = this.isVersionedCookie(cookie)
				? !!((cookie as IVersionedCookie).value)
				: defaultValue
		}
		
		return result;
	};
	
	/**
	 * Gets the 'Zoom Mode Show Again' cookie.
	 * @param defaultValue The default value if the cookie does not exist.
	 * @returns true if the zoom mode notification should show again; otherwise, false.
	 */
	public getZoomModeShowAgain = (defaultValue: boolean): boolean => {
		const cookie = this.get(CookieProvider._zoomModeShowAgain);

		return cookie === undefined ? defaultValue : !!cookie;
	};

	/**
	 * Gets the 'Game Stats' cookie.
	 * @param defaultValue The default value if the cookie does not exist.
	 * @returns The game stats.
	 */
	public getGameStats = (defaultValue: Record<string, IGameStats>): Record<string, IGameStats> => {
		const cookie = this.get(CookieProvider._gameStats);

		return cookie === undefined ? defaultValue : cookie as Record<string, IGameStats>;
	};

	/**
	 * Gets the 'Level Preference' cookie.
	 * @param defaultValue The default value if the cookie does not exist.
	 * @returns The level preference.
	 */
	public getLevelPreference = (defaultValue: ILevelPreference): ILevelPreference => {
		const cookie = this.get(CookieProvider._levelPreference);

		return cookie === undefined	? defaultValue : cookie as ILevelPreference;
	};

	/**
	 * Gets a cookie with the given name to the given value.
	 * @param name The name of the cookie.
	 * @private
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	private get = (name: string): object | undefined => {
		let result = undefined;

		if (name) {
			result = CookieProvider._cookies.get(name);
			result = result ? result['value'] : undefined;
		}

		return result;
	};

	/**
	 * Determines if a given value is a versioned cookie.
	 * @param value The value.
	 * @returns true if the value is a versioned cookie; otherwise, false.
	 * @private
	 */
	private isVersionedCookie = (value: unknown): boolean => {
		const keys: string[] = value ? Object.keys(value as IVersionedCookie) : [];
		
		return (
			keys.indexOf('version') >= 0 &&
			keys.indexOf('value') >= 0
		);
	};
}