/**
 * The Constants class defines constant values for the game.
 * @export
 * @class Constants
 */
export class Constants {
	/**
	 * Defines the serialization version for game serialization.
	 * @static
	 */
	public static serializationVersion: string = '1.0.0';
}