import deepequal from 'deep-equal';
import deepmerge from 'deepmerge';
import { SubscriptionEvent, SubscriptionEvents } from '../../events/subscription-events';
import { deepcopy } from '../../utils';
import { IModelCacheManager, IStore } from '../model-interfaces';
import { ISettings, ISettingsUpdate } from '../views/settings';

/**
 * The SettingsStore class implements the store for settings.
 * @export
 * @class SettingsStore
 */
export class SettingsStore implements IStore {
	private _cacheManager: IModelCacheManager;
	private _settings: ISettings;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._cacheManager.name; }

	/**
	 * Creates an instance of SettingsStore.
	 * @param {IModelCacheManager} cacheManager The cache manager.
	 * @memberof SettingsStore
	 */
	constructor(cacheManager: IModelCacheManager) {
		this._cacheManager = cacheManager;
		this._settings = this._cacheManager.get();
	}

	/**
	 * Gets the settings.
	 * @param refresh A Boolean value indicating if the settings should be taken from the most recent database cache.
	 * @returns The settings.
	 */
	public get = (refresh: boolean = false): ISettings => {
		if (refresh) {
			const refreshedSettings: ISettings = this._cacheManager.get() as ISettings;

			if (!deepequal(refreshedSettings, this._settings)) {
				this._settings = refreshedSettings;
				SubscriptionEvent.raise(SubscriptionEvents.SettingsChanged, this.name, this._settings);
			}
		}
		
		return deepcopy(this._settings);
	};

	/**
	 * Updates settings.
	 * @param settings The settings to update.
	 * @param refresh A Boolean value indicating if the settings should be taken from the most recent database cache.
	 * @returns The updated settings.
	 */
	public update = async (settings: ISettingsUpdate, refresh: boolean = false): Promise<ISettings> => {
		let updatedSettings: ISettings = deepmerge(this._settings, settings) as ISettings;
		
		// take the sound effect settings from the update if any
		// this is so that default settings can be removed from the store
		updatedSettings.sound.sfx = settings.sound?.sfx || updatedSettings.sound.sfx;

		if (!deepequal(updatedSettings, this._settings)) {
			// update the store settings synchronously
			this._settings = updatedSettings;

			if (!refresh) {
				SubscriptionEvent.raise(SubscriptionEvents.SettingsChanged, this.name, this._settings);
			}

			// update the database
			updatedSettings = await this._cacheManager.update(settings);

			// optionally update the settings asynchronously from the database
			if (refresh && !deepequal(updatedSettings, this._settings)) {
				this._settings = updatedSettings;
				SubscriptionEvent.raise(SubscriptionEvents.SettingsChanged, this.name, this._settings);
			}
		}

		return this._settings;
	};
}