import { Style } from '../../../game-to-app/enums';
import { IIdValue } from '../../../game-to-app/interfaces';
import { Nullable } from '../../../shared/types';
import { ICoordinate } from "./interfaces";

/**
 * The GameStyle class defines the styles and style names.
 * @export
 */
export class GameStyle {

	/**
	 * Gets the string value associated with a style.
	 * @param id The style Id.
	 * @returns The name of the style.
	 * @static
	 */
	public static getValue = (id: Style): Nullable<string> => {
		const filtered = GameStyle.getStyles().filter((value) => {
			return value.id === id.toString();
		});

		return filtered.length > 0 ? filtered[0].value : null;
	};

	/**
	 * Gets all the avaulable game styles with their names.
	 * @returns An array of id values.
	 * @static
	 */
	public static getStyles = (): IIdValue[] => {
		return [
			{
				id: Style.Classic.toString(),
				value: 'Classic',
			},
			{
				id: Style.Cube.toString(),
				value: 'Cube',
			},
			{
				id: Style.Box.toString(),
				value: 'Box',
			},
			{
				id: Style.Pane.toString(),
				value: 'Pane',
			},
		];
	};

	/**
	 * Gets the spin for a given style.
	 * @param style The style.
	 * @returns The spin.
	 * @static
	 */
	public static getSpin = (style: Style): ICoordinate => {
		let result: ICoordinate = {
			x: 0,
			y: 0,
		};

		switch (style) {
			case Style.Box:
			case Style.Cube:
				result = {
					x: 45,
					y: 25,
				};
				break;
			case Style.Pane:
				result = {
					x: 45,
					y: -65,
				};
				break;
			case Style.Classic:
				result = {
					x: 0,
					y: 0,
				};
				break;
		}

		return result;
	};
}