import * as React from 'react';
import spinnerStyles from './spinner.module.css';

/**
 * The Spinner class implements the <Spinner/> component.
 * @export
 * @class Spinner
 */
export class Spinner extends React.Component {

	/**
	 * Renders the <Spinner/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		return (
			<div className={spinnerStyles.frame}>
				<div className={spinnerStyles.spinner}>
					<div className={`${spinnerStyles.item} ${spinnerStyles.one}`}></div>
					<div className={`${spinnerStyles.item} ${spinnerStyles.two}`}></div>
					<div className={`${spinnerStyles.item} ${spinnerStyles.three}`}></div>
				</div>
			</div>
		);
	};
}