import { ISerializedTimer } from './serialization-interfaces';
import { Nullable, TimerIntervalEvent } from './types';

/**
 * The Timer class implements a timer for the game.
 * @export
 * @class Timer
 */
export class Timer {

	/**
	 * Gets the timer seconds.
	 * @readonly
	 */
	public get seconds(): number { return this._seconds; }

	/**
	 * Gets a Boolean value indicating whether the timer is running.
	 * @readonly
	 */
	public get isRunning(): boolean { return !!this._timerId; }

	/**
	 * Gets a Boolean value indicating whether the timer is paused.
	 * @readonly
	 */
	public get isPaused(): boolean { return this._isPaused; }

	private _seconds: number = 0;
	private _timerId: number = 0;
	private _onTimer: Nullable<TimerIntervalEvent> = null;
	private _isPaused: boolean = false;

	/**
	 * Creates an instance of Timer.
	 * @param onTimer The timer callback function.
	 * @memberof Timer
	 */
	constructor(onTimer: Nullable<TimerIntervalEvent>) {
		this._onTimer = onTimer;
	}

	/**
	 * Starts the timer.
	 * @param seconds The initial number of seconds; optional.
	 */
	public start = (seconds: number = 0): void => {
		this.reset(true, seconds);
		this.setTimerInterval();
	};

	/**
	 * Stops the timer.
	 */
	public stop = (): void => {
		if (this._timerId) {
			clearInterval(this._timerId);
			this._timerId = 0;
		}
	};

	/**
	 * Resets the timer.
	 * @param resetOnTimer A Boolean value that indicates whether to reset the timer event handler; optional.
	 * @param seconds The number of seconds to reset the timer to; optional.
	 */
	public reset = (resetOnTimer: boolean = true, seconds: number = 0): void => {
		this.stop();
		this.resume();
		this.setSeconds(seconds, resetOnTimer);
	};

	/**
	 * Pauses the timer.
	 * @returns true if the timer was paused; otherwise, false.
	 */
	public pause = (): boolean => {
		const result: boolean = !this._isPaused;

		if (result) {
			this._isPaused = true;
		}

		return result;
	};

	/**
	 * Resumes the timer.
	 * @returns true if the timer was resumed; otherwise, false.
	 */
	public resume = (): boolean => {
		const result: boolean = this._isPaused;

		if (result) {
			this._isPaused = false;
		}

		return result;
	};

	/**
	 * Sets the timer interval.
	 * @private
	 */
	private setTimerInterval = (): void => {
		if (!this._timerId) {
			this._timerId = window.setInterval(() => {
				this.setSeconds(this._seconds + (this._isPaused ? 0 : 1), !this._isPaused);
			},
			1000);
		}
	}
	/**
	 * Invokes the timer callback with the elapsed number of seconds.
	 * @param seconds The number of seconds.
	 * @param setOnTimer A Boolean value that indicates whether to invoke the timer event handler; optional.
	 */
	private setSeconds = (seconds: number, setOnTimer: boolean = true): void => {
		this._seconds = seconds;

		if (setOnTimer && this._onTimer) {
			this._onTimer(this._seconds);
		}
	};

	/**
	 * Serializes the timer into an object.
	 * @returns The serialized timer.
	 */
	public serialize = (): ISerializedTimer => {
		const result: ISerializedTimer = {
			seconds: this._seconds,
		};

		return result;
	};

	/**
	 * Creates a timer instance from a serialized timer.
	 * @param serializedTimer The serialized timer.
	 * @param onTimer The onTimer function.
	 * @returns The timer.
	 * @static
	 */
	public static createFromSerialization = (
		serializedTimer: ISerializedTimer,
		onTimer: Nullable<TimerIntervalEvent>): Timer => {

		const timer: Timer = new Timer(onTimer);
		timer._seconds = serializedTimer.seconds;

		return timer;
	};
}