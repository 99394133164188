/**
 * The AudioSource class provides the source for audio files.
 */
export class AudioSource {
	private static _boomPath = 'boom.mp3';
	private static _clickPath = 'click.mp3';
	private static _winPath = 'win.mp3';
	private static _lossPath = 'loss.mp3';

	/**
	 * Gets the 'boom' source.
	 * @static
	 */
	public static Boom: string = AudioSource._boomPath || '';

	/**
	 * Gets the 'click' source.
	 * @static
	 */
	public static Click: string = AudioSource._clickPath || '';

	/**
	 * Gets the 'loss' source.
	 * @static
	 */
	public static Loss: string = AudioSource._lossPath || '';

	/**
	 * Gets the 'win' source.
	 * @static
	 */
	public static Win: string = AudioSource._winPath || '';
}
