import { IReviewProvider } from '../interfaces';

/**
 * The NoOpReviewProvider class implements functions to prompt users for a review.
  * @export
  * @class NoOpReviewProvider
 */
export class NoOpReviewProvider implements IReviewProvider {

	/**
	 * Gets a Boolean value indicating whether the provider is enabled.
	 * @readonly
	 */
	public get isEnabled(): boolean { return false; }

	/**
	 * Initializes the provider on app start
	 */
	public initialize = (): void => {
		// do nothing
	};

	/**
	 * No-op implementation of prompting for an app review.
	 * @param checkEligibility A Boolean value indicating whether to check for eligibility.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public requestPrompt = async (checkEligibility: boolean): Promise<void> => {
		// do nothing
	};

	/**
	 * Determines if the store listing can be opened.
	 * @returns A Boolean value indicating whrther the store listing can be opened.
	 */
	public canOpenStoreListing = (): boolean => {
		return false;
	};

	/**
	 * Opens the store listing.
	 */
	public openStoreListing = (): void => {
		// do nothing
	};

	/**
	 * Determines whether the app is currently eligible to prompt for a review.
	 * @returns A Boolean value indicating whether the app is currently eligible to prompt for a review.
	 */
	public isEligibleForPrompt = (): boolean => {
		return false;
	};

	/**
	 * Updates the current session with a win or loss.
	 * @param win A Boolean value indicating whether the game was won.
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public addSessionGameResult = (win: boolean): void => {
		// do nothing
	};
}