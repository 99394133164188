import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelSettingsValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the settings model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelSettingsValues> {
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {
		// define default settings
		const defaultSettings: IModelSettingsValues = {
			...this._metadata.defaults[0],
			...this._metadata.systemValues,
		};

		// create default record
		await this._database.add(this._metadata.name, defaultSettings);

		return true;
	};
}