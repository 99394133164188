import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelSettingsValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the settings model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelSettingsValues> {
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {

		// load user preferences
		const settings: IModelSettingsValues = await this._database.get(
			this._metadata.name,
			this._metadata.name);

		// update, set default auto save value = true
		await this._database.put(
			this._metadata.name,
			{
				...settings,
				game: {
					...settings.game,
					enableAutoSave: true,
				},
				...this._metadata.systemValues,
			}
		);

		return true;
	};
}