import deepEqual from 'deep-equal';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Nullable } from '../../../shared/types';
import { SubscriptionEvent, SubscriptionEvents } from '../../shared/events/subscription-events';
import { Constants } from '../shared/constants';
import { ZoomPositioning } from '../shared/enums';
import { ICoordinate } from '../shared/interfaces';
import { IZoomWrapperProps } from './interfaces';
import { IProps } from './props';
import { IState } from './state';
import zoomStyles from './zoom.module.css';

/**
 * The Zoom class implements the <Zoom/> component.
 * @export
 * @class Zoom
 * @extends {React.Component<IProps>}
 */
export class Zoom extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;
	private _position: ICoordinate = { x: 0, y: 0 };
	/**
	 * Creates an instance of Zoom.
	 * @param {IProps} props
	 * @memberof Zoom
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			position: {
				x: 0,
				y: 0,
			},
			prevRelativeTargetPosition: null,
			savedPosition: null,
			restorePosition: false,
		};
	}

	/**
	 * A react lifecycle function for the <Zoom/> component.
	 */
	public componentDidMount = (): void => {
		this._isMounted = true;

		this.props.components.set(Constants.zoomComponentId, this._ref);

		if (this.state.position.x !== this._position.x ||
			this.state.position.y !== this._position.y) {
			this.setState({ position: this._position });
		}

		SubscriptionEvent.subscribe(SubscriptionEvents.ZoomPositioning, this.onZoomPositioning);
	};

	/**
	 * A react lifecycle function for the <Zoom/> component.
	 */
	public componentDidUpdate = (): void => {
		if (this.state.position.x !== this._position.x ||
			this.state.position.y !== this._position.y) {
			
			this.setState({ position: this._position });
		}

		if ((this.state.prevRelativeTargetPosition && !this.props.relativeTargetPosition) ||
			(!this.state.prevRelativeTargetPosition && this.props.relativeTargetPosition) ||
			(!deepEqual(this.state.prevRelativeTargetPosition, this.props.relativeTargetPosition))) {
			
			this.setState({ prevRelativeTargetPosition: this.props.relativeTargetPosition || null });
		}

		if (this.state.restorePosition) {
			this.setState({
				savedPosition: null,
				restorePosition: false,
			});
		}
	};

	/**
	 * A react lifecycle function for the <Zoom/> component.
	 */
	public componentWillUnmount = (): void => {
		this._isMounted = false;

		this.props.components.remove(Constants.zoomComponentId);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.ZoomPositioning, this.onZoomPositioning);
	};

	/**
	 * Renders the <Zoom/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { container, content, options } = this.props;
		const { savedPosition, restorePosition } = this.state;

		const wrapper = {
			width: content.width >= container.width
				? Math.max(container.width, (2 * content.width) - container.width)
				: container.width,
			height: content.height >= container.height
				? Math.max(container.height, (2 * content.height) - container.height)
				: container.height,
		};
		
		// gets the target position, if any
		// positioning may be required to bring the active area into view for the assistant.
		let position: Nullable<ICoordinate> = this.getPosition();

		if (restorePosition && savedPosition) {
			position = savedPosition;
		}

		return (
			<div
				id={this.props.id}
				ref={this._ref}
				className={zoomStyles.outerWrapper}
				style={{
					width: `${container.width}px`,
					height: `${container.height}px`,
					maxWidth: `${container.width}px`,
					maxHeight: `${container.height}px`,
					position: 'relative',
				}}>
				<TransformWrapper
					defaultScale={options.scale}
					defaultPositionX={-((content.width * options.scale) - container.width) / 2}
					defaultPositionY={-((content.height * options.scale) - container.height) / 2}
					options={{
						disabled: !options.zoomEnabled && !options.panEnabled,
						limitToBounds: true,
						limitToWrapper: true,
						centerContent: true,
						minScale: options.minScale,
						maxScale: options.maxScale,
					}}
					pan={{
						disabled: !options.panEnabled,
						padding: options.panEnabled,
						paddingSize: 0,
					}}
					wheel={{
						disabled: !options.zoomEnabled,
					}}
					pinch={{ disabled: !options.zoomEnabled }}
					scalePadding={{ disabled: true }}
					doubleClick={{ disabled: true }}
					onZoomChange={options.onZoomChange}
					zoomIn={{ animation: false }}
					zoomOut={{ animation: false }}
					positionX={position ? position.x : undefined}
					positionY={position ? position.y : undefined}
				>
					{(zoomProps: IZoomWrapperProps) => {
						this._position = {
							x: zoomProps.positionX,
							y: zoomProps.positionY,
						};

						return (
							<React.Fragment>
								<div
									className={`${zoomStyles.innerWrapper} ${zoomStyles.center}`}
									style={{
										height: `${wrapper.height}px`,
										width: `${wrapper.width}px`,
									}}
								>
									<div
										id='zoom-center'
										className={zoomStyles.centerComponent}
										style={{
											height: `${container.height}px`,
											width: `${container.width}px`,
										}}
									>
										<TransformComponent>
											<div
												style={{
													height: `${content.height}px`,
													width: `${content.width}px`,
												}}
											>
												{this.props.children}
											</div>
										</TransformComponent>
									</div>
								</div>
							</React.Fragment>
						)
					}}
				</TransformWrapper>
			</div>
		);
	};

	/**
	 * Saves or restores a zoom position.
	 * @param positioning The positioning action.
	 */
	public onZoomPositioning = (positioning: ZoomPositioning): void => {
		
		switch (positioning) {
			case ZoomPositioning.Save:
				// do not overwrite already saved position
				if (!this.state.savedPosition) {
					this.setState({ savedPosition: this.state.position });
				}
				break;
			case ZoomPositioning.Restore:
				this.setState({ restorePosition: true });
				break;
		}
	};

	/**
	 * Gets the position.
	 * @returns The position for the zoom component.
	 */
	private getPosition = (): Nullable<ICoordinate> => {
		const { container, content, options, relativeTargetPosition } = this.props;
		const { position, prevRelativeTargetPosition: prev } = this.state;

		let result: Nullable<ICoordinate> = null;
		
		// the valid area for x, y positions
		// x: left: 0 -> right: -((content.width * options.scale) - container.width)
		// y: top: 0 -> bottom: -((content.height * options.scale) - container.height)

		// if there is a relative target position and it is different than the previous target position
		// the target position is used to bring an area into view that is currently outside the container view window
		if (relativeTargetPosition) {
			if ((prev && !relativeTargetPosition) ||
				(!prev && relativeTargetPosition) ||
				(!deepEqual(prev, relativeTargetPosition))) {

				// the relative center of the zoom component
				const center: ICoordinate = {
					x: relativeTargetPosition.zoom.width / 2,
					y: relativeTargetPosition.zoom.height / 2,
				};

				// the distance of the relative target position to the center
				const delta: ICoordinate = {
					x: center.x - (relativeTargetPosition.relative.x + (relativeTargetPosition.relative.width / 2)),
					y: center.y - (relativeTargetPosition.relative.y + (relativeTargetPosition.relative.height / 2)),
				};

				// the relative target position should be at the center, unless that would be an invalid position 
				// considering the content area.
				result = {
					x: Math.max(-(content.width * options.scale - container.width), Math.min(0, position.x + delta.x)),
					y: Math.max(-(content.height * options.scale - container.height), Math.min(0, position.y + delta.y))
				}
			}
		}

		return result;
	};
}