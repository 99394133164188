import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import notificationStyles from '../notification.module.css';
import { IProps } from './props';

/**
 * The NotificationZoomMode class implements the <NotificationZoomMode/> component.
 * @remarks The control shows quick start help.
 * @export
 * @class NotificationZoomMode
 * @extends {React.Component}
 */
export class NotificationZoomMode extends React.Component<IProps> {

	/**
	 * Creates an instance of NotificationZoomMode.
	 * @param {IProps} props The props for the <NotificationZoomMode/> component.
	 * @memberof NotificationZoomMode
	 */
	constructor(props: IProps) {
		super(props);
	}

	/**
	 * Renders the <NotificationZoomMode/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		return (
			<Container className={notificationStyles.wrapper}>
				<Container className='d-flex justify-content-center'>
					<Row xs={1} className={notificationStyles.padded}>
						<Col>
							<div className={`${notificationStyles.sm} ${notificationStyles.darkText} text-center`}>
								<p>Zoom in and out using the pinch gesture or the mouse wheel.</p>
								<p>To avoid inadvertent clicks while zooming, other controls have been disabled.</p>
								<p>To reactivate game controls tap or click the <FontAwesomeIcon icon={faLock} /> icon.</p>
								<p>While in zoom mode use, use the directional pad to rotate the board.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	};
}