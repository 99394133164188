import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from 'idb';
import { Style } from '../../../../../../game-to-app/enums';
import { LevelDefinitionManager } from '../../../../../../game-to-app/level-definition-manager';
import { Dimension } from '../../../../../../game-to-app/types';
import { deepcopy } from '../../../../utils';
import { TransactionMode } from '../../../enums';
import { IModelCacheManager, IModelMetadata } from '../../../model-interfaces';
import { ILevel, ILevelUpdate } from '../../../views/levels';
import { IModelLevelValues } from './model';

/**
 * The CacheManager class implements a cache for the Levels model.
 * @export
 * @class CacheManager
 * @implements {IModelCache}
 */
export class CacheManager implements IModelCacheManager {
	private _database: IDBPDatabase;
	private _metadata: IModelMetadata<IModelLevelValues>;
	private _cache: Record<string, ILevel> = {};

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._metadata.name; }

	/**
	 * Creates an instance of CacheManager.
	 * @param {IDBPDatabase} database The database.
	 * @param {IModelMetadata} metadata The model metadata.
	 * @memberof CacheManager
	 */
	constructor(database: IDBPDatabase, metadata: IModelMetadata<IModelLevelValues>) {
		this._database = database;
		this._metadata = metadata;
	}

	/**
	 * Loads the model into the cache.
	 * @returns A promise that resolves when the cache is loaded.
	 */
	public load = async (): Promise<Record<string, ILevel>> => {
		const records: IModelLevelValues[] = await this._database.getAll(this._metadata.name);

		this._cache = records.reduce((acc: Record<string, ILevel>, item: IModelLevelValues): Record<string, ILevel> => {
			acc[item.id] = item;

			return acc;
		}, {});

		return this.get();
	};

	/**
	 * Updates a record in the cache and database.
	 * @param level The level.
	 * @returns A promise that resolves when the database was updated.
	 */
	public update = async (level: ILevelUpdate): Promise<Record<string, ILevel>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		const record: IModelLevelValues = await store.get(level.id);
		const base: IModelLevelValues = record || { ...this._metadata.defaults[0], ...this._metadata.systemValues };

		const dimensions: Dimension = {
			width: level.dimensions?.width !== undefined ? level.dimensions.width : base.dimensions.width,
			height: level.dimensions?.height !== undefined ? level.dimensions.height : base.dimensions.height,
			depth: level.dimensions?.depth !== undefined ? level.dimensions.depth : base.dimensions.depth,
		};

		const style: Style = level.style !== undefined ? level.style : base.style;
		const cellCount: number = LevelDefinitionManager.getCellCount(dimensions, style);

		// initialize update from updated level and base
		const update: IModelLevelValues = {
			...{
				id: level.id ? level.id : base.id,
				name: level.name !== undefined ? level.name : base.name,
				style: level.style !== undefined ? level.style : base.style,
				level: level.level !== undefined ? level.level : base.level,
				enableFlags: level.enableFlags !== undefined ? level.enableFlags : base.enableFlags,
				dimensions: dimensions,
				mines: Math.min(
					level.mines ? level.mines : base.mines,
					cellCount - 1,
				),
				hintLimit: level.hintLimit ? level.hintLimit : base.hintLimit,
			},
			...this._metadata.systemValues,
		};

		// create or update in database
		await store.put(update);

		// refresh cache
		await this.load();

		// return the entire levels cache
		return this.get();
	};

	/**
	 * Deletes a record in the cache and database.
	 * @param id The id.
	 * @returns A promise that resolves when the database record was deleted.
	 */
	public delete = async (id: string): Promise<Record<string, ILevel>> => {
		const transaction: IDBPTransaction = this._database.transaction([this._metadata.name], TransactionMode.ReadWrite);
		const store: IDBPObjectStore = transaction.objectStore(this._metadata.name);

		// create or update in database
		await store.delete(id);

		// refresh cache
		await this.load();

		// return the entire levels cache
		return this.get();
	};

	/**
	 * Gets the levels.
	 * @returns The levels.
	 */
	public get = (): Record<string, ILevel> => {
		return deepcopy(this._cache) as Record<string, ILevel>;
	};
}