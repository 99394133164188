import { ShopServiceStatus } from '../../enums';
import { Events as ShopEvents } from '../../events/events';
import { IShopProduct } from '../../interfaces';
import { IShopProvider } from '../interfaces';

/**
 * The NoOpShopProvider class implements a no-op shop provider.
 * @export
 * @class NoOpShopProvider
 * @implements {IShopProvider}
 */
export class NoOpShopProvider implements IShopProvider {
	private _events: ShopEvents = new ShopEvents();
	
	/**
	 * Gets the provider name.
	 * @readonly
	 */
	public get name(): string { return 'No-op'; }

	/**
	 * Gets a Boolean value indicating whether the provider is enabled.
	 * @readonly
	 */
	public get isEnabled(): boolean { return false; }

	/**
	 * Gets a Boolean value indicating whether the provider is open.
	 * @readonly
	 */
	public get isOpen(): boolean { return false; }

	/**
	 * Gets an enum value indicating the billing service status.
	 * @readonly
	 */
	public get serviceStatus(): ShopServiceStatus { return ShopServiceStatus.Unavailable; }

	/**
	 * Creates an instance of NoOpShopProvider.
	 * @param events The shop event handler.
	 * @memberof NoOpShopProvider
	 */
	public constructor(events: ShopEvents) {
		// events
		this._events = events;
	}

	/**
	 * Gets the shop products.
	 * @returns The products.
	 */
	public getProducts = (): Record<string, IShopProduct> => {
		return {};
	};

	/**
	 * Gets all product ids.
	 * @returns The product ids.
	 */
	public getProductIds = (): string[] => {
		return [];
	};

	/**
	 * Checks if an ad removing product is owned.
	 * @returns always returns false.
	 */
	public getAdRemovingProductIds = (): string[] => {
		return [];
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public purchase = (id: string): void => {
		// no-op
	};
	
	/**
	 * Initializes the shop.
	 */
	public initialize = (): void => {
		// no-op
	};
}

