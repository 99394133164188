import { Cell } from './cell';
import { GameOptions } from './game-options';
import { ISerializedFace } from './serialization-interfaces';
import { Nullable } from './types';

/**
 * The Face class represents a face on the game board.
 * @export
 */
export class Face {
	
	/**
	 * Gets the face width.
	 * @readonly
	 */
	public get width(): number { return this.height > 0 ? this._cells[0].length : 0; }

	/**
	 * Gets the face height.
	 * @readonly
	 */
	public get height(): number { return this._cells ? this._cells.length : 0; }

	/**
	 * Gets the face cells.
	 * @readonly
	 */
	public get cells(): Cell[][] { return this._cells; }

	private readonly _cells: Cell[][] = [];
	private readonly _options: GameOptions;

	/**
	 * Creates an instance of Face.
	 * @param cells The cells.
	 * @param options The game options.
	 * @memberof Face
	 */
	public constructor(cells: Cell[][], options: GameOptions) {
		this._cells = cells;
		this._options = options;
	}

	/**
	 * Gets a cell by its x, y coordinates.
	 * @returns The cell.
	 */
	public getCell = (x: number, y: number): Nullable<Cell> => {
		let result: Nullable<Cell> = null;

		if (x >= 0 && x < this.width &&
			y >= 0 && y < this.height) {
			result = this._cells[y][x];
		}

		return result;
	};

	/**
	 * Updates the face's cells status to show mine locations.
	 */
	public showMines = (): void => {
		if (this._cells) {
			this._cells.forEach((cells: Cell[]) => {
				if (cells) {
					cells.forEach((cell: Cell) => {
						if (cell) {
							cell.updateStatusShowMine();
						}
					});
				}
			})
		}
	};

	/**
	 * Serializes the face into an object.
	 * @returns The serialized face.
	 */
	public serialize = (): ISerializedFace => {
		const result: ISerializedFace = {
			cellIds: this._cells.map((cells: Cell[]) => cells.map((cell: Cell) => cell.id)),
		};

		return result;
	};

	/**
	 * Creates a face instance from a serialized face.
	 * @param serializedFace The serialized face.
	 * @param options The game options.
	 * @param cells The cells.
	 * @returns The face.
	 * @static
	 */
	public static createFromSerialization = (
		serializedFace: ISerializedFace,
		options: GameOptions,
		cells: Record<string, Cell>): Face => {

		// restore cell array in same order as before serialization
		const faceCells: Cell[][] = serializedFace.cellIds.map((cellIds: string[]) =>
			cellIds.map((cellId: string) => cells[cellId])
		);
		
		const face: Face = new Face(faceCells, options);

		return face;
	};
}