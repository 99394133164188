
import { App, AppInfo, AppState, RestoredListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Minimize } from 'minescube-plugin-minimize';
import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { Routes } from '../../components/shared/routes';
import { AppType, NetworkStatus } from '../enums';
import { SubscriptionEvent, SubscriptionEvents } from '../events/subscription-events';
import { IAppType, IRouteLocation } from '../interfaces';
import { QueryString } from '../querystring';
import { Events } from '../telemetry/events';
import { ICustomProperties } from '../telemetry/interfaces';
import { Telemetry } from '../telemetry/telemetry';
import { Constants } from './constants';
import { PlatformTypes } from './enums';

/**
 * The Platform class provides platform abstraction for the application.
 * @export
 * @class Platform
 */
export class Platform {
	// the singleton platform instance
	private static _instance: Platform = new Platform();

	/**
	 * The app type getter.
	 * @static
	 * @readonly
	 * @memberof Platform
	 */
	public static get appType(): IAppType { return Platform._instance._appType; }

	/**
	 * The app type.
	 * @memberof Platform
	 */
	private _appType: IAppType = {
		type: AppType.Unknown,
		platformVersion: metadata.version,
		appVersion: metadata.version,
		name: 'Unknown',
	};

	/**
	 * Creates an instance of Platform.
	 * @memberof Platform
	 */
	private constructor() {
		// singleton, instance should only be created from this class.
	}

	/**
	 * Initializes the platform.
	 * @returns A promise that resolves when the initialization is complete.
	 * @static
	 */
	public static initialize = (routeLocation: IRouteLocation): Promise<void> => {
		return Platform._instance.initialize(routeLocation);
	}
	/**
	 * Determines if the platform is Android.
	 * @returns true if the platform is Android; otherwise, false.
	 * @static
	 */
	public static isAndroid = (): boolean => {
		return Platform.appType.type === AppType.Android;
	};

	/**
	 * Determines if the platform is Android Trusted Web.
	 * @returns true if the platform is Android Trusted Web; otherwise, false.
	 * @static
	 */
	public static isAndroidTrustedWeb = (): boolean => {
		return Platform.appType.type === AppType.AndroidTrustedWeb;
	};

	/**
	 * Determines if the platform is Web.
	 * @returns true if the platform is Android Trusted Web; otherwise, false.
	 * @static
	 */
	public static isWeb = (): boolean => {
		return Platform.appType.type === AppType.Web;
	};

	/**
	 * Determines if the platform is unknown.
	 * @returns true if the platform is unknown; otherwise, false.
	 * @static
	 */
	public static isUnknown = (): boolean => {
		return Platform.appType.type === AppType.Unknown;
	};

	/**
	 * Gets the availability of the application as a string.
	 * @returns The availability.
	 * @static
	 */
	public static getAvailability = (): string => {
		const availability: NetworkStatus[] = [...Constants.defaultAvailability[Platform.appType.type]] || [];

		if (navigator.serviceWorker?.controller?.state === 'activated' &&
			availability.indexOf(NetworkStatus.Offline) < 0) {
			availability.push(NetworkStatus.Offline);
		}

		const availabilityNames: string[] = availability
			.map((status: NetworkStatus) => Constants.networkStatusNames[status])
			.sort()
			.reverse();

		return availabilityNames.join(' and ');
	};

	/**
	 * Determines if the current application is a production app.
	 * @returns true if the current application is a production app; otherwise, false.
	 * @static
	 */
	public static isProductionApplication = (): boolean => {
		return !!metadata.version && !metadata.version.endsWith(Constants.developmentVersionSuffix);
	};

	/**
	 * Minimizes the application.
	 * @static
	 */
	public static minimizeApplication = (): void => {
		if (Platform.isAndroid()) {
			Minimize.minimize();
		}
	};

	/**
	 * An event handler for when the application is closed.
	 * @static
	 */
	public static onExitApplication = (): void => {
		// cleanup
		SubscriptionEvent.unsubscribe(SubscriptionEvents.ExitApplication, Platform.onExitApplication);
		App.removeAllListeners();
	};

	/**
	 * Initializes the platform singleton type.
	 * @param routeLocation The route location.
	 * @returns A promise that resolves when the initialization is complete.
	 */
	private initialize = (routeLocation: IRouteLocation): Promise<void> => {
		return new Promise((resolve, reject) => {

			// raise an event when app state changes
			App.addListener('appStateChange', (state: AppState) => {
				SubscriptionEvent.raise(SubscriptionEvents.AppStateChanged, state.isActive);
			});
			
			// raise an event when the back button is clicked.
			App.addListener('backButton', () => {
				SubscriptionEvent.raise(SubscriptionEvents.AppBackButtonClicked);
			});

			// raise an event when the back button is clicked.
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			App.addListener('appRestoredResult', (event: RestoredListenerEvent) => {
				// no-op
			});
			
			// subscribe to exit event
			SubscriptionEvent.subscribe(SubscriptionEvents.ExitApplication, Platform.onExitApplication);

			// if previously detected, then there is no need to check again
			if (Platform.appType.type === AppType.Unknown) {

				if (Capacitor.getPlatform() === PlatformTypes.Android) {
					// get the version asynchronously
					App
						.getInfo()
						.then((info: AppInfo) => {
							this._appType = {
								type: AppType.Android,
								name: Constants.defaultNames[AppType.Android],
								platformVersion: info.version,
								appVersion: metadata.version,
							};

							// resolve promise
							resolve();
						})
						.catch((error) => {
							// failed
							reject(error);
						});
				}
				else {
					// if the current route is the root
					if (routeLocation?.pathname === Routes.Root) {
						// check query string for Android app query parameters
						if (routeLocation?.search) {
							const utmSource: Nullable<string> | undefined = QueryString.getQueryString(
								routeLocation.search,
								'utm_source');

							const version: Nullable<string> | undefined = QueryString.getQueryString(
								routeLocation.search,
								'version');

							if (utmSource === 'trusted-web-activity' && version) {
								this._appType = {
									type: AppType.AndroidTrustedWeb,
									platformVersion: version,
									appVersion: metadata.version,
									name: Constants.defaultNames[AppType.AndroidTrustedWeb],
								};
							}
						}
					}

					// if not detected yet, then it must be web
					if (this._appType.type === AppType.Unknown) {
						this._appType = {
							type: AppType.Web,
							platformVersion: metadata.version,
							appVersion: metadata.version,
							name: Constants.defaultNames[AppType.Web],
						};
					}

					// resolve promise
					resolve();
				}
			}
		});
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}