/**
 * The Guid class provides a function to generate unique identifiers.
 * @export
 * @class Guid
 */
export class Guid {
	/**
	 * Generates a new Guid identifier.
	 * @returns A new Guid identifier.
	 * @static
	 */
	public static newGuid = (): string => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character): string => {
			const random: number = Math.random() * 16 | 0;
			const result = character == 'x' ? random : (random & 0x3 | 0x8);

			return result.toString(16);
		});
	};
}