import { Platform } from "../../platform/platform";

/**
 * The AudioSource class provides the source for audio files.
 */
export class AudioSource {
	private _boomPath = !Platform.isAndroid() ?  require('../../../sounds/boom.mp3') : null;
	private _clickPath = !Platform.isAndroid() ? require('../../../sounds/click.mp3') : null;
	private _winPath = !Platform.isAndroid() ? require('../../../sounds/win.mp3') : null;
	private _lossPath = !Platform.isAndroid() ? require('../../../sounds/loss.mp3') : null;

	/**
	 * Gets the 'boom' source.
	 * @readonly
	 */
	public get boom(): string { return this._boomPath?.default || ''; }

	/**
	 * Gets the 'click' source.
	 * @readonly
	 */
	public get click(): string { return this._clickPath?.default || ''; }

	/**
	 * Gets the 'loss' source.
	 * @readonly
	 */
	public get loss(): string { return this._lossPath?.default || ''; }

	/**
	 * Gets the 'win' source.
	 * @readonly
	 */
	public get win(): string { return this._winPath?.default || ''; }
}
