import { faCheck, faLock, faSearchPlus, faShareAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Levels, Style } from '../../../game-to-app/enums';
import { IIdValue, ILevelDefinition } from '../../../game-to-app/interfaces';
import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { SubscriptionEvent, SubscriptionEvents } from '../../shared/events/subscription-events';
import { Platform } from '../../shared/platform/platform';
import { ReviewManager } from '../../shared/reviews/review-manager';
import { ShopManager } from '../../shared/shop/shop-manager';
import { Scoreboard } from '../scoreboard/scoreboard';
import { SocialMediaShareType, UpdateHeightControl } from '../shared/enums';
import { GameStyle } from '../shared/game-style';
import { LevelManager } from '../shared/level-manager';
import { Routes } from '../shared/routes';
import { OnUpdateHeightComplete } from '../shared/types';
import navigationStyles from './navigation.module.css';
import { IProps } from './props';
import { IState } from './state';
import { PendingOnClick } from './types';

/**
 * The Navigation class implements the <Navigation/> component.
 * @export
 * @class Navigation
 * @extends {React.Component<IProps, IState>}
 */
export class Navigation extends React.Component<IProps, IState> {
	private _ref = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;

	/**
	 * Creates an instance of Navigation.
	 * @param {IProps} props
	 * @memberof Navigation
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			expanding: false,
			expanded: false,
			height: 0,
			heightObserverId: 0,
			pendingOnClick: null,
			showShopIcon: this.showShopIcon(),
		};
	}

	/**
	 * A react lifecycle function for the <Navigation/> component.
	 */
	public componentDidMount = (): void => {
		this._isMounted = true;
		this.updateHeight();
		const heightObserverId = window.setInterval(this.updateHeight, 100);
		this.setState({ heightObserverId: heightObserverId });

		SubscriptionEvent.subscribe(SubscriptionEvents.OwnsAllProductsStatusChanged, this.onAllProductsOwnedChanged);
	};

	/**
	 * A react lifecycle function for the <Navigation/> component.
	 */
	public componentWillUnmount = (): void => {
		this._isMounted = false;

		if (this.state.heightObserverId) {
			window.clearInterval(this.state.heightObserverId);
		}

		SubscriptionEvent.unsubscribe(SubscriptionEvents.OwnsAllProductsStatusChanged, this.onAllProductsOwnedChanged);
	};

	/**
	 * Renders the <Navigation/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const levels: ILevelDefinition[] = LevelManager.getLevelDefinitionsForStyle(this.props.navigation.selected.style);
		const styles: IIdValue[] = GameStyle.getStyles();
		const styleName: string = GameStyle.getValue(this.props.navigation.selected.style) || 'Style';
		const selectedLevel: ILevelDefinition = levels.filter((levelDefinition: ILevelDefinition): boolean =>
			levelDefinition.id === this.props.navigation.selected.id)[0];
		const levelName: string = selectedLevel?.name || 'Level';

		let newIconClassName: string = navigationStyles.newIcon;

		if (this.props.navigation.gameOver.lastGameResult?.won) {
			newIconClassName = navigationStyles.newIconWon;
		}

		if (this.props.navigation.gameOver.lastGameResult?.lost) {
			newIconClassName = navigationStyles.newIconLost;
		}

		const dpadIconClassNames = [
			navigationStyles.dpadIcon,
			this.props.navigation.directionalPad.active ? navigationStyles.active : '',
			this.props.navigation.selected.style === Style.Classic ? navigationStyles.disabled : ''
		].join(' ');

		const showAppStoreMenuItem: boolean = this.showAppStoreMenuItem();
		const showShopMenuItem: boolean = this.showShopMenuItem();
		const showReviewMenuItem: boolean = this.showReviewMenuItem();

		const shareOrShop: JSX.Element = !this.state.showShopIcon
			? <div
				onClick={this.onClickShareSocial}
				className={`${navigationStyles.share}`}>
				<div>
					<FontAwesomeIcon className={navigationStyles.faShareAlt} icon={faShareAlt} />
				</div>
			</div>
			: <div
				onClick={this.onClickShopIcon}
				className={`${navigationStyles.shop}`}>
				<div>
					<div
						className={navigationStyles.shopIcon}
					/>
				</div>
			</div>;

		return (
			<div
				className={`${navigationStyles.navigation} container`}
			>
				<Navbar
					id='navbar-container'
					ref={this._ref}
					bg='dark'
					variant='dark'
					expand='xl'
					fixed='top'
					expanded={this.state.expanded}
					onToggle={this.onToggle}
				>
					<Navbar.Brand href='#' className={navigationStyles.brand}>
						<div className={`${navigationStyles.image} ${navigationStyles.brandLogo}`} />
						<span className={navigationStyles.brandName}>mines<sup> 3</sup></span>
					</Navbar.Brand>
					<Container className={navigationStyles.shareMenuCollapsed}>
						{shareOrShop}
						<Navbar.Toggle className={navigationStyles.toggle} aria-controls='navbar' />
					</Container>
					<Navbar.Collapse id='navbar'
						onEntering={this.onNavbarExpanded}
						onEntered={this.onNavbarExpanded}
						onExited={this.onNavbarCollapsed}
						className={navigationStyles.navbarCollapse}
					>
						<Nav className='mr-auto'>
							<NavDropdown
								defaultValue={this.props.navigation.selected.style}
								title={`${styleName}`}
								id='dropdown-style'
							>
								{styles.map((style) => {
									return (
										<NavDropdown.Item
											key={`${style.id}`}
											eventKey={`${style.id}`}
											href='#'
											onSelect={this.onClickSelectedStyle}
										>
											{style.value}
										</NavDropdown.Item>
									);
								})}
							</NavDropdown>
							<NavDropdown
								defaultValue={this.props.navigation.selected.level}
								title={<div className={navigationStyles.title}>
									<div className={navigationStyles.titleText}>{`${levelName}`}</div>
								</div>}
								id='dropdown-level'
							>
								{levels.map((level) => {
									return (
										<NavDropdown.Item
											key={`${level.level}###${level.id}`}
											eventKey={`${level.level}###${level.id}`}
											href='#'
											onSelect={this.onClickSelectedLevel}
										>
											{level.name}
										</NavDropdown.Item>
									);
								})}
							</NavDropdown>
							<NavDropdown
								title='Options'
								id='options'
							>
								<NavDropdown.Item
									key='options-assistant'
									eventKey='options-assistant'
									href='#'
									onSelect={this.onClickAssistant}
									className={navigationStyles.checkable}
								>
									<FontAwesomeIcon
										className={this.props.navigation.assistant.active
											? navigationStyles.checked
											: navigationStyles.unchecked}
										icon={faCheck}
									/>
									Assistant
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									key='options-settings'
									eventKey='options-settings'
									to={Routes.Settings}
									onSelect={this.onClickSettings}
									className={navigationStyles.checkable}
								>
									<FontAwesomeIcon
										className={navigationStyles.unchecked}
										icon={faCheck}
									/>
									Settings
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									key='options-levels'
									eventKey='options-levels'
									to={Routes.Levels}
									onSelect={this.onClickCustomLevels}
									className={navigationStyles.checkable}
								>
									<FontAwesomeIcon
										className={navigationStyles.unchecked}
										icon={faCheck}
									/>
									Custom Levels
								</NavDropdown.Item>

							</NavDropdown>
							<NavDropdown
								title='App'
								id='app'
							>
								{showShopMenuItem
									? < NavDropdown.Item
										as={Link}
										key='options-shop'
										eventKey='options-shop'
										to={Routes.Shop}
										onSelect={this.onClickShop}
										className={navigationStyles.checkable}
									>
										<FontAwesomeIcon
											className={navigationStyles.unchecked}
											icon={faCheck}
										/>
										Shop
									</NavDropdown.Item>
									: null}
								{showAppStoreMenuItem
									? < NavDropdown.Item
										as={Link}
										key='options-appstore'
										eventKey='options-appstore'
										to={Routes.AppStore}
										onSelect={this.onClickAppStore}
										className={navigationStyles.checkable}
									>
										<FontAwesomeIcon
											className={navigationStyles.unchecked}
											icon={faCheck}
										/>
										Get the App
									</NavDropdown.Item>
									: null}
								{showReviewMenuItem
									? < NavDropdown.Item
										key='options-review'
										eventKey='options-review'
										href='#'
										onSelect={this.onClickReview}
										className={navigationStyles.checkable}
									>
										<FontAwesomeIcon
											className={navigationStyles.unchecked}
											icon={faCheck}
										/>
										Review
									</NavDropdown.Item>
									: null}
								<NavDropdown.Item
									key='options-share'
									eventKey='options-share'
									href='#'
									onSelect={this.onClickShareSocial}
									className={navigationStyles.checkable}
								>
									<FontAwesomeIcon
										className={navigationStyles.unchecked}
										icon={faCheck}
									/>
									Share
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown
								title='Help'
								id='help'
							>
								<NavDropdown.Item
									as={Link}
									key='help-play'
									eventKey='help-play'
									onSelect={this.onClickHelp}
									to={Routes.Help}
								>
									How to Play
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									key='help-quickstart'
									eventKey='help-quickstart'
									to={Routes.QuickStart}
									onSelect={this.onClickQuickStart}
								>
									Quick Start
								</NavDropdown.Item>
								<NavDropdown.Item
									as={Link}
									key='help-about'
									eventKey='help-about'
									to={Routes.About}
									onSelect={this.onClickAbout}
								>
									About
								</NavDropdown.Item>
							</NavDropdown>
						</Nav>
					</Navbar.Collapse>
					<Container className={navigationStyles.shareExpanded}>
						{shareOrShop}
					</Container>
					<Container className={navigationStyles.commands}>
						<Container className={navigationStyles.innerCommands}>
							<div
								onClick={this.onClickNewGame}
								className={`${navigationStyles.new}`}>
								<div>
									<div
										className={newIconClassName}
									/>
								</div>
							</div>
							<div
								onClick={this.props.navigation.events.onClickZoom}
								className={`${navigationStyles.zoom} ${this.props.navigation.zoom.active ? navigationStyles.active : ''}`}
							>
								<div>
									<FontAwesomeIcon className={navigationStyles.icon} icon={faSearchPlus} />
									{this.props.navigation.zoom.scale.toFixed(2)}
								</div>
							</div>
							<div
								onClick={this.props.navigation.events.onClickLock}
								className={`${navigationStyles.lock} ${this.props.navigation.lock.locked ? navigationStyles.active : ''}`}>
								<div>
									<FontAwesomeIcon className={navigationStyles.icon} icon={this.props.navigation.lock.locked ? faLock : faUnlock} />
								</div>
							</div>
							<div
								onClick={this.props.navigation.events.onClickDirectionalPad}
								className={`${navigationStyles.dpad}`}>
								<div>
									<div
										className={dpadIconClassNames}
									/>
								</div>
							</div>
						</Container>
					</Container>
					<Container className={navigationStyles.scoreBoardContainer}>
						<div className={navigationStyles.scoreBoard}>
							<Scoreboard
								score={this.props.navigation.scoreboard.score}
								stats={this.props.navigation.scoreboard.stats}
							/>
						</div>
					</Container>
					<div className={navigationStyles.spacer}></div>
				</Navbar>
			</div>
		);
	};

	/**
	 * The event handler for collapsing or expanding the navigation bar.
	 * @param expanded A Boolean value indicating whether the navigation menu should be expanded.
	 * @param pendingOnClick An event handler to execute after the state was updated; optional.
	 * @private
	 */
	private onToggle = (
		expanded: boolean,
		pendingOnClick?: Nullable<PendingOnClick>): void => {
		let toggleNow: boolean = false;

		this.setState(
			(prevState: Readonly<IState>) => {
				toggleNow = prevState.expanded !== expanded;

				return ({
					expanded: expanded,
					pendingOnClick: (toggleNow && pendingOnClick ? pendingOnClick : prevState.pendingOnClick) || prevState.pendingOnClick,
				});
			},
			(): void => {
				if (!toggleNow && pendingOnClick) {
					pendingOnClick();
				}
			}
		);
	};

	/**
	 * The click event handler for the 'New Game' link.
	 * @private
	 */
	private onClickNewGame = (): void => {
		this.onToggle(false, this.props.navigation.events.onClickNewGame);
	};

	/**
	 * The click event handler for the level dropdown in the navigation bar.
	 * @param eventKey The event key.
	 * @param event The event.
	 * @private
	 */
	private onClickSelectedLevel = (
		eventKey: string | null,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		event?: React.SyntheticEvent<unknown>): void => {
		const parts: string[] = eventKey?.split('###') || [];
		let level: Levels = Levels.Easy;
		let id: string = '';

		if (parts.length > 1) {
			level = parseInt(parts[0]);
			id = parts[1];
		}
		else {
			// fallback - should not be needed
			const levels: ILevelDefinition[] = LevelManager
				.getLevelDefinitionsForStyle(this.props.navigation.selected.style);

			level = levels[0].level;
			id = levels[0].id;
		}

		this.onToggle(false, (): void => this.props.navigation.events.onClickChangeLevel(
			this.props.navigation.selected.style,
			level,
			id));
	};

	/**
	 * The click event handler for the style dropdown in the navigation bar.
	 * @param eventKey The event key.
	 * @param event The event.
	 * @private
	 */
	private onClickSelectedStyle = (
		eventKey: string | null,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		event?: React.SyntheticEvent<unknown>): void => {
		let style: Style = Style.Classic;

		if (eventKey) {
			style = parseInt(eventKey);
		}

		// switching styles when on custom level -> default to easy
		const level: Levels = this.props.navigation.selected.level === Levels.Custom
			? Levels.Easy
			: this.props.navigation.selected.level;

		// get the level id
		const levels: ILevelDefinition[] = LevelManager
			.getLevelDefinitionsForLevel(this.props.navigation.selected.style, level);

		this.onToggle(false, (): void => this.props.navigation.events.onClickChangeLevel(
			style,
			level,
			levels[0].id));
	};

	/**
	 * The click event handler for the Options / Assistant option in the navigation bar dropdown.
	 * @private
	 */
	private onClickAssistant = (): void => {
		this.onToggle(false, this.props.navigation.events.onClickAssistant);
	};

	/**
	 * The click event handler for the Options / Settings option in the navigation bar dropdown.
	 * @private
	 */
	private onClickSettings = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the Options / Custom Levels option in the navigation bar dropdown.
	 * @private
	 */
	private onClickCustomLevels = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the shop icon in the navigation bar dropdown.
	 * @private
	 */
	private onClickShopIcon = (): void => {
		this.props.navigation.events.onClickShop();
	};

	/**
	 * The click event handler for the Options / Shop option in the navigation bar dropdown.
	 * @private
	 */
	private onClickShop = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the Help / How to Play option in the navigation bar dropdown.
	 * @private
	 */
	private onClickHelp = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the Help / Quick Start option in the navigation bar dropdown.
	 * @private
	 */
	private onClickQuickStart = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the Help / App Store option in the navigation bar dropdown.
	 * @private
	 */
	private onClickAppStore = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the Help / About option in the navigation bar dropdown.
	 * @private
	 */
	private onClickAbout = (): void => {
		this.onToggle(false);
	};

	/**
	 * The click event handler for the social share button.
	 * @private
	 */
	private onClickShareSocial = (): void => {
		this.onToggle(
			false,
			(): void => this.props.navigation.events.onClickShareSocial(SocialMediaShareType.Page, null)
		);
	};

	/**
	 * The click event handler for the review option.
	 * @private
	 */
	private onClickReview = (): void => {
		this.onToggle(
			false,
			(): void => ReviewManager.openStoreListing()
		);
	};

	/**
	 * The event handler for when the navigation bar is expanded.
	 * @private
	 */
	private onNavbarExpanded = (): void => {
		this.setState(() => {
			return ({ expanding: true });
		});

		this.updateHeight();
	};

	/**
	 * The event handler for when the navigation bar is collapsed.
	 * @private
	 */
	private onNavbarCollapsed = (): void => {
		let pendingOnClick: Nullable<PendingOnClick> = null;

		this.setState((prevState: Readonly<IState>) => {
			pendingOnClick = prevState.pendingOnClick;
			return ({ expanding: false, pendingOnClick: null });
		},
			(): void => {
				this.updateHeight(
					false,
					(): void => {
						if (pendingOnClick) {
							pendingOnClick();
						}
					}
				);
			}
		);
	};

	/**
	 * An event handler for when the product ownership status changes.
	 */
	private onAllProductsOwnedChanged = (): void => {
		this.setState({ showShopIcon: this.showShopIcon() });
	};

	/**
	 * Updates the height of the component and propagates the value if changed.
	 * @param isExpanding A Boolean value indicating whether to the navigation bar is expanding; optional.
	 * @private
	 */
	private updateHeight = (
		isExpanding: Nullable<boolean> = null,
		onComplete: Nullable<OnUpdateHeightComplete> = null): void => {

		if (this._isMounted) {
			const { height, expanding } = this.state;
			const elementHeight: number = this._ref.current?.getBoundingClientRect()?.height || 0;

			if (elementHeight !== height || isExpanding !== null) {
				this.setState({ height: elementHeight });

				this.props.navigation.events.onUpdateHeight(
					UpdateHeightControl.NavigationBar,
					elementHeight,
					isExpanding !== null ? isExpanding : expanding,
					onComplete
				);
			}
		}
	};

	/**
	 * Determines if the app store menu item should be shown.
	 * @returns true if the app store menu item should be shown; otherwise, false.
	 */
	private showAppStoreMenuItem = (): boolean => {
		return !Platform.isAndroid() && metadata.appstore.stores.some((store) => store.enabled);
	};

	/**
	 * Determines if the shop menu item should be shown.
	 * @returns true if the shop menu item should be shown; otherwise, false.
	 */
	private showShopMenuItem = (): boolean => {
		return ShopManager.isEnabled;
	};

	/**
	 * Determines if the shop menu item should be shown.
	 * @returns true if the shop menu item should be shown; otherwise, false.
	 */
	private showReviewMenuItem = (): boolean => {
		return ReviewManager.canOpenStoreListing();
	};

	/**
	 * Determines if the shop icon should be shown.
	 * @returns true if the shop icon should be shown; otherwise, false.
	 */
	private showShopIcon = (): boolean => {
		return (ShopManager.isEnabled) && !ShopManager.ownsAllProducts;
	};
}