import { Dictionary } from '../dictionary';

export class Events {
	private _listeners: Dictionary<EventListener[]> = new Dictionary();

	/**
	* Adds a listener for an event.
	* @param event The event.
	* @param listener The listener.
	* @returns true if the listener was added; otherwise, false.
	*/
	public addListener = (event: string, listener: EventListener): boolean => {
		const listeners: EventListener[] = this._listeners.get(event) || [];
		const result = listeners.indexOf(listener) < 0;
		
		if (result) {
			listeners.push(listener);
			this._listeners.set(event, listeners);
		}

		return result;
	};

	/**
	 * Removes a listener for an event.
	 * @param event The event.
	 * @param listener The listener.
	 * @returns true if the listener was unsubscribed; otherwise, false.
	 */
	public removeListener = (event: string, listener: EventListener): boolean => {
		const listeners: EventListener[] = this._listeners.get(event) || [];
		const index = listeners.indexOf(listener);

		if (index >= 0) {
			listeners.splice(index, 1);
			this._listeners.set(event, listeners);
		}

		return index >= 0;
	};

	/**
	 * Raises an event.
	 * @param event The event.
	 * @param args The event parameters.
	 * @static
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public raise = (event: string, ...args: any[]): void => {
		const listeners: EventListener[] = [...(this._listeners.get(event) || [])];

		listeners.forEach((listener: EventListener): void => {
			listener(...args);
		});
	};
}

/** 
 * Defines the type for an event listener.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventListener = (...args: any[]) => void;