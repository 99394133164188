import { IAPProduct, InAppPurchase2 as Store, PlayStoreReceipt } from '@ionic-native/in-app-purchase-2';
import { Nullable } from '../../../../shared/types';
import { AppStoreShopProductType, ShopProductStatus } from '../../enums';
import { IShopProduct, IShopProductDeclaration } from '../../interfaces';

/**
 * The ProductConverter class provides functions convert between the external and internal product definitions.
 * @export
 * @class ProductConverter
 */
export class ProductConverter {

	/**
	 * Converts an internal product to an external product.
	 * @returns The external product.
	 * @static
	 */
	public static toInternal = (
		product: IAPProduct,
		declarations: Nullable<Record<string, IShopProductDeclaration>>): Nullable<IShopProduct> => {
		const type: Nullable<AppStoreShopProductType> = ProductConverter.productTypeToInternal(product?.type);
		let result: Nullable<IShopProduct> = null;

		if (type !== null) {
			const declaration: Nullable<IShopProductDeclaration> = declarations
				? (declarations[product.id] || null)
				: null;
			
			const purchaseToken: Nullable<string> = product.transaction
				? (product.transaction as PlayStoreReceipt)?.purchaseToken
				: null;
			
			if (declaration?.enabled) {
				result = ({
					id: product.id,
					type: type,
					name: product.title,
					description: product.description,
					status: ProductConverter.productStateToInternal(product.state),
					price: product.price,
					currency: product.currency,
					available: product.canPurchase,
					sortOrder: declaration ? declaration.sortOrder : Number.MAX_VALUE,
					enabled: true,
					testProduct: declaration.testProduct,
					removesAds: declaration.removesAds,
					token: purchaseToken,
				});
			}
		}

		return result;
	};

	/**
	 * Converts the product type from internal to external definition.
	 * @param type The internal product type.
	 * @returns The external product type.
	 * @private
	 */
	public static productTypeToExternal = (type: Nullable<AppStoreShopProductType> | undefined): Nullable<string> => {
		let result: Nullable<string> = null;

		if (type !== null && type !== undefined) {
			switch (type) {
				case AppStoreShopProductType.NonConsumable:
					result = Store.NON_CONSUMABLE;
					break;
				case AppStoreShopProductType.Consumable:
					result = Store.CONSUMABLE;
					break;
				case AppStoreShopProductType.FreeSubscription:
					result = Store.FREE_SUBSCRIPTION;
					break;
				case AppStoreShopProductType.PaidSubscription:
					result = Store.PAID_SUBSCRIPTION;
					break;
				case AppStoreShopProductType.NonRenewingSubscription:
					result = Store.NON_RENEWING_SUBSCRIPTION;
					break;
			}
		}
		
		return result;
	};

	public static productStateToInternal = (state: string): ShopProductStatus => {
		let result: ShopProductStatus = ShopProductStatus.Unknown;

		switch (state) {
			case Store.APPROVED:
				result = ShopProductStatus.Approved;
				break;
			case Store.DOWNLOADED:
				result = ShopProductStatus.Downloaded;
				break;
			case Store.DOWNLOADING:
				result = ShopProductStatus.Downloading;
				break;
			case Store.FINISHED:
				result = ShopProductStatus.Finished;
				break;
			case Store.INITIATED:
				result = ShopProductStatus.Initiated;
				break;
			case Store.INVALID:
				result = ShopProductStatus.Invalid;
				break;
			case Store.OWNED:
				result = ShopProductStatus.Owned;
				break;
			case Store.REGISTERED:
				result = ShopProductStatus.Registered;
				break;
			case Store.REQUESTED:
				result = ShopProductStatus.Requested;
				break;
			case Store.VALID:
				result = ShopProductStatus.Valid;
				break;
		}
		return result;
	}
	/**
	 * Converts the product type from external to internal definition.
	 * @param type The external product type.
	 * @returns The internal product type.
	 * @private
	 */
	public static productTypeToInternal = (type: string): Nullable<AppStoreShopProductType> => {
		let result: Nullable<AppStoreShopProductType> = null;

		switch (type) {
			case Store.NON_CONSUMABLE:
				result = AppStoreShopProductType.NonConsumable;
				break;
			case Store.CONSUMABLE:
				result = AppStoreShopProductType.Consumable;
				break;
			case Store.FREE_SUBSCRIPTION:
				result = AppStoreShopProductType.FreeSubscription;
				break;
			case Store.PAID_SUBSCRIPTION:
				result = AppStoreShopProductType.PaidSubscription;
				break;
			case Store.NON_RENEWING_SUBSCRIPTION:
				result = AppStoreShopProductType.NonRenewingSubscription;
				break;
		}

		return result;
	};
}