import { SubscriptionEvent, SubscriptionEvents } from '../shared/events/subscription-events';

/**
 * The ServiceWorkerConfiguration class configures event handlers for the service worker.
 * @export
 * @class ServiceWorkerConfiguration
 */
export class ServiceWorkerConfiguration {

	/**
	 * The event handler for the 'success' event.
	 * @param registration The service worker registration.
	 * @static
	 */
	public static onSuccess = (registration: ServiceWorkerRegistration): void => {
		SubscriptionEvent.raise(SubscriptionEvents.ServiceWorkerInstalled, registration);
	}

	/**
	 * The event handler for the 'update' event.
	 * @param registration The service worker registration.
	 * @static
	 */
	public static onUpdate = (registration: ServiceWorkerRegistration): void => {
		SubscriptionEvent.raise(SubscriptionEvents.ServiceWorkerUpdated, registration);
	}
}