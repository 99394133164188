
import { IDBPDatabase } from 'idb';
import { Nullable } from '../../../../../../shared/types';
import { DefaultMetadata } from '../../../default-metadata';
import { ModelNames } from '../../../enums';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager } from '../../../model-interfaces';
import { CacheManager } from './cache-manager';
import { IModelGameStatsValues } from './model';
import { UpgradeManager } from './upgrade-manager';

/**
 * The Metadata class defines the model metadata.
 */
export class Metadata extends DefaultMetadata<IModelGameStatsValues> implements IModelMetadata<IModelGameStatsValues> {
	protected _name: string = ModelNames.GameStats;
	protected _schemaVersion: number = 2;
	protected _defaults: IModelGameStatsValues[] = [];

	/**
	 * Initializes the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	protected initializeUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		return new UpgradeManager(database, this);
	};

	/**
	 * Initializes the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	protected initializeCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		return new CacheManager(database, this);
	};
}