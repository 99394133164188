import deepequal from 'deep-equal';
import deepmerge from 'deepmerge';
import { SubscriptionEvent, SubscriptionEvents } from '../../events/subscription-events';
import { deepcopy } from '../../utils';
import { IModelCacheManager, IStore } from '../model-interfaces';
import { IUserPreferences, IUserPreferencesUpdate } from '../views/userpreferences';

/**
 * The UserPreferencesStore class implements the store for user preferences.
 * @export
 * @class UserPreferencesStore
 */
export class UserPreferencesStore implements IStore {
	private _cacheManager: IModelCacheManager;
	private _userPreferences: IUserPreferences;

	/**
	 * Gets the name.
	 * @readonly
	 */
	public get name(): string { return this._cacheManager.name; }

	/**
	 * Creates an instance of UserPreferencesStore.
	 * @param {IModelCacheManager} cacheManager The cache manager.
	 * @memberof UserPreferencesStore
	 */
	constructor(cacheManager: IModelCacheManager) {
		this._cacheManager = cacheManager;
		this._userPreferences = this._cacheManager.get();
	}

	/**
	 * Gets the user preferences.
	 * @param refresh A Boolean value indicating if the settings should be taken from the most recent database cache.
	 * @returns The user preferences.
	 */
	public get = (refresh: boolean = false): IUserPreferences => {
		if (refresh) {
			const refreshedUserPreferences: IUserPreferences = this._cacheManager.get() as IUserPreferences;

			if (!deepequal(refreshedUserPreferences, this._userPreferences)) {
				this._userPreferences = refreshedUserPreferences;
				SubscriptionEvent.raise(SubscriptionEvents.UserPreferencesChanged, this.name, this._userPreferences);
			}
		}

		return deepcopy(this._userPreferences);
	};

	/**
	 * Updates user preferences.
	 * @param userPreferences The user preferences to update.
	 * @param refresh A Boolean value indicating if the settings should be taken from the most recent database cache.
	 * @returns The updated user preferences.
	 */
	public update = async (userPreferences: IUserPreferencesUpdate, refresh: boolean = false): Promise<IUserPreferences> => {
		let updatedUserPreferences: IUserPreferences = deepmerge(this._userPreferences, userPreferences) as IUserPreferences;

		// review sessions should be replaced, not merged
		if (userPreferences.review?.sessions !== undefined) {
			updatedUserPreferences.review.sessions = [...(userPreferences.review.sessions || [])];
		}

		if (!deepequal(updatedUserPreferences, this._userPreferences)) {
			// update the store settings synchronously
			this._userPreferences = updatedUserPreferences;

			if (!refresh) {
				SubscriptionEvent.raise(SubscriptionEvents.UserPreferencesChanged, this._userPreferences);
			}

			// update the database
			updatedUserPreferences = await this._cacheManager
				.update(userPreferences)
				.catch(() => {
					// set updated user preferences in case of update failure
					updatedUserPreferences = this._userPreferences;
				});


			// optionally update the settings asynchronously from the database
			if (refresh && !deepequal(updatedUserPreferences, this._userPreferences)) {
				this._userPreferences = updatedUserPreferences;
				SubscriptionEvent.raise(SubscriptionEvents.UserPreferencesChanged, this.name, this._userPreferences);
			}
		}

		return this._userPreferences;
	};
}