import { ILevelPreference } from '../../../../../components/shared/interfaces';
import { CookieProvider } from '../../../cookie-provider';
import { DefaultUpgrade } from '../../../default-upgrade';
import { IModelUserPreferenceValues } from './model';

/**
 * The UpgradeManager class implements the upgrade for the user preferences model.
 * @export
 * @class UpgradeManager
 * @extends {DefaultUpgrade}
 */
export class UpgradeManager extends DefaultUpgrade<IModelUserPreferenceValues> {
	/**
	* Performs data migration for the database upgrade.
	* @param oldVersion The old database version.
	* @param newVersion The new database version.
	* @returns A promise that resolves when the migration is complete.
	*/
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public migrate = async (oldVersion: number, newVersion: number): Promise<boolean> => {
		// get current preferences from cookies
		const cookies: CookieProvider = new CookieProvider();
		const levelPreference: ILevelPreference = cookies.getLevelPreference({
			style: this._metadata.defaults[0].level.style,
			level: this._metadata.defaults[0].level.level,
		});
		const quickStartShowAgain: boolean =
			cookies.getQuickStartShowAgain(this._metadata.defaults[0].notifications.quickStart);
		const zoomModeShowAgain: boolean =
			cookies.getZoomModeShowAgain(this._metadata.defaults[0].notifications.zoomMode);

		// prepare values
		const preferences: IModelUserPreferenceValues = {
			...{
				id: this._metadata.name,
				level: {
					level: levelPreference.level,
					style: levelPreference.style,
				},
				notifications: {
					quickStart: quickStartShowAgain,
					zoomMode: zoomModeShowAgain,
				},
			},
			...this._metadata.systemValues,
		};

		// create user preferences
		await this._database.add(this._metadata.name, preferences);

		return true;
	};
}