import { DBSchema } from 'idb';
import { IModelMetadataVersioned } from '../../model-interfaces';
import { Metadata as settingsMetadata } from '../v1/settings/metadata';
import { IModelSettings } from '../v1/settings/model';
import { Metadata as gameStatsMetadata } from './gamestats/metadata';
import { IModelGameStats } from './gamestats/model';
import { Metadata as levelsMetadata } from './levels/metadata';
import { IModelLevels } from './levels/model';
import { Metadata as userPreferencesMetadata } from './user-preferences/metadata';
import { IModelUserPreferences } from './user-preferences/model';

/**
 * Defines the interface for the v2 application model.
 * @export
 * @interface IModel
 * @extends {DBSchema}
 * @extends {IModelGameStats}
 * @extends {IModelSettings}
 * @extends {IModelUserPreferences}
 * @extends {IModelLevels}
 */
export interface IModel extends DBSchema, IModelGameStats, IModelSettings, IModelUserPreferences, IModelLevels { }

/** 
 * Defines the v2 model metadata.
 */
export const modelMetadata: IModelMetadataVersioned[] = [
	{ metadata: new gameStatsMetadata(), requiresUpgrade: true },
	{ metadata: new settingsMetadata(), requiresUpgrade: false },
	{ metadata: new userPreferencesMetadata(), requiresUpgrade: true },
	{ metadata: new levelsMetadata(), requiresUpgrade: true },
];