
import { Nullable } from '../../../shared/types';
import { IReviewProvider } from './interfaces';
import { ReviewFactory } from './review-factory';

/**
 * The ReviewManager class manages prompting for reviews in the application.
 * @export
 * @class ReviewManager
 */
export class ReviewManager {
	// the singleton platform instance
	private static _instance: Nullable<ReviewManager> = null;
	private _reviewProvider: IReviewProvider = ReviewFactory.getReviewProvider();

	/**
	 * Initializes the review manager.
	 * @static
	 */
	public static initialize = (): void => {
		if (ReviewManager._instance === null) {
			ReviewManager._instance = new ReviewManager();
			ReviewManager._instance._reviewProvider.initialize();
		}
	};

	/**
	 * Determines if the store listing can be opened.
	 * @returns A Boolean value indicating whrther the store listing can be opened.
	 * @static
	 */
	public static canOpenStoreListing = (): boolean => {
		// make sure the manager is initialized.
		ReviewManager.initialize();

		// prompt
		return (ReviewManager._instance as ReviewManager)._reviewProvider.canOpenStoreListing();
	};

	/**
	 * Opens the store listing.
	 * @static
	 */
	public static openStoreListing = (): void => {
		// make sure the manager is initialized.
		ReviewManager.initialize();

		// prompt
		(ReviewManager._instance as ReviewManager)._reviewProvider.openStoreListing();
	};

	/**
	 * Prompts for an app review.
	 * @param checkEligibility A Boolean value indicating whether to check for eligibility.
	 * @static
	 */
	public static requestPrompt = async (checkEligibility: boolean): Promise<void> => {
		// make sure the manager is initialized.
		ReviewManager.initialize();

		// prompt
		return (ReviewManager._instance as ReviewManager)._reviewProvider.requestPrompt(checkEligibility);
	};

	/**
	 * Determines whether the app is currently eligible to prompt for a review.
	 * @returns A Boolean value indicating whether the app is currently eligible to prompt for a review.
	 * @static
	*/
	public static isEligibleForPrompt = (): boolean => {
		// make sure the manager is initialized.
		ReviewManager.initialize();

		// prompt
		return (ReviewManager._instance as ReviewManager)._reviewProvider.isEligibleForPrompt();
	};

	/**
	 * Updates the current session with a win or loss.
	 * @param win A Boolean value indicating whether the game was won.
	 * @static
	 */
	public static addSessionGameResult = (win: boolean): void => {
		// make sure the manager is initialized.
		ReviewManager.initialize();

		// add result
		(ReviewManager._instance as ReviewManager)._reviewProvider.addSessionGameResult(win);
	};
}