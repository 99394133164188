
import { IVariableMap } from './interfaces';

/**
 * The Constraint class implements a minesweeper constraint.
 * @export
 * @class Constraint
 */
export class Constraint {
	/**
	 * Gets the id.
	 * @readonly
	 */
	public get id(): string { return this._id; }

	/**
	 * Gets the mine count.
	 * @readonly
	 */
	public get mineCount(): number { return this._mineCount; }

	/**
	 * Gets the variables count.
	 * @readonly
	 */
	public get variablesCount(): number { return this._variableIds.length; }

	private _id: string;
	private _variableIds: string[];
	private _mineCount: number;
	private _variableMap: IVariableMap;

	/**
	 * Creates an instance of Constraint.
	 * @param id The id.
	 * @param variableIds The variable ids.
	 * @param mineCount The mine count.
	 * @memberof Constraint
	 */
	constructor(id: string, variableIds: string[], mineCount: number) {
		this._variableIds = variableIds.sort((a: string, b: string) => {
			return a > b ? 1 : (a < b ? -1 : 0);
		});
		
		this._id = id;
		this._mineCount = mineCount;
		this._variableMap = this.getVariableMap();
	}

	/**
	 * Determines if the constraint has a given variable Id.
	 * @returns true if the constraint has the variable Id; otherwise, false.
	 */
	public hasVariableId = (variableId: string): boolean => {
		return this._variableMap.mapByName[variableId] !== undefined;
	};

	/**
	 * Gets the variable Ids.
	 * @returns The variable Ids.
	 */
	public getVariableIds = (): string[] => {
		return [...this._variableIds];
	};

	/**
	 * Removes a variable Id and a total value from the constraint.
	 * @param variableId The variable Id to remove.
	 * @param value The mine count value to remove.
	 * @returns true if the variable Id was removed; otherwise, false.
	 */
	public removeVariableId = (variableId: string, value: number): boolean => {
		const result: boolean = this.hasVariableId(variableId);

		if (result) {
			this._variableIds.splice(this._variableMap.mapByName[variableId], 1);
			this._variableMap = this.getVariableMap();
			this._mineCount -= value;
		}

		return result;
	};

	/**
	 * Determines if the constraint is a trivial constraint.
	 * @returns true if the constraint is trivial; otherwise, false.
	 * @remarks A constraint is trivial if the number of variables matches the number of mines; or if the number of mines is zero.
	 */
	public isTrivial = (): boolean => {
		return this._variableIds.length === this._mineCount || this._mineCount === 0;
	};

	/**
	 * Retrieves a string representation of the constraint.
	 * @returns a string representation of the constraint.
	 */
	public toString = (): string => {
		const constraint: string = this._variableIds.map((variableId: string) => `(${variableId})`).join(' + ');

		return `${this._id}: ${constraint} = ${this._mineCount}`;
	};

	/**
	 * Gets the variable map for the constraint.
	 * @returns the variable map.
	 * @private
	 * @memberof Constraint
	 */
	private getVariableMap = (): IVariableMap => {
		const result: IVariableMap = {
			mapByName: {},
			mapByIndex: {},
		};

		for (let i: number = 0; i < this._variableIds.length; i++) {
			result.mapByName[this._variableIds[i]] = i;
			result.mapByIndex[i] = this._variableIds[i];
		}

		return result;
	};
}