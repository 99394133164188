
import { IDBPDatabase } from 'idb';
import { Levels, Style } from '../../../../../../game-to-app/enums';
import { GameLevels } from '../../../../../../game-to-app/game-levels';
import { ILevelDefinition } from '../../../../../../game-to-app/interfaces';
import { Nullable } from '../../../../../../shared/types';
import { Guid } from '../../../../guid';
import { DefaultMetadata } from '../../../default-metadata';
import { ModelNames } from '../../../enums';
import { IModelCacheManager, IModelMetadata, IModelUpgradeManager } from '../../../model-interfaces';
import { CacheManager } from './cache-manager';
import { IModelSavedGameValues } from './model';
import { UpgradeManager } from './upgrade-manager';

/**
 * The Metadata class defines the model metadata.
 */
export class Metadata extends DefaultMetadata<IModelSavedGameValues> implements IModelMetadata<IModelSavedGameValues> {
	protected _name: string = ModelNames.SavedGames;
	protected _schemaVersion: number = 3;
	protected _defaultGameLevel = GameLevels.get(Style.Classic, Levels.Easy) as ILevelDefinition;
	protected _defaults: IModelSavedGameValues[] = [
		{
			...{
				id: Guid.newGuid(),
				info: {
					auto: false,
					level: {
						style: Style.Classic,
						level: Levels.Easy,
						id: '',
						name: '',
						enableFlags: true,
						mines: 0,
						hintLimit: 0,
						dimension: {
							depth: 2,
							height: 2,
							width: 2,
						},
						enabledFaces: {},
					},
					assistantEnabled: false,
					move: null,
				},
				date: new Date(),
				game: null,
			},
			...this.systemValues,
		},
	];

	/**
	 * Initializes the upgrade manager.
	 * @param database The database.
	 * @returns The upgrade manager; otherwise, null.
	 */
	protected initializeUpgradeManager = (database: IDBPDatabase): Nullable<IModelUpgradeManager> => {
		return new UpgradeManager(database, this);
	};

	/**
	 * Initializes the cache manager.
	 * @param database The database.
	 * @returns The cache manager.
	 */
	protected initializeCacheManager = (database: IDBPDatabase): IModelCacheManager => {
		return new CacheManager(database, this);
	};
}