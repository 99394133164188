/**
 * Defines the cell status.
 * @export
*/
export enum CellStatus {
	Covered,
	Uncovered,
	Flagged,
}

/**
 * Defines the solution status.
 * @export
 */
export enum SolutionStatus {
	Mine,
	Clear,
	Unknown,
	Discover,
}

/**
 * Defines the solution calculation method.
 * @export
 */
export enum SolutionCalculationMethod {
	Trivial,
	Calculation,
	Unsolved,
}