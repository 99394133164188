import { IDictionary } from './interfaces';
	
/**
 * The Dictionary<T> class implements a dictionary.
 * @export
 * @class Dictionary
 * @template T The dictionary value type.
 */
export class Dictionary<T> {
	private _dictionary: IDictionary<T> = {};

	/**
	 * Adds a value to the dictionary.
	 * @param key The key.
	 * @param value The value.
	 * @returns true if the value was added; otherwise, false.
	 */
	public add = (key: string, value: T): boolean => {
		const result = !this.has(key);

		if (result) {
			this._dictionary[key] = value;
		}

		return result;
	};

	/**
	 * Sets a value to the dictionary.
	 * Any existing value for the key will be overridden.
	 * @param key The key.
	 * @param value The value.
	 */
	public set = (key: string, value: T): void => {
		this._dictionary[key] = value;
	};

	/**
	 * Removes a value from the dictionary.
	 * @param key The key.
	 * @returns true if the value was removed; otherwise, false.
	 */
	public remove = (key: string): boolean => {
		const result = this.has(key);

		if (result) {
			delete this._dictionary[key];
		}

		return result;
	};

	/**
	 * Retrieves a value from the dictionary.
	 * @param key The key.
	 * @returns The value; otherwise, undefined.
	 */
	public get = (key: string): T | undefined => {
		return this._dictionary[key];
	};

	/**
	 * Determines if the dictionary has a given key.
	 * @param key The key.
	 * @returns true if the dictionary has the key; otherwise, false.
	 */
	public has = (key: string): boolean => {
		return this._dictionary[key] !== undefined;
	};

	/**
	 * Gets the dictionary keys.
	 * @returns The dictionary keys.
	 */
	public keys = (): string[] => {
		return Object.keys(this._dictionary);
	};

	/**
	 * Gets the dictionary values.
	 * @returns The dictionary values.
	 */
	public values = (): T[] => {
		return this.keys().map((key: string): T => this.get(key) as T);
	};
}