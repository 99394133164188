import { SplashScreen } from '@capacitor/splash-screen';
import * as React from 'react';
import { BrowserRouter as Router, Route, RouteComponentProps, Switch } from 'react-router-dom';
import metadata from '../../../metadata/metadata.json';
import { Nullable } from '../../../shared/types';
import { AdManager } from '../../shared/ads/ad-manager';
import { AudioManager } from '../../shared/audio/audio-manager';
import { ErrorCodes, ErrorSource } from '../../shared/error-codes';
import { SubscriptionEvent, SubscriptionEvents } from '../../shared/events/subscription-events';
import { Platform } from '../../shared/platform/platform';
import { ReviewManager } from '../../shared/reviews/review-manager';
import { ShopManager } from '../../shared/shop/shop-manager';
import { Database } from '../../shared/storage/database';
import { Events } from '../../shared/telemetry/events';
import { ICustomProperties } from '../../shared/telemetry/interfaces';
import { Telemetry } from '../../shared/telemetry/telemetry';
import { TelemetryService } from '../../shared/telemetry/telemetry-service';
import { App } from '../app/app';
import { ApplicationStatus, ContentTypeHelp, ContentTypeLegal, ContentTypeLevels, ContentTypeSettings, SplashStyle } from '../shared/enums';
import { Routes } from '../shared/routes';
import { SavedGameManager } from '../shared/savedgame-manager';
import { Splash } from '../splash/splash';
import TelemetryProvider from '../telemetry/telemetry-provider';
import { ErrorBoundary } from './error-boundary/error-boundary';
import { IProps } from './props';
import { RouteListener, RouteLocation } from './route-listener/route-listener';
import { IState } from './state';

// lazy load
const About = React.lazy(() => import('../pages/about/about'));
const AppStore = React.lazy(() => import('../pages/app-store/app-store'));
const Help = React.lazy(() => import('../pages/help/help'));
const HelpContent = React.lazy(() => import('../pages/help/content/help-content'));
const Legal = React.lazy(() => import('../pages/legal/legal'));
const Levels = React.lazy(() => import('../pages/levels/levels'));
const LevelsContent = React.lazy(() => import('../pages/levels/content/levels-content'));
const Profile = React.lazy(() => import('../pages/profile/profile'));
const QuickStart = React.lazy(() => import('../pages/quickstart/quick-start'));
const Settings = React.lazy(() => import('../pages/settings/settings'));
const SettingsContent = React.lazy(() => import('../pages/settings/content/settings-content'));
const Shop = React.lazy(() => import('../pages/shop/shop'));

/**
 * The AppRoute class implements the <AppRoute/> component.
 * @export
 * @class AppRoute
 * @extends {React.Component<IProps, IState>}
 */
export class AppRoute extends React.Component<IProps, IState> {
	/**
	 * Creates an instance of AppRoute.
	 * @param {IProps} props The props.
	 * @memberof AppRoute
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			telemetry: '',
			applicationStatus: ApplicationStatus.Loading,
			splashScreenHidden: true,
		};

		this.initialize();

		// handle event when user leaves the application
		window.addEventListener('beforeunload', this.finalize);
	}

	/**
	 * The event handler for a route change.
	 * @param location The route location.
	 */
	public onRouteChange = (location: RouteLocation): void => {
		// log telemetry event
		Telemetry.event(
			Events.RouteNavigation,
			{ pathName: location.pathname }
		);
	};

	/**
	 * The event handler for a store change.
	 * @param name The store name.
	 */
	public onStoreChange = (name: string): void => {
		// log telemetry event
		Telemetry.event(
			Events.DatabaseStoreChanged,
			{ name: name }
		);
	};

	/**
	 * Renders the <AppRoute/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		let content: React.ReactNode = null;

		// handle splash screen
		this.hideAndroidSplashScreenOnLoaded();

		// get the content to render
		switch (this.state.applicationStatus) {
			case ApplicationStatus.Loading:
				content = this.getLoadScreen();
				break;
			case ApplicationStatus.Running:
				content = this.getRoutes();
				break;
			case ApplicationStatus.Waiting:
				content = this.getErrorScreen(this.state.applicationStatusMessage || [''], true);
				break;
			case ApplicationStatus.Closing:
				content = this.getErrorScreen(this.state.applicationStatusMessage || [''], false);
				break;
		}

		return (
			<Router>
				<TelemetryProvider key={this.state.telemetry} instrumentationKey={this.state.telemetry}>
					{content}
				</TelemetryProvider>
			</Router>
		);
	};

	/**
	 * Gets the route component.
	 * @returns The route component.
	 */
	private getRoutes = (): React.ReactNode => {
		return (
			<div>
				<RouteListener>
					<React.Suspense fallback={<Splash />}>
						<ErrorBoundary
							id='app-errorboundary'
							source={ErrorSource.Application}
							errorCode={ErrorCodes.ApplicationError}
							event={Events.ApplicationError}
						>
							<Route
								path={Routes.Root}
								render={(props: RouteComponentProps): React.ReactNode =>
									<App
										route={{ ...props }}
										id='minescube'
									/>
								}
							/>
						</ErrorBoundary>
					</React.Suspense>
					<React.Suspense fallback={<Splash />}>
						<Switch>
							<Route
								exact
								path={Routes.About}
								render={(props: RouteComponentProps): React.ReactNode =>
									<About
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.Help}
								render={(props: RouteComponentProps): React.ReactNode =>
									<Help route={{ ...props }} />
								}
							/>
							<Route
								exact
								path={Routes.HelpOverview}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Overview}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpPlaying}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Playing}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpControlsTouch}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.ControlsTouch}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpControlsMouse}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.ControlsMouse}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpScore}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Score}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpLevels}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Levels}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpAssistant}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Assistant}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpSettings}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Settings}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpShop}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Shop}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.HelpFeedback}
								render={(props: RouteComponentProps): React.ReactNode =>
									<HelpContent
										contentType={ContentTypeHelp.Feedback}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.QuickStart}
								render={(props: RouteComponentProps): React.ReactNode =>
									<QuickStart route={{ ...props }} />
								}
							/>
							<Route
								exact
								path={Routes.Settings}
								render={(props: RouteComponentProps): React.ReactNode =>
									<Settings route={{ ...props }} />
								}
							/>
							<Route
								exact
								path={Routes.SettingsAssistant}
								render={(props: RouteComponentProps): React.ReactNode =>
									<SettingsContent
										contentType={ContentTypeSettings.Assistant}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.SettingsGame}
								render={(props: RouteComponentProps): React.ReactNode =>
									<SettingsContent
										contentType={ContentTypeSettings.Game}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.SettingsSound}
								render={(props: RouteComponentProps): React.ReactNode =>
									<SettingsContent
										contentType={ContentTypeSettings.Sound}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.Levels}
								render={(props: RouteComponentProps): React.ReactNode =>
									<Levels route={{ ...props }} />
								}
							/>
							<Route
								exact
								path={Routes.LevelsList}
								render={(props: RouteComponentProps): React.ReactNode =>
									<LevelsContent
										contentType={ContentTypeLevels.List}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.LevelsNew}
								render={(props: RouteComponentProps): React.ReactNode =>
									<LevelsContent
										contentType={ContentTypeLevels.New}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.LevelsEdit}
								render={(props: RouteComponentProps): React.ReactNode =>
									<LevelsContent
										contentType={ContentTypeLevels.Edit}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.Terms}
								render={(props: RouteComponentProps): React.ReactNode =>
									<Legal
										contentType={ContentTypeLegal.Terms}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.Privacy}
								render={(props: RouteComponentProps): React.ReactNode =>
									<Legal
										contentType={ContentTypeLegal.Privacy}
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.AppStore}
								render={(props: RouteComponentProps): React.ReactNode =>
									<AppStore
										route={{ ...props }}
									/>
								}
							/>
							<Route
								exact
								path={Routes.Shop}
								render={(props: RouteComponentProps): React.ReactNode =>
									Platform.isAndroid()
										? < Shop
											route={{ ...props }}
										/>
										: <AppStore
											route={{ ...props }}
										/>
								}
							/>
							<Route
								exact
								path={Routes.Profile}
								render={(props: RouteComponentProps): React.ReactNode =>
									<Profile
										route={{ ...props }}
									/>
								}
							/>
						</Switch>
					</React.Suspense>
				</RouteListener>
			</div>
		);
	};

	/**
	 * Gets the load screen.
	 * @returns The load screen.
	 */
	private getLoadScreen = (): React.ReactNode => {
		return (
			Platform.isAndroid() ? null : <Splash />
		);
	};

	/**
	 * Gets the error screen.
	 * @param message The message to show.
	 * @param showSpinner A Boolean value indicating whether to show the spinner.
	 * @returns The load screen.
	 */
	private getErrorScreen = (message: string[], showSpinner: boolean): React.ReactNode => {
		return (
			<Splash
				style={SplashStyle.Custom}
				showSpinner={showSpinner}
				message={message || ['Something went wrong. Please restart the app.']}
			/>
		);
	};

	/**
	 * Hides the Android splashscreen when app is finished loading.
	 */
	private hideAndroidSplashScreenOnLoaded = (): void => {

		// hide the splash screen when loaded
		if (Platform.isAndroid() &&
			this.state.applicationStatus !== ApplicationStatus.Loading &&
			!this.state.splashScreenHidden) {

			SplashScreen.hide({ fadeOutDuration: 200 }).then(
				() => {
					this.setState({ splashScreenHidden: true });
				}
			);
		}
	};

	/**
	 * Initializes the application.
	 */
	private initialize = (): void => {

		Platform.initialize(window.location)
			.then(() => {
				// get telemetry environment
				let telemetry: Nullable<string> = TelemetryService.getEnvironment(
					window.location.toString(),
					Platform.appType.name,
					Platform.appType.appVersion)?.key || null;

				if (!telemetry) {
					// didn't find a matching telemetry environment, use default
					telemetry = TelemetryService.getDefaultEnvironment()?.key || '';

					this.logTelemetryEvent(
						Events.TelemetryDefault,
						{
							location: window.location.toString(),
							appType: Platform.appType.name,
							appVersion: Platform.appType.appVersion,
						});
				}

				this.setState({
					telemetry: telemetry,
				}, () => {
					// initialize
					this.initializeDatabase();
				});
			})
			.catch((error) => {
				this.logTelemetryEvent(
					Events.PlatformError,
					{
						source: ErrorSource.Platform,
						errorCode: ErrorCodes.PlatformInitialization,
						error: error,
					});

				this.setState({
					applicationStatus: ApplicationStatus.Closing,
					applicationStatusMessage: [
						'The application needs to restart.',
						'Please close all instances of the application.',
						`(Error code: ${ErrorCodes.PlatformInitialization})`,
						'The Android application requires a WebView with Chrome version 60 or higher.',
						'Please update Chrome, then restart the application.'
					],
				});
			});
	};

	/**
	 * Initializes the database.
	 */
	private initializeDatabase = (): void => {
		// subscribe to change events
		SubscriptionEvent.subscribe(SubscriptionEvents.RouteChange, this.onRouteChange);
		SubscriptionEvent.subscribe(SubscriptionEvents.GameStatsChanged, this.onStoreChange);
		SubscriptionEvent.subscribe(SubscriptionEvents.LevelsChanged, this.onStoreChange);
		SubscriptionEvent.subscribe(SubscriptionEvents.ProductsChanged, this.onStoreChange);
		SubscriptionEvent.subscribe(SubscriptionEvents.SavedGamesChanged, this.onStoreChange);
		SubscriptionEvent.subscribe(SubscriptionEvents.SettingsChanged, this.onStoreChange);
		SubscriptionEvent.subscribe(SubscriptionEvents.UserPreferencesChanged, this.onStoreChange);

		Database
			.open(this.onDatabaseBlocked, this.onDatabaseBlocking, this.onDatabaseTerminated)
			.catch((error) => {
				this.logTelemetryEvent(
					Events.DatabaseError,
					{
						source: ErrorSource.Route,
						errorCode: ErrorCodes.DatabaseOpenFailed,
						error: error,
					});

				this.setState({
					applicationStatus: ApplicationStatus.Closing,
					applicationStatusMessage: [
						'The application needs to restart.',
						'Please close all instances of the application and reopen.',
						`(Error code: ${ErrorCodes.DatabaseOpenFailed})`
					],
				});

				// rethrow, otherwise fulfilled promise will run.
				throw error;
			})
			.then((result: boolean) => {
				if (result) {
					this.initializeApplication().then((result: boolean) => {
						if (result) {
							this.setState({
								applicationStatus: ApplicationStatus.Running,
							});
						}
						else {
							this.setState({
								applicationStatus: ApplicationStatus.Closing,
								applicationStatusMessage: [
									'The application needs to restart.',
									'Please close all instances of the application and reopen.',
									`(Error code: ${ErrorCodes.ApplicationInitialization})`
								],
							});
						}
					});
				}
				else {
					this.setState({
						applicationStatus: ApplicationStatus.Closing,
						applicationStatusMessage: [
							'The application needs to restart.',
							'Please close all instances of the application and reopen.',
							`(Error code: ${ErrorCodes.DatabaseOpenFailed})`
						],
					});
				}
			}, () => {
				// error already handled
			});
	};

	/**
	 * Initializes the application.
	 * @returns A promise that resolves with a Boolean success value when the application was initialized.
	 */
	private initializeApplication = async (): Promise<boolean> => {
		let result: boolean = true;

		// do not throw from this method. Resolve as false instead.
		try {
			const loadedGame: boolean = await SavedGameManager.loadAutoSavedGame();

			if (loadedGame) {
				this.logTelemetryEvent(Events.AutoSavedGameLoaded);
			}

			// load sound effects
			await AudioManager.load();

			// initialize shop
			ShopManager.initialize();

			// initialize ad manager
			AdManager.initialize();

			// initialize reviews
			ReviewManager.initialize();
		}
		catch (error) {
			result = false;
		}

		return result;
	};

	/**
	 * An event handler for when opening the database is blocked by another instance of the app 
	 * running with a lower version.
	 */
	private onDatabaseBlocked = (): void => {
		this.logTelemetryEvent(
			Events.DatabaseError,
			{
				source: ErrorSource.Route,
				errorCode: ErrorCodes.DatabaseBlocked,
			});

		this.setState({
			applicationStatus: ApplicationStatus.Waiting,
			applicationStatusMessage: [
				`The ${metadata.app} application is updating to a new version.`,
				`Please close other instances of the application to continue.`,
				`(Error code: ${ErrorCodes.DatabaseBlocked})`,
			],
		});
	}

	/**
	 * An event handler for when the current database connection is blocking a version upgrade 
	 * initiated by another instance of the app.
	 */
	private onDatabaseBlocking = (): void => {
		this.logTelemetryEvent(
			Events.DatabaseError,
			{
				source: ErrorSource.Route,
				errorCode: ErrorCodes.DatabaseBlocking,
			});

		this.setState({
			applicationStatus: ApplicationStatus.Closing,
			applicationStatusMessage: [
				'Something went wrong.',
				`Please close all ${metadata.app} instances and reopen.`,
				`(Error code: ${ErrorCodes.DatabaseBlocking})`,
			],
		});
	};

	/**
	 * An event handler for when the current database connection was terminated. 
	 */
	private onDatabaseTerminated = (): void => {
		this.logTelemetryEvent(
			Events.DatabaseError,
			{
				source: ErrorSource.Route,
				errorCode: ErrorCodes.DatabaseTerminated,
			});

		this.setState({
			applicationStatus: ApplicationStatus.Waiting,
			applicationStatusMessage: [
				'Something went wrong.',
				`Please close and reopen this ${metadata.app} instance.`,
				`(Error code: ${ErrorCodes.DatabaseTerminated})`
			],
		});
	};

	/**
	 * An event handler for when the application exits.
	 */
	private onExitApplication = (): void => {
		this.logTelemetryEvent(Events.ExitApplication);

		// unsubscribe
		SubscriptionEvent.unsubscribe(SubscriptionEvents.ExitApplication, this.onExitApplication);
	};

	/**
	 * Finalizes the application.
	 */
	private finalize = (): void => {
		SubscriptionEvent.unsubscribe(SubscriptionEvents.RouteChange, this.onRouteChange);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.GameStatsChanged, this.onStoreChange);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.LevelsChanged, this.onStoreChange);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.ProductsChanged, this.onStoreChange);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.SavedGamesChanged, this.onStoreChange);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.SettingsChanged, this.onStoreChange);
		SubscriptionEvent.unsubscribe(SubscriptionEvents.UserPreferencesChanged, this.onStoreChange);

		SubscriptionEvent.subscribe(SubscriptionEvents.ExitApplication, this.onExitApplication);
		SubscriptionEvent.raise(SubscriptionEvents.ExitApplication);
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		Telemetry.event(
			event.toString(),
			customProperties);
	};
}