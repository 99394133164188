import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { TelemetryService } from '../../shared/telemetry/telemetry-service';
import { IProps } from './props';
import { IState } from './state';

/**
 * The TelemetryProvider class implements the <TelemetryProvider/> component.
 * @export
 * @class TelemetryProvider
 */
class TelemetryProvider extends React.Component<IProps, IState> {

	/**
	 * Creates an instance of TelemetryProvider.
	 * @param {IProps} props The props for the <TelemetryProvider/> component.
	 */
	constructor(props: IProps) {
		super(props);

		this.state = {
			service: null,
		};
	}

	/**
	 * A react lifecycle function for the <TelemetryProvider/> component.
	 */
	public componentDidMount = (): void => {
		const { history } = this.props;
		const { service } = this.state;
		
		if (!service && this.props.instrumentationKey && history) {
			const service: TelemetryService = TelemetryService.create(this.props.instrumentationKey, history);

			this.setState({ service: service });
		}
	};

	/**
	 * Renders the <TelemetryProvider/> component.
	 * @returns The react component.
	 */
	public render = (): React.ReactNode => {
		const { children } = this.props;
		return (
			<Fragment>
				{children}
			</Fragment>
		);
	}
}

export default withRouter(withAITracking(TelemetryService.reactPlugin as ReactPlugin, TelemetryProvider));