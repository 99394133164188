import { AdLoadInfo, AdMob, AdMobError, AdMobInitializationOptions, AdOptions, InterstitialAdPluginEvents } from '@capacitor-community/admob';
import metadata from '../../../../metadata/metadata.json';
import { Nullable } from '../../../../shared/types';
import { Platform } from '../../platform/platform';
import { Events } from '../../telemetry/events';
import { ICustomProperties } from '../../telemetry/interfaces';
import { Telemetry } from '../../telemetry/telemetry';
import { DateUtils } from '../../utils';
import { Constants } from '../constants';
import { AdServeResultType, AdServeStatus, AdType } from '../enums';
import { IAdProvider, IAdServeResult, IServeInterstitialOptions } from '../interfaces';
import { IAdMobOptions } from './interfaces';

/**
 * The AdMobAdProvider class implements an ad provider that serve Google AdMob ads for the mobile app.
 * @export
 * @class AdMobAdProvider
 * @implements {IAdProvider}
 */
export class AdMobAdProvider implements IAdProvider {
	private _nextShowAdDateTime: Date = DateUtils.addSeconds(new Date(), Constants.interstitialAdIntervalSeconds);
	private _isInitialized: boolean = false;
	private _adIsAvailable: boolean = false;
	private _adIsShowing: boolean = false;
	private _adShowStartTime: Nullable<Date> = null;
	private _sequentialFailedToLoad: number = 0;
	private _sequentialFailedToShow: number = 0;
	private _onAdDismissed: Nullable<(duration: number) => void> = null;
	private _onAdFailedToShow: Nullable<(error: AdMobError) => void> = null;
	private _options: IAdMobOptions;
	private _serveStatus: AdServeStatus = AdServeStatus.Active;

	/**
	 * Gets a Boolean value indicating whether the provider is enabled.
	 * @readonly
	 */
	public get isEnabled(): boolean { return this._options.enabled; }

	/**
	 * Creates an instance of AdMobAdProvider.
	 * @memberof AdMobAdProvider
	 */
	public constructor() {
		this._options = this.getInitializationOptions();

		if (this._options.enabled) {
			AdMob.initialize(this._options.initialization as AdMobInitializationOptions).then(() => {
				this.logToConsole(`AdMob initialized`);

				// set state
				this._isInitialized = true;

				// set show time
				this.setNextShowAdDateTime();
				
				// add event listeners
				AdMob.addListener(InterstitialAdPluginEvents.Loaded, this.onAdLoaded);
				AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, this.onAdFailedToLoad);
				AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, this.onAdFailedToShow);
				AdMob.addListener(InterstitialAdPluginEvents.Showed, this.onAdShown);
				AdMob.addListener(InterstitialAdPluginEvents.Dismissed, this.onAdDismissed);

				// prepare the first ad
				AdMob.prepareInterstitial(this._options.ad as AdOptions);
			});
		}

		// serve status
		this.activate(this.isEnabled ? AdServeStatus.Active : AdServeStatus.InActive);
	}

	/**
	 * Activates or deactivates the ad provider.
	 * @param status The ad serve status.
	 * @returns The ad serve status.
	 * @memberof AdMobAdProvider
	 */
	public activate = (status: AdServeStatus): AdServeStatus => {
		if (this.isEnabled) {
			this._serveStatus = status;

			this.logToConsole(`activate with serve status ${AdServeStatus[this._serveStatus]}`);
		}
		
		return this._serveStatus;
	};

	/**
	 * Serves an interstitial ad.
	 * @param options The server options; optional.
	 * @returns A promise that is resolved when the ad is closed.
	 * @memberof AdMobAdProvider
	 */
	public serveInterstitial = (options?: IServeInterstitialOptions): Promise<IAdServeResult> => {
		let promise: Nullable<Promise<IAdServeResult>> = null;

		if (this.isAdReadyToShow()) {

			this.logToConsole(`ready to show interstitial ad`);

			// begin serve callback
			if (options?.onServeBegin) {
				options.onServeBegin();
			}

			promise = new Promise<IAdServeResult>((resolve) => {

				// wait for dismissal of the ad
				this._onAdDismissed = (duration: number) => {

					// prepare result
					const result: IAdServeResult = {
						type: AdType.Interstitial,
						result: AdServeResultType.Dismissed,
						duration: duration,
					};

					// reset
					this._onAdDismissed = null;

					// done callback
					if (options?.onServeDone) {
						options.onServeDone();
					}
					
					// resolve promise
					resolve(result);
				};

				// wait for failed to show event
				this._onAdFailedToShow = (error: AdMobError) => {

					// prepare result
					const result: IAdServeResult = {
						type: AdType.Interstitial,
						result: AdServeResultType.Error,
						duration: 0,
						error: error.message,
					};

					// reset
					this._onAdFailedToShow = null;

					// done callback
					if (options?.onServeDone) {
						options.onServeDone();
					}
					
					// resolve promise
					resolve(result);
				};
			});

			// show the ad after a timeout.
			// the timeout gives the user a chance to see the state prevents inadvertend clicks on the ad.
			window.setTimeout(
				() => AdMob.showInterstitial(),
				Constants.interstitialAdDelayBeforeShowSeconds * 1000);
		}
		else {
			this.logToConsole(`not ready to show interstitial ad`);

			const result: IAdServeResult = {
				type: AdType.Interstitial,
				result: AdServeResultType.NotReady,
				duration: 0,
			};

			promise = new Promise<IAdServeResult>((resolve) => {
				resolve(result);
			});
		}

		return promise as Promise<IAdServeResult>;
	};

	private getInitializationOptions = (): IAdMobOptions => {
		let result: Nullable<IAdMobOptions> = null;

		if (metadata.ads.adMob.enabled) {
			// use real ads only in production mode
			if (Platform.isProductionApplication()) {
				result = {
					enabled: true,
					initialization: {
						initializeForTesting: false,
						requestTrackingAuthorization: false,
						testingDevices: [],
					},
					ad: {
						adId: metadata.ads.adMob.adUnitId,
					},
					minInterstitialAdIntervalSeconds: metadata.ads.adMob.enableTestIntervals
						? Constants.interstitialAdIntervalSecondsTestMode
						: Constants.interstitialAdIntervalSeconds,
					minIntervalSecondsBetweenLoadRetries: Constants.minIntervalSecondsBetweenLoadRetries,
					isTestMode: false,
				};
			}
			else {
				// developer build
				result = {
					enabled: true,
					initialization: {
						initializeForTesting: true,
						requestTrackingAuthorization: false,
						testingDevices: metadata.ads.adMob.testDeviceIds,
					},
					ad: {
						adId: metadata.ads.adMob.testAdUnitId,
					},
					minInterstitialAdIntervalSeconds: Constants.interstitialAdIntervalSecondsTestMode,
					minIntervalSecondsBetweenLoadRetries: Constants.minIntervalSecondsBetweenLoadRetries,
					isTestMode: true,
				};
			}
		}
		else {
			// AdMob provider is not enabled
			result = {
				enabled: false,
			};
		}
		
		return result as IAdMobOptions;
	};

	/**
	 * Determines if an ad is ready to show.
	 * @returns A Boolean value indicating if an ad is ready to show.
	 * @private
	 * @memberof AdMobAdProvider
	 */
	private isAdReadyToShow = (): boolean => {
		return (
			this._options.enabled &&
			this._serveStatus == AdServeStatus.Active &&
			this._isInitialized &&
			this._adIsAvailable &&
			!this._adIsShowing &&
			this._nextShowAdDateTime < new Date());
	}
	/**
	 * The event handler for when the ad was loaded.
	 * @param info The load info.
	 * @private
	 */
	private onAdLoaded = (info: AdLoadInfo): void => {
		this.logToConsole(`Interstitial Ad Loaded ${info.adUnitId}`);

		// set state
		this._adIsAvailable = true;
		this._sequentialFailedToLoad = 0;

		// telemetry
		this.logTelemetryEvent(Events.AdLoaded,	{ unit: info.adUnitId });
	};

	/**
	 * The event handler for then an ad failed to load.
	 * @param error The error.
	 * @private
	 */
	private onAdFailedToLoad = (error: AdMobError): void => {
		this.logToConsole(`Interstitial Ad failed to load ${error.code} ${error.message}`);

		// set state
		this._adIsAvailable = false;
		this._adIsShowing = false;
		this._sequentialFailedToLoad += 1;

		// try loading another ad, give up after the max retry count was reached
		if (this._sequentialFailedToLoad < Constants.maxFailedToLoadRetries) {
			const wait: number = (
				this._sequentialFailedToLoad *
				(this._options.minIntervalSecondsBetweenLoadRetries || Constants.minIntervalSecondsBetweenLoadRetries) * 1000
			);

			window.setTimeout(() => AdMob.prepareInterstitial(this._options.ad as AdOptions), wait);
		}
		else {
			// try again after waiting for one ad interval.
			window.setTimeout(() => {
				this._sequentialFailedToLoad = 0;
				AdMob.prepareInterstitial(this._options.ad as AdOptions);
			}, (this._options.minInterstitialAdIntervalSeconds || Constants.interstitialAdIntervalSeconds) * 1000);
		}

		// telemetry
		this.logTelemetryEvent(Events.AdFailedToLoad, { code: error.code, error: error.message });
	};

	/**
	 * The event handler for when an ad fails to show.
	 * @param error The error.
	 * @private
	 */
	private onAdFailedToShow = (error: AdMobError): void => {
		this.logToConsole(`Interstitial Ad failed to show ${error.code} ${error.message}`);

		// set state
		this._adIsAvailable = false;
		this._adIsShowing = false;
		this._sequentialFailedToShow += 1;

		// try loading another ad, give up after the max retry count was reached
		if (this._sequentialFailedToShow < Constants.maxFailedToShowRetries) {
			const wait: number = (
				this._sequentialFailedToShow *
				(this._options.minIntervalSecondsBetweenLoadRetries || Constants.minIntervalSecondsBetweenLoadRetries) * 1000
			);

			window.setTimeout(() => AdMob.prepareInterstitial(this._options.ad as AdOptions), wait);
		}
		else {
			// try again after waiting for one ad interval.
			window.setTimeout(() => {
				this._sequentialFailedToShow = 0;
				AdMob.prepareInterstitial(this._options.ad as AdOptions);
			}, (this._options.minInterstitialAdIntervalSeconds || Constants.interstitialAdIntervalSeconds) * 1000);
		}

		// raise event
		if (this._onAdFailedToShow) {
			this._onAdFailedToShow(error);
		}

		// telemetry
		this.logTelemetryEvent(Events.AdFailedToShow, { code: error.code, error: error.message });
	};

	/**
	 * The event handler for when an ad is shown.
	 * @private
	 */
	private onAdShown = () => {
		this.logToConsole('Interstitial Ad showed');

		// set state
		this._adIsShowing = true;
		this._adShowStartTime = new Date();
		this._sequentialFailedToShow = 0;

		// telemetry
		this.logTelemetryEvent(Events.AdShown);
	};

	/**
	 * The event handler for when an ad is dismissed.
	 * @private
	 */
	private onAdDismissed = () => {
		this.logToConsole('Interstitial Ad dismissed');

		// calculate duration that the ad was shown for
		const duration = this._adShowStartTime !== null
			? Math.round((new Date().getTime() - this._adShowStartTime.getTime()) / 1000)
			: -1;
		
		// set state
		this._adIsAvailable = false;
		this._adIsShowing = false;
		this._adShowStartTime = null;

		// set show time
		this.setNextShowAdDateTime();

		// load next ad
		AdMob.prepareInterstitial(this._options.ad as AdOptions);

		// raise event
		if (this._onAdDismissed) {
			this._onAdDismissed(duration);
		}

		// telemetry
		this.logTelemetryEvent(Events.AdDismissed, { duration: duration });
	};

	/**
	 * Sets the next show ad date and time.
	 * @private
	 * @memberof AdMobAdProvider
	 */
	private setNextShowAdDateTime = (): void => {
		// set the next show time, no ad will be show before this time was reached
		this._nextShowAdDateTime = DateUtils.addSeconds(
			new Date(),
			this._options.minInterstitialAdIntervalSeconds || Constants.interstitialAdIntervalSeconds);
	};

	/**
	 * Logs a message to the console when in test mode.
	 * @private
	 */
	private logToConsole = (message: string): void => {
		if (this._options.isTestMode && !!message) {
			console.log(message);
		}
	};

	/**
	 * Logs a telemetry event.
	 * @param event The telemetry event.
	 * @param customProperties The custom properties; optional.
	 * @private
	 */
	private logTelemetryEvent = (event: Events, customProperties?: ICustomProperties): void => {
		// augment properties
		const properties = {
			provider: 'AdMob',
			type: 'Interstitial',
			...customProperties
		};

		Telemetry.event(event.toString(), properties);
	};
}

