/**
 * Defines the ad serve result type.
 * @export
 */
export enum AdServeResultType {
	NoProvider,
	NotReady,
	Dismissed,
	Error,
}

/**
 * Defines the types of ads.
 * @export
 */
export enum AdType {
	Interstitial,
}

/**
 * Defines the ad serve status.
 * @export
 */
export enum AdServeStatus {
	Active,
	InActive,
}